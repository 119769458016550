<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-collapse v-model="activeName">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
        <el-collapse-item name="1">
          <template slot="title">
           <h2>退款查詢</h2>
          </template>
        <el-row :gutter="20">
          <el-col :span="7">
              <el-input placeholder="客戶名稱" v-model="qClientUserDisName">
                <template slot="prepend">客戶名稱</template>
              </el-input>
          </el-col>
          <el-col :span="7">
              <el-input placeholder="客戶手機" v-model="qClientUserPhone">
                <template slot="prepend">客戶手機</template>
              </el-input>
          </el-col>
          <el-col :span="8">
              <el-input placeholder="訂單號" v-model="qClientOrderId">
                <template slot="prepend">訂單號</template>
              </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">          
            門市&nbsp;&nbsp;<el-select v-model="qStoreId" :disabled="this.$store.getters.userType!='0'">
              <el-option 
                v-for="item in stores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">          
            處理狀態&nbsp;&nbsp;<el-select v-model="qStatus" style="width:120px;" :disabled="this.$store.getters.userType!='0'">
              <el-option 
                v-for="item in status"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
            <el-col :span="13">
              申請日期
              <el-date-picker v-model="qStartApplyDate" type="date" placeholder="申請時間(起)"></el-date-picker> ~
              <el-date-picker v-model="qEndApplyDate" type="date" placeholder="申請時間(訖)"></el-date-picker>
            </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="8">
            <el-button type="primary" @click="query()">查詢</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
            <el-button type="primary" @click="showCreateForm()">新增</el-button>
            <el-button type="primary" @click="downloadExcel()">下載報表</el-button>
          </el-col>    

        </el-row>
        </el-collapse-item>
      </el-card>
      
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="applyStatusDisName" width="120px" label="狀態">  <template slot-scope="scope">
           <el-tag :type="scope.row.statusType">
            {{scope.row.applyStatusDisName}}
          </el-tag></template></el-table-column>
        <el-table-column   prop="clientOrderId" width="160px"   label="訂單號"></el-table-column>
        <el-table-column   prop="storeDisName"  width="140px" label="店家">  </el-table-column>
        <el-table-column   prop="userDisName" width="120px" label="客戶">  </el-table-column>
        <el-table-column   prop="refund" width="120px" label="退款金額">  </el-table-column>
        <el-table-column   prop="isPlusFee" width="120px" label="手續費">  </el-table-column>
        <el-table-column   prop="clientUserBankName" width="140px" label="銀行">  </el-table-column>
        <el-table-column   prop="clientUserBankBranch" width="140px" label="分行">  </el-table-column>
        <el-table-column   prop="clientUserBankAccount" width="140px" label="退款帳號">  </el-table-column>
        <el-table-column   prop="clientUserName" width="140px" label="退款戶名">  </el-table-column>
        <el-table-column   prop="applyDate" width="140px" label="申請時間">  </el-table-column>
        <el-table-column   prop="updateDate" width="140px" label="更新時間"></el-table-column>
        <el-table-column   prop="updator" width="120px" label="更新人">  </el-table-column>
        <el-table-column   prop="note" width="240px" label="備註"></el-table-column>
        <!-- <el-table-column   prop="adminNote" label="備註"></el-table-column> -->
        <el-table-column   fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
    </el-collapse>
  <!-- 明細 -->
  <el-dialog title=" " :close-on-click-modal="false" :visible.sync="dialogFormVisible" font-size="150%" width="90%" center>
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">明細
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientOrderId}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶帳號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.clientUserId}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.userDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td style="border-style:solid; border-width:1px;">{{form.userPhone}}</td>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款方式:</td>
          <td style="border-style:solid; border-width:1px;">{{form.payTypeDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂金:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.deposit}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="(item, key) in clientOrderTrans" :key="key">
                {{item.updateDate}}-{{item.moneyType}}-{{item.disName}}-({{item.amt}}元)
              </div>
            </td>
          </tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">狀態:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.status}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">退款金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.refund}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">會員退款銀行:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.bankName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">會員退款分行:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.bankBranch}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">會員退款帳號:</td>
          <td  style="border-style:solid; border-width:1px;"> {{form.bankAccount}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">會員退款戶名:</td>
          <td  style="border-style:solid; border-width:1px;"> {{form.clientUserName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">扣手續費:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.isPlusFee}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.reserveNote}}
          </td></tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">財務備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input style="width:400px" v-model="form.finNote" />
            <el-button type="primary" @click="changeFinNote">儲存備註</el-button>
          </td></tr>
          <tr v-show="form.applyStatus != 'F'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">財務處理:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-switch v-model="form.applyStatus" active-color="#13ce66" inactive-color="#ff4949" active-value="R" inactive-value="T" />
            <span v-show="form.applyStatus === 'T'">已處理</span>
            <span v-show="form.applyStatus === 'R'">已退款</span>
            <el-button type="primary" @click="changeApplyStatus">儲存狀態</el-button>
          </td></tr>
          
        </tbody>
      </table>
    </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false" style="font-size:150%">退  出</el-button>
  </div>
</el-dialog>
 <!-- 新增 -->
  <el-dialog title=" " :close-on-click-modal="false" :visible.sync="dialogCreateFormVisible" font-size="150%" width="90%" center>
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">新增退款紀錄
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
          <el-input v-model="creatForm.clientOrderId" placeholder="無訂單退款請留白" @change="checkClientOrderId()"/></td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td style="border-style:solid; border-width:1px;"><el-input v-model="creatForm.userDisName" /></td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td style="border-style:solid; border-width:1px;">  <el-input v-model="creatForm.userPhone" /></td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">退款方式:</td>
          <td style="border-style:solid; border-width:1px;"><el-select v-model="creatForm.payType">
              <el-option v-for="payType in payTypeList" 
              :key="payType.code" :label="payType.disName" :value="payType.code" /></el-select></td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">退款金額:</td>
          <td style="border-style:solid; border-width:1px;">  <el-input v-model="creatForm.refund" /></td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">狀態:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> <el-select v-model="creatForm.status">
              <el-option v-for="item in status" 
              :key="item.code" :label="item.disName" :value="item.code" />
              </el-select></td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">退款銀行:</td>
          <td style="border-style:solid; border-width:1px;"><el-input v-model="creatForm.bankName" /></td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">退款分行:</td>
          <td style="border-style:solid; border-width:1px;"> <el-input v-model="creatForm.bankBranch" /></td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">退款帳號:</td>
          <td style="border-style:solid; border-width:1px;"><el-input v-model="creatForm.bankAccount" /></td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">退款戶名:</td>
          <td style="border-style:solid; border-width:1px;"><el-input v-model="creatForm.clientUserName"  /></td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">手續費另計:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
              <el-checkbox v-model="creatForm.plusFee" true-label="T" false-label="F" checked>
                <span style="font-size:200%;margin-top:20px">扣手續費</span>
                </el-checkbox></td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">財務備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input style="width:400px" v-model="creatForm.finNote" />
          </td></tr>
          
        </tbody>
      </table>
    </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="danger" @click="confirmUploadRefund()" style="font-size:150%">確認新增</el-button>
    <el-button @click="dialogCreateFormVisible = false" style="font-size:150%">退  出</el-button>
  </div>
</el-dialog>
  </div>
</template>


<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      qClientUserPhone:'',qClientOrderId:'',qClientUserDisName:'',qStoreId:'',qStatus:'',
      qStartApplyDate:null,qEndApplyDate:null,
      tableData:[],
      clientOrderTrans: [], // 支付紀錄
      dialogFormVisible: false,
      form: {
        deposit:0,
        note: "",
        finNote: '',
        applyStatus: 'T'
      },
      creatForm:{
        clientOrderId: '',
        payType: 'ATM',
        refund: 0,
        status: 'T',
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        clientUserName: '',
        finNote:'',
        plusFee:'T',
        userDisName:'',
        userPhone:''
      },
      formLabelWidth: '120px',
      stores: [],
      storesMap: [],
      status: [{code:'',disName:'全部'},{code:'T',disName:'已處理'},{code:'R',disName:'已退款'}],
      payTypeList:[{code: 'CASH',disName:'現金'},{code: 'ATM',disName:'轉帳'},{code: 'CREDIT',disName:'信用卡'},{code: 'LINEPAY',disName:'LINE PAY'},{code: 'EZCARD',disName:'悠遊卡'},{code: 'ICP',disName:'iCashPay'}],
      selectStores: [],
      flows:[],
      activeName: '1', // 搜尋手風琴預設
      assignRowBankNo: '',
      assignRowReadFlag: 'Y',
      fullscreenLoading: false,
      isHandle: false,
      dialogCreateFormVisible: false
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>=1){
          this.stores.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e)
        });
        this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
        }        
      });   
  },
  name: 'FinancialRefundQuery',
  methods :{
    query(){
      let _qStartApplyDate = this.qStartApplyDate === null ? '' : moment(this.qStartApplyDate).format('YYYYMMDD');
      let _qEndApplyDate = this.qEndApplyDate === null ? '' : moment(this.qEndApplyDate).format('YYYYMMDD');
      this.fullscreenLoading = true
      let qForm= {page:this.pageCurrent,
       qClientUserPhone:this.qClientUserPhone,   
       qClientOrderId:this.qClientOrderId,
       qStoreId:this.qStoreId,
       qClientUserDisName:this.qClientUserDisName,
       qStatus: this.qStatus,
       qStartApplyDate: _qStartApplyDate,
       qEndApplyDate: _qEndApplyDate
       }
      this.$axios
        .get('../api/financialRefund/getRefunds', {params:qForm}) 
        .then((res)=>{
          this.fullscreenLoading = false
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store=>{
            store.applyDate = this.$date_(store.applyDate)
              this.tableData.push(store)
              store.isPlusFee = store.plusFee === 'T' ? '是' : '否'
              if (store.applyStatus === 'T') {
                store.statusType = 'warning'
                store.applyStatusDisName = '已處理'
              } else if (store.applyStatus === 'R'){
                store.statusType = 'success'
                store.applyStatusDisName = '已退款'
              } else {
                store.applyStatusDisName = '未處理'
                store.statusType = 'danger'
              }
            })
          this.pageTotal=res.data.pageTotal
      }).catch(()=>{
        this.fullscreenLoading = false
          this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' });
      });  
    },
    reset(){
      this.qClientUserPhone=''
      this.qClientOrderId=''
      this.qStoreId=this.stores[0].code
      this.qClientUserDisName=''
      this.query()
    },
    showDetailForm(clientOrder){
      this.fullscreenLoading = true
      this.assignRowBankNo = clientOrder.bankNo
      this.assignRowReadFlag = clientOrder.readFlag
      this.$axios
        .get('../api/financialRefund/getRefundDetail?clientOrderId='+clientOrder.clientOrderId)
        .then((res)=>{
          this.fullscreenLoading = false
          this.dialogFormVisible = true
          this.form = Object.assign({}, this.form, res.data[0])
          if(this.form.plusPrice=='0'||this.form.plusPrice==''){
            this.form.plusPrice = '0'
          }
          this.form.clientOrderId = clientOrder.clientOrderId
          this.form.refund = clientOrder.refund
          this.form.status = clientOrder.applyStatusDisName
          this.form.applyStatus = clientOrder.applyStatus
          this.form.finNote = clientOrder.note
          this.form.bankName = clientOrder.clientUserBankName
          this.form.bankAccount = clientOrder.clientUserBankAccount
          this.form.bankBranch = clientOrder.clientUserBankBranch
          this.form.isPlusFee = clientOrder.plusFee === 'T' ? '是' : '否'
          this.form.clientUserName=clientOrder.clientUserName

          this.clientOrderTrans.splice(0, this.clientOrderTrans.length)
          res.data[0].clientOrderTrans.forEach(e=>{
            if (e.moneyType == 'CASH') {
              e.moneyType = '現金'
            } else if (e.moneyType == 'CREDIT') {
              e.moneyType = '信用卡'
            } else if (e.moneyType == 'LINEPAY') {
              e.moneyType = 'LINE Pay'
            } else if (e.moneyType == 'ATM') {
              e.moneyType = 'ATM 轉帳'
            } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
            } else { e.moneyType = '未知付款方式' }
            e.updateDate=this.$date_(e.updateDate)
            if (e.status !== 'SUCCESS') {
            e.updateDate = e.updateDate + '(付款失敗)'
            }
            this.clientOrderTrans.push(e)
            this.form.payType = e.moneyType
          })
          
          this.form.note = clientOrder.note
          this.form.eDate = this.$date_(this.form.eDate)
          this.form.sDate = this.$date_(this.form.sDate)
          //
          if (clientOrder.moneyType == 'CASH') {
            this.form.payTypeDisName = '現金'
          } else if (clientOrder.moneyType == 'CREDIT') {
            this.form.payTypeDisName = '信用卡'
          } else if (clientOrder.moneyType == 'LINEPAY') {
            this.form.payTypeDisName = 'LINE Pay'
          } else if (clientOrder.moneyType == 'ATM') {
            this.form.payTypeDisName = 'ATM 轉帳'
          } else if (clientOrder.moneyType == 'ICP') {
              this.form.payTypeDisName = 'iCash pay'
            } else { this.form.payTypeDisName = '未知付款方式' }
            
          
      }).catch((res)=>{
        console.log(res)
        this.fullscreenLoading = false
        this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' });
      });
    },
    showCreateForm(){
      this.creatForm = Object.assign({
        clientOrderId: '',
        payType: 'ATM',
        refund: 0,
        status: 'T',
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        clientUserName: '',
        finNote:'',
        plusFee:'T',
        userDisName:'',
        userPhone:''
      })
      this.dialogCreateFormVisible = true
    },
    changeFinNote(){
      this.$confirm('是否確定更改備註?','注意', {
        confirmButtonText: '確定',
        cencelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fullscreenLoading = true
        let pa = {
          clientOrderId: this.form.clientOrderId,
          note: this.form.finNote}
        this.$axios.post('../api/clientUserApplyCancelOrder/changeNote',pa)
          .then(() => {
            this.$alert('備註修改成功','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
            this.query()
          }).catch(() => {
            this.$alert('備註修改失敗','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
          })
      })
    },
    changeApplyStatus(){
      this.$confirm('是否確定更改處理狀態?','注意', {
        confirmButtonText: '確定',
        cencelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fullscreenLoading = true
        let pa = {
          clientOrderId: this.form.clientOrderId,
          applyStatus: this.form.applyStatus}
        this.$axios.post('../api/clientUserApplyCancelOrder/changeApplyStatus',pa)
          .then(() => {
            this.$alert('處理狀態修改成功','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
            this.query()
          }).catch(() => {
            this.$alert('處理狀態修改失敗','結果',{
              confirmButtonText: '確定'
            })
            this.fullscreenLoading = false
          })
      })
    },
    checkClientOrderId(){
      this.$axios.get('../api/financialRefund/checkClientOrderId?clientOrderId='+this.creatForm.clientOrderId).then((res) =>{
        if(res.data.rs !== 'success'){
          this.$alert(res.data.rsMsg,'結果',{confirmButtonText:'確定'})
          this.creatForm.clientOrderId = ''
        } else {
            this.creatForm.userDisName = res.data.data.disName
            this.creatForm.userPhone = res.data.data.phone
        }
      })
    },
    confirmUploadRefund(){
      this.$confirm('是否確定新增退款紀錄','注意', {
        confirmButtonText: '確定',
        cencelButtonText: '取消',
        type: 'warning'
      }).then(() => {
          this.fullscreenLoading = true
          this.$axios.post('../api/financialRefund/uploadRefund',this.creatForm).then((res) =>{
              this.fullscreenLoading = false
              if (res.data.rs === 'success') {
                this.$alert('新增成功', '結果', { confirmButtonText: '確定' })
                this.dialogCreateFormVisible = false
                this.query()
              } else {
                 this.$alert('新增失敗', '結果', { confirmButtonText: '確定' }) 
              }
          }).catch(()=>{
              this.$alert('不知名錯誤', '結果', { confirmButtonText: '確定' }) 
              this.fullscreenLoading = false
          })

      })
    },
    downloadExcel(){
      if (this.qStartApplyDate === null || this.qEndApplyDate === null) {
        this.$alert('請正確填寫日期區間','結果',{confirmButtonText : '確定'})
        return
      }
      
      if ( moment(this.qEndApplyDate) - moment(this.qStartApplyDate) > 90*86400000 ) { // 86400000 一天
        this.$alert('發票開立日期區間不可超過90天', '結果', { confirmButtonText: '確定' });
        return
      }
      
      let _qStartApplyDate = this.qStartApplyDate === null ? '' : moment(this.qStartApplyDate).format('YYYYMMDD');
      let _qEndApplyDate = this.qEndApplyDate === null ? '' : moment(this.qEndApplyDate).format('YYYYMMDD');
      
      
        window.open('../api/financialRefund/downloadExcel?qStoreId='+this.qStoreId
      +'&qClientUserPhone='+this.qClientUserPhone
      +'&qClientOrderId='+this.qClientOrderId
      +'&qClientUserDisName='+this.qClientUserDisName
      +'&qStatus='+this.qStatus
      +'&qStartApplyDate='+_qStartApplyDate
      +'&qEndApplyDate='+_qEndApplyDate)
    },
  }
}
</script>
