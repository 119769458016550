<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="名稱" v-model="qDisName">
              <template slot="prepend">名稱</template>
            </el-input>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col>

      </el-row>      
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="areaId"    label="ID"    width="180">    </el-table-column>
        <el-table-column   prop="disName"    label="名稱" >    </el-table-column>
        <el-table-column    fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
            <!-- <el-button  @click="deleteRow(scope.row)" type="danger" size="small">刪除</el-button> -->
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>

<el-dialog title="編輯視窗" :visible.sync="dialogFormVisible"  >
  <el-form :model="form" ref="editForm" :rules="rules">
    <el-form-item label="ID" :label-width="formLabelWidth">
      <el-input v-model="form.areaId" autocomplete="off" readonly></el-input>
    </el-form-item>
    <el-form-item label="名稱" :label-width="formLabelWidth" prop="disName">
      <el-input v-model="form.disName" autocomplete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditStore">確 定</el-button>
  </div>
</el-dialog>

  </div>
</template>


<script>
export default {
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
    labelPosition: 'right',
        formLabelAlign: {
          disName: '234',
          region: '',
          type: ''
        },
        qDisName:'',
        tableData:[],
        dialogFormVisible: false,
        dialogAuthVisible: false,
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        formLabelWidth: '120px'
      ,rules:{
        disName: [{
          required: true,
          message: '無法為空值',
          trigger: 'blur'
        }],
      }
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
      });
  },
  name: 'AdminArea',
  components: {
  },
  computed:{
  },
  methods :{
    query(){
      this.$axios
        .get('../api/adminArea/getAreas?page='+this.pageCurrent,{})
        .then((res)=>{
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(area=>{this.tableData.push(area)})
          this.pageTotal=res.data.pageTotal
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
      });  
    },
    confirmEditStore(){
      this.$refs['editForm'].validate((valid) => {
          if (valid) {
            let url='../api/adminArea/updateArea'
            if(this.form.areaId=='自動產生'){
              this.form.areaId=undefined;
              url='../api/adminArea/createArea'
            }
            this.$axios
              .post(url,
                this.form)
              .then(()=>{
                this.query()
                this.dialogFormVisible=false
            }).catch((res)=>{
                console.log(res.data);
            });      
          } else {
            console.log('error submit!!');
            return false;
          }
      });      
    },
    showCreateDetailForm(){
      this.form = Object.assign({}, this.form, {areaId:'自動產生',disName:''})
      this.dialogFormVisible=true
    },
    showDetailForm(area){
      this.$axios
        .get('../api/adminArea/getArea?areaId='+area.areaId)
        .then((res)=>{
          this.dialogFormVisible=true

          this.form = Object.assign({}, this.form, res.data)
          // res.data.forEach(store=>{this.tableData.push(store)})
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
      });
    },
    deleteRow(index) {
      console.log(index)
    }
  }
}
</script>
