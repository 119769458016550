<!-- eslint-disable vue/require-v-for-key -->
<template>
<div v-loading.fullscreen.lock="fullscreenLoading">
  <!-- kanban -->
  <el-row :gutter="10" class="vol-row">
    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='return1'?'selectit':'empty'" @click.native="clickCard('return1')">
        當日待還車<span style="color:green;font-size:40px;margin-left:10px;">{{board.return1}}</span>單
      </el-card>
    </el-col>
    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='return2'?'selectit':'empty'" @click.native="clickCard('return2')">
        累積超時租車<span style="color:green;font-size:40px;margin-left:10px;">{{board.return2}}</span>單
      </el-card>
    </el-col>
    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='return3'?'selectit':'empty'" @click.native="clickCard('return3')">
        租車中<span style="color:green;font-size:40px;margin-left:10px;">{{board.return3}}</span>單
      </el-card>
    </el-col>
    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='return4'?'selectit':'empty'" @click.native="clickCard('return4')">
        已還車等扣款<span style="color:green;font-size:40px;margin-left:10px;">{{board.return4}}</span>單
      </el-card>
    </el-col>
  </el-row>  
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="10">
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        地區&nbsp;&nbsp;&nbsp;<el-select v-model="q.qAreaId" clearable placeholder="">
          <el-option v-for="item in areas" :key="item.code" :label="item.disName" :value="item.code">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        店家狀態&nbsp;&nbsp;&nbsp;<el-select v-model="q.qStoreStatus" clearable placeholder="">
          <el-option v-for="item in storeStatusList" :key="item.code" :label="item.disName" :value="item.code">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        店家&nbsp;&nbsp;&nbsp;<el-select v-model="qStoreId_" multiple clearable filterable placeholder=""> <!--複數選擇  修改-->
          <el-option v-for="item in stores" :key="item.code" :label="item.disName" :value="item.code" v-show="displayStore(item)">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        <el-input placeholder="車號" v-model="q.qCarNo">
          <template slot="prepend">車號</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-top:10px;">
      <el-col :span="8">
        <div class="grid-content bg-purple"></div>
        <el-input placeholder="會員帳號或電話或姓名" v-model="q.qPhone">
          <template slot="prepend">會員帳號、電話、姓名</template>
        </el-input>
      </el-col>

      <el-col :span="8">
        <div class="grid-content bg-purple"></div>
        <el-input placeholder="訂單號" v-model="q.qClientOrderId">
          <template slot="prepend">訂單號</template>
        </el-input>
      </el-col>

      <el-col :gutter="20" style="margin-top: 10px;">   
        預計還車日期 
          <el-date-picker v-model="qSDate" type="date"  placeholder="預計還車日期"> </el-date-picker>        
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;
          <el-date-picker v-model="qEDate" type="date"  placeholder="預計還車日期"> </el-date-picker>        
      </el-col> 
  
      
    </el-row>
    <el-row :gutter="20" style="margin-top:10px;">
      <el-col :span="8">
        <el-radio-group v-model="q.qRentDateType" size="medium">
          <el-radio-button label="全部">全部</el-radio-button>
          <el-radio-button label="短租">短租</el-radio-button>
          <el-radio-button label="長租">長租</el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="8">
        <el-radio-group v-model="q.qOrderType" size="medium">
          <el-radio-button label="取車時間先"></el-radio-button>
          <el-radio-button label="還車時間先"></el-radio-button>
        </el-radio-group>
      </el-col>      
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="clickCard(null)">查詢</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="carNo" label="車號" width="100"></el-table-column>
      <el-table-column prop="clientOrderId" label="訂單號" width="160"></el-table-column>
      <el-table-column prop="clientUserDisName" label="客戶"> </el-table-column>
      <el-table-column prop="storeDisName" label="店家"> </el-table-column>
      <el-table-column prop="phone" label="電話" width="120"> </el-table-column>
      <el-table-column prop="realStartDate" label="實際取車日期" width="160"> </el-table-column>
      <el-table-column prop="eDate" label="預計還車日期" width="160" :formatter="formatDate"> </el-table-column>
      <el-table-column prop="flow" label="狀態"> </el-table-column>
      <el-table-column prop="rentDateTypeDisName" label="類別"> </el-table-column>
      <el-table-column prop="prodPriceDisName" label="車類"> </el-table-column>
      <el-table-column prop="reserveNote" label="員工訂單備註"> </el-table-column>
      <el-table-column prop="boundleCreditCard" label="綁定信用卡"><template slot-scope="scope">
          <el-tag :type="scope.row.isBoundleType">
            {{scope.row.isBoundle}}
          </el-tag>
          </template> 
      </el-table-column>
      <el-table-column prop="price" align="right" label="金額"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button @click="showChangeForm(scope.row)" type="primary" size="small" v-show="scope.row.orgFlow=='100'">訂單異動</el-button>
          <el-button @click="showChangeForm(scope.row)" type="primary" size="small" v-show="scope.row.orgFlow=='400'">訂單資料</el-button>
          <el-button v-show="scope.row.orgFlow == '100'" @click="showDetailForm(scope.row)" type="primary" size="small">還車</el-button>
          <el-button v-show="scope.row.orgFlow == '400'" @click="showDetailForm(scope.row)" type="danger" size="small">扣款</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="q.pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="90%">
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">還車視窗
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientOrderId}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.clientUserDisName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td style="border-style:solid; border-width:1px;">{{form.clientPhone}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶出生年月日:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientBirthday}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單管道:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientType | filter_clientType}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車類:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.prodPriceDisName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車型:</td>
          <td style="border-style:solid; border-width:1px;">{{form.catDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車號:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.carNo}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.sDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單還車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.eDate}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.realStartDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際還車時間:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.realEndDate}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">加購保障:</td>
          <td style="border-style:solid; border-width:1px;">{{form.plusInsuranceDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取車預付金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.beforePrice}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">已付訂金:</td>
          <td style="border-style:solid; border-width:1px;">{{form.deposit}}({{form.depositPayMethod}})</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單總金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.price}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">加購金額:</td>
          <td style="border-style:solid; border-width:1px;">{{form.plusPrice}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">尾款:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.prePrice}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">優惠卷名稱:</td>
          <td style="border-style:solid; border-width:1px;">{{form.promotionDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">折扣金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.discount}}</td></tr>

          <tr v-if="form.flow=='1000'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取消原因:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.cancelNote}}</td></tr>

          <tr v-if="enabledDistance=='T'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">租車里程</td>
          <td  style="border-style:solid; border-width:1px;">{{form.startDistance}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">還車里程</td>
          <td style="border-style:solid; border-width:1px;">{{form.endDistance}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶喜好:</td>
          <td style="border-style:solid; border-width:1px;">{{form.adminNote}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">類別:</td>
          <td style="border-style:solid; border-width:1px;">{{(form.rentDateType==='2')?'長租':'短租'}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">安全帽</td>
          <td style="border-style:solid; border-width:1px;">3/4：{{form.accessoriesHelmetFull}}頂，半罩：{{form.accessoriesHelmet}}頂</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">雨衣：</td>
          <td style="border-style:solid; border-width:1px;">{{form.accessoriesRaincoat}}件</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶訂單備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientNote}}</td></tr>    
    
          <tr @click="showClientPic"><td colspan="4" style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶證件:<el-button size="small" v-show="!showPic"  type="primary">顯示</el-button><el-button size="small" v-show="showPic" type="primary">隱藏</el-button></td></tr>
          <tr  v-show="showPic">
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic1"><img :src="pic1" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic2"><img :src="pic2" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic3"><img :src="pic3" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic4"><img :src="pic4" style="width:250px;height:180px;"/></a></td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">歷史紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyBooking" :key="item.clientOrderId" :value="item.clientOrderId">
                下單時間:{{item.createDate}}-備註:{{item.reserveNote}}(訂單號:{{item.clientOrderId}})
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="(item, key) in clientOrderTrans" :key="key">
                {{item.updateDate}}-{{item.moneyType}}-{{item.disName}}-({{item.amt}}元)
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單流程:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyFlow" :key="item.flow" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>
        <!-- </tbody>
      </table> -->
      <tr><td colspan="4" style=" border-style:solid; border-width:1px;">
        <el-row style="margin-top:10px;" >
        </el-row>
        <el-form-item v-show="form.flow=='400'" label="租車時數" :label-width="formLabelWidth" class="title">
            <span class="form-font">{{riddenHours}}小時(約{{riddenDays}}天)</span>
        </el-form-item>
        <el-form-item label="員工訂單備註:" :label-width="formLabelWidth" class="title">
        <el-input v-model="form.reserveNote" autocomplete="off" class="bs" style="width:80%"></el-input>
        <el-button type="primary" @click="updateReserveNote">更新備註</el-button>
      </el-form-item>
        <el-form-item v-show="form.flow=='100' && enabledDistance == 'T' && form.rentDateType == '1'" label="目前里程:" :label-width="formLabelWidth" class="title">
          <el-input v-model="form.nowDistance" autocomplete="off" class="form-font"></el-input>
        </el-form-item>
        <el-form-item v-show="form.flow=='100'" label="" :label-width="formLabelWidth" class="title">
          <el-radio v-model="selectOtherGetDateTime" :label='false' @change="resetPriceList()">使用原來租車時間</el-radio>
          <el-radio v-model="selectOtherGetDateTime" :label='true' @change="resetPriceList()">更改租車時間</el-radio>          
        </el-form-item>
        <el-form-item label="原始租車時間:" :label-width="formLabelWidth" class="title" v-show="selectOtherGetDateTime">
          <el-col :span="6" class="item-label form-font"> {{form.realStartDate}}</el-col>
          <el-col :span="2" class="form-font">異動後</el-col>
          <el-col :span="6">
            <el-date-picker style="width:160px" :clearable="false" :editable="false"  v-model="form.change_sDateDate" type="date" @change="setForceGetPrice" class="fs"></el-date-picker>
          </el-col>
          <el-col :span="4" class="fs">
            <el-time-select style="width:120px" :clearable="false" :editable="false" v-model="form.change_sDateHour" :picker-options="{
                start: '00:00',
                step: '00:10',
                end: '23:50'
              }" placeholder="" @change="setForceGetPrice">
            </el-time-select>
          </el-col>
        </el-form-item>
        
        <el-form-item v-show="form.flow=='100'" label="" :label-width="formLabelWidth" class="title">
          <el-radio v-model="selectOtherReturnDateTime" :label='false' @change="resetPriceList()">使用現在還車時間</el-radio>
          <el-radio v-model="selectOtherReturnDateTime" :label='true' @change="resetPriceList()">更改還車時間</el-radio>          
        </el-form-item>
        <el-form-item label="指定還車時間:" :label-width="formLabelWidth" class="title" v-show="selectOtherReturnDateTime">
          <el-col :span="2" class="form-font">
            異動後
          </el-col>
          <el-col :span="6">
            <el-date-picker style="width:160px" :clearable="false" :editable="false"  v-model="form.change_eDateDate" type="date" @change="setForceGetPrice" class="fs">
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="fs">
            <el-time-select style="width:120px" :clearable="false" :editable="false" v-model="form.change_eDateHour" :picker-options="{
                start: '00:00',
                step: '00:10',
                end: '23:50'
              }" placeholder="" @change="setForceGetPrice">
            </el-time-select>
          </el-col>
        </el-form-item>
        <div>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice==false && form.rentDateType == '1'" label="" :label-width="formLabelWidth" class="title">
            <el-radio v-model="selectSpecDiscount" :label='true' @change="resetPriceList()">使用自訂折扣金額</el-radio>
            <el-radio v-model="selectSpecDiscount" :label='false' @change="resetPriceList()">使用優惠碼</el-radio>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice==false && form.rentDateType == '1'" :label="selectSpecDiscount?'折扣金額:':'優惠卷代碼:'" :label-width="formLabelWidth" class="title">
            <el-select v-show="!selectSpecDiscount" v-model="form.promotionTransRowId" placeholder="選擇優惠券" :disabled="!(form.promotionCode == null || form.promotionCode == '')">
              <el-option v-for="item in form.promotionList" :key="item.promotionTransRowId" :value="item.promotionTransRowId" :label="item.disname"></el-option>
            </el-select>
            <el-input v-show="!selectSpecDiscount" placeholder="輸入優惠碼" v-model="form.promotionCode" autocomplete="off" class="form-font" :disabled="!(form.promotionTransRowId == null || form.promotionTransRowId == '')"></el-input>
            <el-input v-show="selectSpecDiscount" v-model="form.specDiscount" autocomplete="off" class="form-font"></el-input>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice==false && form.rentDateType == '1'" label="票券" :label-width="formLabelWidth" class="title">
            {{ticket.ticketDisname}}
            <el-input v-show="ticket.ticketNo == ''" v-model="form.ticketCode" autocomplete="off" class="form-font"></el-input>
            <el-button  @click="getTicketData()">使用票券</el-button>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice==false && form.rentDateType == '1'" label="加購金額:" :label-width="formLabelWidth" class="title">
            <el-input v-model="form.plusPrice" autocomplete="off" class="form-font"></el-input>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice==false && form.rentDateType == '1'" label="" :label-width="formLabelWidth" class="title">
            <el-button @click="getPriceForReturn()" type="primary" size="small" class="bs" :loading="isCal">{{isCal?'重新計算中':'重新計算'}}</el-button>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice==false && form.rentDateType == '1'" label="建議費用明細:" :label-width="formLabelWidth" class="title">
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && form.rentDateType == '1'" label="租車時數" :label-width="formLabelWidth" class="title">
            <span class="form-font">{{rideHour}}小時(約{{rideDay}}天)</span>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice==false && form.rentDateType == '1'" label="費用小計:" :label-width="formLabelWidth" class="title">
            <div style="font-size:150%;">&emsp;<span style="margin-left:20px;">{{this.form.price}}元</span></div>
            <div :class="priceDetail.class" v-for="(priceDetail,index) in form.priceList" :key="index" class="form-font">
              <span  v-show="index === 1 || index === 0" style=""></span>
              {{priceDetail.name}}
              <span  style="">{{priceDetail.amt}}元</span>
            </div>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && form.deposit > 0 && form.rentDateType == '1'" label="預付訂金:" :label-width="formLabelWidth" class="title red-title"  style="color:red">
            <span class="form-font">{{form.deposit*-1}}元</span>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && form.beforePrice > 0 && form.rentDateType == '1'" label="取車預付:" :label-width="formLabelWidth" class="title red-title"  style="color:red">
            <span class="form-font">{{form.beforePrice*-1}}元</span>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice==false && form.rentDateType == '1'" label="最後付款金額:" :label-width="formLabelWidth" class="title">
            <span class="form-font">
            {{form.payPrice}}元
            </span>
          </el-form-item>
          <el-form-item v-show="form.flow=='100' && form.rentDateType == '1'" label="" :label-width="formLabelWidth" class="title">
            <el-radio v-model="selectSpecPrice" :label='false' @change="resetPriceList()">使用平台計算金額</el-radio>
            <el-radio v-model="selectSpecPrice" :label='true' @change="resetPriceList()">使用自訂金額</el-radio>
          <el-form-item v-show="form.flow=='100' && selectSpecPrice && form.rentDateType == '1'" label="自訂總金額" :label-width="formLabelWidth" class="title">
            <div style="font-size:150%;color:red;">1.使用自訂總金額，折扣金額，優惠卷，加購金額既使有數值將全部不列入計算和不列記錄</div>
            <div style="font-size:150%;color:red;">2.自訂總金額小於會員付訂金額，請自行計算退款金額，並將計算後金額退款給會員</div>
            <el-input  v-model="form.specPrice" autocomplete="off" class="form-font"></el-input>
          </el-form-item>
          </el-form-item>
          <el-form-item v-show="form.rentDateType == '2'" label="長租訂單說明" :label-width="formLabelWidth" class="title">
            <div style="font-size:150%;color:red;">此訂單為長租訂單，完成最後一期扣款視為付款完成</div>
            <div style="font-size:150%;color:red;">押金請使用現金退回</div>
          </el-form-item>
          <el-form-item label="里程說明:" :label-width="formLabelWidth" 
            v-show="form.rentDateType == '2'" class="title">
            <el-input v-model="form.stagePriceNote" autocomplete="off" :disabled="true" class="bs"></el-input>
          </el-form-item>
          <el-form-item label="加購說明:" :label-width="formLabelWidth" 
            v-show="form.rentDateType == '2'" class="title">
            <el-input v-model="form.plusBuyNote" autocomplete="off" :disabled="true" class="bs"></el-input>
          </el-form-item>

          <el-form-item v-show="form.rentDateType == '2'" label="付款期數" :label-width="formLabelWidth" class="title">
            <table>
              <tr><th>付款期數</th><th><el-button size="mini" type="primary" @click="clickAddPayStage()">增加期數</el-button></th></tr>
              <template v-for="(row) in payStageList">
                <tr>
                  <td style="color:red;">付款期數:({{row.seq}})</td>
                  <td>
                    <el-switch :disabled="row.realPay" v-model="row.canPay" inactive-color="red" active-value="Y" inactive-value="N" active-text="開放付款"></el-switch>
                  </td>
                  <td colspan="2">付款時間:
                    <el-date-picker style="width:250px;" :clearable="false" :editable="false" :disabled="!row.canUpdate" v-model="row.chargeDate" type="datetime" placeholder="付款日期"></el-date-picker>
                  </td>
                  <td colspan="3">
                    完成時間:<el-input style="width:200px;" v-model="row.payDate" :disabled="true"></el-input>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>費用:<el-input style="width:80px;" :disabled='!row.canUpdate' v-model="row.price" @blur="recountStagePrice()"></el-input></td>
                  <td>起始里程:<el-input :disabled='true' style="width:80px;"  v-model="row.startDistance" @blur="recountStagePrice()"></el-input></td>
                  <td>結束里程:<el-input style="width:80px;" :disabled='row.realPay' v-model="row.endDistance" @blur="recountStagePrice()"></el-input></td>
                  <td>差異:{{row.diffDistance}}</td>
                </tr>
                <tr>
                  <td>前台費用說明({{row.seq}}):</td>
                  <td colspan="6"><el-input :disabled='!row.canUpdate' v-model="row.note"></el-input></td>
                </tr>
                <tr>
                  <td>轉帳末5碼({{row.seq}}):</td>
                  <td colspan="6"><el-input v-model="row.moneyTypeExtend"></el-input></td>
                </tr>
                <tr>
                  <td>員工備註說明({{row.seq}}):</td>
                  <td colspan="5"><el-input :disabled='!row.canUpdate' v-model="row.adminNote"></el-input></td>
                  <td>
                    <el-button v-show="row.canAdd" @click="confirmAddPayStage(row)" type="success">新增</el-button>
                    <el-button @click="updateRentDateTypeLongPayStage(row.rowId)" type="primary" v-show="row.canUpdate && !row.canAdd ">更新</el-button>
                    <el-button @click="deleteRentDateTypeLongPayStage(row.rowId)" type="danger" v-show="row.canUpdate && !row.canAdd && row.realPay">刪除</el-button>
                    <el-button @click="confirmClientOrder100ByStagePay(row.rowId, 'credit')" type="danger" v-show="!row.realPay">信用卡扣款</el-button>
                    <el-button @click="confirmClientOrder100ByStagePay(row.rowId, 'cash')" type="info" v-show="!row.realPay">現金扣款</el-button>
                    <el-button @click="confirmClientOrder100ByStagePay(row.rowId, 'ATM')" type="info" v-show="!row.realPay">轉帳收款</el-button>
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="2"></td>
                <td colspan="2">目前總費用:</td>
                <td>{{stageTotalPrice}}元(不含押金)</td>
                <td colspan="3"></td>
              </tr>
            </table>
          </el-form-item>
        </div>
        <el-form-item v-show=" form.flow=='400' && form.rentDateType == '1'" label="轉帳末5碼" :label-width="formLabelWidth" class="title">
          <el-input v-model="form.moneyTypeExtend" autocomplete="off" class="form-font"></el-input>
        </el-form-item>
        <el-form-item v-show="form.flow=='100' || form.flow=='400'" label="員工碼" :label-width="formLabelWidth" class="title">
          <el-input v-model="form.checkCode" autocomplete="off" show-password class="form-font"></el-input>
        </el-form-item></td>
      </tr>
        <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">開立發票:<br/>(留白則為個人發票)</td>
          <td colspan="3" style="border-style:solid; border-width:1px;" >
            <div>
              <el-switch v-show="form.flow=='400' || (form.rentDateType == '2' && form.flow=='100')" v-model="form.doInvoice" 
                active-color="#13ce66" inactive-color="#ff4949" active-value="true" inactive-value="false" active-text="開立發票"  @change="changeInvoice()"
                :disabled="form.lockInvoiceInfo">
              </el-switch>
              <div v-show="form.doInvoice === 'true'">
              統一編號:<el-input style="width:200px;" v-model="form.vatNo" :disabled="form.lockInvoiceInfo"></el-input><br/>
              公司抬頭:<el-input style="max-width:600px;"  v-model="form.companyTitle" :disabled="form.lockInvoiceInfo"></el-input>
              </div>
              <div v-show="form.doInvoice === 'true'">
              載具編號:<el-input style="width:200px;" v-model="form.carrierNum" :disabled="form.lockInvoiceInfo"></el-input><br/>
              </div>
            </div>
          </td>
        </tr>
        <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">亞洲萬里通</td>
          <td colspan="3" style="border-style:solid; border-width:1px;" >
            <div>
              <div>
              會員卡號:<el-input style="max-width:600px;"  v-model="form.asiaMilesId" :disabled="form.lockInvoiceInfo"></el-input>
              </div>
            </div>
          </td>
          </tr>
        </tbody>
      </table>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false" class="bs">取 消</el-button>
      <el-button v-show="form.flow=='400' && form.boundleCreditCard === 'T' && form.rentDateType == '1'" type="primary" @click="updateClientOrder500ByBoundleCreditCardPay()" class="bs">信用卡扣款</el-button>
      <el-button v-show="form.flow=='400' && form.rentDateType == '1'" type="primary" @click="updateClientOrder500ByCashFrom400()" class="bs">現金收款</el-button>
      <el-button v-show="form.flow=='400' && form.rentDateType == '1'" type="primary" @click="updateClientOrder500ByATMFrom400()" class="bs">轉帳收款</el-button>
      <el-button v-show="form.flow=='100'" type="primary" @click="updateClientOrder400WaitBoundleCreditCardPay()" class="bs">確定還車,等待收款</el-button>
      <!-- <el-button v-show="form.flow=='100'" type="primary" @click="updateClientOrder500ByCashFrom100()" class="bs">現金收款和開立發票</el-button> -->
    </div>
  </el-dialog>

  <!-- 異動視窗 -->
  <el-dialog title="" :close-on-click-modal="false" :visible.sync="dialogFormChangeVisible" width="90%">
    <el-form :model="form" style=" border-spacing:0px; font-size:150%; text-align:center; ">訂單異動視窗
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px; width:100%">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientOrderId}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.clientUserDisName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td style="border-style:solid; border-width:1px;">{{form.clientPhone}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶出生年月日:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientBirthday}}</td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.realStartDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車類:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.prodPriceDisName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車型:</td>
          <td style="border-style:solid; border-width:1px;">{{form.catDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車號:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.carNo}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">預計還車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.eDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單管道:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientType | filter_clientType}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取車預付金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.beforePrice}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;"></td>
          <td style="border-style:solid; border-width:1px;"></td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂金:</td>
          <td style="border-style:solid; border-width:1px;">{{form.deposit}}({{form.depositPayMethod}})</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單總金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.price}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶喜好:</td>
          <td style="border-style:solid; border-width:1px;">{{form.adminNote}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">類別:</td>
          <td style="border-style:solid; border-width:1px;">{{(form.rentDateType==='2')?'長租':'短租'}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">安全帽</td>
          <td style="border-style:solid; border-width:1px;">3/4：{{form.accessoriesHelmetFull}}頂，半罩：{{form.accessoriesHelmet}}頂</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">雨衣：</td>
          <td style="border-style:solid; border-width:1px;">{{form.accessoriesRaincoat}}件</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶訂單備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientNote}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">員工訂單備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input v-model="form.reserveNote" autocomplete="off" class="bs" style="width:80%"></el-input>
            <el-button type="primary" @click="updateReserveNote">更新備註</el-button>
          </td></tr>
          

          <tr @click="showClientPic"><td colspan="4" style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶證件:<el-button size="small" v-show="!showPic" type="primary">顯示</el-button><el-button size="small" v-show="showPic" type="primary">隱藏</el-button></td></tr>
          <tr  v-show="showPic">
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic1"><img :src="pic1" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic2"><img :src="pic2" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic3"><img :src="pic3" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic4"><img :src="pic4" style="width:250px;height:180px;"/></a></td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">歷史紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyBooking" :key="item.clientOrderId" :value="item.clientOrderId">
                下單時間:{{item.createDate}}-備註:{{item.reserveNote}}(訂單號:{{item.clientOrderId}})
              </div>
            </td>
          </tr>


          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單流程:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyFlow" :key="item.updateDate" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>
          <tr v-show="form.flow!='400'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">修改車號:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;" >
              <div>               
                <span>原本車輛:</span> <br/>
                <span class="margin-right-20px">車號:{{form.carNo}}</span>
                <span class="margin-right-20px">起始里程:{{form.startDistance}}</span> 
                <span class="margin-right-20px">結束里程:
                  <el-input class="distance-input margin-right-20px" v-model.number="originCarEndDistance" type="text" @blur="originMinusMile"></el-input>
                </span>
                <span class="margin-right-20px" >已使用里程:{{differenceDistance.toString()}}</span>
              </div> 
              <br/>
              <div>               
                <span>異動後車輛:</span> <br/>
                <div>
                  異動後車號: 
                  <el-select class="distance-input" v-model="changeAvailableCarNo" clearable @blur="selectCarNo">
                      <el-option width=120
                        v-for="item in availableCarNos"
                        :key="item.carNo"
                        :label="item.carNo"
                        :value="item.prodId">
                      </el-option>
                  </el-select> 
                  <span v-show="changeEnabledDistance == 'T'">
                    起始里程:
                    <el-input class="distance-input margin-right-20px" v-model.number="changeAvailableCarDistance" @change="changeMinusMile" placeholder='0'></el-input>
                  </span>
                  <span>
                    異動後起始里程: {{changeCarStartDistance}}
                  </span>
                  <el-button style="float:right;" type="primary" @click="confirmChangeCarNo()" class="bs">確定修改車號</el-button>
                </div>
              </div>
            </td>
          </tr>
          <tr v-show="form.flow!='400'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取消訂單:<br/>0元訂單不開發票</td>
            <td colspan="3" style="border-style:solid; border-width:1px;" >
              <div>
                統一編號:<el-input style="width:200px;" v-model="changeReturnPayVatNo" ></el-input>(留白則為個人發票)<br/>
                公司抬頭:<el-input style="max-width:600px;"  v-model="changeReturnPayVatCompanyTitle" ></el-input>
              </div>
              <div>
                載具編號:<el-input style="width:200px;" v-model="changeReturnPayCarrierNum" ></el-input><br/>
              </div>
              <div>
                <el-checkbox v-model="form.plusFee" true-label="T" false-label="F" checked>
                <span style="font-size:200%;margin-top:20px">扣手續費</span>
                </el-checkbox>
              </div>
              <div>申請取消日期:{{form.applyDate}}</div>
              <div>退款銀行名稱：{{form.clientUserBankName}}</div>
              <div>退款銀行分行：{{form.clientUserBankBranch}}</div>
              <div>退款銀行帳戶：{{form.clientUserBankAccount}}</div>
              <div>訂金:{{form.deposit}}元({{form.depositPayMethod}})</div>
              <div>
                退款金額:
                  <el-input class="distance-input" v-model.number="changeReturnPay" ></el-input>元
              </div>
              <div>
                取消原因:<el-input style="width:450px;" v-model="changeCancelNote"></el-input>
                <el-button style="float:right;" type="primary" @click="confimrChangeReturnPay()" class="bs">確定取消訂單</el-button>
              </div>
            </td>
          </tr>
          <tr v-show="form.flow!='400'">
            <td colspan="4" style=" border-style:solid; border-width:1px;">
              <el-row style="margin-top:10px;" >
              </el-row>
              <el-form-item label="原始還車日期:" :label-width="formLabelWidth" class="title">
                <el-col :span="6" class="item-label form-font">
                  {{form.eDate}}
                </el-col>
                <el-col :span="2" class="form-font">
                  異動後
                </el-col>
                <el-col :span="6">
                  <el-date-picker style="width:160px" :clearable="false" :editable="false" 
                  v-model="form.change_eDateDate" type="date" @change="setForceGetPrice" class="fs">
                  </el-date-picker>
                </el-col>
                <el-col :span="4" class="fs">
                  <el-time-select style="width:120px"
                  :clearable="false"
                  :editable="false"
                  v-model="form.change_eDateHour" :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '23:00'
                    }" placeholder="" @change="setForceGetPrice">
                  </el-time-select>
                </el-col>
              </el-form-item>
              <div>
                <el-form-item label="" :label-width="formLabelWidth">
                  <el-button @click="getPriceForChange()" type="primary" size="small" class="bs" :loading="isCal">{{isCal?'重新計算中':'重新計算'}}</el-button>
              </el-form-item>
              <el-form-item  label="費用明細:" :label-width="formLabelWidth" class="title">
                <div style="font-size:150%;">&emsp;費用小計:<span style="margin-left:20px;">{{this.form.price}}元</span></div>
                <div v-for="(priceDetail,index) in form.priceList" :key="index" class="form-font">
                  <span v-show="index === 1" style="margin-left:20px;"></span>
                  {{priceDetail.name}}
                  <span :class="priceDetail.class" style="">{{priceDetail.amt}}元</span>
                </div>
              </el-form-item>  
              <el-form-item v-show="form.deposit > 0" label="預付訂金:" :label-width="formLabelWidth" class="title red-title"  style="color:red">
                <span class="form-font">{{form.deposit*-1}}元</span>
              </el-form-item>
              <el-form-item v-show="form.beforePrice > 0" label="取車預付:" :label-width="formLabelWidth" class="title red-title"  style="color:red">
                <span class="form-font">{{form.beforePrice*-1}}元</span>
              </el-form-item>
              <el-form-item label="預估金額:" :label-width="formLabelWidth" class="title">
                  <span style="font-size:150%;">{{form.detailPrice}}元</span>
                </el-form-item>
              </div>
              <!-- <div v-if="form.rentDateType==='2'">
                <el-form-item label="金額:" :label-width="formLabelWidth" class="title">
                  <el-input  v-model="form.detailPrice"></el-input>
                </el-form-item>
              </div> -->
              <el-form-item label="員工碼:" :label-width="formLabelWidth" v-show="form.flow=='50' && dialogFormVisible==true" class="title">
                <el-input v-model="form.checkCode" autocomplete="off"  show-password class="fs"></el-input>
              </el-form-item>
              <el-button style="float:right;" type="primary" @click="confirmChangeClientOrder()" class="bs">確定異動日期</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormChangeVisible = false" class="bs">取 消</el-button>
    </div>
  </el-dialog>
</div>
</template>

<style lang="scss" scoped >
 .title > :first-child{
   font-size: 70%;
 }
 .fs > :first-child{
   font-size: 130%;
 }
 .bs > :first-child{
   font-size: 150%;
 }
 .distance-input{
   width:180px;
 }
 .distance-input ::v-deep .el-input__inner{
   text-align: right;
   font-size: 150%;
 }
 .red-title ::v-deep .el-form-item__label {
  color:red !important;
}
</style>

<script>
import * as moment from "moment/moment";

export default {
  data() {
    return {
      stageTotalPrice: 0,
      payStageList:[],
      board: { return1:0, return2:0, return3:0, return4:0 },
      emptyQty:0,
      pageTotal: 0,
      sysTime: '',
      pic1:null,
      pic2:null,
      pic3:null,
      pic4:null,
      historyBooking:[],
      historyFlow:[],
      clientOrderTrans: [],
      qSDate:'',
      qEDate:'',
      q:{qAreaId:'',qPhone: '', qStoreStatus:'', qStoreId: '',qCarNo: '',qType:null,pageCurrent: 1,qSDate:'',qEDate:'',qClientOrderId:null,qOrderType:'取車時間先',qRentDateType:'全部'},
      qStoreId_:'',
      storeStatusList:[
        {code:'', disName:'全部'},
        {code:'1', disName:'營業中'},
        {code:'0', disName:'停業'},
      ],
      tableData: [],
      dialogFormVisible: false,
      dialogFormChangeVisible: false,
      selectOtherReturnDateTime: false,
      selectOtherGetDateTime: false,
      selectSpecDiscount: true,
      selectSpecPrice: false,
      form: {
        stagePriceNote:'',
        plusBuyNote:'',
        eDate: '',
        realStartDate:'',
        realEndDate: '',
        payPrice: '',
        plusPrice: '',
        nowDistance: '',
        startDistance: '',
        checkCode: '',
        priceList:[],
        boundleCreditCard:'F',
        adminNote:'',
        clientNote:'',
        reserveNote:'',
        deposit:0,
        depositPayMethod: '',
        change_sDateDate:'',
        change_sDateHour:'',
        change_eDateDate:'',
        change_eDateHour:'',
        rentDateType:'1',
        vatNo: '',
        companyTitle: '',
        isrPrice: 0,
        doInvoice: 'true',
        promotionCode:'',
        promotionTransRowId: '',
        discount: 0,
        specPrice: 0,
        oldPrice: 0,
        accessoriesHelmet: 0,
        accessoriesHelmetFull: 0,
        accessoriesRaincoat: 0,
        beforePay: 'F',
        beforePrice: 0,
        beforePayMethod: '',
        carrierNum:'',
        lockInvoiceInfo: false,
        moneyTypeExtend: '',
        asiaMilesId: '',
        ticketCode: ''
      },
      rideHour: '' ,
      rideDay: '',
      formLabelWidth: '139px',
      areas: [],
      stores: [],
      changeCarNo: ['1'],
      availableCarNos: [],
      changeAvailableCarNo: '',
      changeAvailableCarDistance: 1,
      changeReturnPay: 0,
      changeReturnPayVatNo: '',
      changeReturnPayVatCompanyTitle: '',
      changeReturnPayCarrierNum:'',
      changeCancelNote: '',
      fullscreenLoading: false,
      isCal: false,
      switchInvoice: true,
      showPic: false,
      changeEnabledDistance: 'T',
      enabledDistance: 'T',
      riddenHours:'',
      riddenDays:'',
      ticket: {
        ticketNo: '',
        ticketDisname: '',
        ticketDiscount: ''
      },
      originCarEndDistance:0, // 原本車輛的結束里程
      differenceDistance:0,  // 差額里程
      changeCarStartDistance:0  // 異動後起始里程
    }
  },
  created() {
   //
  },
  mounted() {
    if(this.$route.query.qType != undefined){
      this.q.qType=this.$route.query.qType
    }
    if(this.$route.query.qStoreId != undefined && this.$route.query.qStoreId != ''){
      this.q.qStoreId = this.$route.query.qStoreId
      this.qStoreId_ = []
      let tempStore = this.$route.query.qStoreId.split(',')
      tempStore.forEach(ele => {
        this.qStoreId_.push(parseInt(ele))
      })
    }
    if(this.$route.query.clientOrderId != undefined){
      this.q.qClientOrderId=this.$route.query.clientOrderId
    }
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        if(res.data.areas.length>1){
          this.areas.push({code:'',disName:'全部'})
        }
        res.data.areas.forEach(e => {
          this.areas.push(e)
        });
        this.q.qAreaId = this.areas[0].code
        /*if(res.data.authStores.length>1){
          this.stores.push({code:null,disName:'全部'})  //
        }*/
        res.data.authStores.forEach(e => {
          // this.stores.push(e)
          this.stores.push({
            code:e.code,
            disName:e.disName,
            status:e.status,
            areaId:e.areaId
          })
        });

        this.q.qStoreId=this.stores[0].code
        this.clickCard(this.q.qType)
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
      this.originCarEndDistance = this.form.startDistance
  },
  watch:{
    'form.startDistance': function(newVal){
      this.originCarEndDistance = newVal
    }
  },
  name: 'AdminQuickReturn',
  components: {},
  computed: {},
  methods: {
    clickAddPayStage(){
      this.payStageList.push({seq: (this.payStageList.length) ,
      price:'0',chargeDate: moment().add(1,'days').add(1,'hours').startOf('hour').format('YYYY-MM-DD HH:mm:ss'), 
      canAdd: true,
      canUpdate: true,
      moneyTypeExtend: null,
      realPay:true
    })
    },
    confirmAddPayStage(row){
      if(row.price==null || /\\D/.test(row.price)) {
        this.$alert('數字填寫不正確', '結果', { confirmButtonText: '確定' });
        return
      }
      this.fullscreenLoading = true
      let pa = {
        price: row.price,
        clientOrderId: this.form.clientOrderId,
        note: row.note,
        adminNote: row.adminNote,
        canPay: row.canPay,
        chargeDate: moment(row.chargeDate).format('YYYYMMDDHHmmss'),
      }
      let url = '../api/clientOrderQuickReturn/confirmAddPayStage'
      this.$axios.post(url, pa).then((res) => {
        this.fullscreenLoading = false
        if(res.data.rs === 'success') {
          this.payStageList = []
          this.getClientRentDateLongPayStageList(this.form.clientOrderId)
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
        } else {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
        }
      }).catch(() => {
        this.fullscreenLoading = false
        this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
      });
    },
    deleteRentDateTypeLongPayStage(clientOrderStageTransRowId) {
      let pa = {
        clientOrderStageTransRowId: clientOrderStageTransRowId,
      }
      this.fullscreenLoading = true
      let url = '../api/clientOrderQuickReturn/deleteRentDateTypeLongPayStage'
      this.$axios.post(url, pa).then((res) => {
        this.fullscreenLoading = false
        if(res.data.rs === 'success') {
          this.payStageList = []
          this.getClientRentDateLongPayStageList(this.form.clientOrderId)
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
        } else {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
        }
      }).catch(() => {
        this.fullscreenLoading = false
        this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
      })
    },
    updateRentDateTypeLongPayStage(clientOrderStageTransRowId){ // 更新某期的金額請款日期與說明
      let pa = {
        clientOrderStageTransRowId: clientOrderStageTransRowId,
      }
      for(let row of this.payStageList) {
        if(row.rowId == clientOrderStageTransRowId) {
          pa.price = row.price
          pa.note = row.note
          pa.adminNote = row.adminNote
          pa.endDistance = row.endDistance
          pa.canPay = row.canPay
          pa.chargeDate = moment(row.chargeDate).format('YYYY-MM-DD HH:mm:ss') 
          break;
        }
      }
      this.fullscreenLoading = true
      let url = '../api/clientOrderQuickReturn/updateRentDateTypeLongPayStage'
      this.$axios.post(url, pa).then((res) => {
        this.fullscreenLoading = false
        if(res.data.rs === 'success') {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          this.recountStagePrice()
        } else {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
        }
      }).catch(() => {
        this.fullscreenLoading = false
        this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
      });
    },
    recountStagePrice () {
      if(this.form.rentDateType == '2') {
        let price = 0
        this.payStageList.forEach(e=>{
          if(e.stageIndex > 0){
            if(e.price != null && /^\d+$/.test(e.price)) {
              price += parseInt(e.price)
            }
            
            if(e.endDistance != null && typeof e.endDistance  == 'string' && /^\d+$/.test(e.endDistance)){
              e.endDistance = parseInt(e.endDistance)
              e.diffDistance = e.endDistance - e.startDistance
            }else if(e.endDistance != null && typeof e.endDistance  == 'number'){
              e.diffDistance = e.endDistance - e.startDistance
            }
          }
        })
        this.stageTotalPrice = price
      } else {
        this.stageTotalPrice = 0
      }
    },
    changeInvoice(){
      this.switchInvoice =this.form.doInvoice === 'true'
    },
    confimrChangeReturnPay () {
      if (/\D/g.test(this.changeReturnPay) || this.changeReturnPay === '') {
        this.$alert('請輸入有效金額','結果',{confirmButtonText:'確定'})
        return
      } else if (parseInt(this.changeReturnPay) > this.form.deposit) {
        this.$alert('退款金額不可大於訂金','結果',{confirmButtonText:'確定'})
        return
      }
      this.$confirm('是要取消訂單呢?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (!(this.changeReturnPayVatNo == ''|| this.changeReturnPayVatNo == null) || this.changeReturnPayVatCompanyTitle != '') {
            if(this.changeReturnPayVatNo.length != 8){
              this.$alert('請填寫正確統一編號','注意',{confirmButtonText:'確定'})
              return
            } else if (this.changeReturnPayVatCompanyTitle.length == 0 || this.changeReturnPayVatCompanyTitle.length >= 60){
              this.$alert('公司抬頭字元長度需介於1~60個字元','注意',{confirmButtonText:'確定'})
              return
            }
            if (this.changeReturnPayCarrierNum != ''){
              this.$alert('打統編發票不可存載具','注意',{confirmButtonText:'確定'})
              return
            }
          }
          if (this.changeReturnPayCarrierNum !== '') {
            if (!this.changeReturnPayCarrierNum.match(/^\/[\dA-Z0-9+-.]{7}$/)) {
              this.$alert('手機載具號碼格式不合','注意',{confirmButtonText:'確定'})
              return
            }
          }
          let _depositPayMethod = 'CASH'
            if (this.form.depositPayMethod === '信用卡') {
              _depositPayMethod = 'CREDIT'
            }else if (this.form.depositPayMethod === 'LINE PAY'){
              _depositPayMethod = 'LINEPAY'
            }else if (this.form.depositPayMethod === '悠遊卡'){
              _depositPayMethod = 'EZCARD'
            }else if (this.form.depositPayMethod === '轉帳'){
              _depositPayMethod = 'ATM'
            }
          this.fullscreenLoading = true
          let pa = {
            clientOrderId: this.form.clientOrderId,
            returnPay: this.changeReturnPay,
            vatNo: this.changeReturnPayVatNo,
            vatCompanyTitle: this.changeReturnPayVatCompanyTitle,
            carrierNum: this.changeReturnPayCarrierNum,
            cancelNote: this.changeCancelNote,
            plusFee: this.form.plusFee,
            returnType: _depositPayMethod,
            clientUserBankAccount: this.form.clientUserBankAccount
          };
          let url = '../api/clientOrderQuickReturn/returnClientOrderEntirePay'
          this.$axios.post(url, pa).then((res) => {
            this.fullscreenLoading = false
            if(res.data.rs === 'success') {
              this.query()
              this.dialogFormChangeVisible = false
            }
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }).catch(() => {
            this.fullscreenLoading = false
            this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
          });
        }).catch(() => {
          // 
      }); 
    },
    confirmChangeCarNo () {
      if (this.changeEnabledDistance == 'F') {
        this.changeAvailableCarDistance = 0
      }
      if (this.changeAvailableCarNo == '' | this.changeAvailableCarNo == null) {
        this.$alert('請選擇車號', '結果', { confirmButtonText: '確定' });
        return
      }
      if (this.changeAvailableCarDistance <= 0 && this.changeEnabledDistance == 'T') {
        this.$alert('里程數不對', '結果', { confirmButtonText: '確定' });
        return
      } 
      let param = {
        clientOrderId: this.form.clientOrderId,
        distance: this.changeAvailableCarDistance, // 原車輛起始里程
        changeDistance: this.changeCarStartDistance, //異動起始里程
        prodId: this.changeAvailableCarNo
      }
      this.$confirm('是否確定修改車號','注意',{
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickReturn/changeClientOrderCarNo'
          this.$axios.post(url, param).then((res) => {
            this.fullscreenLoading = false
              if (res.data.rs === 'success') {
                this.$alert('修改成功', '結果', { confirmButtonText: '確定' })            
                this.query()
                this.dialogFormChangeVisible = false
              }else{
                this.$alert(res.data.errMsg, '結果', { confirmButtonText: '確定' })
              }
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('發生不知名錯誤', '結果', { confirmButtonText: '確定' })
            });
        })
    },
    confirmChangeClientOrder(){
      if(this.form.rentDateType !=='2' && this.form.detailPrice === null){
        this.$alert('短租訂單請先計算金額', '結果', { confirmButtonText: '確定' })
        return
      }
      if(this.form.rentDateType === '2') {
        if(!/^\d+$/g.test(this.form.detailPrice)){
          this.$alert('長租訂單金輸入異動金額或金額是格式不對', '結果', { confirmButtonText: '確定' })
          return
        }
      }
      let endDate = moment(this.form.change_eDateDate).format('YYYYMMDD')+this.form.change_eDateHour + '00'
      endDate = endDate.replaceAll(/\D/g,'')
      let pa = {
        clientOrderId: this.form.clientOrderId,
        sDate:null,
        eDate:endDate,
        price:this.form.detailPrice
      };
      this.$confirm('是否確定異動訂單?','注意',{
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickReturn/changeClientOrderOnlyEDate'
          this.$axios
            .post(url, pa)
            .then(() => {
              this.fullscreenLoading = false
              this.query()
              this.dialogFormChangeVisible = false
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('異動失敗請洽系統管理者', '結果', { confirmButtonText: '確定' });
            });      
        })
    },
    setForceGetPrice(){
      this.form.priceList.splice(0,this.form.priceList.length)
      this.form.detailPrice=null
    },
    showChangeForm(clientOrder){
      this.pic1 = null
      this.pic2 = null
      this.pic3 = null
      this.pic4 = null
      this.showPic = false
      this.dialogFormChangeVisible = true
      this.changeAvailableCarNo = ''
      this.changeAvailableCarDistance = ''
      this.changeReturnPay = 0
      this.changeReturnPayVatNo = ''
      this.changeReturnPayVatCompanyTitle = ''
      this.changeReturnPayCarrierNum = ''
      this.changeCancelNote = ''
      this.changeEnabledDistance = 'T'
      let params = {
        clientOrderId: clientOrder.clientOrderId
      }
      this.form.lockInvoiceInfo = false
      // eslint-disable-next-line no-unused-vars
      this.$axios.get('../api/booking/getSysTime14D', {}).then((res) => {
        
        this.$axios
          .get('../api/clientOrderQuickReturn/getClientOrderForQuickForm', {
            params: params
          })
          .then((res) => {
            if (this.form.usedCarrierNum != undefined && this.form.usedCarrierNum != null && this.form.usedCarrierNum != ''){
              this.changeReturnPayCarrierNum  = this.form.usedCarrierNum
            }
            this.dialogFormChangeVisible = true
            this.form = Object.assign({}, this.form, res.data[0])
            let eDate = this.form.eDate;
            let momentEdate=moment(this.form.eDate, "YYYYMMDDHHmmSS");
            this.changeCancelNote = res.data[0].applyCancelNote
            this.form.change_eDateDate=momentEdate
            this.form.change_eDateHour=momentEdate.format('HH:mm')
            this.form.eDateDate = new Date(this.$getDateTime_('date', eDate));
            this.form.eDateHour = this.$getDateTime_('time', eDate).substring(0, 5);
            this.form.sDate = this.$date_(this.form.sDate)
            this.form.realStartDate=this.$date_(this.form.realStartDate)
            this.form.eDate=this.$date_(this.form.eDate)
            this.form.applyDate = this.$date_(this.form.applyDate)
            
            this.form.catDisName = this.$catDisName(this.$store.state.cats, this.form.catId)
            
            this.form.priceList.splice(0,this.form.priceList.length)
            this.form.prodId=null
            this.form.detailPrice=null
            this.form.nowDistance=''
            this.form.checkCode=''
            this.form.depositPayMethod = this.$payTypeDisName(this.form.depositPayMethod)
            if(this.form.rentDateType === '2') {
              this.form.detailPrice = this.form.price
            }
            this.historyFlow.splice(0, this.historyFlow.length)
            res.data[0].historyFlow.forEach(e=>{
              e.updateDate=this.$date_(e.updateDate)
              this.historyFlow.push(e)
            })
            this.historyBooking.splice(0, this.historyBooking.length)
            res.data[0].historyBooking.forEach(e=>{
              e.createDate=this.$date_(e.createDate)
              this.historyBooking.push(e)
            })
            let query2 ={ clientOrderId: clientOrder.clientOrderId, 
              storeId: clientOrder.storeId}
              this.$axios
                .get('../api/clientOrderQuickReturn/getAvailbeCarNo', {
                  params: query2
                }).then((res) => {
                  if (res.data.rs === 'success') {
                    this.availableCarNos.splice(0,this.availableCarNos.length)
                    res.data.carNos.forEach(e => {
                      if (e.carNo !== this.form.carNo){
                        this.availableCarNos.push(e)
                      }
                    })
                  } else{
                    this.$alert('連線錯誤' + res.data.rsMsg,'結果',{confirmButtonText:'確定'})
                  }
                })
          }).catch(() => {
            this.$alert('show data error','結果',{confirmButtonText:'確定'})
          });
      })
    },
    updateClientOrder500ByCashFrom100(){
      if(this.form.payPrice==null||this.form.payPrice.toString()==''){
        this.$alert('請按下計算金額', '結果', { confirmButtonText: '確定' })
        return
      }
      if(this.form.checkCode==''){
        this.$alert('請輸入員工確認碼', '結果', { confirmButtonText: '確定' })
        return
      }
      if (this.selectOtherReturnDateTime && (this.form.change_eDateDate === undefined || this.form.change_eDateHour === undefined)) {
        this.$alert('實際還車時間格式不對', '結果', { confirmButtonText: '確定' })
        return
      }

      let pa = {
        clientOrderId: this.form.clientOrderId,
        plusPrice: this.form.plusPrice,
        price: this.form.price,
        payPrice: this.form.payPrice,
        endDistance: this.form.nowDistance,
        checkCode: this.form.checkCode,
        vatNo: this.vatNo,
        companyTitle: this.companyTitle,
        carrierNum: this.form.carrierNum,
        isrPrice: this.form.isrPrice,
        promotionCode: this.form.promotionCode,
        promotionTransRowId: this.form.promotionTransRowId,
        discount: this.form.discount
      }
      if (this.selectOtherReturnDateTime) {
        let endDate = moment(this.form.change_eDateDate).format('YYYYMMDD') + this.form.change_eDateHour + '00'
        endDate = endDate.replaceAll(/\D/g,'')
        pa.realEndDate = endDate
      }
      this.$confirm('是要現金收款(' + this.form.nowDistance + ')嗎?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          this.$axios
            .post('../api/clientOrderQuickReturn/updateClientOrder500ByCashFrom100', pa)
            .then((res) => {
              this.fullscreenLoading = false
              if(res.data.rs === 'success'){
                this.$alert('付款完成', '結果', { confirmButtonText: '確定' });
                this.query()
                this.dialogFormVisible = false
              }else{
                this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
              }
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('付款失敗，請洽系統管理員', '結果', { confirmButtonText: '確定' });
            })
        })
    },
    getPriceForChange() {
      let param = {}
      let apiUrl = '../api/clientOrderQuickReturn/getClientOrderPrice'
      // 訂單異動作業
      let endDate = moment(this.form.change_eDateDate).format('YYYYMMDD')+this.form.change_eDateHour
      if(!moment(endDate.replaceAll(/\D/g, ''),'YYYYMMDDHHmm').isAfter(new Date())){
        this.$alert('還車時間必須在現在時間之後', '結果', { confirmButtonText: '確定' });
        return
      }
      endDate = endDate.replaceAll(/\D/g, '') + '00'
      this.form.plusPrice = 0
      param = {
        clientOrderId: this.form.clientOrderId,
        eDate: endDate
      }
      this.isCal = true
      this.$axios.get(apiUrl, {params: param})
        .then((res) => {
          this.isCal = false
          this.form.priceList.splice(0,this.form.priceList.length)
          let ps = res.data.priceList
          this.form.price = 0
          this.form.deposit = 0

          for (var key in ps) {
            if (key === 'rideHour') {
              this.rideHour = ps[key]
            } else {
              if (key === 'ridePrice') {
                // ps[key] = 3
                this.form.price = this.form.price + ps[key]
                this.costPrice = this.costPrice + ps[key]
                this.form.priceList.push({ name: '車資費用:', amt: ps[key] })
                // 超額里程說明(單純額外備註顯示用，後端已經把金額列入計算)
                if (res.data.additionNote !== undefined) {
                  this.form.priceList.push({ name: '(含超額里程費):', amt: res.data.additionNote.overDistance })
                }
              } else if (key === 'isrPrice') {
                if (ps['ridePrice'] === 0) {
                  ps[key] = 0
                }
                this.form.price = this.form.price + ps[key] 
                this.costPrice = this.costPrice + ps[key] //車資
                this.form.priceList.push({ name: '保障費用:', amt: ps[key] })
              } else if (key === 'deposit') {
                if (ps[key] > 0) {
                  this.form.deposit = ps[key]
                  // this.form.priceList.push({ name: '預付訂金:', amt: (ps[key] * -1), class: 'amt-red' })
                }
              }
            }
          }
          this.form.beforePrice = res.data.beforePrice
          if (this.form.beforePrice == undefined) {
            this.form.beforePrice = 0
          } else if (this.form.price < (this.form.deposit + this.form.beforePrice)) {
            // 如果算出來的車資 小於預付 車資多不退 少要補齊
            this.form.price = (this.form.deposit + this.form.beforePrice)
            this.costPrice=this.form.price
          }
          this.form.detailPrice = this.form.price - this.form.deposit - this.form.beforePrice
        }).catch(() => {
          this.isCal = false
          this.$alert('計算失敗請洽系統管理者', '結果', { confirmButtonText: '確定' });
        })
    },
    getPriceForReturn() {
      let distance = 0
      let param = {}
      let apiUrl = '../api/clientOrderQuickReturn/getClientOrderPrice'
      if(this.form.plusPrice == null){
        this.$alert('請輸入加購金額','結果',{confirmButtonText:'確定'})
        return;
      }
      if (this.enabledDistance === 'T') {
        distance = parseInt(this.form.nowDistance) - parseInt(this.form.startDistance)
      }
      param = {
        clientOrderId: this.form.clientOrderId,
        distance: distance
      }
      if (this.selectOtherReturnDateTime && (this.form.change_eDateDate === undefined || this.form.change_eDateHour === undefined)) {
        this.$alert('實際還車時間格式不對', '結果', { confirmButtonText: '確定' })
        return
      }
      if (this.selectOtherReturnDateTime) {
        let endDate = moment(this.form.change_eDateDate).format('YYYYMMDD') + this.form.change_eDateHour + '00'
        endDate = endDate.replaceAll(/\D/g,'')
        param.eDate = endDate
      }

      if (this.selectOtherGetDateTime && (this.form.change_sDateDate === undefined || this.form.change_sDateHour === undefined)) {
        this.$alert('更改取車時間格式不對', '結果', { confirmButtonText: '確定' })
        return
      }
      if (this.selectOtherGetDateTime) {
        let startDate = moment(this.form.change_sDateDate).format('YYYYMMDD') + this.form.change_sDateHour + '00'
        startDate = startDate.replaceAll(/\D/g,'')
        param.sDate = startDate
      }


      if(this.selectOtherGetDateTime && this.selectOtherReturnDateTime) {
        if (parseInt(param.sDate) >= parseInt(param.eDate)) {
          this.$alert('取車時間不可晚於還車時間', '結果', { confirmButtonText: '確定' })
          return
        }
      }

      if(this.form.plusPrice !== null && /^-?\d+$/g.test(this.form.plusPrice)){
        if(parseInt(this.form.plusPrice) < 0) {
          this.$alert('加購金額不可為負數', '結果', { confirmButtonText: '確定' })
          return
        }
      }else{
        this.$alert('加購金額格式不對', '結果', { confirmButtonText: '確定' })
        return
      }

      if (this.selectSpecDiscount) {
        if(this.form.specDiscount !== null && /^-?\d+$/g.test(this.form.specDiscount)){
          if(parseInt(this.form.specDiscount) < 0) {
            this.$alert('折扣金額不可為負數', '結果', { confirmButtonText: '確定' })
            return
          }
        }else{
          this.$alert('折扣金額格式不對', '結果', { confirmButtonText: '確定' })
          return
        }
        this.form.promotionCode = undefined
        this.form.promotionTransRowId = undefined
        param.discount = this.form.specDiscount
      }else{
        this.form.specDiscount =undefined
        param.promotionCode = this.form.promotionCode
        param.promotionTransRowId = this.form.promotionTransRowId
      }

      this.isCal = true
      this.$axios.get(apiUrl, {params: param}).then((res) => {
          this.isCal = false
          this.form.priceList.splice(0,this.form.priceList.length)
          if(res.data.promotionNote !== undefined) {// 優惠卷錯誤
            this.$alert(res.data.promotionNote, '結果', { confirmButtonText: '確定' })
            this.form.payPrice = null
            this.form.price = null
            return
          }
          let ps = res.data.priceList
          this.form.price = 0
          this.form.price = this.form.price + parseInt(this.form.plusPrice)
          
          this.form.deposit = 0
          for (var key in ps) {
            if (key === 'rideHour') {
              this.rideHour = ps[key]
              let rest24Hours = this.rideHour % 24
              try {
                this.rideDay = ((this.rideHour-rest24Hours) / 24) + (rest24Hours /24.0) ;
                this.rideDay = this.rideDay.toFixed(1)
              } catch (error) {
                console.log(error);
              }
            } else {
              if (key === 'ridePrice') {
                if (ps[key] <0 ) { // 車資不可為負值
                  ps[key] = 0
                }
                this.form.price = this.form.price + ps[key]
                this.costPrice = this.costPrice + ps[key]
                this.form.priceList.push({ name: '車資費用:', amt: ps[key] })
                // 超額里程說明(單純額外備註顯示用，後端已經把金額列入計算)
                if (res.data.additionNote !== undefined) {
                  this.form.priceList.push({ name: '(含超額里程費):', amt: res.data.additionNote.overDistance })
                }
              } else if (key === 'isrPrice') {
                if (ps['ridePrice'] === 0) {
                  ps[key] = 0
                }
                this.form.price = this.form.price + ps[key] 
                this.costPrice = this.costPrice + ps[key] //車資
                this.form.priceList.push({ name: '保障費用:', amt: ps[key] })
                this.form.isrPrice = ps[key]
              } else if (key === 'deposit') {
                if (ps[key] > 0) {
                  this.form.deposit = ps[key]
                  // this.form.priceList.push({ name: '預付訂金:', amt: (ps[key] * -1), class: 'amt-red' })
                }
              }
            }
          }
          this.form.priceList.push({ name: '加購金額:', amt: this.form.plusPrice })
          
          this.form.beforePrice = res.data.beforePrice
          if (this.form.beforePrice == undefined) {
            this.form.beforePrice = 0
          } else if (this.form.price < (this.form.deposit + this.form.beforePrice)) {
            // 如果算出來的車資 小於預付 車資多不退 少要補齊
            // 如果算出來的車資 小於訂金 車資多退
            if (this.form.price >= this.form.deposit  || this.form.beforePrice > 0) {
              this.form.price = (this.form.deposit + this.form.beforePrice)
              this.costPrice=this.form.price
            }
          }

          // 有正確的折扣
          let promotionType = res.data.promotionType
          let discount = res.data.discount
          let ticketDiscount = 0
          let canDiscountPrice =this.form.price
          if (this.form.isrPrice !== undefined && this.form.isrPrice > 0 ) {
            canDiscountPrice = canDiscountPrice- this.form.isrPrice
          }
          if (this.ticket.ticketNo !== '') {
            ticketDiscount = this.ticket.ticketDiscount >= canDiscountPrice ? canDiscountPrice : this.ticket.ticketDiscount
            canDiscountPrice = canDiscountPrice- this.form.discount
          }

          if (promotionType !== undefined && discount !== undefined) {
            if (promotionType === 'PRICE') {
              this.form.discount = discount >= canDiscountPrice ? canDiscountPrice : discount
            } else if (promotionType === 'PERCEN') {
              //保險費用不能打折 只有車資可以
              this.form.discount = (canDiscountPrice * (1 - (discount / 100))).toFixed(0)
            }
          }else if(discount !== undefined) {
            this.form.discount = discount + ticketDiscount
          }

          if (this.form.discount !==undefined && this.form.price > 0 && this.form.discount > 0) {
            if (this.form.price - this.form.discount <= 0) {
              this.form.discount = this.form.price + 0 // 只有抵扣一些
            }
            this.form.priceList.push({ name: '優惠項目:', amt: (this.form.discount * -1), class: 'amt-red' })
          
            this.form.price = this.form.price - this.form.discount // 小計

            // 有可能要退款
            if (this.form.price <= 0) {
              this.form.returnPrice = this.form.price * (-1)
              this.form.price = 0
            }
          }
          
          this.form.payPrice = this.form.price - this.form.deposit - this.form.beforePrice
          this.form.specPrice =this.form.price
          this.form.oldPrice = this.form.price + 0
        }).catch(() => {
          this.isCal = false
          this.$alert('不知名錯誤', '結果', { confirmButtonText: '確定' });
        });
    },
    displayStore(item) {
      const areaSelect = item.areaId == this.q.qAreaId || this.q.qAreaId == ''
      const statusSelect = item.status == this.q.qStoreStatus || this.q.qStoreStatus == '' 
      return areaSelect && statusSelect
    },
    query() {
      this.q.qSDate = this.qSDate == null || this.qSDate == '' ? '' : moment(this.qSDate).format('YYYYMMDDHHmmss');    
      this.q.qEDate = this.qEDate == null || this.qEDate == '' ? '' : moment(this.qEDate).format('YYYYMMDDHHmmss');
      
      this.$axios
        .get('../api/clientOrderQuickReturn/getClientOrdersQuickReturn', {params:this.q})
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.tableData.data.forEach(clientOrder => {
            clientOrder.orgFlow = clientOrder.flow
            clientOrder.flow = this.$flow_(clientOrder.flow)
            clientOrder.sDate = this.$date_(clientOrder.sDate)
            clientOrder.realStartDate=this.$date_(clientOrder.realStartDate)
            this.tableData.push(clientOrder)
          if(clientOrder.boundleCreditCard === 'T') {
            clientOrder.isBoundleType = 'success'
            clientOrder.isBoundle = '是'
          } else {
            clientOrder.isBoundleType = 'warning'
            clientOrder.isBoundle = '否'
          }
          if(clientOrder.rentDateType == '1'){
            clientOrder.rentDateTypeDisName = '短租'
          } else if(clientOrder.rentDateType == '2'){
            clientOrder.rentDateTypeDisName = '長租'
          }
          })  
          this.pageTotal = res.data.tableData.pageTotal
          this.board=Object.assign({},this.board,res.data.board)
          if(this.q.qClientOrderId!=null&&this.tableData.length>0){
            this.q.qClientOrderId=null
            this.showDetailForm(this.tableData[0])
          }
        }).catch((res) => {
          console.log(res)
          this.$alert('login error','結果',{confirmButtonText:'確定'})
        });
    },
    clickCard(qType){
        this.q.qType=qType;
        this.query()
        this.q.qStoreId = ''
        this.qStoreId_.forEach(e=>{
          this.q.qStoreId = this.q.qStoreId + e +','
        })
        if (this.qStoreId_.length > 0){
          this.q.qStoreId = this.q.qStoreId.substring(0,this.q.qStoreId.length -1)
        }
    },
    updateClientOrder500ByATMFrom400() {
      if (this.form.moneyTypeExtend == '') {
        this.$alert('請填寫匯款末5碼','注意',{confirmButtonText:'確定'})
        return
      }
      let requestFrom ={
        clientOrderId: this.form.clientOrderId,
        vatNo: this.form.vatNo,
        companyTitle: this.form.companyTitle,
        carrierNum: this.form.carrierNum,
        doInvoice: this.form.doInvoice,
        moneyTypeExtend: this.form.moneyTypeExtend
      }
      if (this.form.doInvoice === 'true'&(this.form.vatNo != '' || this.form.companyTitle != '')) {
        if(this.form.vatNo.length != 8){
          this.$alert('請填寫正確統一編號','注意',{confirmButtonText:'確定'})
          return
        } else if (this.form.companyTitle.length == 0 || this.form.companyTitle.length >= 60){
          this.$alert('公司抬頭字元長度需介於1~60個字元','注意',{confirmButtonText:'確定'})
          return
        }
        if (this.form.carrierNum != ''){
          this.$alert('打統編之發票不可存載具','注意',{confirmButtonText:'確定'})
          return
        }
      }
      if (this.form.carrierNum !== '') {
        if (!this.form.carrierNum.match(/^\/[\dA-Z0-9+-.]{7}$/)) {
          this.$alert('手機載具號碼格式不合','注意',{confirmButtonText:'確定'})
          return
        }
      }
      let msg = '確定匯款帳號'+this.form.moneyTypeExtend+'(' + this.form.prePrice + '元)收款?'
      if (this.form.prePrice < 0 ) {
        msg = '訂金金額 大於 訂單金額 需要現金退款('+this.form.prePrice+')元'
      }
      this.$confirm(msg, '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickReturn/updateClientOrder500ByATMFrom400'
          this.$axios.post(url, requestFrom)
            .then((res) => {
              this.fullscreenLoading = false
              if(res.data.rs=='success'){
                this.query()
                this.dialogFormVisible = false
              }
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('收款錯誤', '結果', { confirmButtonText: '確定' });
            })
        })
    },
    updateClientOrder500ByCashFrom400() {
      let requestFrom ={
        clientOrderId: this.form.clientOrderId,
        vatNo: this.form.vatNo,
        companyTitle: this.form.companyTitle,
        carrierNum: this.form.carrierNum,
        doInvoice: this.form.doInvoice
      }
      if (this.form.doInvoice === 'true'&(this.form.vatNo != '' || this.form.companyTitle != '')) {
        if(this.form.vatNo.length != 8){
          this.$alert('請填寫正確統一編號','注意',{confirmButtonText:'確定'})
          return
        } else if (this.form.companyTitle.length == 0 || this.form.companyTitle.length >= 60){
          this.$alert('公司抬頭字元長度需介於1~60個字元','注意',{confirmButtonText:'確定'})
          return
        }
        if (this.form.carrierNum != ''){
          this.$alert('打統編之發票不可存載具','注意',{confirmButtonText:'確定'})
          return
        }
      }
      if (this.form.carrierNum !== '') {
        if (!this.form.carrierNum.match(/^\/[\dA-Z0-9+-.]{7}$/)) {
          this.$alert('手機載具號碼格式不合','注意',{confirmButtonText:'確定'})
          return
        }
      }
      
      let msg = '確定現金(' + this.form.prePrice + '元)收款?'
      if (this.form.prePrice < 0 ) {
        msg = '訂金金額 大於 訂單金額 需要現金退款('+this.form.prePrice+')元'
      }
      this.$confirm(msg, '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickReturn/updateClientOrder500ByCashFrom400'
          this.$axios.post(url, requestFrom)
            .then((res) => {
              this.fullscreenLoading = false
              if(res.data.rs=='success'){
                this.query()
                this.dialogFormVisible = false
              }
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('收款錯誤', '結果', { confirmButtonText: '確定' });
            })
        })
    },
    validFormBeforePay(){
      this.form.companyTitle = this.form.companyTitle ==null ? '' : this.form.companyTitle
      this.form.vatNo = this.form.vatNo ==null ? '' : this.form.vatNo
      if (this.form.doInvoice === 'true' && (this.form.vatNo != '' || this.form.companyTitle != '')) {
        if(this.form.vatNo.length != 8){
          this.$alert('請填寫正確統一編號','注意',{confirmButtonText:'確定'})
          return false
        } else if (this.form.companyTitle.length == 0 || this.form.companyTitle.length >= 60){
          this.$alert('公司抬頭字元長度需介於1~60個字元','注意',{confirmButtonText:'確定'})
          return false
        }
        if (this.form.carrierNum != ''){
          this.$alert('打統編之發票不可存載具','注意',{confirmButtonText:'確定'})
          return false
        }
      }
      if (this.form.carrierNum !== '') {
        if (!this.form.carrierNum.match(/^\/[\dA-Z0-9+-.]{7}$/)) {
          this.$alert('手機載具號碼格式不合','注意',{confirmButtonText:'確定'})
          return false
        }
      }
      return true
    },
    confirmClientOrder100ByStagePay(clientOrderStageTransRowId, payMethod){
      if(this.$isEmpty(clientOrderStageTransRowId)){
        return this.$alert('未成功新增期敷，如持續錯誤請告知系統人員','注意',{
          confirmButtonText:'確認',
        })
      }
      let pa = {
        clientOrderStageTransRowId: clientOrderStageTransRowId,
        clientOrderId: this.form.clientOrderId,
        vatNo: this.form.vatNo,
        companyTitle: this.form.companyTitle,
        carrierNum: this.form.carrierNum,
        doInvoice: this.form.doInvoice,
        checkCode: this.form.checkCode,
        payMethod: payMethod,
      }
      for(let row of this.payStageList) {
        if(row.rowId == clientOrderStageTransRowId) {
          pa.price = row.price
          pa.endDistance = row.endDistance
          pa.moneyTypeExtend = row.moneyTypeExtend
          break
        }
      }

      if(pa.endDistance==null || /\\D/.test(pa.endDistance)) {
        this.$alert('結束里程填寫不正確', '結果', { confirmButtonText: '確定' });
        return
      }
      if(!this.validFormBeforePay()){
        return
      }
      if(payMethod == 'ATM') {
        if (pa.moneyTypeExtend == null) {
          this.$alert('轉帳收款必填末5碼','結果',{ confirmButtonText: '確定' })
          return
        } else if (!pa.moneyTypeExtend.match(/\d{5}/)){
          this.$alert('末5碼不正確','結果',{ confirmButtonText: '確定' })
          return
        }
      }
      // 判斷是否最後一期
      let msg = `扣款最後里程(${pa.endDistance})，確定扣款(${pa.price}元)?`
      if(this.payStageList[this.payStageList.length-1].rowId == clientOrderStageTransRowId ) {
        msg = `請退款押金${this.payStageList[0].price}元現金，${msg}` 
      }
      this.$confirm(msg, '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickReturn/confirmClientOrder100ByStagePay'
          this.$axios.post(url, pa)
            .then((res) => {
              this.fullscreenLoading = false
              if(res.data.rs=='success'){
                let allPay = true
                for(let row of this.payStageList) {
                  if(row.rowId == clientOrderStageTransRowId) {
                    row.payDate = moment().format('YYYY-MM-DD')
                    row.realPay = true
                  } else {
                    if(row.payDate == null) {
                      allPay = false
                    }
                  }
                }

                if(allPay) {
                  this.query()
                  this.dialogFormVisible = false
                }else{
                  this.getClientRentDateLongPayStageList(this.form.clientOrderId)
                }
              }
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('扣款錯誤', '結果', { confirmButtonText: '確定' });
            })
        })
    },
    updateClientOrder500ByBoundleCreditCardPay() {
      let requestFrom ={
        clientOrderId: this.form.clientOrderId,
        vatNo: this.form.vatNo,
        companyTitle: this.form.companyTitle,
        carrierNum: this.form.carrierNum,
        doInvoice: this.form.doInvoice
      }
      if(!this.validFormBeforePay()){
        return
      }

      let msg = '確定信用卡扣款(' + this.form.prePrice + '元)?'
      if (this.form.prePrice < 0 ) {
        msg = '訂金金額 大於 訂單金額 需要現金退款('+this.form.prePrice+')元'
      }
      this.$confirm(msg, '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickReturn/updateClientOrder500ByBoundleCreditCardPay'
          this.$axios.post(url, requestFrom)
            .then((res) => {
              this.fullscreenLoading = false
              if(res.data.rs=='success'){
                this.query()
                this.dialogFormVisible = false
              }
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('扣款錯誤', '結果', { confirmButtonText: '確定' });
            })
        })
    },
    getTicketData() {
      let param = { ticketCode: this.form.ticketCode }
      this.$axios.post('../api/clientOrderQuickReturn/exchangeTicket',
        param).then((res) => {
        if (res.data.rs === 'success') {
          this.ticket.ticketNo = this.form.ticketCode
          this.ticket.ticketDisname = res.data.data.Productname
          this.ticket.ticketDiscount = res.data.data.Price
          this.resetPriceList()
          // do something
        } else {
          this.$alert(res.data.rsMsg,'注意', { confirmButtonText: '確定' })
          this.ticketCode = ''
        }
      })
    },
    updateClientOrder400WaitBoundleCreditCardPay() {
      if(this.form.plusPrice==null || this.form.plusPrice.toString() == ''){
        this.form.plusPrice = 0
      }

      if(this.enabledDistance === 'F') {
        this.form.nowDistance = 0
      }
      if(this.form.rentDateType == '1' &&  this.selectSpecPrice == false &&( this.form.payPrice == null || this.form.payPrice.toString() == '' || this.form.price === null)){
        this.$alert('請按下計算金額', '結果', { confirmButtonText: '確定' });
        return
      } else if (this.selectSpecPrice && (this.form.specPrice === '' || /\D/g.test(this.form.specPrice))) {
        this.$alert('請輸入自訂金額', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.form.checkCode==''){
        this.$alert('請輸入員工確認碼', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.selectOtherReturnDateTime && (this.form.change_eDateDate ==undefined || this.form.change_eDateHour === undefined)) {
        this.$alert('實際還車時間資料不齊全，無法還車', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.selectOtherGetDateTime && (this.form.change_sDateDate ==undefined || this.form.change_sDateHour === undefined)) {
        this.$alert('更改取車時間資料不齊全，無法還車', '結果', { confirmButtonText: '確定' });
        return
      }
      if (this.form.vatNo != '' || !(this.form.companyTitle == null || this.form.companyTitle =='')) {
        if(this.form.vatNo.length != 8){
          this.$alert('請填寫正確統一編號','注意',{confirmButtonText:'確定'})
          return
        } else if (this.form.companyTitle.length == 0 || this.form.companyTitle.length >= 60){
          this.$alert('公司抬頭字元長度需介於1~60個字元','注意',{confirmButtonText:'確定'})
          return
        }
        if (this.form.carrierNum != ''){
          this.$alert('打統編之發票不可存載具','注意',{confirmButtonText:'確定'})
          return
        }
      }
      if (this.form.carrierNum !== '') {
        if (!this.form.carrierNum.match(/^\/[\dA-Z0-9+-.]{7}$/)) {
          this.$alert("載具編號錯誤", "注意", { confirmButtonText: "確定" });
          return
        }
      }
      if (this.form.asiaMilesId !== '' && this.form.asiaMilesId !== null) {
          if (!this.form.asiaMilesId.match(/[0-9]{10}/)) {
            this.$alert("亞洲萬里通會員編號格式錯誤", "注意", { confirmButtonText: "確定" });
            return
          } else {
            let checkNum = this.form.asiaMilesId.charAt(9)
            let modTarget = 0
            for (let i = 0; i < 9; i++) {
              modTarget += parseInt(this.form.asiaMilesId.charAt(i)) * (i + 1)
            }
            if (modTarget % 10 !== parseInt(checkNum)) {
              this.$alert("亞洲萬里通會員編號格式錯誤", "注意", { confirmButtonText: "確定" });
              return
            }
          }
        }
      let requestFrom ={
        clientOrderId: this.form.clientOrderId,
        price: this.form.price,
        plusPrice: this.form.plusPrice,
        payPrice: this.form.payPrice,
        isrPrice: this.form.isrPrice,
        endDistance: this.form.nowDistance,
        checkCode: this.form.checkCode,
        vatNo: this.form.vatNo,
        companyTitle: this.form.companyTitle,
        carrierNum: this.form.carrierNum,
        promotionCode: this.form.promotionCode,
        promotionTransRowId: this.form.promotionTransRowId,
        discount: this.form.discount
      }
      if (this.selectSpecPrice) { // 自訂金額
        requestFrom.plusPrice = 0
        requestFrom.discount = 0
        requestFrom.promotionCode = ''
        requestFrom.promotionTransRowId = ''
        requestFrom.price = this.form.specPrice
        requestFrom.isrPrice = 0
        requestFrom.oldPrice = 0 // 原始建議售價
        requestFrom.payPrice = this.form.specPrice - this.form.deposit -this.form.beforePrice// 尾款
      }
      
      if (this.selectOtherReturnDateTime) {
        let endDate = moment(this.form.change_eDateDate).format('YYYYMMDD') + this.form.change_eDateHour + '00'
        endDate = endDate.replaceAll(/\D/g,'')
        requestFrom.realEndDate = endDate
      }
      if (this.selectOtherGetDateTime) {
        let startDate = moment(this.form.change_sDateDate).format('YYYYMMDD') + this.form.change_sDateHour + '00'
        startDate = startDate.replaceAll(/\D/g,'')
        requestFrom.realStartDate = startDate
      }
      // if(requestFrom != null) {
      //   return
      // }
      if (this.ticket.ticketNo !== '') {
          let param = {
            clientOrderId: this.form.clientOrderId,
            clientUserId: this.form.clientUserId,
            ticketCode: this.ticket.ticketNo,
            ticketDisname: this.ticket.ticketDisname,
          }
          this.$axios.post('../api/clientOrderQuickReturn/orderConfirmTicket',param).then((res) => {
            if (res.data.rs != 'success') {
              this.fullscreenLoading = false
              this.$alert(res.data.rsMsg,'注意', { confirmButtonText: "確定" })
              return
            }
          })
        }
      let msg = '是否還車等待扣款(' + requestFrom.payPrice + '元)，訂單已付訂金' + this.form.deposit + '元?'
      if (this.form.rentDateType == '2') {
        msg = '此為長租訂單，是否確定還車等待扣款?'
      }
      this.$confirm(msg, '注意', { confirmButtonText: '確定', cancelButtonText: '取消', type: 'warning'
        }).then(() => {
          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickReturn/updateClientOrder400WaitBoundleCreditCardPay'
          this.$axios.post(url, requestFrom)
            .then((res) => {
              this.fullscreenLoading = false
              if(res.data.rs=='success'){
                this.query()
                this.dialogFormVisible = false
              }
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('扣款錯誤', '結果', { confirmButtonText: '確定' });
            })
        })
    },
    getClientRentDateLongPayStageList(clientOrderId){
      // 取得訂單資料
      this.$axios.get('../api/clientOrderQuickReturn/getClientOrderRentDateTypeLongStagePayList', {
        params: {clientOrderId}
      }).then((stagePayListRes) => {
        
        stagePayListRes.data.clientOrderStageTrans.forEach(e => {
          e.seq = e.stageIndex
          if(e.stageIndex == 0 ) {
            e.stageIndex = '押金'
            e.seq = '押金'
          }
          e.chargeDate = this.$date_(e.chargeDate)
          if(e.payDate !=null) {
            e.payDate = this.$date_(e.payDate)
          }
          if(!this.form.lockInvoiceInfo && e.payStatus == 'SUCCESS' && e.stageType == 'STAGE') {
            this.form.lockInvoiceInfo = true
          }
          if(e.payStatus == 'SUCCESS') {
            e.payStatus = '成功'
          }
          e.realPay = false
          e.canUpdate = null
          if(e.endDistance == null) {
            e.endDistance = e.startDistance
          }
        })
        for(let row of stagePayListRes.data.clientOrderStageTrans) {
          if(row.payDate == null && row.realPay == false && row.stageType != 'HOCK') {
            row.realPay = true
            break
          }
        }
        for(let row of stagePayListRes.data.clientOrderStageTrans) {
          if(row.payDate == null) {
            row.canUpdate = true
            row.realPay = false
          } else{
            row.realPay = true
          }
        }

        this.payStageList = stagePayListRes.data.clientOrderStageTrans
        this.form.plusBuyNote = stagePayListRes.data.note.plusBuyNote
        this.form.stagePriceNote = stagePayListRes.data.note.stagePriceNote
        this.recountStagePrice()
      })
    },
    showDetailForm(clientOrder) {
      this.payStageList = []
      this.pic1 = null
      this.pic2 = null
      this.pic3 = null
      this.pic4 = null
      this.form.payPrice = null
      this.showPic = false
      this.selectOtherReturnDateTime = false
      this.form.change_eDateDate = undefined
      this.form.change_eDateHour = undefined
      this.selectOtherGetDateTime = false
      this.form.change_sDateDate = undefined
      this.form.change_sDateHour = undefined
      this.oldPrice = 0
      this.form.specDiscount = 0
      this.selectSpecDiscount = true
      this.selectSpecPrice =false
      this.enabledDistance = 'T'
      this.riddenHours = ''
      this.riddenDays = ''
      this.ticket.ticketNo = ''
      this.ticket.ticketDisname = ''
      this.ticket.ticketDiscount = ''
      this.$axios
        .get('../api/booking/getSysTime', {})
        .then((res) => {
          this.sysTime = res.data
        }).catch(() => {

        });

      let params = {
        clientOrderId: clientOrder.clientOrderId,
        catId: this.form.catId
      }
      this.vatNo = ''
      this.companyTitle = ''
      this.carrierNum = ''
      this.form.lockInvoiceInfo = false
      this.form.moneyTypeExtend = ''
      this.$axios
        .get('../api/clientOrderQuickReturn/getClientOrderForQuickForm', {
          params: params
        }).then((res) => {
          if(res.data[0].rentDateType == '2') {
            this.getClientRentDateLongPayStageList(clientOrder.clientOrderId)
          }
          let time = Date.parse(this.sysTime)
          let realEndDate = new Date()
          realEndDate.setTime(time)
          this.dialogFormVisible = true
          if(res.data[0].plusPrice==null){
            res.data[0].plusPrice = '0'
          }
          this.form.discount = 0
          this.form.promotionTransRowId = ''
          this.form.promotionCode = ''
          this.form.checkCode=''
          this.form = Object.assign({}, this.form,res.data[0])
          this.form.doInvoice = 'true'
          this.form.priceList.splice(0,this.form.priceList.length)
          if (this.form.realEndDate === undefined || this.form.realEndDate === null) {
            this.form.realEndDate = moment(realEndDate).format('YYYY-MM-DD HH:mm:ss');
          } else {
            this.riddenHours = (moment(this.form.realEndDate,'YYYYMMDDHHmmss')-moment(this.form.realStartDate,'YYYYMMDDHHmmss'))/60/60/1000
            this.form.realEndDate = this.$date_(this.form.realEndDate)
            this.riddenDays = this.riddenHours/24
            this.riddenDays = this.riddenDays.toFixed(1)
            this.riddenHours =this.riddenHours.toFixed(0)
          }
          this.form.eDate = this.$date_(this.form.eDate)
          this.form.sDate = this.$date_(this.form.sDate)
          this.form.realStartDate = this.$date_(this.form.realStartDate)
          this.form.nowDistance = (parseInt(this.form.startDistance) + 1).toString()
          this.form.plusInsuranceDisName = this.form.plusInsurance == '1' ? '是' : '否'
          this.form.catDisName = this.$catDisName(this.$store.state.cats, this.form.catId)
          
          this.form.depositPayMethod = this.$payTypeDisName(this.form.depositPayMethod)
          this.historyFlow.splice(0, this.historyFlow.length)
          res.data[0].historyFlow.forEach(e=>{
            e.updateDate=this.$date_(e.updateDate)
            this.historyFlow.push(e)
          })
          this.historyBooking.splice(0, this.historyBooking.length)
          res.data[0].historyBooking.forEach(e=>{
            e.createDate=this.$date_(e.createDate)
            this.historyBooking.push(e)
          })
          this.clientOrderTrans.splice(0, this.clientOrderTrans.length)
          res.data[0].clientOrderTrans.forEach(e=>{
            // console.log(e.moneyType)
            if (e.moneyType == 'CASH') {
              e.moneyType = '現金'
            } else if (e.moneyType == 'CREDIT') {
              e.moneyType = '信用卡'
            } else if (e.moneyType == 'LINEPAY') {
              e.moneyType = 'LINE Pay'
            } else if (e.moneyType == 'ATM') {
              e.moneyType = 'ATM 轉帳'
            } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
            } else { e.moneyType = '未知付款方式' }
            e.updateDate=this.$date_(e.updateDate)
            if (e.status !== 'SUCCESS') {
              e.updateDate = e.updateDate + '(付款失敗)'
            }
            this.clientOrderTrans.push(e)
          }) 
          if (this.form.companyTitle == undefined || this.form.companyTitle == null ) {
            this.form.companyTitle = ''
          }
          //欄位名稱轉換
          this.form.companyTitle = this.form.vatCompanyTitle
          if (this.form.vatNo == undefined || this.form.vatNo == null ) {
            this.form.vatNo = ''
          }
          if (this.form.carrierNum == undefined || this.form.carrierNum == null ) {
            this.form.carrierNum = ''
            if (this.form.usedCarrierNum != undefined && this.form.usedCarrierNum != null && this.form.usedCarrierNum != ''){
              this.form.carrierNum  = this.form.usedCarrierNum
            }
          }
          this.enabledDistance = res.data[0].enabledDistance[0].enabledDistance
          
        }).catch((res) => {
          console.log(res)
          this.$alert('login error','結果',{confirmButtonText:'確定'})
        });
    },
    formatDate(value){
      return this.$date_(value.eDate)
    },
    resetPriceList () {
      this.form.priceList.splice(0,this.form.priceList.length)
      this.form.payPrice = null
      this.form.price = null
    },
    showClientPic () {
      this.showPic = !this.showPic
      if (this.showPic) {
          if (this.form.pic1 === null) { this.form.pic1 = 'clientUser/null'}
          if (this.form.pic2 === null) { this.form.pic2 = 'clientUser/null'}
          if (this.form.pic3 === null) { this.form.pic3 = 'clientUser/null'}
          if (this.form.pic4 === null) { this.form.pic4 = 'clientUser/null'}

          this.pic1 = '../api/adminClientUser/getUserPic/'+this.form.pic1
          this.pic2 = '../api/adminClientUser/getUserPic/'+this.form.pic2
          this.pic3 = '../api/adminClientUser/getUserPic/'+this.form.pic3
          this.pic4 = '../api/adminClientUser/getUserPic/'+this.form.pic4
      } 
    },
    selectCarNo () {
      this.availableCarNos.forEach((car) => {
        if (car.prodId == this.changeAvailableCarNo) {
          this.changeEnabledDistance = car.enabledDistance
        }
      })
    },
    updateReserveNote() {
      let param = {
        clientOrderId: this.form.clientOrderId,
        reserveNote: this.form.reserveNote
      }
      this.$confirm('是否確定修改備註','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() =>{
        this.$axios.post('../api/clientOrderQuickGet/updateReserveNote',param).then((res) =>{
          if (res.data.rs === 'success') {
            this.$alert('更新備註成功','結果',{confirmButtonText:'確定'})
            this.query()
          } else {
            this.$alert('更新備註失敗','結果',{confirmButtonText:'確定'})
          }
        })
      })
    },
    originMinusMile(){
      let originCarEndDistance = parseFloat(this.originCarEndDistance);
      let startDistance = parseFloat(this.form.startDistance);
      
      if (originCarEndDistance < startDistance) {
        this.$alert('結束里程不能小於起始里程', '錯誤', { confirmButtonText: '確定' });
        this.originCarEndDistance = this.form.startDistance; 
        this.differenceDistance = 0; 
      } else {
        this.differenceDistance = originCarEndDistance - startDistance;
      }
      if(this.differenceDistance == 0 | this.differenceDistance > 0){
        this.changeCarStartDistance = this.changeAvailableCarDistance - this.differenceDistance
      }
      if(this.originCarEndDistance >= 60){
        this.$alert('該車輛里程已使用60公里','注意',{confirmButtonText:'確定'})
      }
    },
    changeMinusMile(){
      if (this.changeAvailableCarDistance == '' | this.changeEnabledDistance == null) {
        this.changeCarStartDistance = this.changeAvailableCarDistance - this.differenceDistance
        this.$alert('起始里程不得為空', '結果', { confirmButtonText: '確定' });
        return
      } else{
        this.changeCarStartDistance = this.changeAvailableCarDistance - this.differenceDistance
      }
    }
  }
}
</script>
<style scoped >
.selectit{
  background-color: yellowgreen;
}
.empty{
  background-color: white;
}
.form-font{
  font-size: 150%;
}
.amt-red {
  color:red;
}
.amt {
  color:#6e757c;
}
.margin-right-20px {
  margin-right: 20px;
}
</style>
