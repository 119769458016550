<!-- eslint-disable vue/require-v-for-key -->
<template>
<div v-loading.fullscreen.lock="fullscreenLoading">
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="10" >
      <el-col  :xs="24" :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='get1'?'selectit':'empty'" @click.native="cardClick('get1')">
          預約待確認<span style="color:green;font-size:40px;margin-left:10px;">{{board.get1}}</span>單
        </el-card>
      </el-col>
      <el-col :xs="24"  :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='get2'?'selectit':'empty'"  @click.native="cardClick('get2')">
          預約待付款<span style="color:green;font-size:40px;margin-left:10px;">{{board.get2}}</span>單
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='get3'?'selectit':'empty'" @click.native="cardClick('get3')">
          當日待取車<span style="color:green;font-size:40px;margin-left:10px;">{{board.get3}}</span>單
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='get4'?'selectit':'empty'" @click.native="cardClick('get4')">
          累積逾期未取車<span style="color:green;font-size:40px;margin-left:10px;">{{board.get4}}</span>單
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='get6'?'selectit':'empty'" @click.native="cardClick('get6')">
          累計等待取車<span style="color:red;font-size:40px;margin-left:10px;">{{board.get6}}</span>單
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='get5'?'selectit':'empty'" @click.native="cardClick('get5')">
          現場客逾期取車<span style="color:red;font-size:40px;margin-left:10px;">{{board.get5}}</span>單
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='refund'?'selectit':'empty'" @click.native="cardClick('refund')">
          等待退款訂單<span style="color:red;font-size:40px;margin-left:10px;">{{board.refund}}</span>單
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="setComfirmScheduleModalEvent(true)">
          <span style="color:green;font-size:40px;margin-left:10px;">庫存表</span>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
        <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qType=='get7'?'selectit':'empty'" @click.native="cardClick('get7')">
          自動取消<span style="color:red;font-size:40px;margin-left:10px;">{{board.get7}}</span>單
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="10" style="margin-top:10px;">
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        地區&nbsp;&nbsp;&nbsp;<el-select v-model="q.qAreaId" clearable placeholder="">
          <el-option width=120 v-for="item in areas" :key="item.code" :label="item.disName" :value="item.code">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        店家狀態&nbsp;&nbsp;&nbsp;<el-select v-model="q.qStoreStatus" clearable placeholder="">
          <el-option width=120 v-for="item in storeStatusList" :key="item.code" :label="item.disName" :value="item.code">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
      <div class="grid-content bg-purple"></div>
        門市&nbsp;&nbsp;&nbsp;<el-select v-model="qStoreId_" multiple clearable filterable placeholder="" @change="getQueryStoreId"> <!--複數選擇-->
          <el-option width=120 v-for="item in stores" :key="item.code" :label="item.disName" :value="item.code" v-show="displayStore(item)">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        訂單狀態&nbsp;&nbsp;&nbsp;<el-select v-model="q.qFlow" clearable placeholder="">
          <el-option width=120 v-for="item in flows" :key="item.code" :label="item.disName" :value="item.code">
          </el-option>
        </el-select>
      </el-col>
    </el-row> 
    <el-row :gutter="20" style="margin:10px 0 10px 0;">
      <el-col :span="10">
        <div class="grid-content bg-purple"></div>
        <el-input placeholder="會員帳號或電話或姓名" v-model="q.qPhone">
          <template slot="prepend">會員帳號、電話、姓名</template>
        </el-input>
      </el-col>
      <el-col :span="10">
        <div class="grid-content bg-purple"></div>
        <el-input placeholder="訂單號" v-model="q.clientOrderId">
          <template slot="prepend">訂單號</template>
        </el-input>
      </el-col>
    </el-row> 

    <el-row :gutter="20" >
      <el-col :span="9">
        排序<el-radio-group v-model="q.qOrderType" size="medium" @change="query">
        <el-radio-button :label="orderType_1"></el-radio-button>
        <el-radio-button :label="orderType_2"></el-radio-button>
        <el-radio-button :label="orderType_3"></el-radio-button>
        <el-radio-button :label="orderType_4"></el-radio-button>
        </el-radio-group>      
      </el-col>  
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="cardClick(null)">查詢</el-button>
      </el-col>
    </el-row>
  </el-card>

  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="clientUserDisName" label="客戶"> </el-table-column>
      <el-table-column prop="storeDisName" label="店家"> </el-table-column>
      <el-table-column prop="clientUserId" label="客戶帳號" width="100%"> </el-table-column>
      <el-table-column prop="phone" label="電話" width="100%"> </el-table-column>
      <el-table-column prop="sDate" label="取車日期" width="160"> </el-table-column>
      <el-table-column prop="eDate" label="還車日期" width="160"> </el-table-column>
      <el-table-column prop="flow" label="狀態"><template slot-scope="scope">
          <el-tag :type="scope.row.flowType">
            {{scope.row.flow}}
          </el-tag>
          </template></el-table-column>
      <el-table-column prop="prodPriceDisName" label="車類"> </el-table-column>
      <el-table-column prop="boundleCreditCard" label="綁定信用卡" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isBoundleType">
            {{scope.row.isBoundle}}
          </el-tag>
        </template> 
      </el-table-column>
      <el-table-column prop="reserveNote" label="員工訂單備註" width="220"> </el-table-column>
      <el-table-column prop="clientNote" label="客戶訂單備註" width="220"> </el-table-column>
      <el-table-column prop="clientType" label="訂單管道" ><template slot-scope="scope">{{scope.row.clientType|filter_clientType}}</template></el-table-column>
      <el-table-column prop="price" align="right" label="金額"> </el-table-column>
      <el-table-column prop="createDate" label="訂單成立時間" width="160"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="300">
        <template slot-scope="scope">
          <el-button @click="showChangeDetailForm(scope.row)" type="primary" size="small">訂單異動</el-button>
          <el-button @click="setComfirmModalEvent(true,scope.row)" type="primary" size="small">身分驗證</el-button>
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">下一步</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="q.pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="90%">
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">取車視窗
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td class="dialog-col-style">訂單號:</td>
          <td class="dialog-value-style">{{form.clientOrderId}}</td>
          <td class="dialog-col-style">客戶帳號:</td>
          <td class="dialog-value-style">{{form.clientUserId}}</td></tr>

          <tr><td class="dialog-col-style">客戶名稱:</td>
          <td class="dialog-value-style"> {{form.clientUserDisName}}</td>
          <td class="dialog-col-style">信箱:</td>
          <td class="dialog-value-style">{{form.clientEmail}}</td></tr>

          <tr><td class="dialog-col-style">客戶電話:</td>
          <td class="dialog-value-style">{{form.clientPhone}}</td>
          <td class="dialog-col-style">客戶出生年月日:</td>
          <td class="dialog-value-style"> {{form.clientBirthday}}</td></tr>
          
          <tr><td class="dialog-col-style">訂單取車時間:</td>
          <td class="dialog-value-style">{{form.sDate}}</td>
          <td class="dialog-col-style">車類:</td>
          <td class="dialog-value-style"> {{form.prodPriceDisName}}</td></tr>

          <tr><td class="dialog-col-style">車型:</td>
          <td class="dialog-value-style">{{form.catDisName}}</td>
          <td class="dialog-col-style">車號:</td>
          <td class="dialog-value-style"> {{form.carNo}}</td></tr>

          <tr><td class="dialog-col-style">訂單還車時間:</td>
          <td class="dialog-value-style">{{form.eDate}}</td>
          <td class="dialog-col-style">訂單管道:</td>
          <td class="dialog-value-style"> {{form.clientType | filter_clientType}}</td></tr>

          <tr v-if="false"><td class="dialog-col-style">加購保障:</td>
          <td class="dialog-value-style"></td>
          <td class="dialog-col-style">加購服務:</td>
          <td class="dialog-value-style"> {{form.price}}</td></tr>

          <tr><td class="dialog-col-style">訂金:</td>
          <td class="dialog-value-style">{{form.deposit}}({{form.depositPayMethod}})</td>
          <td class="dialog-col-style">訂單總金額:</td>
          <td class="dialog-value-style"> {{form.price}}</td></tr>

          <tr><td class="dialog-col-style">客戶喜好:</td>
          <td class="dialog-value-style">{{form.adminNote}}</td>
          <td class="dialog-col-style">類別:</td>
          <td class="dialog-value-style">{{(form.rentDateType==='2')?'長租':'短租'}}</td></tr>

          <tr><td class="dialog-col-style">駕照類型:</td>
          <td colspan="3" class="dialog-value-style">{{form.motoLicenseType}}</td></tr>

          <tr><td class="dialog-col-style">客戶訂單備註:</td>
          <td colspan="3" class="dialog-value-style">{{form.clientNote}}</td></tr>
          
          <tr @click="showClientPic"><td colspan="4" class="dialog-col-style">客戶證件:<el-button size="small" v-show="!showPic" type="primary">顯示</el-button><el-button size="small" v-show="showPic" type="primary">隱藏</el-button></td></tr>
          <tr  v-show="showPic">
            <td class="dialog-value-style"><a target="_blank" :href="pic1"><img :src="pic1" style="width:250px;height:180px;"/></a></td>
            <td class="dialog-value-style"><a target="_blank" :href="pic2"><img :src="pic2" style="width:250px;height:180px;"/></a></td>
            <td class="dialog-value-style"><a target="_blank" :href="pic3"><img :src="pic3" style="width:250px;height:180px;"/></a></td>
            <td class="dialog-value-style"><a target="_blank" :href="pic4"><img :src="pic4" style="width:250px;height:180px;"/></a></td>
          </tr>
          
          <tr><td class="dialog-col-style">歷史紀錄:</td>
            <td colspan="3" class="dialog-value-style">          
              <div v-for="item in historyBooking" :key="item.clientOrderId" :value="item.clientOrderId">
                下單時間:{{item.createDate}}-備註:{{item.reserveNote}}(訂單號:{{item.clientOrderId}})
              </div>
            </td>
          </tr>
          <tr><td class="dialog-col-style">訂單流程:</td>
            <td colspan="3" class="dialog-value-style">          
              <div v-for="(item,index) in historyFlow" :key="index" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>
        <!-- </tbody>
      </table> -->
      <tr>
        <td colspan="4" style=" border-style:solid; border-width:1px;">
          <el-row style="margin-top:10px;" >
          </el-row>

      <!-- <el-form-item label="退訂金額:" :label-width="formLabelWidth" v-show="form.flow=='50' && form.deposit > 0 " class="title">
        <el-input v-model="form.returnPrice" autocomplete="off" class="bs"></el-input>
      </el-form-item>       -->
      <!-- <el-form-item label="取消原因:" :label-width="formLabelWidth" v-show="form.flow=='0'||form.flow=='10'||form.flow=='50'" class="title">
        <el-input v-model="form.cancelNote" autocomplete="off" class="bs"></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="" :label-width="formLabelWidth" v-show="form.flow=='0'||form.flow=='10'||form.flow=='50'" class="title">
        <el-button type="primary" v-show="form.flow=='50' && form.deposit > 0 " class="bs" @click="confirmEditStore('1100')">取消訂單</el-button>      
      </el-form-item> -->
            <el-form-item label="員工訂單備註:" :label-width="formLabelWidth" class="title">
              <el-input v-model="form.reserveNote" autocomplete="off" class="bs" style="width:80%"></el-input>
              <el-button type="primary" @click="updateReserveNote">更新備註</el-button>
            </el-form-item>

            <el-form-item label="預估還車日期:" :label-width="formLabelWidth" v-show="form.flow=='50'" class="title">
              <el-col :span="12" class="fs">
                <el-date-picker :clearable="false" :editable="false" v-model="form.eDateDate" type="date" placeholder="預估還車日期">
                </el-date-picker>
              </el-col>
              <el-col :span="12" class="fs">
                <el-time-select style="width:120px;" v-model="form.eDateHour" :clearable="false" :editable="false"
                :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
                </el-time-select>
              </el-col>
            </el-form-item>
            <el-form-item label="隨車配件:" :label-width="formLabelWidth" v-show="form.flow=='50'"  class="title">
                <el-col :span="6">雨衣：
                  <el-select v-model="form.raincoat" style="width:70px">
                    <el-option key="0" value="0" label="0"></el-option>
                    <el-option key="1" value="1" label="1"></el-option>
                    <el-option key="2" value="2" label="2"></el-option>
                    <el-option key="3" value="3" label="3"></el-option>
                    <el-option key="4" value="4" label="4"></el-option>
                    <el-option key="5" value="5" label="5"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">半罩安全帽：
                  <el-select v-model="form.helmet"  style="width:70px">
                    <el-option key="0" value="0" label="0"></el-option>
                    <el-option key="1" value="1" label="1"></el-option>
                    <el-option key="2" value="2" label="2"></el-option>
                    <el-option key="3" value="3" label="3"></el-option>
                    <el-option key="4" value="4" label="4"></el-option>
                    <el-option key="5" value="5" label="5"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">3/4安全帽：
                  <el-select v-model="form.helmetFull" style="width:70px">
                    <el-option key="0" value="0" label="0"></el-option>
                    <el-option key="1" value="1" label="1"></el-option>
                    <el-option key="2" value="2" label="2"></el-option>
                    <el-option key="3" value="3" label="3"></el-option>
                    <el-option key="4" value="4" label="4"></el-option>
                    <el-option key="5" value="5" label="5"></el-option>
                  </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="車號:" :label-width="formLabelWidth" v-show="form.flow=='50'" class="title">
              <el-select v-model="form.prodId" clearable placeholder="" style="width:400px" @change="changeCarNo" class="bs">
                <el-option  v-for="item in carList" :key="item.code" :label="item.disName" :value="item.code" class="bs">
                </el-option>
              </el-select>
              <el-input type="tel" placeholder="車號查詢" style="width:100px;margin-left:10px" size="small" @input="serchProd" v-model="qCarNo"></el-input>
            </el-form-item>
            <el-form-item label="目前里程(KM):" :label-width="formLabelWidth" v-show="form.flow=='50' && enabledDistance =='T'" class="title">
              <el-input v-model="form.nowDistance" autocomplete="off" class="bs"></el-input>
            </el-form-item>
            <div v-show="form.rentDateType === '2'" style="color:red;">
              此訂單為長租方案，請注意押金與分期金額
            </div>
            <el-form-item label="長租押金:" :label-width="formLabelWidth" 
              v-show="(form.flow=='50' || form.flow=='10' || form.flow=='0') &&  form.rentDateType === '2'" class="title">
              <el-input v-model="form.hock" autocomplete="off" class="bs"></el-input>
            </el-form-item>
            <el-form-item label="里程說明:" :label-width="formLabelWidth" 
              v-show="(form.flow=='50' || form.flow=='10' || form.flow=='0') &&  form.rentDateType === '2'" class="title">
              <el-input v-model="form.stagePriceNote" autocomplete="off" class="bs"></el-input>
            </el-form-item>
            <el-form-item label="加購說明:" :label-width="formLabelWidth" 
              v-show="(form.flow=='50' || form.flow=='10' || form.flow=='0') &&  form.rentDateType === '2'" class="title">
              <el-input v-model="form.plusBuyNote" autocomplete="off" class="bs"></el-input>
            </el-form-item>

            <el-form-item label="" :label-width="formLabelWidth"
               v-show="(form.flow=='50' || form.flow=='10' || form.flow=='0') && form.rentDateType === '2'" class="title">
              原定單總金額{{form.price}}元，分{{form.payStage.length}}期，
              每期{{form.payStage.length > 0 ? (form.price/form.payStage.length).toFixed(0) : 0}}元
              <el-button @click="addPayStage()" type="primary" size="small" class="bs" >追加付款期數</el-button>
              <el-button v-show="(form.flow=='50' || form.flow=='10' || form.flow=='0')" @click="saveStagePay()" type="primary" size="small" class="bs" >儲存分期資料</el-button>
            </el-form-item>

            <el-form-item label="付款期數" :label-width="formLabelWidth" 
              v-show="(form.flow=='50' || form.flow=='10' || form.flow=='0') && form.rentDateType === '2'" class="title">
              <table>
                <template v-for="(row) in form.payStage">
                  <!-- // eslint-disable-next-line vue -->
                  <tr>
                    <td style="color:red;">付款期數:({{row.seq}})</td>
                    <td>
                      <el-switch :disabled="row.disablePaySwitch" v-model="row.canPay" inactive-color="red" active-value="Y" inactive-value="N" active-text="開放付款"></el-switch>
                    </td>
                    <td>付款時間:</td>
                    <td>
                      <el-date-picker style="width:200px;" :clearable="false" :editable="false" v-model="row.chargeDate" type="datetime" placeholder="付款日期"></el-date-picker>
                    </td>
                    <td>費用:<el-input style="width:80px;"  v-model="row.price" @blur="recountStagePrice()"></el-input></td>
                    <td>起始里程:<el-input :disabled='true' style="width:80px;"  v-model="row.startDistance" @blur="recountStagePrice()"></el-input></td>
                    <td>結束里程:<el-input style="width:80px;" :disabled='true' v-model="row.endDistance" @blur="recountStagePrice()"></el-input></td>
                  </tr>
                  <tr>
                    <td>前台費用說明({{row.seq}}):</td>
                    <td colspan="6"><el-input v-model="row.note"></el-input></td>
                  </tr>
                  <tr>
                    <td>員工備註說明({{row.seq}}):</td>
                    <td colspan="5"><el-input v-model="row.adminNote"></el-input></td>
                    <td><el-button v-show="row.seq > 1" @click="deleteStageTrans(row.seq)" type="danger">刪除分期({{row.seq}})</el-button></td>
                  </tr>
                </template>
                <tr>
                  <td></td>
                  <td>原費用:</td>
                  <td>{{form.price}}元</td>
                  <td>目前總費用:</td>
                  <td>{{stageTotalPrice}}元(不含押金)</td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </el-form-item>

            <el-form-item label="加購保障:" :label-width="formLabelWidth" v-show="form.flow=='50' && form.saleInsuranceAbled && form.rentDateType === '1'" class="title">
              <el-switch v-model="form.plusInsurance" active-color="#13ce66" inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
            <el-form-item label="" :label-width="formLabelWidth" v-show="form.flow=='50' && form.rentDateType === '1'" class="title">
              <el-button @click="getPrice()" type="primary" size="small" class="bs" :loading="isCal">{{isCal?'重新計算中':'重新計算'}}</el-button>
            </el-form-item>
            <el-form-item  label="費用明細:" :label-width="formLabelWidth" v-show="form.flow=='50' && form.rentDateType === '1'" class="title">
              <div style="font-size:150%;">&emsp;費用小計:<span style="margin-left:20px;">{{this.form.price}}元</span></div>
              <div v-for="(priceDetail,index) in form.priceList" :key="index" style="font-size:150%">
                <span v-show="index === 1" style="margin-left:20px;"></span>
                {{priceDetail.name}}
                <span :class="priceDetail.class" style="">{{priceDetail.amt}}元</span>
              </div>
            </el-form-item>
            <el-form-item label="預估總金額:" :label-width="formLabelWidth" v-show="form.flow=='50' && form.rentDateType === '1'" class="title">
              <span style="font-size:150%;">{{form.detailPrice}}元</span>
            </el-form-item>
            <el-form-item v-show="form.deposit > 0 && form.rentDateType === '1'" label="預付訂金:" :label-width="formLabelWidth" class="title red-title"  style="color:red">
              <span style="font-size:150%">{{form.deposit*-1}}元</span>
            </el-form-item>
            <el-form-item label="取車預付(元):" :label-width="formLabelWidth" v-show="form.flow=='50' && form.beforePay === 'T' && form.rentDateType === '1'" class="title red-title">
              <el-input v-model="form.beforePrice" autocomplete="off" class="bs"></el-input>
            </el-form-item>
            <el-form-item label="員工碼:" :label-width="formLabelWidth" v-show="form.flow=='50'" class="title">
              <el-input v-model="form.checkCode" autocomplete="off"  show-password class="bs"></el-input>
            </el-form-item>
          </td>
        </tr>
        </tbody>
      </table>
    </el-form>   

    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false" class="bs">取 消</el-button>
      <el-button type="primary" @click="confirmEditStore('10')" v-show="form.flow=='0' && form.rentDateType === '1'" class="bs">確認訂單，等待收訂</el-button>
      <el-button type="primary" @click="confirmEditStoreNextFlow50(0)" v-show="form.flow=='10'||form.flow=='0'" class="bs">不收訂，等待取車</el-button>
      <el-button type="primary" @click="confirmEditStoreNextFlow50()" v-show="form.flow=='10'" class="bs">確定現金收訂</el-button>
      <!-- <el-button type="danger" @click="confirmEditStore('1000')" v-show="form.flow=='0' || form.flow=='10' || (form.flow == '50' && form.deposit == 0)" class="bs">取消預約</el-button> -->
      <el-button type="danger" @click="confirmRecovery10Flow()" v-show="form.flow=='50'" class="bs">訂金狀態變更</el-button>
      <el-button type="primary" @click="confirmEditStore('100')" v-show="form.flow=='50'" class="bs">{{form.beforePay=== 'T' ? '收取預付金額和租車' : '確定租車'}}</el-button>
    </div>
  </el-dialog>

  <el-dialog title="" :close-on-click-modal="false" :visible.sync="dialogFormChangeVisible" width="90%">
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">訂單異動視窗
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td class="dialog-col-style">訂單號:</td>
          <td class="dialog-value-style">{{form.clientOrderId}}</td>
          <td class="dialog-col-style">客戶帳號:</td>
          <td class="dialog-value-style">{{form.clientUserId}}</td></tr>

          <tr><td class="dialog-col-style">客戶名稱:</td>
          <td  class="dialog-value-style"> {{form.clientUserDisName}}</td>
          <td class="dialog-col-style">信箱:</td>
          <td class="dialog-value-style">{{form.clientEmail}}</td></tr>
          
          <tr><td class="dialog-col-style">客戶電話:</td>
          <td class="dialog-value-style">{{form.clientPhone}}</td>
          <td class="dialog-col-style">客戶出生年月日:</td>
          <td class="dialog-value-style"> {{form.clientBirthday}}</td></tr>
          
          <tr><td class="dialog-col-style">訂單取車時間:</td>
          <td class="dialog-value-style">{{form.sDate}}</td>
          <td class="dialog-col-style">車類:</td>
          <td class="dialog-value-style"> {{form.prodPriceDisName}}</td></tr>

          <tr><td class="dialog-col-style">車型:</td>
          <td class="dialog-value-style">{{form.catDisName}}</td>
          <td class="dialog-col-style">車號:</td>
          <td class="dialog-value-style"> {{form.carNo}}</td></tr>

          <tr><td class="dialog-col-style">訂單還車時間:</td>
          <td class="dialog-value-style">{{form.eDate}}</td>
          <td class="dialog-col-style">訂單管道:</td>
          <td class="dialog-value-style"> {{form.clientType | filter_clientType}}</td></tr>

          <tr><td class="dialog-col-style">訂金:</td>
          <td class="dialog-value-style">{{form.deposit}}({{form.depositPayMethod}})</td>
          <td class="dialog-col-style">訂單總金額:</td>
          <td class="dialog-value-style"> {{form.price}}</td></tr>

          <tr><td class="dialog-col-style">客戶喜好:</td>
          <td class="dialog-value-style">{{form.adminNote}}</td>
          <td class="dialog-col-style">類別:</td>
          <td class="dialog-value-style">{{(form.rentDateType==='2')?'長租':'短租'}}</td></tr>

          <tr><td class="dialog-col-style">駕照類型:</td>
          <td colspan="3" class="dialog-value-style">{{form.motoLicenseType}}</td></tr>

          <tr><td class="dialog-col-style">客戶訂單備註:</td>
          <td colspan="3" class="dialog-value-style">{{form.clientNote}}</td></tr>

          <tr @click="showClientPic"><td colspan="4" class="dialog-col-style">客戶證件:<el-button size="small" v-show="!showPic" type="primary">顯示</el-button><el-button size="small" v-show="showPic" type="primary">隱藏</el-button></td></tr>
          <tr  v-show="showPic">
            <td class="dialog-value-style"><a target="_blank" :href="pic1"><img :src="pic1" style="width:250px;height:180px;"/></a></td>
            <td class="dialog-value-style"><a target="_blank" :href="pic2"><img :src="pic2" style="width:250px;height:180px;"/></a></td>
            <td class="dialog-value-style"><a target="_blank" :href="pic3"><img :src="pic3" style="width:250px;height:180px;"/></a></td>
            <td class="dialog-value-style"><a target="_blank" :href="pic4"><img :src="pic4" style="width:250px;height:180px;"/></a></td>
          </tr>
          
          <tr><td class="dialog-col-style">歷史紀錄:</td>
            <td colspan="3" class="dialog-value-style">          
              <div v-for="item in historyBooking" :key="item.clientOrderId" :value="item.clientOrderId">
                下單時間:{{item.createDate}}-備註:{{item.reserveNote}}(訂單號:{{item.clientOrderId}})
              </div>
            </td>
          </tr>

          <tr><td class="dialog-col-style">訂單流程:</td>
            <td colspan="3" class="dialog-value-style">          
              <div v-for="item in historyFlow" :key="item.flow" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>
          <tr  v-show="form.flow=='50' || form.flow=='10'|| form.flow=='0' "><td class="dialog-col-style">取消訂單:<br/>0元訂單不開發票</td>
            <td colspan="3" class="dialog-value-style" >
              <div>
                統一編號:<el-input style="width:200px;" v-model="changeReturnPayVatNo" ></el-input>(留白則為個人發票)<br/>
                公司抬頭:<el-input style="max-width:600px;"  v-model="changeReturnPayVatCompanyTitle" ></el-input>
              </div>
              <div>
                <el-checkbox v-model="form.plusFee" true-label="T" false-label="F" checked>
                <span style="font-size:200%;margin-top:20px">扣手續費</span>
                </el-checkbox>
              </div>
              <div>申請取消日期:{{form.applyDate}}</div>
              <div>退款銀行名稱：{{form.clientUserBankName}}</div>
              <div>退款銀行分行：{{form.clientUserBankBranch}}</div>
              <div>退款銀行帳戶：{{form.clientUserBankAccount}}</div>
              <div>訂金:{{form.deposit}}({{form.depositPayMethod}})</div>
              <div>退款金額:
                  <el-input class="distance-input" style="width:120px;" v-model.number="changeReturnPay" ></el-input></div>
              <div>取消原因:<el-input style="width:450px;" v-model="changeCancelNote"></el-input>
                <el-button style="float:right;" type="primary" @click="confimrChangeReturnPay()" class="bs">確定取消訂單</el-button>
              </div>
            </td>
          </tr> 
          <tr><td colspan="4" style=" border-style:solid; border-width:1px;"> 
            <el-row style="margin-top:10px;" >
              
              <el-form-item label="原始客戶帳號:" :label-width="formLabelWidth" class="title">
                <el-col :span="6" class="item-label" style=" font-size:150%;">
                  {{form.clientUserId}}</el-col>
                <el-col :span="2" style=" font-size:150%;">
                  異動後</el-col>
                
                <el-col :span="6" class="fs">
                  <el-input v-model="form.change_clientUserId"></el-input>
                </el-col>

              </el-form-item>
            </el-row>
            <el-row style="margin-top:10px;" >
              
              <el-form-item label="原始取車日期:" :label-width="formLabelWidth" class="title">
                <el-col :span="6" class="item-label" style=" font-size:150%;">
                  {{form.sDate}}</el-col>
                <el-col :span="2" style=" font-size:150%;">
                  異動後</el-col>
                
                <el-col :span="6" class="fs">
                  <el-date-picker style="width:160px" :clearable="false" :editable="false" 
                  v-model="form.change_sDateDate" type="date" @change="setForceGetPrice" />
                </el-col>
                <el-col :span="4" class="fs">
                  <el-time-select style="width:120px" :clearable="false" :editable="false"
                  v-model="form.change_sDateHour" :picker-options="{ start: '00:00', step: '00:30', end: '23:00'
                    }" placeholder="" @change="setForceGetPrice">
                  </el-time-select>
                </el-col>
                <!-- <el-col :span="2">
                  <el-button type="primary" @click="confirmChangeClientOrder('sDate')">取車日期確定異動</el-button>
                </el-col>           -->
              </el-form-item>
            </el-row>
            <el-form-item label="原始還車日期:" :label-width="formLabelWidth" class="title">
              <el-col :span="6" class="item-label" style="font-size:150%">
                {{form.eDate}}</el-col>
              <el-col :span="2" style="font-size:150%">
                異動後</el-col>
              <el-col :span="6" class="fs">
                <el-date-picker style="width:160px" :clearable="false" :editable="false" 
                v-model="form.change_eDateDate" type="date" @change="setForceGetPrice">
                </el-date-picker>
              </el-col>
              <el-col :span="4" class="fs">
                <el-time-select style="width:120px" :clearable="false" :editable="false"
                v-model="form.change_eDateHour" :picker-options="{ start: '00:00', step: '00:30', end: '23:00' }"
                placeholder="" @change="setForceGetPrice" />
              </el-col>
            </el-form-item>            

            <el-form-item label="原始車型:" :label-width="formLabelWidth" class="title">
              <el-col :span="6" class="item-label" style="font-size:150%">
                {{form.prodPriceDisName}}
              </el-col>
              <el-col :span="2" style="font-size:150%">
                異動後
              </el-col>
              <el-col :span="6" class="fs">
                <el-select v-model="changeProdStockRowId" clearable placeholder="" >
                  <el-option width=120 v-for="item in form.change_prodStockRowId" :key="item.prodStockId" :label="item.disName" :value="item.prodStockId">
                  </el-option>
                </el-select>
              </el-col>        

            </el-form-item>
            <el-row>
              <el-form-item label="" :label-width="formLabelWidth">
                <el-button @click="getPrice()" type="primary" size="small" class="bs" :loading="isCal">{{isCal?'重新計算中':'重新計算'}}</el-button>
              </el-form-item>
              <el-form-item  label="費用明細:" :label-width="formLabelWidth" class="title">
                <div v-for="item in form.priceList" :key="item.item" style="font-size:150%;">
                  {{item}}
                </div>
              </el-form-item>  
              <el-form-item label="預估金額:" :label-width="formLabelWidth" class="title">
              <span style="font-size:150%">{{form.detailPrice}}元</span>
              </el-form-item>
            </el-row>
            <el-form-item label="員工碼:" :label-width="formLabelWidth" v-show="form.flow=='50' &&dialogFormVisible==true" class="title">
              <el-input v-model="form.checkCode" autocomplete="off"  show-password style="font-size:150%"></el-input>
            </el-form-item>
            </td>
          </tr>

        </tbody>
    </table>
    </el-form>
    <div slot="footer" class="dialog-footer">        
      <el-button type="primary" @click="confirmChangeClientUserId()" class="bs">客戶確定異動</el-button>
      <el-button type="primary" @click="confirmChangeClientOrderStock()" class="bs">車型確定異動</el-button>
      <el-button type="primary" @click="confirmChangeClientOrder()" class="bs">確定異動日期</el-button>
      <el-button @click="dialogFormChangeVisible = false" class="bs">取 消</el-button>
    </div>
  </el-dialog>
  <kycDialog 
    :comfirmModal="comfirmModal"
    :comfirmModelsContent="comfirmModelsContent"
    @setComfirmModalEvent="setComfirmModalEvent"
    @comfirmModalOkEvent="comfirmModalOkEvent"
    @comfirmModalCancelEvent="comfirmModalCancelEvent">
  </kycDialog>
  <clientOrderScheduleDialog 
    ref="clientOrderScheduleDialog"
    :comfirmModal="comfirmScheduleModal"
    :storeId= "qStoreId_[0]"
    @setComfirmModalEvent="setComfirmScheduleModalEvent"
    @comfirmModalOkEvent="comfirmScheduleModalOkEvent"
    @comfirmModalCancelEvent="comfirmScheduleModalCancelEvent">
  </clientOrderScheduleDialog>
  
</div>
</template>
<script>
import * as moment from "moment/moment";
import KycDialog from '@/components/kycDialog/kycDialog'
import ClientOrderScheduleDialog from '@/components/clientOrderSchedule/clientOrderScheduleDialog'

export default {
  components:{
    KycDialog, ClientOrderScheduleDialog,
  },
  data() {
    return {
      stageTotalPrice: 0,
      comfirmModal: false,
      comfirmModelsContent: '',
      comfirmScheduleModal: false,
      comfirmScheduleModelsContent: '',
      board:{get1:0,get2:0,get3:0,get4:0,get5:0,get6:0,refund:0,get7:0},
      emptyQty:0,
      pageTotal: 0,
      pic1:null,
      pic2:null,
      pic3:null,
      pic4:null,
      tableData: [],
      historyBooking:[],
      historyFlow:[],
      dialogFormVisible: false,dialogFormChangeVisible:false,
      sysTime: '',
      q:{clientOrderId:null,qAreaId:null,qStoreStatus:null,qStoreId:null,qPhone:null,qFlow:null,sDate:null,qType:null,pageCurrent: 1,qOrderType:'訂單成立⇡'},
      qStoreId_:'',
      orderType_1:'訂單成立⇡',orderType_2:'取車時間⇡',orderType_3:'訂單狀態⇡',orderType_4:'還車時間⇡',
      form: {
        plusBuyNote: '',
        stagePriceNote: '',
        hock: 3000,
        stageTotalPrice: 0,
        payStage:[{seq:1,price:'0',chargeDate: moment().format('YYYY-MM-DD HH:mm:ss'), startDistance: '', endDistance: '', note: '', adminNote:'', canPay: 'N'}],
        eDateDate: '',
        eDateHour: '',
        name: '',
        region: '',
        date1: '',
        date2: '',
        type: [],
        resource: '',
        desc: '',
        plusInsurance: '',
        flow: '',
        cancelNote: '',
        reserveNote:'',
        adminNote:'',
        motoLicenseType:'',
        clientNote:'',
        priceList:[],
        checkCode:'',
        nowDistance:'',
        detailPrice:null,
        prodId:null,
        deposit:0,
        depositPayMethod: '',
        change_sDateDate:'',
        change_eDateDate:'',
        change_sDateHour:'',
        change_eDateHour:'',
        change_prodStockRowId:null,
        eDate:null,sDate:null,catId:null,prodPriceRowId:null,returnPrice:null,
        rentDateType:'',
        insurancePrice:'',
        raincoat: 1,
        helmet: 1,
        helmetFull: 0,
        beforePrice: 0,
        beforePay: 'F',
        beforePayMethod: '',
        saleInsuranceAbled: false,
        plusFee: 'T',
        clientEmail: '',
        change_clientUserId:'',
        prodDistance: 0
      },
      formLabelWidth: '139px',
      formLabelWidth_w: '250px',
      areas: [],
      storeStatusList: [
        {code:'', disName:'全部'},
        {code:'1', disName:'營業中'},
        {code:'0', disName:'停業'}
      ],
      stores: [],
      carNos: [],
      carList:[],
      flows:[{code:'',disName:'全部'},{code:'0',disName:'等待確認'},{code:'10',disName:'等待付訂'},{code:'50',disName:'等待取車'}],
      isLongRent:false,
      fullscreenLoading: false,
      isCal: false,
      changeReturnPay: 0,
      changeReturnPayVatNo: '',
      changeReturnPayVatCompanyTitle: '',
      changeCancelNote: '',
      changeProdStockRowId: null,
      showPic: false,
      enabledDistance: 'T',
      qCarNo: ''
    }
  },
  created() {
    if(this.$route.query.qType!=undefined){
      this.q.qType=this.$route.query.qType
    }else{
      this.q.qType=null
    }
    if(this.$route.query.clientOrderId!=undefined){
      this.q.clientOrderId=this.$route.query.clientOrderId
      this.q.qType=null
    }
    if(this.$route.query.qStoreId != undefined && this.$route.query.qStoreId != ''){
      this.q.qStoreId = this.$route.query.qStoreId
      let tempStore = this.$route.query.qStoreId.split(',')
      this.qStoreId_ = []
      tempStore.forEach(e =>{
        this.qStoreId_.push(parseInt(e))
      })
    }

    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        if(res.data.areas.length>1){
          this.areas.push({code:'',disName:'全部'})
        }
        res.data.areas.forEach(e => {
          this.areas.push(e)
        });
        this.q.qAreaId = this.areas[0].code
        res.data.authStores.forEach(e => {
          this.stores.push({
            code: e.code,
            disName: e.disName,
            areaId: e.areaId,
            status: e.status
          })
        });
        this.q.qFlow=this.flows[0].code
        this.q.qStoreStatus='1'

        // res.data.storeStatus.forEach(e => { // 查後端店家狀態，未來擴展用
        //   this.storeStatusList.push({
        //     code: e.code,
        //     disName: e.disName
        //   })
        // })
        this.cardClick(this.q.qType)
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  name: 'AdminQuickGet',
  methods: {
    getQueryStoreId(){
      if (this.qStoreId_.length > 0) {
        this.comfirmScheduleModelsContent = this.qStoreId_[0];
      } else {
        this.comfirmScheduleModelsContent = '';
      }
    },
    getClientRentDateLongPayStageList(clientOrderId){
      // 取得訂單資料
      this.$axios.get('../api/clientOrderQuickReturn/getClientOrderRentDateTypeLongStagePayList', {
        params: {clientOrderId}
      }).then((stagePayListRes) => {
        stagePayListRes.data.clientOrderStageTrans.forEach(e => {
          e.seq = e.stageIndex
          if(e.seq == 0 ) {
            e.seq = '押金'
          }
          e.chargeDate = this.$date_(e.chargeDate)
          if(e.payDate !=null) {
            e.payDate = this.$date_(e.payDate)
          }
          if(e.payStatus == 'SUCCESS') {
            e.payStatus = '成功'
          }
        })
        for(let row of stagePayListRes.data.clientOrderStageTrans) {
          if(row.payDate == null) {
            row.disablePaySwitch = false
          } else {
            row.disablePaySwitch = true
          }
        }
        this.form.payStage = stagePayListRes.data.clientOrderStageTrans
        if(this.form.payStage.length == 0) {
          this.addPayStage()
        }
        this.form.plusBuyNote = stagePayListRes.data.note.plusBuyNote
        this.form.stagePriceNote = stagePayListRes.data.note.stagePriceNote
        if(stagePayListRes.data.note.stagePriceNote == undefined) {
          this.form.stagePriceNote = `Gogoro 月租方案包含600公里免費里程，周租方案包含150公里，超額里程費用說明，601-1200每公里加收3.5元，1201以上每公里加收5元，Viva車款 601以上每公里加收1元`
        }
        this.recountStagePrice()
      })
    },
    deleteStageTrans(index){
      let rowIndex = 0
      for(let r of this.form.payStage) {
        rowIndex++
        if(r.seq == index) {
          break
        }
      }
      this.form.payStage.splice((rowIndex-1), 1)
      rowIndex = 0
      for(let r = 0;r<this.form.payStage.length;r++) {
        if(this.form.payStage[r].seq == '押金') {
          continue
        }
        rowIndex++
        this.form.payStage[r].seq  = rowIndex
      }
      this.recountStagePrice()
    },
    addPayStage(){
      let seq = (this.form.payStage.length + 1)
      if(this.form.payStage.length > 0 && this.form.payStage[0].seq == '押金') {
        seq = (this.form.payStage.length )
      }
      this.form.payStage.push({canPay: 'N', seq: seq, price:'0',chargeDate: moment().add(1, 'days').add(1, 'hours').startOf('hour').format('YYYY-MM-DD HH:mm:ss'), startDistance: '', endDistance: '', note: '', adminNote:''})
    },
    setComfirmModalEvent (bol,clientOrder) {
      if(bol){
        if (!/^[A-Z]\d{9}$/g.test(clientOrder.clientUserId)) {
          this.$alert('此會員帳號格式不對，只可使用於符合台灣身分證資料格式','結果',{confirmButtonText:'確定'})
          return
        } else if (!/^\d\d\d\d-?\d\d-?\d\d$/g.test(clientOrder.clientBirthday)) {
          this.$alert('此會員生日格式不對','結果',{confirmButtonText:'確定'})
          return
        }
      }
      this.comfirmModal = bol
      this.comfirmModelsContent = clientOrder
    },
    comfirmModalOkEvent (bol) {
      this.comfirmModal = bol
    },
    comfirmModalCancelEvent (bol) {
      this.comfirmModal = bol
    },
    setComfirmScheduleModalEvent (bol) {
      if (bol) {
        if (this.qStoreId_.length === 0 || this.qStoreId_[0] === '' || this.qStoreId_[0] === undefined) {
          this.$alert('請選擇要查詢的門市', '結果', { confirmButtonText: '確定' });
          return;
        }
        if(this.qStoreId_.length>1){
          this.$alert('庫存表查詢只能選一店家','結果',{confirmButtonText:'確定'})
          this.qStoreId_=''
          return
        }
        this.$nextTick(() => { // 丟店家查詢參數給clientOrderScheduleDialog並呼叫方法
          this.$refs.clientOrderScheduleDialog.$emit('updateStoreId', this.qStoreId_[0]);
          this.$refs.clientOrderScheduleDialog.query();
          this.$refs.clientOrderScheduleDialog.queryParamsFromQuickGet();
        });
      }
      this.comfirmScheduleModal = bol
    },
    comfirmScheduleModalOkEvent (bol) {
      this.comfirmScheduleModal = bol
    },
    comfirmScheduleModalCancelEvent (bol) {
      this.comfirmScheduleModal = bol
    },
    confimrChangeReturnPay () {
      if (/\D/g.test(this.changeReturnPay) || this.changeReturnPay === '') {
        this.$alert('請輸入有效金額','結果',{confirmButtonText:'確定'})
        return
      } else if (parseInt(this.changeReturnPay) > this.form.deposit) {
        this.$alert('退款金額不可大於訂金','結果',{confirmButtonText:'確定'})
        return
      }
      if ( this.form.flow === '50') {
        this.$confirm('是否確定取消訂單呢?', '注意', {
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.fullscreenLoading = true
            let _depositPayMethod = 'CASH'
            if (this.form.depositPayMethod === '信用卡') {
              _depositPayMethod = 'CREDIT'
            }else if (this.form.depositPayMethod === 'LINE PAY'){
              _depositPayMethod = 'LINEPAY'
            }else if (this.form.depositPayMethod === '悠遊卡'){
              _depositPayMethod = 'EZCARD'
            }else if (this.form.depositPayMethod === '轉帳'){
              _depositPayMethod = 'ATM'
            }
            let pa = {
              clientOrderId: this.form.clientOrderId,
              returnPay: this.changeReturnPay,
              vatNo: this.changeReturnPayVatNo,
              vatCompanyTitle: this.changeReturnPayVatCompanyTitle,
              cancelNote: this.changeCancelNote,
              plusFee: this.form.plusFee,
              returnType: _depositPayMethod,
              clientUserBankAccount: this.form.clientUserBankAccount
            };
            let url = '../api/clientOrderQuickReturn/returnClientOrderEntirePay'
            this.$axios.post(url, pa).then((res) => {
              this.fullscreenLoading = false
              if(res.data.rs === 'success') {
                this.query()
                this.dialogFormChangeVisible = false
              }
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
            });
        }).catch(() => {
          //
        }); 
      } else if (this.form.flow === '0' || this.form.flow === '10') {
        this.form.cancelNote = this.changeCancelNote
        this.confirmEditStore('1000')
      }
        
    },
    displayStore (item) {
      const areaSelect = item.areaId == this.q.qAreaId || this.q.qAreaId == ''
      const statusSelect = item.status == this.q.qStoreStatus || this.q.qStoreStatus == ''
      return areaSelect && statusSelect
    },
    cardClick(qType){
      this.q.qStoreId = ''
      this.qStoreId_.forEach(e=>{
        this.q.qStoreId = this.q.qStoreId + e +','
      })
      if (this.qStoreId_.length > 0){
        this.q.qStoreId = this.q.qStoreId.substring(0,this.q.qStoreId.length -1)
      }
      if (qType === 'refund'){
        this.$router.push({
          path:'/clientUserApplyCancelForm',
          query: { qType:qType,qStoreId:this.qStoreId_[0]}
        });
      }
      if (qType === 'get7'){
        this.$router.push({
          path:'/adminClientOrder',
          query: { qType:qType,qStoreId:this.qStoreId_[0]}
        });
      }
      this.q.qType=qType;
      this.query()
    },
    checkDistance() {
      if (this.enabledDistance === 'F') {
        this.form.nowDistance = '0'
      } else {
        if (parseInt(this.form.nowDistance) - parseInt(this.form.prodDistance) >= 10 &&parseInt(this.form.nowDistance) - parseInt(this.form.prodDistance) < 40) {
          this.$alert('', '注意', { confirmButtonText: '確定' })
        }
      }
    },
    getPrice() {
      if (this.enabledDistance === 'F') {
        this.form.nowDistance = '0'
      }
      //取車作業使用
      let sDate=null;
      let eDate = this.form.eDateDate;
      eDate = moment(eDate).format('YYYYMMDD')
      eDate = eDate + this.form.eDateHour.replace(/\D/gi,'') + '00'
      let now = moment(new Date())
      // console.log(this.form.change_eDateHour)
      //修改時間使用
      if(this.dialogFormChangeVisible){
        sDate=moment(this.form.change_sDateDate).format('YYYYMMDD')+this.form.change_sDateHour.replace(/\D/gi,'') + '00';
        eDate=moment(this.form.change_eDateDate).format('YYYYMMDD')+this.form.change_eDateHour.replace(/\D/gi,'') + '00';
      }
      
      if(now > moment(eDate,'YYYYMMDDHHmmss')){
        this.$alert('還車時間不可早於現在時間', '結果', {confirmButtonText: '確定'});
        return 
      }

      this.isCal = true
      this.$axios
      .get('../api/booking/getSysTime14D', {})
      .then((res) => {
        if(this.dialogFormVisible){
          this.form.realStartDate=res.data
          sDate=res.data
        }
        
        let param = {
          clientOrderId: this.form.clientOrderId, //透過訂單去找計價邏輯
          prodPriceRowId: this.form.prodPriceRowId,//當初訂單的計價
          sDate: sDate, //要有年月日時分秒
          eDate: eDate,
          plusInsurance: this.form.plusInsurance ? '1' : '0'
        };
        if (this.changeProdStockRowId !== null && this.changeProdStockRowId !== '') {
          this.form.change_prodStockRowId.forEach(e =>{
          if (e.prodStockId === this.changeProdStockRowId) {
            param.prodPriceRowId = e.prodPriceRowId
          }
          })
        }

        this.$axios
          .get('../api/clientOrderQuickGet/getClientOrderPrice', {
            params: param
          })
          .then((res) => {
            this.isCal = false
            this.form.priceList.splice(0,this.form.priceList.length)
            let ps = res.data.priceList
            this.form.price = 0
            this.form.deposit = 0
            for (var key in ps) {
              if (key === 'rideHour') {
                this.rideHour = ps[key]
              } else {
                if (key === 'ridePrice') {
                  // ps[key] = 3
                  this.form.price = this.form.price + ps[key]
                  this.costPrice = this.costPrice + ps[key]
                  this.form.priceList.push({ name: '車資費用:', amt: ps[key] })
                  // 超額里程說明(單純額外備註顯示用，後端已經把金額列入計算)
                  if (res.data.additionNote !== undefined) {
                    this.form.priceList.push({ name: '(含超額里程費):', amt: res.data.additionNote.overDistance })
                  }
                } else if (key === 'isrPrice') {
                  if (ps['ridePrice'] === 0) {
                    ps[key] = 0
                  }
                  this.form.price = this.form.price + ps[key] 
                  this.costPrice = this.costPrice + ps[key] //車資
                  this.form.priceList.push({ name: '保障費用:', amt: ps[key] })
                } else if (key === 'deposit') {
                  if (ps[key] > 0) {
                    this.form.deposit = ps[key]
                  }
                }
              }
            }
            this.form.detailPrice = this.form.price
            this.form.beforePrice = this.form.price - this.form.deposit
          }).catch(() => {
            this.isCal = false
            this.$alert('計算失敗請洽系統管理者', '結果', { confirmButtonText: '確定' });
          });
      }).catch(() => {
        this.isCal = false
        this.$alert('計算失敗請洽系統管理者', '結果', { confirmButtonText: '確定' });
      });
    },
    query() {
      this.$axios
        .get('../api/clientOrderQuickGet/getClientOrdersQuickGet', {
          params: this.q
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.tableData.data.forEach(store => {
            if (store.flow == '100') {
              store.flowType=''
            } else if (store.flow == '500' || store.flow == '600' ||  store.flow == '1000' || store.flow == '1100') {
              store.flowType='info'
            } else if (store.flow == '50') {
              store.flowType='success'
            } else if (store.flow == '10') {
              store.flowType='warning'
            } else if (store.flow == '400' || store.flow == '0' ) {
              store.flowType='danger'
            }
            store.flow = this.$flow_(store.flow)
            store.sDate = this.$date_(store.sDate)
            store.eDate = this.$date_(store.eDate)
            store.createDate = this.$date_(store.createDate)
            this.tableData.push(store)
            if(store.boundleCreditCard === 'T') {
              store.isBoundleType = 'success'
              store.isBoundle = '是'
            } else {
              store.isBoundleType = 'warning'
              store.isBoundle = '否'
            }
          })
          this.pageTotal = res.data.tableData.pageTotal
          this.board=Object.assign({},this.board,res.data.board);
          if(this.q.clientOrderId!=null&&this.tableData.length>0){
            this.q.clientOrderId=null
            this.showChangeDetailForm(this.tableData[0])
          }
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error','結果',{confirmButtonText:'確定'})
        });  
    },
    getClientOrderGetForBoard(qType){
      this.$axios
        .get('../api/clientOrderQuickGet/getClientOrderGetForBoard', {
          params: {qStoreId:this.q.qStoreId,qType:qType}
        }).then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.tableData.data.forEach(store => {
            store.flow = this.$flow_(store.flow)
            store.sDate = this.$date_(store.sDate)
            this.tableData.push(store)
          })
        }).catch((res) => {
          console.log(res);
        });    
    },
    validDate(oldDateTime,newDate,newTime){
        if(newDate==null||newDate==''||
          newTime==null||newTime==''){
          this.$alert('請填入正確日期與時間','結果',{confirmButtonText:'確定'})
          return
        }else{
          return true
        }
    },
    setForceGetPrice(){
      this.form.priceList.splice(0,this.form.priceList.length)
      this.form.detailPrice=null
    },
    confirmChangeClientOrder(){
      if(this.form.detailPrice==null){
        this.$alert('請先計算金額','結果',{confirmButtonText:'確定'})
        return 
      }
      //先檢查有沒有超時
      let pa ={};
      // if(type=='sDate'){
      
      let newDateTime=this.validDate(this.form.sDate,this.form.change_sDateDate,this.form.change_sDateHour);
      if(newDateTime==null){
        return
      }
      let newEDateTime=this.validDate(this.form.sDate,this.form.change_eDateDate,this.form.change_eDateHour);
      if(newEDateTime==null){
        return
      }

      let sDateStr=moment(this.form.change_sDateDate).format('YYYYMMDD')
      let newSDateTime=parseInt((sDateStr+this.form.change_sDateHour).replace(/\D/gi,'')+'00')

      let eDateStr=moment(this.form.change_eDateDate).format('YYYYMMDD')
      newEDateTime=parseInt((eDateStr+this.form.change_eDateHour).replace(/\D/gi,'')+'00')   

      // let nowDate=parseInt(moment(this.form.change_eDateDate).format('YYYYMMDDHHmm'))
      // console.log(newEDateTime,newSDateTime)
      // if(nowDate>newSDateTime){ //有問題
      //   alert('取車時間不可比現在早')
      //   return
      // }

      if(newSDateTime>=newEDateTime){
        this.$alert('還車日期不可比取車日期早','結果',{confirmButtonText:'確定'})
        return
      }

      pa= {
        clientOrderId: this.form.clientOrderId,
        sDate:newSDateTime,
        eDate:newEDateTime,
        price:this.form.detailPrice
      };
      this.$confirm('是否確定更改日期','注意',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{

      this.fullscreenLoading = true
      let url = '../api/clientOrderQuickGet/changeClientOrder'

      this.$axios
        .post(url, pa)
        .then(() => {
          this.fullscreenLoading = false
          this.query()
          this.dialogFormChangeVisible = false
        }).catch((res) => {
          this.fullscreenLoading = false
          this.$alert('傳送失敗','結果',{confirmButtonText:'確定'})
          console.log(res.data);
        });
        }).catch(()=>{
          return
        })
    },
    confirmChangeClientOrderStock(){
      if(this.form.detailPrice==null){
        this.$alert('請先計算金額','結果',{confirmButtonText:'確定'})
        return 
      }
      let prodCatId_ = ''
      let prodStockId_ = ''
      let prodPriceId_ = ''
      this.form.change_prodStockRowId.forEach(e =>{
        if (e.prodStockId === this.changeProdStockRowId) {
          prodCatId_ = e.catId
          prodStockId_=e.prodStockId
          prodPriceId_ = e.prodPriceRowId
        }
      })
      let pa= {
        prodCatId: prodCatId_,
        clientOrderId: this.form.clientOrderId,
        prodStockId: prodStockId_,
        prodPriceId: prodPriceId_,
        price:this.form.detailPrice
      };
      this.$confirm('是否確定更改車型','注意',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
      this.fullscreenLoading = true
      let url = '../api/clientOrderQuickGet/changeClientOrderStock'
      this.$axios
        .post(url, pa)
        .then(() => {
          this.fullscreenLoading = false
          this.query()
          this.dialogFormChangeVisible = false
        }).catch((res) => {
          this.fullscreenLoading = false
          this.$alert('傳送失敗','結果',{confirmButtonText:'確定'})
          console.log(res.data);
        });
        }).catch(()=>{
          return
        })
    },
    confirmChangeClientUserId(){
      this.form.change_clientUserId = this.form.change_clientUserId.toUpperCase()
      this.$axios
        .get('../api/booking/checkClientUserId?clientUserId='+this.form.change_clientUserId,{})
        .then((res)=>{
          if(res.data.disName==undefined){
            this.$alert('該用戶沒有註冊過，無法轉移訂單', '結果', { confirmButtonText: '確定' });
            return
          } else {
            let pa= {
              clientOrderId: this.form.clientOrderId,
              newClientUserId: this.form.change_clientUserId
            };
            this.$confirm('是否確定轉移訂單至客戶：'+res.data.disName,'注意',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
              }).then(()=>{
            this.fullscreenLoading = true
            let url = '../api/clientOrderQuickGet/changeClientOrderUser'
            this.$axios.post(url, pa).then(() => {
                this.fullscreenLoading = false
                this.query()
                this.dialogFormChangeVisible = false
              }).catch((res) => {
                this.fullscreenLoading = false
                this.$alert('傳送失敗','結果',{confirmButtonText:'確定'})
                console.log(res.data);
              });
              }).catch(()=>{
                return
              })

          }
      }).catch(()=>{

      }); 
    },
    saveStagePay(){
      let pa = {
        clientOrderId: this.form.clientOrderId,
        flow: '50',
        reserveNote:this.form.reserveNote,
        deposit:this.form.deposit
      };
      if (this.form.rentDateType == 2) {
        pa.insurancePrice = 0
        pa.plusInsurance='0'
        pa.price = this.stageTotalPrice
        this.form.payStage.forEach(e => {
          if(e.chargeDate != null) {
            e.chargeDateStr = moment(e.chargeDate).format('YYYYMMDDHHmmss')
          }
        })
        pa.payStage = JSON.stringify(this.form.payStage)
        pa.hock = this.form.hock
        pa.plusBuyNote = this.form.plusBuyNote
        pa.stagePriceNote = this.form.stagePriceNote
      }
      this.fullscreenLoading = true
      let url = '../api/clientOrderQuickGet/saveStagePay'
      this.$axios.post(url, pa).then((res) => {
          this.fullscreenLoading = false
          if (res.data.rs !== 'success') {
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          } else {
            this.$alert('儲存完成', '結果', { confirmButtonText: '確定' });
          }
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert('更新失敗', '結果', { confirmButtonText: '確定' });
        });      
    },
    confirmEditStoreNextFlow50(deposit){ //收訂或不收訂 利用deposit = 0 判斷
      let confirmTitle = ''
      if (deposit !== undefined) {
        this.form.deposit = 0
        confirmTitle = '確定不收訂，等待取車嗎?'
      } else {
        this.form.deposit = 200
        confirmTitle = '確定已收現金訂金，等待取車嗎?'
      }

      let pa = {
        clientOrderId: this.form.clientOrderId,
        flow: '50',
        reserveNote:this.form.reserveNote,
        deposit:this.form.deposit
      };

      // 長租
      if (this.form.rentDateType == 2) {
        pa.insurancePrice = 0
        pa.plusInsurance='0'
        pa.price = this.stageTotalPrice
        this.form.payStage.forEach(e => {
          if(e.chargeDate != null) {
            e.chargeDateStr = moment(e.chargeDate).format('YYYYMMDDHHmmss')
          }
        })
        pa.payStage = JSON.stringify(this.form.payStage)
        pa.hock = this.form.hock
        pa.plusBuyNote = this.form.plusBuyNote
        pa.stagePriceNote = this.form.stagePriceNote
      }

      this.$confirm(confirmTitle,'注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{

          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickGet/receiveZeroPay'
          this.$axios.post(url, pa).then((res) => {
              this.fullscreenLoading = false
              if (res.data.rs === 'success') {
                this.query()
                this.dialogFormVisible = false
              } else {
                  this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
              }
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('訂單更新失敗', '結果', { confirmButtonText: '確定' });
            });
        }).catch(()=>{
          return
        })
    },
    confirmRecovery10Flow(){
      let pa = {
        clientOrderId: this.form.clientOrderId
      };
      
      this.$confirm('是否確定更訂金狀態變更，如有收取訂金請留意須退還訂金?','注意',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{

      this.fullscreenLoading = true
      let url = '../api/clientOrderQuickGet/confirmRecovery10Flow'
      this.$axios
        .post(url, pa)
        .then((res) => {
          this.fullscreenLoading = false
          if(res.data.rs=='success'){
            this.query()
            this.dialogFormVisible = false
          }else{
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert('不知名錯誤', '結果', { confirmButtonText: '確定' });
        });
        }).catch(()=>{
          return
        })
    },
    confirmEditStore(nexFlow) {
      let confirmTitle = ''
      if (nexFlow === '100') { //確定取車
        if(this.form.prodId == null || this.form.prodId == ''){
          this.$alert('車號不可為空', '結果', {confirmButtonText: '確定'});
          return;
        }
        if (this.form.rentDateType == '1' && this.form.detailPrice == null) {
          this.$alert('請先計算金額', '結果', {confirmButtonText: '確定'});
          return 
        }
        if (this.form.checkCode == '') {
          this.$alert('請先輸入員工碼', '結果', {confirmButtonText: '確定'});
          return 
        }
        if (this.form.nowDistance == '' && this.enabledDistance == 'T') {
          this.$alert('里程數格式不對', '結果', {confirmButtonText: '確定'});
          return 
        }
        if (this.form.nowDistance == '' && this.enabledDistance == 'F') {
          this.$alert('請先按下重新計算', '結果', {confirmButtonText: '確定'});
          return 
        }
        
        confirmTitle = '確定開始租車嗎?'
        if (this.form.beforePay === 'T') {
          confirmTitle = '確定收取預付金額'+ this.form.beforePrice +'元，與開始租車嗎?'
        }

        if(this.form.rentDateType == '2') {
          confirmTitle = `長租押金:${this.form.hock}，訂單費用${this.stageTotalPrice}元，原始訂單費用${this.form.price}，確定開始租車嗎?`
        }

        let eDate = this.form.eDateDate;
        let str = eDate.getFullYear().toString()
        str += (eDate.getMonth() + 1) >= 10 ? (eDate.getMonth() + 1).toString() : '0' + (eDate.getMonth() + 1).toString()
        str += eDate.getDate() >= 10 ? eDate.getDate().toString() : '0' + eDate.getDate().toString()
        str = str + this.form.eDateHour
        let now = moment(new Date())
        if(now > moment(str,'YYYYMMDDHH:mm')){
          this.$alert('還車時間不可早於現在時間', '結果', {confirmButtonText: '確定'});
          return 
        }
        if (this.enabledDistance !== 'F') {
          if (parseInt(this.form.nowDistance) - parseInt(this.form.prodDistance) >= 10 &&parseInt(this.form.nowDistance) - parseInt(this.form.prodDistance) < 40) {
            this.$confirm('輸入里程大於登記里程10公里以上，請確認是否正確','注意',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
              }).catch(()=> {
                return
              })
          }
          if (parseInt(this.form.nowDistance) - parseInt(this.form.prodDistance) >= 40) {
            this.$alert('輸入里程大於登記里程40公里以上，請重新輸入', '結果', {confirmButtonText: '確定'});
            return
          }
        }
      this.$confirm(confirmTitle,'注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.fullscreenLoading = true
          let url = '../api/adminUser/validCheckCode?storeId=' + this.form.storeId + '&checkCode=' + this.form.checkCode;
          this.$axios.get(url).then((res) => {
              if (res.data.rs) {
                let pa = {
                  clientOrderId: this.form.clientOrderId,
                  catId: this.form.catId,
                  eDate: str,
                  startDistance: this.form.nowDistance,
                  prodId: this.form.prodId,// 確定的車牌
                  plusInsurance: this.form.plusInsurance ? '1' : '0',
                  checkCode:this.form.checkCode,
                  reserveNote:this.form.reserveNote,
                  insurancePrice:this.form.insurancePrice,
                  price: this.form.detailPrice,
                  deposit: this.form.deposit,
                  raincoat: this.form.raincoat,
                  helmet: this.form.helmet,
                  helmetFull: this.form.helmetFull,
                }
                // 長租
                if (this.form.rentDateType == 2) {
                  pa.insurancePrice = 0
                  pa.plusInsurance='0'
                  pa.price = this.stageTotalPrice
                  this.form.payStage.forEach(e => {
                    if(e.chargeDate != null) {
                      e.chargeDateStr = moment(e.chargeDate).format('YYYYMMDDHHmmss')
                    }
                  })
                  pa.payStage = JSON.stringify(this.form.payStage)
                  pa.hock = this.form.hock
                  pa.plusBuyNote = this.form.plusBuyNote
                  pa.stagePriceNote = this.form.stagePriceNote
                }
                if (this.form.beforePay === 'T') {
                  pa.beforePrice = this.form.beforePrice
                  pa.beforePay = this.form.beforePay
                }

                // console.log(pa)
                // if(pa != null) {
                //   return
                // }

                let url = '../api/clientOrderQuickGet/startClientOrder'
                this.$axios.post(url, pa).then((res) => {
                    this.fullscreenLoading = false
                    if(res.data.rs == 'success'){
                      this.query()
                      this.dialogFormVisible = false
                    }
                    this.$alert(res.data.rsMsg, '結果', {confirmButtonText: '確定'});
                  }).catch(() => {
                    this.fullscreenLoading = false
                    this.$alert('服務器錯誤', '結果', {confirmButtonText: '確定'});
                  });
              } else {
                this.$alert('員工碼錯誤', '結果', {confirmButtonText: '確定'});
                this.fullscreenLoading = false
              }
            }).catch((res) => {
              this.fullscreenLoading = false
              console.log(res.data);
            });
        }).catch(()=>{
          return
        })
      } else if (nexFlow=='10') { // 開放付款
        let pa = {
          clientOrderId: this.form.clientOrderId,
          flow: nexFlow,
          reserveNote:this.form.reserveNote
        };
        this.$confirm('是否已經確認有空車可以租借？','注意',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{

        this.fullscreenLoading = true
        let url = '../api/clientOrderQuickGet/reserveClientOrder'
        this.$axios
          .post(url, pa)
          .then(() => {
            this.fullscreenLoading = false
            this.query()
            this.dialogFormVisible = false
          }).catch(() => {
            this.fullscreenLoading = false
            this.$alert('更新失敗', '結果', {confirmButtonText: '確定'});
          });
        }).catch(()=>{
          return
        })
      } else if (nexFlow == '50') { //確認收定 
        console.log('no use')
      } else if (nexFlow == '1000') { //取消預約
        this.$confirm('是否取消預約呢?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let pa = {
            clientOrderId: this.form.clientOrderId,
            flow: nexFlow,
            cancelNote: this.form.cancelNote
          };
          this.fullscreenLoading = true
          let url = '../api/clientOrderQuickGet/cancelClientOrder'
          this.$axios
            .post(url, pa)
            .then(() => {
              this.fullscreenLoading = false
              this.query()
              this.dialogFormVisible = false
              this.dialogFormChangeVisible = false
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('取消失敗', '結果', {confirmButtonText: '確定'});
            });
        }).catch(() => {

        }); 

      } else if (nexFlow == '1100') { //取消訂單並退費
        this.$confirm('是否取消訂單呢?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let pa = {
              clientOrderId: this.form.clientOrderId,
              flow: nexFlow,
              returnPrice:this.form.returnPrice,
              cancelNote: this.form.cancelNote
            };
            this.fullscreenLoading = true
            let url = '../api/clientOrderQuickGet/cancelClientOrderAndReturnMoney'
            this.$axios
            .post(url, pa)
            .then((res) => {
              this.fullscreenLoading = false
              if(res.data.rs == 'success'){
                this.query()
                this.dialogFormChangeVisible = false
                this.dialogFormVisible = false
              }else{
                this.$alert(res.data.msg, '結果', {confirmButtonText: '確定'});
              }
            }).catch((res) => {
              this.fullscreenLoading = false
              console.log(res.data);
            });
        }).catch(() => {

        });      
      }
    },
    changeCarNo(){
      this.form.priceList.splice(0,this.form.priceList.length)
      if(!this.isLongRent){
        this.form.detailPrice=null
      }
      for(var i in this.carNos){
        if(this.form.prodId==this.carNos[i].code){
          // console.log(this.carNos[i])
          this.form.prodPriceRowId=this.carNos[i].prodPriceRowId
          this.enabledDistance = this.carNos[i].enabledDistance
          this.form.prodDistance = this.carNos[i].nowDistance
          break;
        }
      }
    },
    showDetailForm(clientOrder) {//取車頁面 顯示可以取車的列表
    this.showPic =false
    this.pic1 = null
    this.pic2 = null
    this.pic3 = null
    this.pic4 = null
    this.form.plusInsurancese
    this.form.payStage = []
    let params = {
      clientOrderId: clientOrder.clientOrderId
    }
    this.$axios
      .get('../api/clientOrderQuickGet/getClientOrderForQuickForm', {
        params: params
      })
      .then((res) => {
        this.carNos.splice(0, this.carNos.length)
        this.carList.splice(0, this.carList.length)
        this.dialogFormVisible = true
        this.form = Object.assign({}, this.form, res.data[0])
        this.isLongRent = this.form.rentDateType==='2'
        res.data[0].carNos.forEach(e => {
          if(e.prodPriceRowId!=this.form.prodPriceRowId){
            e.diffPrice='(不同價格)'
          }else{
            e.diffPrice=''
          }
          this.carNos.push({
            code: e.prodId,
            disName: e.carNo+'('+e.color+')'+'本期里程:'+e.monthDistance+'km'+e.diffPrice,
            prodPriceRowId:e.prodPriceRowId,
            enabledDistance: e.enabledDistance,
            nowDistance: e.nowDistance
          })
          this.carList.push({
            code: e.prodId,
            disName: e.carNo+'('+e.color+')'+'本期里程:'+e.monthDistance+'km'+e.diffPrice,
            prodPriceRowId:e.prodPriceRowId,
            enabledDistance: e.enabledDistance,
            nowDistance: e.nowDistance
          })
        })
        this.form.priceList.splice(0,this.form.priceList.length)
        let eDate = this.form.eDate;
        this.form.eDateDate = new Date(this.$getDateTime_('date', eDate));
        this.form.eDateHour = this.$getDateTime_('time', eDate).substring(0, 5);
        this.form.sDate = this.$date_(this.form.sDate)
        this.form.eDate=this.$date_(this.form.eDate)
        
        this.form.plusInsurance = false
        this.form.saleInsuranceAbled = this.form.saleInsuranceAbled === 'T'
        this.form.catDisName = this.$catDisName(this.$store.state.cats, this.form.catId)

        this.form.priceList.splice(0,this.form.priceList.length)
        this.form.prodId=null
        this.form.detailPrice=null
        this.form.nowDistance=''
        this.form.checkCode=''
        this.form.returnPrice = this.form.deposit
        switch(this.form.motoLicense){
          case "M":
            this.form.motoLicenseType = '普通重型機車( 150 CC以下)'
            break
          case "S":
            this.form.motoLicenseType = '輕型機車( 50 CC以下)'
            break
          case "N":
            this.form.motoLicenseType = '沒有駕照（微型電動車）'
            break
          default:
            this.form.motoLicenseType = '資料錯誤'
            break
        }
        this.historyFlow.splice(0, this.historyFlow.length)
        this.form.depositPayMethod = this.$payTypeDisName(this.form.depositPayMethod)
        res.data[0].historyFlow.forEach(e=>{
          e.updateDate=this.$date_(e.updateDate)
          this.historyFlow.push(e)
        })
        this.historyBooking.splice(0, this.historyBooking.length)
        res.data[0].historyBooking.forEach(e=>{
          e.createDate=this.$date_(e.createDate)
          this.historyBooking.push(e)
        })

        if(this.form.rentDateType == '2') { // 長租
          this.getClientRentDateLongPayStageList(this.form.clientOrderId)
        }
        if(this.form.flow=='50'&& 
          (this.form.pic1==null || this.form.pic1==null)){
          this.$alert('請注意,缺少客戶證件照片', '結果', {confirmButtonText: '確定'});
        }
        if(this.form.flow=='50'&&this.carNos.length==0){
          this.$alert('該客戶駕照('+this.form.motoLicenseType+')目前無庫存可以租借', '結果', {confirmButtonText: '確定'});
        }
        if(this.form.beforePrice === undefined) {
          this.form.beforePrice = 0
        }
      }).catch(() => {
          this.$alert('查詢失敗', '結果', {confirmButtonText: '確定'});
      });
    },
    showChangeDetailForm(clientOrder) {//車輛異動頁面
    this.showPic =false
    this.pic1 = null
    this.pic2 = null
    this.pic3 = null
    this.pic4 = null
    this.changeReturnPay = 0
    this.changeReturnPayVatNo = ''
    this.changeReturnPayVatCompanyTitle = ''
    this.changeCancelNote = ''
    this.changeProdStockRowId = null
      let params = {
        clientOrderId: clientOrder.clientOrderId
      }
      this.$axios
      .get('../api/booking/getSysTime14D', {})
      .then(() => {
        // let now14d=res.data;
        this.$axios
          .get('../api/clientOrderQuickGet/getClientOrderForQuickForm', {
            params: params
          })
          .then((res) => {
            this.dialogFormChangeVisible = true
            this.form = Object.assign({}, this.form, res.data[0])
            let eDate = this.form.eDate;
            this.isLongRent = this.form.rentDateType==='2'
            let momentSdate=moment(this.form.sDate, "YYYYMMDDHHmmSS");
            let momentEdate=moment(this.form.eDate, "YYYYMMDDHHmmSS");

            this.changeCancelNote = res.data[0].applyCancelNote

            this.form.change_sDateDate=momentSdate
            this.form.change_eDateDate=momentEdate
            this.form.change_sDateHour=momentSdate.format('HH:mm')
            this.form.change_eDateHour=momentEdate.format('HH:mm')

            this.form.eDateDate = new Date(this.$getDateTime_('date', eDate));
            this.form.eDateHour = this.$getDateTime_('time', eDate).substring(0, 5);
            this.form.sDate = this.$date_(this.form.sDate)
            this.form.eDate=this.$date_(this.form.eDate)
            this.form.applyDate = this.$date_(this.form.applyDate)
            
            this.form.plusInsurance = false
            this.form.catDisName = this.$catDisName(this.$store.state.cats, this.form.catId)

            this.form.priceList.splice(0,this.form.priceList.length)
            this.form.prodId=null
            this.form.detailPrice=null
            this.form.nowDistance=''
            this.form.checkCode=''
            this.form.change_clientUserId=''
            this.historyFlow.splice(0, this.historyFlow.length)
            this.form.depositPayMethod = this.$payTypeDisName(this.form.depositPayMethod)
            res.data[0].historyFlow.forEach(e=>{
              e.updateDate=this.$date_(e.updateDate)
              this.historyFlow.push(e)
            })
            this.historyBooking.splice(0, this.historyBooking.length)
            res.data[0].historyBooking.forEach(e=>{
              e.createDate=this.$date_(e.createDate)
              this.historyBooking.push(e)
            })
            if(this.form.flow=='50'&& 
              (this.form.pic1==null || this.form.pic1==null)){
              this.$alert('請注意,缺少客戶證件照片', '結果', {confirmButtonText: '確定'});
            }
            switch(this.form.motoLicense){
              case "M":
                this.form.motoLicenseType = '普通重型機車( 150 CC以下)'
                break
              case "S":
                this.form.motoLicenseType = '輕型機車( 50 CC以下)'
                break
              case "N":
                this.form.motoLicenseType = '沒有駕照（微型電動車）'
                break
              default:
                this.form.motoLicenseType = '資料錯誤'
                break
            }
          }).catch(() => {
            this.$alert('查詢失敗', '結果', {confirmButtonText: '確定'});
          });

      })
    },
    showClientPic () {
      this.showPic = !this.showPic
      if (this.showPic) {
          if (this.form.pic1 === null) { this.form.pic1 = 'clientUser/null'}
          if (this.form.pic2 === null) { this.form.pic2 = 'clientUser/null'}
          if (this.form.pic3 === null) { this.form.pic3 = 'clientUser/null'}
          if (this.form.pic4 === null) { this.form.pic4 = 'clientUser/null'}

          this.pic1 = '../api/adminClientUser/getUserPic/'+this.form.pic1
          this.pic2 = '../api/adminClientUser/getUserPic/'+this.form.pic2
          this.pic3 = '../api/adminClientUser/getUserPic/'+this.form.pic3
          this.pic4 = '../api/adminClientUser/getUserPic/'+this.form.pic4
      } 
    },
    updateReserveNote() {
      let param = {
        clientOrderId: this.form.clientOrderId,
        reserveNote: this.form.reserveNote
      }
      this.$confirm('是否確定修改備註','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() =>{
        this.$axios.post('../api/clientOrderQuickGet/updateReserveNote',param).then((res) =>{
          if (res.data.rs === 'success') {
            this.$alert('更新備註成功','結果',{confirmButtonText:'確定'})
            this.query()
          } else {
            this.$alert('更新備註失敗','結果',{confirmButtonText:'確定'})
          }
        })
      })
    },
    serchProd(){
      this.carList = Object.assign([])
      this.carNos.forEach(moto =>{
        if(moto.disName.indexOf(this.qCarNo) !== -1 || this.qCarNo == ""){
          this.carList.push(moto)
        }
      })
    },
    recountStagePrice () {
      if(this.form.rentDateType == '2' && this.form.payStage != null) {
        let price = 0
        this.form.payStage.forEach(e=>{
          if(e.seq != '押金' && e.price != null && /^\d+$/.test(e.price)) {
            price += parseInt(e.price)
          }
        })
        this.stageTotalPrice = price
      } else {
        this.stageTotalPrice = 0
      }
    },
  },
  computed:{
  },
  watch:{
    'form.payStage':function(){
      this.recountStagePrice()
    }
  }
}
</script>
<style scoped>
.red-title ::v-deep .el-form-item__label {
  color:red !important;
}

 .title > :first-child{
   font-size: 70%;
 }
 .fs > :first-child{
   font-size: 130%;
 }
 .bs > :first-child{
   font-size: 150%;
 }
</style>
<style scoped lang="scss">
.selectit{
  background-color: yellowgreen;
}
.empty{
  background-color: white;
}
.item-label{
  text-align:right;
  padding-right:20px;
}
.distance-input{
   width:180px;
 }
 .distance-input ::v-deep .el-input__inner{
   text-align: right;
   font-size: 150%;
 }
 .dialog-col-style{
   background-color:#DDDDDD;
   @extend .dialog-value-style;
 }

.dialog-value-style{
   border-style:solid;
   border-width:1px;
 }
</style>
