import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import Vuex from 'vuex'


// import axios2 from 'axios'
// import VueAxios from 'vue-axios'
import VueSignaturePad from 'vue-signature-pad';

import i18n from './i18n'



Vue.config.productionTip = false


Vue.use(VueSignaturePad);

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    newClientUser:null,
    count: 0,
    menuopens:['1','2'],
    auth:{
      login:false,
      f2:true,
      disName:'',
      userType:''
    },
    stores:[],
    cats:[],
    areas:[],
    loginNextPage:''
  },
  mutations: {
    logout(state){
      state.auth.login=false
    },
    login(state,data){
      state.auth.login=true
      state.auth.disName=data.adminUser.disName
      state.auth.userType=data.adminUser.userType
      state.stores.splice(0,data.stores.length)
      data.stores.forEach((e)=>{
          state.stores.push(e)
        });
        state.cats.splice(0,data.cats.length)
      data.cats.forEach((e)=>{
        state.cats.push(e)
      });
      state.areas.splice(0,data.areas.length)
      data.areas.forEach((e)=>{
        state.areas.push(e)
      });
    },
    incrementMu (state) {
      state.auth.f2= (!state.auth.f2)
      console.log('test111')
    }
  },
  actions: {
    changeAuth (context) {
      context.commit('incrementMu')
    },
    login (context,userAdmin) {
      context.commit('login',userAdmin)
    },
    logout (context) {
      context.commit('logout')
    }
  },  
  getters: {
    f2: state => {
      return state.auth.f2
    },
    login:state=>{
      return state.auth.login
    },
    disName:state=>{
      return state.auth.disName
    },
    userType:state=>{
      return state.auth.userType
    }
  }
});

router.beforeEach((to, from, next) => {
  // console.log(from)
  store.state.loginNextPage=from.path
  // console.log(store.state.loginNextPage)
  next()
})
// console.log(store.state);
// Vue.use(VueAxios,axios2 )
// Vue.prototype.$ajax = axios2
// axios2.defaults.baseURL = 'http://121.11.2012iot.com/';
// axios.defaults.headers.common['tokenStr'] = store.state.tokenStr;//这里使用了Vuex中的常量（需要先引入store）
// axios2.defaults.headers.post['Content-Type'] = 'application/json';

Vue.prototype.$flow_ = function(d){ 
  if(d==null||d==undefined){
      return ''
    }
  if(d=='0'){
    return '等待確認'
  }else if(d=='10'){
    return '等待付訂'
  }else if(d=='50'){
    return '等待取車'
  }else if(d=='100'){
    return '租車中'
  }else if(d=='400'){
    return '已還車'
  }else if(d=='500'){
    return '付款完成'
  // }else if(d=='600'){
  //   return '評價完成'
  }else if(d=='1000'){
    return '取消預約'
  }else if(d=='1100'){
    return '取消訂單'
  }else if(d=='1200'){
    return '自動取消'
  }
  return ''
}
Vue.prototype.$flow_combobox = function(){ 
  return [{code:'0',disName:'等待確認'},
    {code:'10',disName:'等待付訂'},
    {code:'50',disName:'等待取車'},
    {code:'100',disName:'租車中'},
    {code:'400',disName:'已還車'},
    {code:'500',disName:'付款完成'},
    // {code:'600',disName:'評價完成'},
    {code:'1000',disName:'取消預約'},
    {code:'1100',disName:'取消訂單'},
    {code:'1200',disName:'自動取消'}
    ]
}

Vue.prototype.$clientType_combobox = function(){ 
  return [{code:'1',disName:'網站預約'},
    {code:'2',disName:'會員系統預約'},
    {code:'3',disName:'現場預約'},
    {code:'4',disName:'電話預約'},
    {code:'5',disName:'OTA平台'},
    {code:'6',disName:'合作店家網站預約'},
    {code:'10',disName:'網站預約線上庫存'},
    {code:'20',disName:'會員系統預約線上庫存'}, 
    {code:'60',disName:'合作店家網站預約線上庫存'},
    {code:'500',disName:'其他'}
    ]
}

Vue.filter('filter_usePurpose', function (value) {
  if (!value) return ''
  value = value.toString()
  switch(value){
    case '1':
      return '旅遊'
    case '2':
      return '出差'
    case '3':
      return '環島'
    case '4':
      return '試乘Gogoro'
    case '5':
      return '活動需求'
  }
  return ''
})

Vue.filter('filter_getFrom', function (value) {
  if (!value) return ''
  value = value.toString()
  switch(value){
    case '1':
      return 'Google關鍵字搜尋'
    case '2':
      return 'FACEBOOK'
    case '3':
      return 'IG'
    case '4':
      return 'Dcard'
    case '5':
      return '傳播媒體(新聞、報紙)'
    case '6':
      return '親朋好友'
    case '7':
      return '合作夥伴 (雨都漫步 路思大 煙波大飯店)'
  }
  return ''
})


Vue.filter('filter_clientType', function (value) {
  if (!value) return ''
  value = value.toString()
  switch(value){
    case '1':
      return '網站預約'
    case '2':
      return '會員系統預約'
    case '3':
      return '現場預約'
    case '4':
      return '電話預約'
    case '5':
      return 'OTA平台'
    case '6':
      return '合作店家網站預約'
    case '10':
      return '網站預約線上庫存'
    case '20':
      return '會員系統預約線上庫存'
    case '60':
      return '合作店家網站預約線上庫存'
    case '500':
      return '其他'
  }
  return ''
})

Vue.filter('filter_commaFormate',value =>{
  return value.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
})

Vue.prototype.$getDateTime_ = function(f,d){ 
  if(d==null||d==undefined){
      return '';
  }
  if(f==null||f==undefined)
    f='time'

  if(d.length === 14){
    if(f=='time'){
      return d.substring(8,10)+':'+d.substring(10,12)+':'+d.substring(12,14);
    }else if(f=='_14'){
        return d.substring(0,4)+'-'+d.substring(4,6)+'-'+d.substring(6,8) +' '+d.substring(8,10)+':'+d.substring(10,12)+':'+d.substring(12,14);
    }else{
      return d.substring(0,4)+'-'+d.substring(4,6)+'-'+d.substring(6,8);
    }
  }
  return ''
}

Vue.prototype.$date_ = function(d){ 
    if(d==null||d==undefined){
        return ''
      }
    if(d.length==14){
      return d.substring(0,4)+'-'+d.substring(4,6)+'-'+d.substring(6,8)+' '+d.substring(8,10)+':'+d.substring(10,12)+':'+d.substring(12,14);
    }else if(d.length==12){
      return d.substring(0,4)+'-'+d.substring(4,6)+'-'+d.substring(6,8)+' '+d.substring(8,10)+':'+d.substring(10,12)+':00';
    }
    return ''
 }

Vue.prototype.$areaDisName = function(areas,areaIdstr){ 
 let areaId=parseInt(areaIdstr)
 
 for(var i in areas){
  let area=areas[i]
   if(area.code==areaId){
     return area.disName;
   }
 }
return ''
}

Vue.prototype.$storeDisName = function(stores,storeIdstr){ 
  let storeId=parseInt(storeIdstr)
  for(var i in stores){
    let store=stores[i]
    if(store.code==storeId){
      return store.disName;
    }
  }
  return ''
}

Vue.prototype.$payTypeDisName = function(payType) { 
  if(payType === 'CASH') {
    return '現金'
  } else if(payType === 'CREDIT') {
    return '信用卡'
  } else if(payType === 'LINEPAY') {
    return 'LINE PAY'
  } else if(payType === 'EZCARD') {
    return '悠遊卡'
  } else if(payType === 'ATM') {
    return '轉帳'
  } else if(payType === 'ICP') {
    return 'iCashPay'
  } else {
    return ''
  }
 }

Vue.prototype.$catDisName = function(cats,catIdstr){ 
  let catId=parseInt(catIdstr)
  
  for(var i in cats){
   let cat=cats[i]
    if(cat.code==catId){
      return cat.disName;
    }
  }
 return ''
}

Vue.prototype.$finStatus = function(finStatus,finStatusCode){ 
  for(var i in finStatus){
   let t=finStatus[i]
    if(t.code === finStatusCode){
      return t.disName;
    }
  }
 return ''
}

Vue.prototype.$isEmpty = function (item) {
  if (item == null || item == '' || item == undefined) {
    return true
  } else {
    return false
  }
}

Vue.use(require("moment"));



new Vue({
  router,
  render: h => h(App),
  i18n,
  store
}).$mount('#app')
