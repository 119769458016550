<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-form :model="form">
        <h2>一頁式還車</h2>
        <el-row>
          <el-col :span="6">
            <el-select v-model="form.storeId" placeholder="" @change="getprods">
              <el-option width="120" v-for="item in stores" :key="item.code" :label="item.disName" :value="item.code"></el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-input type="tel" placeholder="車號查詢" style="width:100px" @input="serchProd" v-model="qCarNo"></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <h3>車輛清單</h3>
          <span>目前選擇：{{ this.form.carNoDisName }} </span>
          <table style="display: block;overflow-x: auto;white-space: nowrap;">
            <td v-for="car in showProds" :key="car.prodId" style="width:100px" @click="selectProd(car)">
              <el-card style="text-align:center" :class="selected == car.prodId ? 'selectit' : 'empty'">
                {{car.carNoDisName}}<span style="color:red" v-if="car.carNoDisName_ != undefined">{{car.carNoDisName_}}</span>
              </el-card>
            </td>
          </table>
          <div v-show="showProds.length == 0" style="color:#bfbfbf">
            搜尋不到車輛
          </div>
        </el-row>
        <h3>
          租借資訊
        </h3>
        <el-row :gutter="10" style="margin-top:8px">
          <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;width:90%">
            <tbody style=" text-align:left">
              <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;min-width:100px">
                  訂單號
                </td><td style="border-style:solid; border-width:1px;min-width:100px">
                  {{ form.clientOrderId }}
                </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;min-width:100px">
                  客戶名稱
                </td><td style="border-style:solid; border-width:1px;min-width:100px">
                  {{ form.clientUserDisName }}
                </td></tr>
              <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  實際取車時間
                </td><td style="border-style:solid; border-width:1px;">
                  {{ form.realStartDate }}
                </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  預計還車時間
                </td><td style="border-style:solid; border-width:1px;">
                  {{ form.eDate }}
                </td></tr>
              <tr><td colspan="2" style="border-style:solid; border-width:1px;"></td>
                <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  實際還車時間
                </td><td style="border-style:solid; border-width:1px;">
                  <el-date-picker type="datetime" size="mini" v-model="form.realEndDate" @change="changeReturnDate" :clearable="false"></el-date-picker>
                </td></tr>
              <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  總租車時間
                </td><td style="border-style:solid; border-width:1px;">
                  {{ rideHour }}小時(約{{ rideDay }}天)
                </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  超時時間
                </td><td style="border-style:solid; border-width:1px;">
                  {{ overHour }}小時(約{{ overDay }}天)
                </td></tr>
              <tr v-if="enabledDistance == 'T'">
                <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  取車里程
                </td><td style="border-style:solid; border-width:1px;">
                  {{ form.startDistance }}
                </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  還車里程
                </td><td style="border-style:solid; border-width:1px;">
                  <el-input type="number" v-model="form.nowDistance" placeholder="現在里程" @change="getPriceForReturn()" size="mini"></el-input>
                </td></tr>
              <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  租車訂單備註
                </td><td colspan="3" style="border-style:solid; border-width:1px;">
                  <el-input type="text" v-model="form.reserveNote" size="mini" style="width:80%"></el-input>
                  <el-button type="primary" size="mini" style="width:20%" @click="updateReserve">
                  修改備註</el-button>
                </td></tr>
              <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                隨車配件
                </td><td style="border-style:solid; border-width:1px;"></td>
                <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                雨衣
                </td><td style="border-style:solid; border-width:1px;">
                  {{ form.accessoriesRaincoat }}件
                </td></tr>
              <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  3/4安全帽
                </td><td style="border-style:solid; border-width:1px;">
                  {{ form.accessoriesHelmetFull }}頂
                </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                  半罩安全帽
                </td><td style="border-style:solid; border-width:1px;">
                  {{ form.accessoriesHelmet }}頂
                </td></tr>
            </tbody>
          </table>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px">
          <el-col :span="12">
            <el-card>
              <el-row>
                <h3>建議費用明細</h3>
                <el-row :gutter="10" style="margin-top:5px">
                  <el-input type="number" v-model="form.payPrice" style="width:200px" @change="changeEntire">
                    <template slot="prepend">尾款金額</template></el-input>
                </el-row>
                <el-form-item label="費用小計:" :label-width="formLabelWidth" class="title">
                  <div style="font-size:150%;">
                    &emsp;<span style="margin-left:20px;">{{ this.form.price }}元</span>
                  </div>
                  <div v-show="isEntireChange == 'F'" :class="priceDetail.class"  :key="index" class="form-font"
                    v-for="(priceDetail, index) in form.priceList">
                    <span v-show="index === 1 || index === 0" style=""></span>
                    {{ priceDetail.name }}
                    <span style="">{{ priceDetail.amt }}元</span>
                  </div>
                </el-form-item>
                <el-form-item v-show="form.deposit > 0" label="預付訂金:" :label-width="formLabelWidth" class="title red-title" style="color:red">
                  <span class="form-font">{{ form.deposit * -1 }}元</span>
                </el-form-item>
                <el-form-item v-show="form.beforeDiscount > 0" label="預付優惠:" :label-width="formLabelWidth" class="title red-title" style="color:red">
                  <span class="form-font">{{ form.beforeDiscount * -1 }}元</span>
                </el-form-item>
                <el-form-item v-show="form.beforePrice > 0" label="取車預付:" :label-width="formLabelWidth" class="title red-title" style="color:red">
                  <span class="form-font">{{ form.beforePrice * -1 }}元</span>
                </el-form-item>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="12" v-show="withoutMofunInvoice == 'F'">
            <el-switch v-model="doInvoice" active-color="#13ce66" inactive-color="#ff4949" active-value="true" inactive-value="false" active-text="開立發票"></el-switch>
            <el-card v-show="doInvoice === 'true'">
              <el-row :gutter="10">
                使用載具
                <div>
                  <el-row :gutter="10" style="margin-top:8px">
                    <el-input v-model="form.carrierNum" placeholder="載具編號">
                      <template slot="prepend">載具編號</template></el-input>
                  </el-row>
                </div>
              </el-row>
              <el-row :gutter="10">
                開立個人發票以下欄位請留白
                <div>
                  <el-row :gutter="10" style="margin-top:8px">
                    <el-input v-model="form.vatNo" placeholder="統一編號">
                      <template slot="prepend">統一編號</template></el-input>
                  </el-row>
                  <el-row :gutter="10" style="margin-top:8px">
                    <el-input v-model="form.companyTitle" placeholder="公司抬頭">
                      <template slot="prepend">公司抬頭</template></el-input>
                  </el-row>
                </div>
              </el-row>
            </el-card>
          </el-col>
        </el-row>

        <el-row :gutter="10" style="margin-top:10px">
          <el-input type="text" v-model="form.reserveNote">
            <template slot="prepend">員工備註</template></el-input>
        </el-row>

        <el-row>
          <el-col :span="5">
            付款方式
            <el-radio>現金</el-radio>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <h3>車輛拍攝</h3>
        </el-row>
        
        <el-row>    <!--函數未刪除[參考上面拍攝]-->
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('picat1')">
              <span v-show="picat1 == ''">車輛正面</span>
              <img class="photo" :src="picat1" style="display:block; margin:auto;" v-show="picat1 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('picat2')">
              <span v-show="picat2 == ''">車輛背面</span>
              <img class="photo" style="display:block; margin:auto;" :src="picat2" v-show="picat2 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('picat3')">
              <span v-show="picat3 == ''">車輛側面</span>
              <img class="photo" style="display:block; margin:auto;" :src="picat3" v-show="picat3 != ''" />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="upload-box" @click="openInputFile('picat4')">
              <span v-show="picat4 == ''">車輛側面</span>
              <img class="photo" style="display:block; margin:auto;" :src="picat4" v-show="picat4 != ''" />
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="10" style="margin-top:10px" v-show="clientQRCodeFormVisable">
          客戶端付款QRCode
          <div style="text-align: center">
            <img :src="clientQRCodeUrl" />
          </div>
          <el-button type="primary" @click="checkClientOrderFlow" style="font-size:150%">已確認付款</el-button>
        </el-row>

        <el-row :gutter="10" style="margin-top:10px" v-show="!clientQRCodeFormVisable" >
          <div style="float:right;">
            <el-button type="primary" v-show="form.payPrice > 0" style="font-size:150%;" @click="confirmOnePageReturnByClientPay()" >
              客戶端付款還車</el-button>
            <el-button type="primary" style="font-size:150%;" @click="confirmOnePagePayByCash()">
              現金付款還車</el-button>
            <el-button type="primary" style="font-size:150%;" @click="to('/adminProdStatus')">
            回系統首頁</el-button>
            <el-button type="primary" @click="to('/OnePageGet')" style="font-size:150%;">
            一頁式租車</el-button>
          </div>
        </el-row>
        <el-collapse v-model="active">
          <el-collapse-item name="1">
            <template slot="title">
              <h2>詳細資料</h2>
            </template>
            <el-form :model="form" style=" border-spacing:0px; text-align:center; ">
              <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly />
              <input type="hidden" v-model="form.catId" autocomplete="off" />
              <input type="hidden" v-model="form.storeId" autocomplete="off" />
              <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
                <tbody style=" text-align:left">
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      訂單號:
                    </td><td colspan="3" style="border-style:solid; border-width:1px;">
                      {{ form.clientOrderId }}
                    </td></tr>
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;width:125px">
                      客戶名稱:
                    </td><td colspan="3" style="border-style:solid; border-width:1px;">
                      {{ form.clientUserDisName }}
                    </td></tr>
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      客戶電話:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.clientPhone }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      客戶出生年月日:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.clientBirthday }}
                    </td></tr>
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      訂單管道:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.clientType | filter_clientType }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      車類:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.prodPriceDisName }}
                    </td></tr>
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      車型:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.catDisName }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      車號:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.carNo }}
                    </td></tr>

                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      訂單取車時間:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.sDate }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      訂單還車時間:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.eDate }}
                    </td></tr>

                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      實際取車時間:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.realStartDate }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      實際還車時間:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.realEndDate_ }}
                    </td></tr>

                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      加購保障:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.plusInsuranceDisName }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      取車預付金額:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.beforePrice }}
                    </td></tr>

                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      已付訂金:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.deposit }}({{ form.depositPayMethod }})
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      訂單總金額:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.price }}
                    </td></tr>

                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      加購金額:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.plusPrice }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      尾款:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.payPrice }}
                    </td></tr>
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      折扣碼:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.promotionCode }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      折扣金額:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.discount }}
                    </td></tr>
                  <tr v-if="form.flow == '1000'">
                    <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      取消原因:
                    </td><td colspan="3" style="border-style:solid; border-width:1px;">
                      {{ form.cancelNote }}
                    </td></tr>

                  <tr v-if="enabledDistance == 'T'">
                    <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      出發前里程
                    </td><td colspan="3" style="border-style:solid; border-width:1px;">
                      {{ form.startDistance }}
                    </td></tr>
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      客戶喜好:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.adminNote }}
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      類別:
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.rentDateType === "2" ? "長租" : "短租" }}
                    </td></tr>
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      安全帽
                    </td><td style="border-style:solid; border-width:1px;">
                      3/4：{{ form.accessoriesHelmetFull }}頂，半罩：{{form.accessoriesHelmet}}頂
                    </td><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      雨衣：
                    </td><td style="border-style:solid; border-width:1px;">
                      {{ form.accessoriesRaincoat }}件
                    </td></tr>

                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      客戶訂單備註:
                    </td><td colspan="3" style="border-style:solid; border-width:1px;">
                      {{ form.clientNote }}
                    </td></tr>

                  <tr @click="showClientPic">
                    <td colspan="4" style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      客戶證件:
                      <el-button size="small" v-show="!showPic" type="primary">顯示</el-button>
                      <el-button size="small" v-show="showPic" type="primary">隱藏</el-button>
                    </td></tr>
                  <tr v-show="showPic">
                    <td style="border-style:solid; border-width:1px;">
                      <a target="_blank" :href="pic1"><img :src="pic1" style="width:250px;height:180px;" /></a>
                    </td><td style="border-style:solid; border-width:1px;">
                      <a target="_blank" :href="pic2"><img :src="pic2" style="width:250px;height:180px;" /></a>
                    </td><td style="border-style:solid; border-width:1px;">
                      <a target="_blank" :href="pic3"><img :src="pic3" style="width:250px;height:180px;" /></a>
                    </td><td style="border-style:solid; border-width:1px;">
                      <a target="_blank" :href="pic4"><img :src="pic4" style="width:250px;height:180px;" /></a>
                    </td></tr>

                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      歷史紀錄:
                    </td><td colspan="3" style="border-style:solid; border-width:1px;">
                      <div v-for="item in historyBooking" :key="item.clientOrderId" :value="item.clientOrderId">
                        下單時間:{{ item.createDate }}-備註:{{item.reserveNote}}(訂單號:{{ item.clientOrderId }})
                      </div>
                    </td></tr>
                  <tr>
                    <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      付款紀錄:
                    </td><td colspan="3" style="border-style:solid; border-width:1px;">
                      <div v-for="(item, key) in clientOrderTrans" :key="key">
                        {{ item.updateDate }}-{{ item.moneyType }}-{{ item.disName }}-({{ item.amt }}元)
                      </div>
                    </td></tr>
                  <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">
                      訂單流程:
                    </td><td colspan="3" style="border-style:solid; border-width:1px;">
                      <div v-for="item in historyFlow" :key="item.flow" :value="item.flow">
                        {{ item.updateDate }}-{{ item.note }}
                      </div>
                    </td></tr>
                </tbody>
              </table>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </el-card>

    <form action="" method="post" ref="uploadFormCat1" v-show="false">
      <input type="file" accept="image/*" @change="uploadFileCat1" ref="fileBtnCat1" />
    </form>
    <form action="" method="post" ref="uploadFormCat2" v-show="false">
      <input type="file" accept="image/*" @change="uploadFileCat2" ref="fileBtnCat2" />
    </form>
    <form action="" method="post" ref="uploadFormCat3" v-show="false">
      <input type="file" accept="image/*" @change="uploadFileCat3" ref="fileBtnCat3" />
    </form>
    <form action="" method="post" ref="uploadFormCat4" v-show="false">
      <input type="file" accept="image/*" @change="uploadFileCat4" ref="fileBtnCat4" />
    </form>
    <!-- <el-dialog title=" " :close-on-click-modal="false" :visible.sync="clientQRCodeFormVisable" font-size="150%" width="90%" center>
      <div style="text-align: center">
        <img :src="clientQRCodeUrl" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkClientOrderFlow" style="font-size:150%">已確認付款</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import imageCompression from "browser-image-compression";
import * as moment from "moment/moment";
export default {
  data() {
    return {
      fullscreenLoading: false,
      clientOrdersDialog: false,
      ruleDialog: false,
      storeRuleDialog: false,
      content: "",
      coldDown: 0,
      agreeMemberRule: false,
      agreeStoreRule: false,
      clientOrders: [],
      pic1: "",
      pic2: "",
      pic3: "",
      pic4: "",
      picat1: "",
      picat2: "",
      picat3: "",
      picat4: "",
      timer: null,
      prods: [],
      showProds: [],
      qCarNo: "",
      selectedCar: null,
      active: "0",
      sysTime: "",
      prodStocks: [],
      form: {
        clientOrderId: "",
        clientUserId: "",
        clientUserDisName: "",
        birthday: undefined,
        adminNote: "",
        phone: "",
        areaId: "",
        carNo: "",
        carNoDisName: '',
        storeId: "",
        sDateDate: "",
        sDateHour: "",
        eDateDate: "",
        eDateHour: "",
        name: "",
        region: "",
        date1: "",
        date2: "",
        type: [],
        resource: "",
        desc: "",
        plusInsurance: true,
        emptyCat: "",
        priceList: [],
        reserveNote: "",
        helmetFull: "0",
        helmet: "1",
        raincoat: "0",
        price: null,
        beforePay: "F",
        beforePrice: 0,
        isSaleInsurance: "F",
        rentDateType: "1",
        realEndDate: "",
        nowDistance: 0,
        payPrice: 0,
        vatNo:"",
        companyTitle: "",
        realEndDate_: "",
        beforeDiscount: 0,
        carrierNum:""
      },
      formLabelWidth: "139px",
      stores: [],
      isCreate: false,
      isCal: false,
      showPic: false,
      historyFlow: [],
      clientOrderTrans: [],
      historyBooking: [],
      //need fix
      enabledDistance: "T",
      rideHour: 0,
      rideDay: 0,
      overHour: 0,
      overDay: 0,
      doInvoice: "false",
      withoutMofunInvoice: "F",
      selected: "",
      isEntireChange: "F",
      clientQRCodeFormVisable: false,
      clientQRCodeUrl: ''
    };
  },
  created() {
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        res.data.authStores.forEach(e => {
          this.stores.push(e);
        });
        this.form.storeId = this.stores[0].code;
        this.$store.dispatch("login", res.data);
        this.$axios.get("../api/booking/getSysTime", {})
          .then(res => {
            this.sysTime = res.data;
            this.init();
          })
          .catch(() => {});
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  name: "OnePageReturn",
  components: {},
  computed: {},
  methods: {


    async uploadFileCat1(e) {
      this.formDataCat1 = new FormData();
      this.uploadPath = "../api/onePageReturn/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formDataCat1.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      this.picat1 = "";
      let tUploadPath = this.uploadPath + this.form.clientOrderId + "/1";
      await this.$axios.post(tUploadPath, this.formDataCat1).then(res => {
          this.picat1 = "../api/onePageReturn/getOrderPic/clientOrder/" + res.data.img;
          this.$refs.uploadFormCat1.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFileCat2(e) {
      this.formDataCat2 = new FormData();
      this.uploadPath = "../api/onePageReturn/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formDataCat2.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath + this.form.clientOrderId + "/2";
      this.picat2 = "";
      await this.$axios.post(tUploadPath, this.formDataCat2).then(res => {
          this.picat2 = "../api/onePageReturn/getOrderPic/clientOrder/" + res.data.img;
          this.$refs.uploadFormCat2.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFileCat3(e) {
      this.formDataCat3 = new FormData();
      this.uploadPath = "../api/onePageReturn/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formDataCat3.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath + this.form.clientOrderId + "/3";
      this.picat3 = "";
      await this.$axios.post(tUploadPath, this.formDataCat3).then(res => {
          this.picat3 = "../api/onePageReturn/getOrderPic/clientOrder/" + res.data.img;
          this.$refs.uploadFormCat3.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },
    async uploadFileCat4(e) {
      this.formDataCat4 = new FormData();
      this.uploadPath = "../api/onePageReturn/upload/";
      try {
        const compressedFile = await imageCompression(e.target.files[0],this.imageOption);
        this.formDataCat4.append("file", compressedFile);
      } catch (error) {
        this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        return;
      }
      let tUploadPath = this.uploadPath + this.form.clientOrderId  + "/4";
      this.picat4 = "";
      await this.$axios.post(tUploadPath, this.formDataCat4).then(res => {
          this.picat4 ="../api/onePageReturn/getOrderPic/clientOrder/" + res.data.img;
          this.$refs.uploadFormCat4.reset();
        }).catch(() => {
          this.$alert("上傳失敗，稍後再試", "注意", {confirmButtonText: "確定"});
        });
    },

    openInputFile(type) {
      switch (type) {
        case "picat1":
          this.$refs.fileBtnCat1.click();
          break;
        case "picat2":
          this.$refs.fileBtnCat2.click();
          break;
        case "picat3":
          this.$refs.fileBtnCat3.click();
          break;
        case "picat4":
          this.$refs.fileBtnCat4.click();
          break;
          
      }
      //
    },

    getprods() {
      this.selected = "";
      this.form.clientOrderId = "";
      this.form.clientUserDisName = "";
      this.form.realStartDate = "";
      this.form.eDate = "";
      this.form.startDistance = "";
      this.form.plusInsurance = false;
      this.form.prodId = "";
      this.form.carNo = "";
      this.form.carNoDisName = "";
      this.form.carrierNum = "";
      this.selectedCar = null;
      this.prods = Object.assign([]);
      this.showProds = Object.assign([]);
      let param = { qStoreId: this.form.storeId };
      this.$axios.get("../api/onePageReturn/getCarNos", { params: param })
        .then(res => {
          res.data.data.forEach(car => {
            if (car.carNoDisName.length > 3) {
              car.carNoDisName_ = car.carNoDisName.substring(car.carNoDisName.length - 3, car.carNoDisName.length)
              car.carNoDisName = car.carNoDisName.substring(0, car.carNoDisName.length - 3);
            }

            this.prods.push(car);
            this.showProds.push(car);
          });
          this.withoutMofunInvoice = res.data.withoutMofunInvoice;
        });
    },
    init() {
      let time = Date.parse(this.sysTime);
      let d = new Date();
      d.setTime(time);
      let min = d.getMinutes() > 30 ? "30" : "00";
      let h = d.getHours() >= 10 ? d.getHours().toString() : "0" + d.getHours().toString();
      this.form.sDateDate = d;
      this.form.sDateHour = h + ":" + min;
      d = new Date(d.getTime() + 24 * 1000 * 3600);
      this.form.eDateDate = d;
      this.form.eDateHour = h + ":" + min;
      this.getprods();
    },
    selectProd(prod) {
      this.selected = prod.prodId;
      this.form.prodId = prod.prodId;
      this.form.carNo = prod.carNo;
      this.form.carNoDisName = prod.carNoDisName + (prod.carNoDisName_ === undefined? '':prod.carNoDisName_ );
      this.selectedCar = prod;
      let param = { clientOrderId: prod.clientOrderId };
      this.$axios.get("../api/clientOrderQuickReturn/getClientOrderForQuickForm", {
          params: param
        }).then(res => {
          let time = Date.parse(this.sysTime);
          let realEndDate = new Date();
          realEndDate.setTime(time);
          this.dialogFormVisible = true;
          if (res.data[0].plusPrice == null) {
            res.data[0].plusPrice = "0";
          }
          this.form.discount = 0;
          this.form.promotionCode = "";
          this.form.checkCode = "";
          this.form = Object.assign({}, this.form, res.data[0]);
          this.form.priceList.splice(0, this.form.priceList.length);
          if (this.form.realEndDate === undefined ||this.form.realEndDate === null) {
            this.form.realEndDate = moment(realEndDate).format("YYYY-MM-DD HH:mm:ss");
            this.form.realEndDate_ = this.form.realEndDate;
          } else {
            this.riddenHours = (moment(this.form.realEndDate, "YYYYMMDDHHmmss") - moment(this.form.realStartDate, "YYYYMMDDHHmmss")) / 60 / 60 / 1000;
            this.form.realEndDate = this.$date_(this.form.realEndDate);
            this.riddenDays = this.riddenHours / 24;
            this.riddenDays = this.riddenDays.toFixed(1);
            this.riddenHours = this.riddenHours.toFixed(0);
          }
          this.form.eDate = this.$date_(this.form.eDate);
          this.form.sDate = this.$date_(this.form.sDate);
          this.form.realStartDate = this.$date_(this.form.realStartDate);
          this.form.nowDistance = (
            parseInt(this.form.startDistance) + 1
          ).toString();
          this.form.plusInsuranceDisName =
            this.form.plusInsurance == "1" ? "是" : "否";
          this.form.catDisName = this.$catDisName(
            this.$store.state.cats,
            this.form.catId
          );

          this.form.depositPayMethod = this.$payTypeDisName(
            this.form.depositPayMethod
          );
          this.historyFlow.splice(0, this.historyFlow.length);
          res.data[0].historyFlow.forEach(e => {
            e.updateDate = this.$date_(e.updateDate);
            this.historyFlow.push(e);
          });
          this.historyBooking.splice(0, this.historyBooking.length);
          res.data[0].historyBooking.forEach(e => {
            e.createDate = this.$date_(e.createDate);
            this.historyBooking.push(e);
          });
          this.clientOrderTrans.splice(0, this.clientOrderTrans.length);
          res.data[0].clientOrderTrans.forEach(e => {
            if (e.moneyType == "CASH") {
              e.moneyType = "現金";
            } else if (e.moneyType == "CREDIT") {
              e.moneyType = "信用卡";
            } else if (e.moneyType == "LINEPAY") {
              e.moneyType = "LINE Pay";
            } else if (e.moneyType == "ATM") {
              e.moneyType = "ATM 轉帳";
            } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
            } else {
              e.moneyType = "未知付款方式";
            }
            e.updateDate = this.$date_(e.updateDate);
            if (e.status !== "SUCCESS") {
              e.updateDate = e.updateDate + "(付款失敗)";
            }
            this.clientOrderTrans.push(e);
          });
          if (this.form.companyTitle == undefined ||this.form.companyTitle == null) {
            this.form.companyTitle = "";
          }
          //欄位名稱轉換
          this.form.companyTitle = this.form.vatCompanyTitle;
          if (this.form.vatNo == undefined || this.form.vatNo == null) {
            this.form.vatNo = "";
          }
          if (this.form.carrierNum == undefined || this.form.carrierNum == null ) {
            this.form.carrierNum = ''
            if (this.form.usedCarrierNum != undefined && this.form.usedCarrierNum != null && this.form.usedCarrierNum != ''){
              this.form.carrierNum  = this.form.usedCarrierNum
            }
          }
          this.enabledDistance = res.data[0].enabledDistance[0].enabledDistance;
          this.getPriceForReturn();
        })
        .catch((e) => {
          console.log(e)
          this.$alert("不知名錯誤", "結果", { confirmButtonText: "確定" });
        });
      //選擇租借車輛
    },
    serchProd() {
      this.showProds = Object.assign([]);
      this.prods.forEach(moto => {
        if (moto.carNoDisName_ == undefined || moto.carNoDisName_ == null) {
          moto.carNoDisName_ = "";
        }
        if ((moto.carNoDisName + moto.carNoDisName_).indexOf(this.qCarNo) !== -1 || this.qCarNo == "" ) {
          this.showProds.push(moto);
        }
      });
    },
    changeEDate(hours) {
      let sDate_ = moment(this.form.sDateDate).format("YYYYMMDD") + this.form.sDateHour.replace(/\D/gi, "") + "00";
      if (this.isRentRightNow) {
        sDate_ = moment(this.sysTime).format("YYYYMMDDHHmmss");
      }
      let eDate_ = moment(sDate_, "YYYYMMDDHHmmss");
      switch (hours) {
        case "12":
          eDate_.add(12, "hours");
          this.form.eDateDate = eDate_;
          this.form.eDateHour = eDate_.format("HH:mm");
          this.rent24hours = false;
          this.rent48hours = false;
          break;
        case "24":
          eDate_.add(24, "hours");
          this.form.eDateDate = eDate_;
          this.form.eDateHour = eDate_.format("HH:mm");
          this.rent12hours = false;
          this.rent48hours = false;
          break;
        case "48":
          eDate_.add(48, "hours");
          this.form.eDateDate = eDate_;
          this.form.eDateHour = eDate_.format("HH:mm");
          this.rent12hours = false;
          this.rent24hours = false;
          break;
        default:
          this.rent12hours = false;
          this.rent24hours = false;
          this.rent48hours = false;
          break;
      }
    },
    to(path) {
      this.$emit("gotoMenu", path.substring(1));
      this.$router.push({ path: path });
    },
    
    showClientPic() {
      this.showPic = !this.showPic;
      if (this.showPic) {
        if (this.form.pic1 === null) {
          this.form.pic1 = "clientUser/null";
        }
        if (this.form.pic2 === null) {
          this.form.pic2 = "clientUser/null";
        }
        if (this.form.pic3 === null) {
          this.form.pic3 = "clientUser/null";
        }
        if (this.form.pic4 === null) {
          this.form.pic4 = "clientUser/null";
        }

        this.pic1 = "../api/adminClientUser/getOrderPic/" + this.form.pic1;
        this.pic2 = "../api/adminClientUser/getOrderPic/" + this.form.pic2;
        this.pic3 = "../api/adminClientUser/getOrderPic/" + this.form.pic3;
        this.pic4 = "../api/adminClientUser/getOrderPic/" + this.form.pic4;
      }
    },
    getPriceForReturn() {
      this.form.beforeDiscount = 0
      this.form.overPrice = 0
      this.form.beforePrice = 0
      this.form.price = 0
      this.form.overPrice = 0
      this.isEntireChange = "F";
      let distance = 0;
      let param = {};
      if (this.form.realEndDate == null || this.form.realEndDate == undefined || this.form.realEndDate == "") {
        this.$alert("請輸入還車日期", "注意", {confirmButtonText: "確定"});
        return;
      }
      let eDate = moment(this.form.realEndDate).format("YYYYMMDDHHmmss");

      if (this.enabledDistance === "T") {
        distance =
          parseInt(this.form.nowDistance) - parseInt(this.form.startDistance);
      }
      param = {
        clientOrderId: this.form.clientOrderId,
        distance: distance,
        eDate: eDate
      };

      this.isCal = true;

      let apiUrl = "../api/clientOrderQuickReturn/getClientOrderPrice";
      if (this.form.beforePay === 'T') {
        apiUrl = "../api/clientOrderQuickReturn/getClientOrderPriceForBeforePay";
      }
      this.$axios.get(apiUrl, { params: param }).then(res => {
          this.isCal = false;
          this.form.priceList.splice(0, this.form.priceList.length);
          if (res.data.promotionNote !== undefined) {
            // 優惠卷錯誤
            this.$alert(res.data.promotionNote, "結果", {confirmButtonText: "確定"});
            this.form.payPrice = null;
            this.form.price = null;
            return;
          }
          let ps = res.data.priceList;
          this.form.price = 0;
          // this.form.price = this.form.price + parseInt(this.form.plusPrice);

          this.form.deposit = 0;
          for (var key in ps) {
            if (key === "rideHour") {
              this.rideHour = ps[key];
              let rest24Hours = this.rideHour % 24;
              try {
                this.rideDay =
                  (this.rideHour - rest24Hours) / 24 + rest24Hours / 24.0;
                this.rideDay = this.rideDay.toFixed(1);
              } catch (error) {
                console.log(error);
              }
            } else {
              if (key === "ridePrice") {
                if (ps[key] < 0) {
                  // 車資不可為負值
                  ps[key] = 0;
                }
                this.form.price = this.form.price + ps[key];
                this.form.priceList.push({ name: "車資費用:", amt: ps[key] });
                // 超額里程說明(單純額外備註顯示用，後端已經把金額列入計算)
                if (res.data.additionNote !== undefined) {
                  this.form.priceList.push({
                    name: "(含超額里程費):",
                    amt: res.data.additionNote.overDistance
                  });
                }
              } else if (key === "isrPrice") {
                if (ps["ridePrice"] === 0) {
                  ps[key] = 0;
                }
                this.form.price = this.form.price + ps[key];
                this.form.priceList.push({ name: "保障費用:", amt: ps[key] });
                this.form.isrPrice = ps[key];
              } else if (key === "deposit") {
                if (ps[key] > 0) {
                  this.form.deposit = ps[key];
                }
              } else if (key === "overPrice") {
                  if (ps[key] > 0) {
                    this.form.price = this.form.price + ps[key]
                    this.form.overPrice = ps[key]
                    this.form.priceList.push({name: "超時費用:" , amt: ps[key]})              
                  }
              } else if (key === 'beforeDiscount') { // 預折扣金額
                  if (ps[key] > 0) {
                    this.form.beforeDiscount = ps[key]
                  }
              } else if (key === 'beforePrice') { // 預付金額
                  if (ps[key] > 0) {
                    this.form.beforePrice = ps[key]
                  }
              }
            }
          }
          if (this.form.plusPrice > 0) {
            this.form.priceList.push({name: "加購金額:",amt: this.form.plusPrice});
          }

          if (this.form.beforePrice == undefined) {// 沒有預付款
            this.form.beforePrice = 0;
          } else { // 有預付款
            // 要把車資費用描述拿掉
            for( let i = 0; i < this.form.priceList.length; i++ ) {
              if(this.form.priceList[i].name === '車資費用:') {
                this.form.priceList.splice(i,1)
                break
              }
            }
            if ( this.form.price < (this.form.deposit + this.form.beforePrice) ) { // 如果算出來的車資 小於預付 車資多不退 少要補齊
              this.form.price = this.form.deposit + this.form.beforePrice + this.form.beforeDiscount;
            } else {
              this.form.price = this.form.deposit + this.form.beforePrice + this.form.beforeDiscount + this.form.overPrice;
            }
          }

          // 有正確的折扣
          let promotionType = res.data.promotionType;
          let discount = res.data.discount;
          let canDiscountPrice = this.form.price;
          if (this.form.isrPrice !== undefined && this.form.isrPrice > 0) {
            canDiscountPrice = canDiscountPrice - this.form.isrPrice;
          }
          if (promotionType !== undefined && discount !== undefined) {
            if (promotionType === "PRICE") {
              this.form.discount = discount >= canDiscountPrice ? canDiscountPrice : discount
            } else if (promotionType === "PERCEN") {
              //保險費用不能打折 只有車資可以
              this.form.discount = (canDiscountPrice * (1 - discount / 100)).toFixed(0);
            }
          } else if (discount !== undefined) {
            this.form.discount = discount;
          }

          if (this.form.discount !== undefined && this.form.price > 0 && this.form.discount > 0) {
            if (this.form.price - this.form.discount <= 0) {
              this.form.discount = this.form.price + 0; // 只有抵扣一些
            }
            this.form.priceList.push({
              name: "優惠項目:",
              amt: this.form.discount * -1,
              class: "amt-red"
            });

            this.form.price = this.form.price - this.form.discount; // 小計

            // 有可能要退款
            if (this.form.price <= 0) {
              this.form.returnPrice = this.form.price * -1;
              this.form.price = 0;
            }
          }
          this.overHour = 0;
          this.overDay = 0;
          let returnDiff = moment(this.form.realEndDate) - moment(this.form.eDate);
          if (returnDiff > 0) {
            this.overHour = (returnDiff / 60 / 60 / 1000).toFixed(0);
            this.overDay = (this.overHour / 24).toFixed(1);
          }

          this.form.payPrice = this.form.price - this.form.deposit - this.form.beforePrice - this.form.beforeDiscount;
          
          this.form.specPrice = this.form.price;
          this.form.oldPrice = this.form.price + 0;
        })
        .catch((e) => {
          this.isCal = false;
          console.log(e)
          this.$alert("不知名錯誤", "結果", { confirmButtonText: "確定" });
        });
    },
    confirmOnePagePayByCash() {
      if (this.form.carNo == null || this.form.carNo == undefined || this.form.carNo == "") {
        this.$alert("請先選擇車輛以獲取訂單資訊", "注意", {confirmButtonText: "確定"});
        return;
      }
      if (this.form.realEndDate == null || this.form.realEndDate == undefined || this.form.realEndDate == "") {
        this.$alert("請輸入還車日期", "注意", {confirmButtonText: "確定"});
        return;
      }
      
      let price = this.form.payPrice;
      let returnPrice = 0;
      if (price < 0) {
        returnPrice = this.form.payPrice * -1;
        if(returnPrice > this.form.deposit + this.form.beforePrice){
          this.$alert('退款金額不可大於訂金加預收額','注意',{confirmButtonText:'確定'})
          return
        }
        price = 0;
      }
      if (this.form.vatNo != '' || !(this.form.companyTitle == null || this.form.companyTitle =='')) {
        if(this.form.vatNo.length != 8){
          this.$alert('請填寫正確統一編號','注意',{confirmButtonText:'確定'})
          return
        } else if (this.form.companyTitle.length == 0 || this.form.companyTitle.length >= 60){
          this.$alert('公司抬頭字元長度需介於1~60個字元','注意',{confirmButtonText:'確定'})
          return
        }
        if (this.form.carrierNum != ''){
          this.$alert('打統編之發票不可存載具','注意',{confirmButtonText:'確定'})
          return
        }
      }
      if (this.form.carrierNum !== '') {
        if (!this.form.carrierNum.match(/^\/[\dA-Z0-9+-.]{7}$/)) {
          this.$alert("載具編號錯誤", "注意", { confirmButtonText: "確定" });
          return
        }
      }
      this.$confirm("是否確定現金付款還車", "注意", {
        confirmButtonText: "確定",
        cancelButtonText: "取消"
      }).then(() => {
        let params = {
          vatNo: this.form.vatNo,
          vatCompanyTitle: this.form.companyTitle,
          clientOrderId: this.form.clientOrderId,
          payPrice: price,
          isrPrice: this.form.isrPrice,
          returnPrice: returnPrice,
          invoiceAmt: this.form.price,
          endDistance: this.form.nowDistance,
          doInvoice: this.doInvoice,
          reserveNote: this.form.reserveNote,
          realEndDate: moment(this.form.realEndDate).format("YYYYMMDDHHmmss"),
          carrierNum: this.form.carrierNum
        };
        this.fullscreenLoading = true;
        this.$axios.post("../api/onePageReturn/confirmOnePagePayByCash", params).then(res => {
          this.fullscreenLoading = false;
          if (res.data.rs === "success") {
            this.$alert("付款完成", "結果", { confirmButtonText: "確定" });
            location.reload();
          } else {
            this.$alert(res.data.rsMsg, "結果", { confirmButtonText: "確定" });
          }
        });
      });
    },
    confirmOnePageReturnByClientPay () {
      if (this.form.carNo == null || this.form.carNo == undefined || this.form.carNo == "") {
        this.$alert("請先選擇車輛以獲取訂單資訊", "注意", {confirmButtonText: "確定"});
        return;
      }
      if (this.form.realEndDate == null || this.form.realEndDate == undefined || this.form.realEndDate == "") {
        this.$alert("請輸入還車日期", "注意", {confirmButtonText: "確定"});
        return;
      }
      
      let price = this.form.payPrice;
      let returnPrice = 0;
      if (price < 0) {
        returnPrice = this.form.payPrice * -1;
        if(returnPrice > this.form.deposit + this.form.beforePrice){
          this.$alert('退款金額不可大於訂金加預收額','注意',{confirmButtonText:'確定'})
          return
        }
        price = 0;
      }
      if (this.form.vatNo != '' || !(this.form.companyTitle == null || this.form.companyTitle =='')) {
        if(this.form.vatNo.length != 8){
          this.$alert('請填寫正確統一編號','注意',{confirmButtonText:'確定'})
          return
        } else if (this.form.companyTitle.length == 0 || this.form.companyTitle.length >= 60){
          this.$alert('公司抬頭字元長度需介於1~60個字元','注意',{confirmButtonText:'確定'})
          return
        }
        if (this.form.carrierNum != ''){
          this.$alert('打統編之發票不可存載具','注意',{confirmButtonText:'確定'})
          return
        }
      }
      if (this.form.carrierNum !== '') {
        if (!this.form.carrierNum.match(/^\/[\dA-Z0-9+-.]{7}$/)) {
          this.$alert("載具編號錯誤", "注意", { confirmButtonText: "確定" });
          return
        }
      }
      this.$confirm("是否確定還車並顯示客戶端QRCode", "注意", {
        confirmButtonText: "確定",
        cancelButtonText: "取消"
      }).then(() => {
        let params = {
          vatNo: this.form.vatNo,
          vatCompanyTitle: this.form.companyTitle,
          clientOrderId: this.form.clientOrderId,
          payPrice: price,
          isrPrice: this.form.isrPrice,
          returnPrice: returnPrice,
          invoiceAmt: this.form.price,
          endDistance: this.form.nowDistance,
          doInvoice: this.doInvoice,
          reserveNote: this.form.reserveNote,
          realEndDate: moment(this.form.realEndDate).format("YYYYMMDDHHmmss"),
          carrierNum: this.form.carrierNum
        };
        this.fullscreenLoading = true;
        this.$axios.post("../api/onePageReturn/updateClientOrder400WaitClientSitePay", params).then(res => {
          this.fullscreenLoading = false;
          if (res.data.rs === "success") {
            this.$alert("已成功還車。", "結果", { confirmButtonText: "確定" });
            this.clientQRCodeFormVisable = true
            this.clientQRCodeUrl = '../api/onePageReturn/getClientSiteQrCode?clientOrderId=' + this.form.clientOrderId
          } else {
            this.$alert(res.data.rsMsg, "結果", { confirmButtonText: "確定" });
          }
        });
      });
    },
    changeEntire() {
      this.isEntireChange = "T";
      this.form.price = parseInt(this.form.plusPrice) + parseInt(this.form.deposit) + parseInt(this.form.beforePrice) + parseInt(this.form.payPrice) + parseInt(this.form.beforeDiscount);
    },
    changeReturnDate() {
      this.form.realEndDate_ = moment(this.form.realEndDate).format("YYYY-MM-DD HH:mm:ss")
      this.getPriceForReturn();
    },
    updateReserve(){
      let param = {
        clientOrderId: this.form.clientOrderId,
        reserveNote: this.form.reserveNote
      }
      this.$confirm('是否確定修改備註','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() =>{
        this.$axios.post('../api/clientOrderQuickGet/updateReserveNote',param).then((res) =>{
          if (res.data.rs === 'success') {
            this.$alert('更新備註成功','結果',{confirmButtonText:'確定'})
          } else {
            this.$alert('更新備註失敗','結果',{confirmButtonText:'確定'})
          }
        })
      })
    },
    checkClientOrderFlow () {
      this.$axios.get('../api/onePageReturn/checkClientOrderFlow?clientOrderId=' + this.form.clientOrderId).then((res) =>{
        if (res.data.rs === 'success') {
          this.$alert('已確認完成訂單','結果',{confirmButtonText:'確定'})
          location.reload();
        } else {
          this.$alert('訂單尚未完成付款','結果',{confirmButtonText:'確定'})
        }
      })
    }
  },
  mounted() {
    //
  },
  beforeDestroy() {
    //
  }
};
</script>
<style scoped>
.photo {
  height: 230px;
}
.terms-of-service {
  border: 1px solid #686868;
  border-radius: 8px;
  width: 95%;
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 16px;
  margin-bottom: 18px;
  text-align: left;
}
.upload-box {
  width: 95%;
  height: 230px;
  border: 1px solid #686868;
  border-radius: 8px;
  color: #bfbfbf;
  margin-bottom: 16px;
}
.selectit {
  background-color: yellowgreen;
}
.empty {
  background-color: white;
}
</style>
