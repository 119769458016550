<template>
<el-dialog title="" :close-on-click-modal="false" :visible.sync="modal" width="90%" ref="dialog">
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; " v-loading.fullscreen.lock="fullscreenLoading" :inline="true">
      <el-row style="margin-bottom: 10px;">
        <el-col :span="24">
          <span>庫存表</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-form-item label="查詢時間">
            <el-date-picker
              :clearable="false"
              :editable="false"
              v-model="q.sd"
              type="date"
              placeholder=""
              style="width:180px;">
            </el-date-picker>
            ~
            <el-date-picker
              :clearable="false"
              :editable="false"
              v-model="q.ed"
              type="date"
              placeholder=""
              style="width:180px;">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="車類">
            <el-select v-model="q.catId" placeholder="" style="width:180px;">
              <el-option width=120
                v-for="item in catList"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="定價方案">
            <el-select v-model="q.prodPriceRowId" placeholder="" style="width:180px;">
              <el-option width=120
                v-for="item in prodPriceList"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="門市">
            <el-select v-model="q.storeId" placeholder="" style="width:200px;" @change="queryParamsEmpty()">
              <el-option width=150
                v-for="item in storeIdList"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="排序">
            <el-radio-group v-model="q.orderType" size="medium">
              <el-radio-button label="createDate">預約時間最早</el-radio-button>
              <el-radio-button label="sd">取車時間最早</el-radio-button>
              <el-radio-button label="ed">還車時間最早</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="includeFlow0">含等待確認</el-checkbox>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="query()">查詢</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr>
            <td colspan="4" style="border-style:solid; border-width:1px;">          
              <div style="overflow: auto;min-height:300px;" :style="tableStyle">
                <table style="border-style:solid; border-width:1px;border-collapse: collapse;">
                  <tbody>
                    <tr style="border:1px black solid;">
                      <td style="font-size:14px;min-width:150px;border:1px black solid;">訂單日期</td>
                      <td :colspan="item.span" style="font-size:10px;min-width:17px;border:1px black solid;" v-for="(item, index) in dateCols" :key="index">{{item.val}}</td>
                    </tr>
                    <tr style="border:1px black solid;">
                      <td style="font-size:14px;min-width:150px;border:1px black solid;">車類/訂單時間</td>
                      <td style="font-size:10px;min-width:17px;border:1px black solid;" v-for="item in cols" :key="item.index">{{item.val}}</td>
                    </tr>
                    <tr v-for="(item,index) in stocksRow" :key="index" style="margin-top:3px;">
                      <td style="font-size:10px;" :style="item.style" v-for="item in item.cols" :key="item.index">{{item.val}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
      </tbody>
    </table>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel()">退出</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as moment from "moment/moment";

export default {
  name: 'ClientOrderScheduleDialog',
  props: ['comfirmModal', 'storeId'],
  data () {
    return {
      includeFlow0:false,
      form:{
        clientOrderId: ''
      },
      q: {
        sd: null,
        ed: null,
        orderType: 'createDate',
        prodPriceRowId: null,
        catId: null,
        storeId:null
      },
      catList: [],
      prodPriceList:[],
      storeIdList:[],
      showRequestInvoiceBtn: false,
      fullscreenLoading:false,
      modal: this.comfirmModal,
      stocks: [],
      stocksRow: [], 
      qsd: '',
      qed: '',
      cols: [], // 第二列只有顯示00~24小時
      dateCols:[], // 第一列只有顯示日期
      tableStyle: {},
      weekDisplay:['日','一','二','三','四','五','六',],
    }
  },
  computed: {
    // 
  },
  watch: {   
    storeId (content) {
      if(content != null) {
        this.q.storeId = content
        this.q.sd = moment().toDate()
        this.q.ed = moment().add(3, 'days').toDate()

        
        this.$axios.get("../api/clientOrderQuickGet/getParamsForSchedule", {
          params: {
            storeId: this.storeId
          }}).then(res => {
            console.log(res)
            this.catList = []
            this.catList.push({
              code: null,
              disName: "全部"
            });
            res.data.cats.forEach(e => {
              this.catList.push(e);
            });
            this.q.catId = this.catList[0].code;
            this.prodPriceList = []
            this.prodPriceList.push({
              code: null,
              disName: "全部"
            });
            res.data.prodPrice.forEach(e => {
              this.prodPriceList.push(e);
            });
            this.q.prodPriceRowId = this.prodPriceList[0].code;
            this.q.storeId = this.modelsContent
            res.data.storeId.forEach(e =>{
              this.storeIdList.push(e)
            })
          })
          .catch(res => {
            if (res.response.status == 401) {
              window.location = "/";
            }
            this.fullscreenLoading = false
          });
        
      }
      this.modelsContent = content
    },
    comfirmModal: function (bol) {
      this.modal = bol
    },
    modal: function (bol) {
      if (!bol) {
        this.$emit('setComfirmModalEvent', false,null)
      }
    }
  },
  methods: {
    queryParamsEmpty (){
      this.q.catId = ''
      this.q.prodPriceRowId =''
    },
    queryParamsFromQuickGet(){
        this.$on('updateStoreId', (storeId) => {
        this.q.storeId = storeId;
      });
    },
    query () {
      this.fullscreenLoading = true
      this.$axios.get('../api/clientOrderQuickGet/queryParamsCarUpdate',{
        params: {
          storeId: this.q.storeId
        }
      }).then((res)=>{
        this.catList = [{
          code: null,
          disName: "全部"
        }].concat(res.data.map(item =>({
          code: item.code,
          disName: item.descTitle
        })))
      })

      this.$axios.get('../api/clientOrderQuickGet/queryParamPricesUpdate',{
        params: {
          storeId: this.q.storeId
        }
      }).then((res)=>{
        this.prodPriceList = [{
          code: null,
          disName: "全部"
        }].concat(res.data.map(item => ({
          code:item.code,
          disName:item.disName
        })))
      })

      this.$axios.get('../api/clientOrderQuickGet/getStoreSchedule', {
        params: {
          storeId: this.q.storeId || this.storeId, 
          sd: moment(this.q.sd).format('YYYYMMDD'), 
          ed: moment(this.q.ed).format('YYYYMMDD'), 
          orderType: this.q.orderType, 
          catId: this.q.catId, 
          prodPriceRowId: this.q.prodPriceRowId
        }
      }).then((res) => {
        let qsd = moment(this.q.sd).format('YYYYMMDD') + '00'
        qsd = qsd.substring(2,10)
        let qed = moment(this.q.ed).format('YYYYMMDD') + '23'
        qed = qed.substring(2,10)
        let tableWidth = window.innerWidth*0.85
        this.tableStyle = {'max-width': (tableWidth+'px')}
        let colors =['4390DF', 'DA5A53', '59CDE2', 'FFC194', '45FFFD', '7AD61D', '78AA1C', '00CCFF','F472D0']
        let colorFlow0 = 'adadad'
        let colorFlow10 = 'ffff00'
        let sdMoment = moment(this.q.sd)
        let edMoment = moment(this.q.ed)

        this.stocks.splice(0,this.stocks.length)
        this.cols.splice(0,this.cols.length)
        this.stocksRow.splice(0,this.stocksRow.length)
        this.dateCols.splice(0,this.dateCols.length)
        
        
        // 格式化時間起始結束時間
        res.data.forEach(e => {
          e.clientOrders.forEach(co => {
            co.sd = co.sd.substring(2,10)
            co.ed = co.ed.substring(2,10)
            if( parseInt(co.sd) < parseInt(qsd)) {
              co.sd = qsd
            }
            if( parseInt(co.ed) > parseInt(qed)) {
              co.ed = qed
            }
            // 不含訂單為 0
            if (!this.includeFlow0 && co.flow === '0'){
              co.exclude = true
            } else {
              co.exclude = false
            }
          })
          for (let i = 0 ; i < e.clientOrders.length ; i++) {
            if (e.clientOrders[i].exclude) {
              e.clientOrders.splice(i, 1)
              i--
            }
          }
          this.stocks.push(e)
        })

        // 當天就要先加上去
        for(let j=0;j<10;j++){// 補滿小時數01,02~09
          let str = sdMoment.format('YYYYMMDD').substring(2,8)+'0'+j
          this.cols.push(str)
        }
        for(let j=10;j<24;j++){
          let str = sdMoment.format('YYYYMMDD').substring(2,8)+j
          this.cols.push(str)
        }

        let days = edMoment.diff(sdMoment, 'days')
        for(let i=0 ;i < days ;i++) {
          sdMoment = sdMoment.add(1,'days')
          for(let j=0;j<10;j++){ // 補滿小時數01,02~09
            let str = sdMoment.format('YYYYMMDD').substring(2,8)+'0'+j
            this.cols.push(str)
          }
          for(let j=10;j<24;j++){
            let str = sdMoment.format('YYYYMMDD').substring(2,8)+j
            this.cols.push(str)
          }
        }
        
        let colorIndex = 0
        this.stocks.forEach(stock => {
          // 同一個車類 同一個色系
          if (colorIndex === 9) {
            colorIndex = 0
          }
          let style = {'background-color': ('#' +colors[colorIndex])}

          let qtyCols = []
          for (let i=0 ; i < this.cols.length ; i++ ){
            qtyCols.push(stock.stockQty)
          }
          stock.clientOrders.forEach(co => {
            // 新增每一個車類內的訂單
            // 第一行為會員名稱
            let  clientUserDisName = ''
            switch (co.flow) {
              case '0':
                clientUserDisName = (co.clientUserDisName + '(待確)')
                break;
              case '10':
                clientUserDisName = (co.clientUserDisName + '(待付)')
                break;
              default:
                clientUserDisName = co.clientUserDisName
                break;
            }
            let row = {cols: [{val:clientUserDisName , index:-1, style: {'border': '1px black solid'}}]}
            let tempCols = row.cols
            let coSdI = parseInt(co.sd)
            let coEdI = parseInt(co.ed)
            let index=0
            for (let i=0 ; i < this.cols.length ; i++ ){
              let currentCol = parseInt(this.cols[i])
              if (co.sd !== undefined && currentCol >= coSdI && currentCol <= coEdI ) {
                let tempStyle = {}
                Object.assign(tempStyle, style)
                if (co.flow === '10') {
                  tempStyle['background-color'] = '#' + colorFlow10
                } else if (co.flow === '0') {
                  tempStyle['background-color'] = '#' + colorFlow0
                }
                tempCols.push({val: '', index, style: tempStyle}) // 有租車
                qtyCols[i]--
              } else {
                let tempStyle = {'border-left': '1px #e3e6e8 solid', 'border-right': '1px #e3e6e8 solid'}
                tempCols.push({val:'', index, style: tempStyle})
              }
              index++
            }
            this.stocksRow.push(row)
          })
          //新增車類總庫存
          let tempStyle = {color:'black', 'background-color': ('#'+colors[colorIndex]), 'border':'1px black solid','font-size':'10px'}
          let row = {cols: [{val:stock.stockDisName,index:-1, style:tempStyle}]}
          let tempCols = row.cols
          for (let i=0 ; i < this.cols.length ; i++ ){
            tempCols.push({val: qtyCols[i], index:this.cols[i], style: tempStyle})
          }
          this.stocksRow.push(row)
          colorIndex++
        })
        
        
        let lastVal = this.cols[0].substring(0,6)
        this.dateCols.push()
        let combineSpan = 1
        //計算第一列日期 合併儲存格公式
        for(let i = 1 ; i < this.cols.length ; i++) {
          if(lastVal !== this.cols[i].substring(0,6)) {
            let val = combineSpan < 4 ? '' : this.formatDayOfWeek(lastVal)
            this.dateCols.push({val, span: combineSpan})
            lastVal = this.cols[i].substring(0,6)
            combineSpan = 1
          } else {
            combineSpan++
            if(i==this.cols.length-1){
              let val = combineSpan < 4 ? '' : this.formatDayOfWeek(this.cols[i].substring(0,6))
              this.dateCols.push({val, span: combineSpan})
            }
          }
        }

        for(let i = 0 ; i < this.cols.length ; i++) {
          this.cols[i] = {index: i,val: this.cols[i].substring(6, 8)}
        }
        this.fullscreenLoading = false
      }).catch((res) => {
        this.fullscreenLoading = false
        console.log(res)
        this.$alert('查詢結果有誤，請確認查詢條件是否正確', '結果', { confirmButtonText: '確定' });
      });
    },
    formatDayOfWeek(date){
      let mo = moment(('20' + date), "YYYYMMDDHH")
      let w = this.weekDisplay[parseInt(mo.format('d'))]
      return mo.format('MM/DD') +'(' + w + ')';
    },
    cancel () {
      this.$emit('comfirmModalCancelEvent', false)
    },
  },
  created () {
    //
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        res.data.authStores.forEach(e => {
          this.storeIdList.push(e)
        });
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  mounted () {
    //
  }
}
</script>
<style scoped>
.col-label{
  background-color:#DDDDDD;
  border-style:solid;
  border-width:1px;
}
.col-value{
  border-style:solid;
  border-width:1px;
}
</style>
