<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="grid-content bg-purple"></div>
          <el-input placeholder="資金帳戶名稱" v-model="qDisName">
            <template slot="prepend">資金帳戶名稱</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple"></div>
          <el-input placeholder="銀行名稱" v-model="qBankDisName">
            <template slot="prepend">銀行名稱</template>
          </el-input>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column prop="disName" label="資金帳戶名稱" > </el-table-column>
        <el-table-column prop="bankDisName" label="銀行名稱" ></el-table-column>
        <el-table-column prop="bankAccount" label="銀行帳戶" ></el-table-column>
        <el-table-column prop="updateDate" label="更新時間" ></el-table-column>
        <el-table-column prop="updator" label="更新人"> </el-table-column>
        <!-- <el-table-column   prop="stockQty" label="車數">  </el-table-column> -->
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              @click="showDetailForm(scope.row)"
              type="primary"
              size="small"
              >編輯</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal"
        :current-page.sync="pageCurrent"
        @current-change="query"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="編輯視窗" :close-on-click-modal="false" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="editForm">
        <el-form-item label="帳戶系統Id" :label-width="formLabelWidth" prop="moneyAccountId">
          <el-input v-model="form.moneyAccountId" autocomplete="off" :disabled="formType=='edit'"></el-input>
        </el-form-item>
        <el-form-item label="帳戶名稱" :label-width="formLabelWidth" prop="disName">
          <el-input v-model="form.disName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="銀行名稱" :label-width="formLabelWidth" prop="bankDisName">
          <el-input v-model="form.bankDisName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="銀行帳戶" :label-width="formLabelWidth" prop="bankAccount">
        <el-input v-model="form.bankAccount" auto-complete="off"></el-input>
      </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditStore">確 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rules: {
        moneyAccountId: [{ required: true, message: "不得為空值" }],
        disName: [{ required: true, message: "不得為空值" }],
        bankDisName: [{ required: true, message: "不得為空值" }],
        bankAccount: [{ required: true, message: "不得為空值" }]
      },
      qDisName:'',qBankDisName:'',
      pageTotal: 0,
      pageCurrent: 1,
      tableData: [],
      form: {moneyAccountId: '',disName: '', bankDisName: '', bankAccount: ''},
      formLabelWidth: "180px",
      dialogFormVisible: false,
      formType: '',
      fullscreenLoading: false
    }
  },
  components: {
    //
  },
  created() {
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        this.query();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  name: "StoreMoneyAccountSetting",
  computed: {
    disName: {
      get() {
        if (this.$store.newClientUser == null) return "xx";
        return this.$store.newClientUser.disName;
      }
    },
    phone: {
      get() {
        if (this.$store.newClientUser == null) return "xx";
        return this.$store.newClientUser.phone;
      }
    }
  },

  methods: {
    query() {
      this.fullscreenLoading =true
      this.$axios
        .get("../api/storyMoneyAccount/getMoneyAccounts", {
          params: {
            page: this.pageCurrent,
            qDisName: this.qDisName,
            qBankDisName: this.qBankDisName
          }
        })
        .then(res => {
          this.tableData.splice(0, this.tableData.length);
          res.data.data.forEach(account => {
            account.updateDate = this.$date_(account.updateDate)
            this.tableData.push(account);
            
          });
          this.pageTotal = res.data.pageTotal;
          this.fullscreenLoading = false
        })
        .catch(() => {
          this.$alert("查詢失敗", "結果", { confirmButtonText: "確定" });
          this.fullscreenLoading = false
        });
    },
    confirmEditStore() {
      let url = ''
      let confimMessage = ''
      if (this.formType === 'edit') {
        url = '../api/storyMoneyAccount/editMoneyAccount'
        confimMessage = '是否確定編輯資金帳戶'
      } else if (this.formType === 'create') {
        url = '../api/storyMoneyAccount/createMoneyAccount'
        confimMessage = '是否確定新增資金帳戶'
      }
      let params = {
        disName: this.form.disName,
        moneyAccountId: this.form.moneyAccountId,
        bankDisName: this.form.bankDisName,
        bankAccount: this.form.bankAccount
      }
      this.$confirm(confimMessage,'注意',{
        confirmButtonText: '確定',
        cancelButtonText:'取消',
        type: 'warning'
      }).then(() =>{
        this.fullscreenLoading = true
        this.$axios.post(url,params).then((res) => {
          this.fullscreenLoading = false
          if (res.data.rs === 'success') {
            this.$alert(res.data.rsMsg,'注意',{
              confirmButtonText:'確定'
            })
              this.dialogFormVisible=false
              this.query()
          } else {
            this.$alert(res.data.rsMsg,'注意',{
              confirmButtonText:'確定'
            })
          }
        }).catch((err) => {
          this.fullscreenLoading = false
          console.log(err)
        })
      })
        //
    },
    showCreateDetailForm() {
      this.formType='create'
      this.dialogFormVisible = true
      this.form.moneyAccountId = ''
      this.form.disName = ''
      this.form.bankDisName = ''
      this.form.bankAccount = ''
      
        //
    },
    showDetailForm(account) {
      this.formType='edit'
      this.dialogFormVisible = true
      this.form.moneyAccountId = account.moneyAccountId
      this.form.disName = account.disName
      this.form.bankAccount = account.bankAccount
      this.form.bankDisName = account.bankDisName
    }
  }
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #ad9393;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #2e2e2e;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 250px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 400px;
  height: 300px;
  display: block;
}
</style>
