import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/clientUser/Login.vue'

import AdminStore from '../views/adminStore/AdminStore.vue'
import AdminContinueHoliday from '../views/adminStore/AdminContinueHoliday.vue'
import AdminProdPrice from '../views/adminStore/AdminProdPrice.vue'
import AdminProdStock from '../views/adminStore/AdminProdStock.vue'
import AdminPromotion from '../views/adminStore/AdminPromotion.vue'
import AdminArea from '../views/adminStore/AdminArea.vue'
import AdminStoreGroup from '../views/adminStore/AdminStoreGroup.vue'

import AdminProd from '../views/adminProd/AdminProd.vue'
import AdminProdCat from '../views/adminProd/AdminProdCat.vue'
import AdminTracker from '../views/adminProd/AdminTracker.vue'

import AdminUser from '../views/adminUser/AdminUser.vue'
import AdminLabor from '../views/adminUser/AdminLabor.vue'
import AdminSelf from '../views/adminUser/AdminSelf.vue'

import AdminClientUser from '../views/adminUser/AdminClientUser.vue'
import ClientUserAtmCheck from '../views/clientUser/ClientUserAtmCheck.vue'
import AdminTrafficTicket from '../views/adminUser/AdminTrafficTicket.vue'
import AdminUserFeedback from '../views/adminUser/AdminUserFeedback.vue'
import MemberCenterAd from '../views/adminUser/MemberCenterAd.vue'
import MemberCreditRule from '../views/adminUser/MemberCreditRule.vue'
import ClientUserApplyCancelForm from '../views/clientUser/ClientUserApplyCancelForm.vue'
import LineMessage from '../views/clientUser/LineMessage.vue'

import AdminClientOrder from '../views/adminOrder/AdminClientOrder.vue'
import AdminAdditionalClientOrder from '../views/adminOrder/adminAdditionalClientOrder.vue'
import AdminQuickGet from '../views/adminOrder/AdminQuickGet.vue'
import Booking from '../views/adminOrder/Booking.vue'
import BookingStatus from '../views/adminOrder/BookingStatus.vue'
import AdminQuickReturn from '../views/adminOrder/AdminQuickReturn.vue'
import AdminAlterClientOrder from '../views/adminOrder/AdminAlterClientOrder.vue'
import OnePageGet from '../views/adminOrder/OnePageGet.vue'
import OnePageReturn from '../views/adminOrder/OnePageReturn.vue'
import AdminStageTrans from '../views/adminOrder/AdminStageTrans.vue'

import AdminProdStatus from '../views/adminReport/AdminProdStatus.vue'
import AdminReportIndex from '../views/adminReport/AdminReportIndex.vue'
import AdminRevenueAnalyze from '../views/adminReport/AdminRevenueAnalyze.vue'
import AdminClientAnalyze from '../views/adminReport/AdminClientAnalyze.vue'
import AdminProdLocation from '../views/adminReport/AdminProdLocation.vue'
import AdminProdHeatmap from '../views/adminReport/AdminProdHeatmap.vue'
import AdminProdPath from '../views/adminReport/AdminProdPath.vue'
import RentingAnalyze from '../views/adminReport/RentingAnalyze.vue'
import CTRAnalyze from '../views/adminReport/CTRAnalyze.vue'

import d3example from '../views/example/d3example.vue'
import wsClientSideEx from '../views/example/wsClientSideEx.vue'

import EmailSend from '../views/email/EmailSend.vue'

import AdminAlert from '../views/adminNotify/AdminAlert.vue'
import ClientOrderChangeLog from '../views/adminNotify/ClientOrderChangeLog.vue'

// financail
import FinancialClientOrderPayQuery from '../views/financial/FinancialClientOrderPayQuery.vue'
import FinancialInvoiceQuery from '../views/financial/FinancialInvoiceQuery.vue'
import StoreMoneyAccountSetting from '../views/financial/StoreMoneyAccountSetting'
import FinancialRefundQuery from '../views/financial/FinancialRefundQuery.vue'
import FinancialWallet from '../views/financial/FinancialWallet.vue'
import FinancialWSTP from '../views/financial/FinancialWSTP.vue'


//解決會出現紅字的問題
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/userIndex',
  //   name: 'UserIndex',
  //   redirect:'/userIndex/userList',
  //   component: UserIndex,
  //   children: [
  //     // 当 /user/:id 匹配成功，
  //     // UserHome 会被渲染在 User 的 <router-view> 中
  //     { path: 'userList', component: UserList },
  //     { path: 'userDetail', component: UserDetail }
  //     // ...其他子路由
  //   ]
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  
  {
    path: '/',
    name: 'Login',
    component: Login
  },
 
  {
    path: '/adminStore',
    name: 'AdminStore',
    component: AdminStore
  },  
  {
    path: '/adminProdCat',
    name: 'AdminProdCat',
    component: AdminProdCat
  },
  
  {
    path: '/adminProdPrice',
    name: 'AdminProdPrice',
    component: AdminProdPrice
  },
  {
    path: '/adminProdStock',
    name: 'AdminProdStock',
    component: AdminProdStock
  },
  {
    path: '/adminProd',
    name: 'AdminProd',
    component: AdminProd
  },
  {
    path: '/adminTracker',
    name: 'AdminTracker',
    component: AdminTracker
  },
  {
    path: '/adminArea',
    name: 'AdminArea',
    component: AdminArea
  },
  
  { // 門市群組
    path: '/adminStoreGroup',
    name: 'AdminStoreGroup',
    component: AdminStoreGroup
  },

  //admin user 使用者管理
  {
    path: '/adminUser',
    name: 'AdminUser',
    component: AdminUser
  },
  //admin 一般員工
  {
    path: '/adminLabor',
    name: 'AdminLabor',
    component: AdminLabor
  },
  //admin 
  {
    path: '/adminSelf',
    name: 'AdminSelf',
    component: AdminSelf
  },  
  
  //client user 客戶管理
  {//client user 客戶設定
    path: '/adminClientUser',
    name: 'AdminClientUser',
    component: AdminClientUser
  },
  {//客戶回饋
    path: '/adminUserFeedback',
    name: 'AdminUserFeedback',
    component: AdminUserFeedback
  },
  {
    path: '/clientUserAtmCheck',
    name: 'ClientUserAtmCheck',
    component: ClientUserAtmCheck
  },
  {//罰單
    path: '/adminTrafficTicket',
    name: 'AdminTrafficTicket',
    component: AdminTrafficTicket
  },
  //訂單管理
  {//訂單管理
    path: '/adminClientOrder',
    name: 'AdminClientOrder',
    component: AdminClientOrder
  },
  {//訂單管理
    path: '/adminQuickGet',
    name: 'AdminQuickGet',
    component: AdminQuickGet
  },     
  {//預約作業
    path: '/booking',
    name: 'Booking',
    component: Booking
  },     
  {//預約作業
    path: '/adminQuickReturn',
    name: 'AdminQuickReturn',
    component: AdminQuickReturn
  },
  {//補單作業
    path: '/adminAdditionalClientOrder',
    name: 'AdminAdditionalClientOrder',
    component: AdminAdditionalClientOrder
  },
  {//長租分期
    path: '/adminStageTrans',
    name: 'AdminStageTrans',
    component: AdminStageTrans
  },
  {//車輛狀況
    path: '/adminProdStatus',
    name: 'AdminProdStatus',
    component: AdminProdStatus
  },
  {// 訂單排序
    path: '/bookingStatus',
    name: 'BookingStatus',
    component: BookingStatus
  },
  {// 優惠碼管理
    path: '/adminPromotion',
    name: 'AdminPromotion',
    component: AdminPromotion
  },
  {// 營收統計
    path: '/adminReportIndex',
    name: 'AdminReportIndex',
    component: AdminReportIndex
  },
  {// 客戶畫像統計
    path: '/adminClientAnalyze',
    name: 'AdminClientAnalyze',
    component: AdminClientAnalyze
  },
  {// 車輛即時位址
    path: '/adminProdLocation',
    name: 'AdminProdLocation',
    component: AdminProdLocation
  },
  {// 營收分析
    path: '/adminRevenueAnalyze',
    name: 'AdminRevenueAnalyze',
    component: AdminRevenueAnalyze
  },
  {
    path: '/d3example',
    name: 'd3example',
    component:d3example
  },
  {
    path: '/wsClientSideEx',
    name: 'wsClientSideEx',
    component:wsClientSideEx
  },
  {
    path: '/emailSend',
    name: 'EmailSend',
    component:EmailSend
  },
  //廣告輪播設定
  {
    path: '/memberCenterAd',
    name: 'MemberCenterAd',
    component:MemberCenterAd
  },
  //客戶信用評等
  {
    path: '/memberCreditRule',
    name: 'MemberCreditRule',
    component:MemberCreditRule
  },
  //客戶取消預約申請
  {
    path: '/clientUserApplyCancelForm',
    name: 'ClientUserApplyCancelForm',
    component:ClientUserApplyCancelForm
  },
  //LineMessage
  {
    path: '/lineMessage',
    name: 'LineMessage',
    component:LineMessage
  },
  { // 熱力圖
    path: '/adminProdHeatmap',
    name: 'AdminProdHeatmap',
    component:AdminProdHeatmap
  },
  { // 警示查詢
    path: '/adminAlert',
    name: 'AdminAlert',
    component: AdminAlert
  },
  {// 車輛路徑
    path: '/adminProdPath',
    name: 'AdminProdPath',
    component: AdminProdPath
  },
  {// 收款查詢
    path: '/financialClientOrderPayQuery',
    name: 'FinancialClientOrderPayQuery',
    component: FinancialClientOrderPayQuery
  },
  {// 發票查詢
    path: '/financialInvoiceQuery',
    name: 'FinancialInvoiceQuery',
    component: FinancialInvoiceQuery
  },
  {//資金帳戶設定
    path: '/storeMoneyAccountSetting',
    name: 'StoreMoneyAccountSetting',
    component: StoreMoneyAccountSetting
  },
  {//編輯訂單
    path: '/adminAlterClientOrder',
    name: 'AdminAlterClientOrder',
    component: AdminAlterClientOrder
  },
  {
    path: '/clientOrderChangeLog',
    name: 'ClientOrderChangeLog',
    component: ClientOrderChangeLog
  },
  {//一頁租車
    path: '/onePageGet',
    name: 'OnePageGet',
    component: OnePageGet
  },
  {//一頁還車
    path: '/onePageReturn',
    name: 'OnePageReturn',
    component: OnePageReturn
  },
  {
    path: '/rentingAnalyze',
    name: 'RentingAnalyze',
    component: RentingAnalyze
  },
  {//應退帳款
    path: '/financialRefundQuery',
    name: 'FinancialRefundQuery',
    component: FinancialRefundQuery
  },
  {// 文中
    path: '/financialWSTP',
    name: 'FinancialWSTP',
    component: FinancialWSTP
  },
  {// 零用金
    path: '/financialWallet',
    name: 'FinancialWallet',
    component: FinancialWallet
  },
  
  {//假日設定
    path: '/adminContinueHoliday',
    name: 'AdminContinueHoliday',
    component: AdminContinueHoliday
  },
  {//關鍵字分析
    path: '/CTRAnalyze',
    name: 'CTRAnalyze',
    component: CTRAnalyze
  }
  
  
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]


const router = new VueRouter({
  mode: 'hash',
  base: '/cms/',
  // base: process.env.BASE_URL,
  routes
})



export default router
