<template>
  <div>
    <el-collapse v-model="activeName">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
        <el-collapse-item name="1">
          <template slot="title">
           <h2>客戶ATM匯款資料查詢</h2>
          </template>
        <el-row :gutter="20">
          <el-col :span="7">
              <el-input placeholder="客戶電話" v-model="qPhone">
                <template slot="prepend">客戶電話</template>
              </el-input>
          </el-col>
          <el-col :span="7">
              <el-input placeholder="客戶帳號" v-model="qClientUserId">
                <template slot="prepend">客戶帳號</template>
              </el-input>
          </el-col>
          <el-col :span="8">
              <el-input placeholder="訂單號" v-model="qClientOrderId">
                <template slot="prepend">訂單號</template>
              </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="7">          
            門市&nbsp;&nbsp;<el-select v-model="qStoreId">
              <el-option 
                v-for="item in stores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">          
            處理狀態&nbsp;&nbsp;<el-select v-model="qReadFlag" style="width:120px;">
              <el-option 
                v-for="item in readFlags"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
              <el-input placeholder="匯款末5碼" v-model="qBankNo">
                <template slot="prepend">匯款末5碼</template>
              </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :gutter="10" :span="24" :md="13">
              填單日期區間 <el-date-picker
                v-model="qSDate"
                type="date"
                placeholder="填單日期">
              </el-date-picker>
              &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
                v-model="qEDate"
                type="date"
                placeholder="填單日期">
              </el-date-picker>
            </el-col>
          <el-col :span="8">
            <el-button type="primary" @click="query()">查詢</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
            <el-button type="primary" @click="downloadExcel()">下載報表</el-button>
          </el-col>    
        </el-row>
        </el-collapse-item>
      </el-card>
      
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="clientOrderId" width="160px"   label="訂單號"></el-table-column>
        <el-table-column   prop="storeDisName"  width="160px" label="店家">  </el-table-column>
        <el-table-column   prop="clientUserDisName" width="120px" label="客戶">  </el-table-column>
        <el-table-column   prop="bankNo" width="100px" label="銀行末5碼">  </el-table-column>
        <el-table-column   prop="phone" width="110px" label="電話"></el-table-column>
        <el-table-column   prop="atmNote" width="180px" label="備註" ></el-table-column>
        <el-table-column   prop="createDate"  label="填單時間" width="150px"></el-table-column>
        <el-table-column   prop="readFlagDisName" label="處理狀態" width="80"></el-table-column>
        <el-table-column   prop="updateDate" label="更新時間" width="150px"></el-table-column>
        <el-table-column   prop="updator" label="更新人"></el-table-column>
        <el-table-column   fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
    </el-collapse>

  <el-dialog title=" " :close-on-click-modal="false" :visible.sync="dialogFormVisible" font-size="150%" width="90%" center>
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">訂單明細
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientOrderId}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;"> {{form.clientUserDisName}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td style="border-style:solid; border-width:1px;">{{form.clientPhone}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">出生年月日:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientBirthday}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單管道:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientType | filter_clientType}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車類:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.prodPriceDisName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車型:</td>
          <td style="border-style:solid; border-width:1px;">{{form.catDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車號:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.carNo}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.sDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單還車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.eDate}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.realStartDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際還車時間:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.realEndDate}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">類別:</td>
          <td style="border-style:solid; border-width:1px;">{{form.rentDateTypeDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">加購保障:</td>
          <td style="border-style:solid; border-width:1px;">{{form.plusInsuranceDisName}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">已付訂金:</td>
          <td style="border-style:solid; border-width:1px;">{{form.deposit}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">最後計算金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.price}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">加購金額:</td>
          <td style="border-style:solid; border-width:1px;">{{form.plusPrice}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款金額:</td>
          <td style="border-style:solid; border-width:1px;">{{form.price}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.reserveNote}}</td></tr>
          <tr v-if="form.flow=='1000'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取消原因:</td>
          <td colspan="3">{{form.cancelNote}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">出發里程</td>
          <td style="border-style:solid; border-width:1px;">{{form.startDistance}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">結束里程</td>
          <td style="border-style:solid; border-width:1px;">{{form.endDistance}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">財務備註:</td>
          <td style="border-style:solid; border-width:1px;">{{form.atmNote}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">類別:</td>
          <td style="border-style:solid; border-width:1px;">{{(form.rentDateType==='2')?'長租':'短租'}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶訂單備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.clientNote}}</td></tr>

          <tr><td colspan="4" style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶證件:</td></tr>
          <tr style="border-style:solid; border-width:5px; ">
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic1"><img :src="pic1" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic2"><img :src="pic2" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic3"><img :src="pic3" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="pic4"><img :src="pic4" style="width:250px;height:180px;"/></a></td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">歷史紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyBooking" :key="item.clientOrderId" :value="item.clientOrderId">
                下單時間:{{item.createDate}}-備註:{{item.reserveNote}}(訂單號:{{item.clientOrderId}})
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="(item, key) in clientOrderTrans" :key="key">
                {{item.updateDate}}-{{item.moneyType}}-{{item.disName}}-({{item.amt}}元)
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單流程:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyFlow" :key="item.flow" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">匯款帳號末5碼:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;"><span style="color:red">{{form.bankNo}}</span></td>
          </tr>
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">財務備註:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;"><el-input v-model="form.atmNote" autocomplete="off"></el-input></td>
          </tr>
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">狀態(綠=已處理):</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">
              <el-switch v-model="form.readFlag" active-color="#13ce66" inactive-color="#ff4949" 
              active-value="Y" inactive-value="N">
              </el-switch>
            </td>
          </tr>
        </tbody>
      </table>
    </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false, transName = '0'" style="font-size:150%">退  出</el-button>
    <el-button type="primary" @click="confirmReceiveCashFromAtm()" v-show="this.assignRowReadFlag !=='Y' || form.flow === '10'" style="font-size:150%">確認收款</el-button>
    <el-button type="primary" @click="confirmChangeStatus()" style="font-size:150%">變更狀態和備住</el-button>
  </div>
</el-dialog>
  </div>
</template>


<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
      pic1:null,pic2:null,pic3:null,pic4:null,
      pageTotal:0,
      pageCurrent:1,
      qClientUserId:'',qClientOrderId:'',qPhone:'',qStoreId:'',qReadFlag:'',qBankNo:'',qSDate:null,qEDate:null,
      tableData:[],
      historyFlow:[],
      historyBooking:[],
      clientOrderTrans: [], // 支付紀錄
      clientOrderSignature: '', // 簽名檔路徑
      dialogFormVisible: false,
      form: {
        deposit:0,
        flow: '',
        atmNote: '',
        readFlag: 'N'
      },
      formLabelWidth: '120px',
      cats: [],
      stores: [],
      prodPrices: [],
      catsMap: [],
      storesMap: [],
      readFlags: [{code:'',disName:'全部'},{code:'Y',disName:'已處理'}, {code:'N',disName:'未處理'}],
      selectStores: [],
      flows:[],
      activeName: '1', // 搜尋手風琴預設
      transName: '0', // 簽名檔手風琴預設
      assignRowBankNo: '',
      assignRowReadFlag: 'Y'
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>=1){
          this.stores.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e)
        });
        this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
        }        
      });   
  },
  name: 'ClientUserAtmCheck',
  methods :{
    query(){
      let sDate = this.qSDate == null ? '' : moment(this.qSDate).format('YYYYMMDD');
      let eDate = this.qEDate == null ? '' : moment(this.qEDate).format('YYYYMMDD');
      let qForm= {page:this.pageCurrent,
       qClientUserId:this.qClientUserId,   
       qClientOrderId:this.qClientOrderId,
       qStoreId:this.qStoreId,
       qPhone:this.qPhone,
       qReadFlag: this.qReadFlag,
       qBankNo: this.qBankNo,
       qSDate: sDate,
       qEDate: eDate
       }
      this.$axios
        .get('../api/clientUserAtmCheck/getClientOrders', {params:qForm}) 
        .then((res)=>{
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store=>{
              this.tableData.push(store)
              if (store.readFlag === 'Y') {
                store.readFlagDisName = '已處理'
              } else {
                store.readFlagDisName = ''
              }
              store.createDate = this.$getDateTime_('_14', store.createDate)
              store.updateDate = this.$getDateTime_('_14', store.updateDate)
            })
          this.pageTotal=res.data.pageTotal
      }).catch(()=>{
          this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' })
      });  
    },
    confirmChangeStatus(){
        this.$confirm('確定修改'+this.form.clientOrderId+'ATM匯款申請資料嗎?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let pa = {
            rowId: this.form.rowId,
            atmNote: this.form.atmNote,
            readFlag: this.form.readFlag
          };
          let url = '../api/clientUserAtmCheck/confirmChangeStatus'
          this.$axios
            .post(url, pa)
            .then(() => {
              this.dialogFormVisible = false
              this.query()
            }).catch(() => {
              this.$alert('更新失敗', '結果', { confirmButtonText: '確定' })
            });
      })      
    },
    confirmReceiveCashFromAtm(){
        this.$confirm('確定收到'+this.form.bankNo+'訂金嗎?', '注意', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.confirmEditStoreNextFlow50()
          this.dialogFormVisible = false
        }).catch(() => {
       
        });      
    },
    confirmEditStoreNextFlow50(){ // 直接轉取車
      this.form.deposit=200
      let pa = {
        clientOrderId: this.form.clientOrderId,
        deposit:this.form.deposit,
        atmNote: this.form.atmNote,
        bankNo: this.form.bankNo
      };
      let url = '../api/clientUserAtmCheck/confirmAtmPay'
      this.$axios
        .post(url, pa)
        .then(() => {
          this.query()
          this.dialogFormVisible = false
        }).catch((res) => {
          this.$alert('傳送失敗', '結果', { confirmButtonText: '確定' })
          console.log(res.data);
        });

    },
    reset(){
      this.qClientUserId=null
      this.qClientOrderId=null
      this.qSDate=null
      this.qStoreId=this.stores[0].code
      this.qPhone=null
      this.qSDate = null
      this.qEDate = null
    },
    showDetailForm(clientOrder){
      this.assignRowBankNo = clientOrder.bankNo
      this.assignRowReadFlag = clientOrder.readFlag
      this.$axios
        .get('../api/clientUserAtmCheck/getClientOrderForm?clientOrderId='+clientOrder.rowId)
        .then((res)=>{
          this.transName = '0'
          this.dialogFormVisible = true
          this.form = Object.assign({}, this.form, res.data[0])
          if(this.form.plusPrice=='0'||this.form.plusPrice==''){
            this.form.plusPrice = '0'
          }
          this.form.bankNo = this.assignRowBankNo
          this.form.eDate = this.$date_(this.form.eDate)
          this.form.sDate = this.$date_(this.form.sDate)
          this.form.realStartDate = this.$date_(this.form.realStartDate)
          this.form.realEndDate = this.$date_(this.form.realEndDate)
          this.form.nowDistance = (parseInt(this.form.startDistance) + 1).toString()
          this.form.plusInsuranceDisName = this.form.plusInsurance == '1' ? '是' : '否'
          this.form.rentDateTypeDisName= this.form.rentDateType == '2'? '長租' : '短租'
          this.form.catDisName = this.$catDisName(this.$store.state.cats, this.form.catId)
          this.pic1 = '/imgs/zocha/' + this.form.pic1
          this.pic2 = '/imgs/zocha/' + this.form.pic2
          this.pic3 = '/imgs/zocha/' + this.form.pic3
          this.pic4 = '/imgs/zocha/' + this.form.pic4
          this.historyFlow.splice(0, this.historyFlow.length)
          res.data[0].historyFlow.forEach(e=>{
            e.updateDate=this.$date_(e.updateDate)
            this.historyFlow.push(e)
          })
          this.historyBooking.splice(0, this.historyBooking.length)
          res.data[0].historyBooking.forEach(e=>{
            e.createDate=this.$date_(e.createDate)
            this.historyBooking.push(e)
          })

          this.clientOrderTrans.splice(0, this.clientOrderTrans.length)
          res.data[0].clientOrderTrans.forEach(e=>{
            if (e.moneyType == 'CASH') {
              e.moneyType = '現金'
            } else if (e.moneyType == 'CREDIT') {
              e.moneyType = '信用卡'
            } else if (e.moneyType == 'LINEPAY') {
              e.moneyType = 'LINE Pay'
            } else if (e.moneyType == 'ATM') {
              e.moneyType = 'ATM 轉帳'
            } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
            } else { e.moneyType = '未知付款方式' }
            e.updateDate=this.$date_(e.updateDate)
            if (e.status !== 'SUCCESS') {
              e.updateDate = e.updateDate + '(付款失敗)'
            }
            this.clientOrderTrans.push(e)
          })
      }).catch((res)=>{
          console.log(res)
          this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' })
      });
    },
    downloadExcel() {
      if(this.qSDate == null || this.qEDate == null) {
        this.$alert('請填寫填單日期區間', '結果', { confirmButtonText: '確定' });
        return
      }

      if ( this.qSDate == null | this.qEDate == null ) {
        if ( this.qSDate != null | this.qEDate != null ) {
          this.$alert('請正確填寫日期區間', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      if ( moment(this.qEDate) - moment(this.qSDate) > 90*86400000 ) { // 86400000 一天
        this.$alert('日期區間不可超過90天', '結果', { confirmButtonText: '確定' });
        return
      }
      let sDate = this.qSDate == null ? '' : moment(this.qSDate).format('YYYYMMDD');
      let eDate = this.qEDate == null ? '' : moment(this.qEDate).format('YYYYMMDD');
      
      window.open('../api/clientUserAtmCheck/downloadExcel?qClientUserId='+this.qClientUserId
      +'&qClientOrderId='+this.qClientOrderId
      +'&qStoreId='+this.qStoreId
      +'&qPhone='+ this.qPhone
      +'&qReadFlag='+this.qReadFlag
      +'&qBankNo='+this.qBankNo
      +'&qSDate='+sDate
      +'&qEDate='+eDate)
    }
  }
}
</script>
