import { render, staticRenderFns } from "./AdminReportIndex.vue?vue&type=template&id=630fb7e6&scoped=true"
import script from "./AdminReportIndex.vue?vue&type=script&lang=js"
export * from "./AdminReportIndex.vue?vue&type=script&lang=js"
import style0 from "./AdminReportIndex.vue?vue&type=style&index=0&id=630fb7e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630fb7e6",
  null
  
)

export default component.exports