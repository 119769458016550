<template>
<el-container>
  <el-aside width="300px">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <!-- 查詢空間 -->
    <el-row :gutter="20" style="margin-top:10px;">
      <el-col>
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="getHeatMapData()">查詢</el-button>
        <el-button type="primary" @click="reset()">重置</el-button>
      </el-col>
    </el-row>
    </el-card>
  </el-aside>
  <el-main>
    <div id="map" class="google-map"></div>
  </el-main>
</el-container>
</template>

<script>
export default {
  data() {
    return {
      ggmap:null,
      qStoreId: '',
      qCarNo: '',
      qClientOrderId: '',
      cars: [],
      selectStores: [],
      storesMap: [],
      stores: [],
      centerLat: '',
      centerLng: '',
      activeName: '1',
      ggservice : null,
      storesLocat: [],
      counter: [0,0,0],
      trackerMap: {},
      heatMapData: []
    }
  },
  created() {
    this.$axios
      .get('../api/params/getParams',{})
      .then((res)=> {
        this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>1){
          this.selectStores.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e)
          this.storesMap[e.code] = e.disName
          this.selectStores.push(e)
        });
        this.qStoreId = this.selectStores[0].code
      }).catch((res)=>{
        if(res.response.status==401){
          window.location = '/';
        }
    })
    this.$axios.get('../api/adminProdLocation/getStoreLocat')
      .then((res) => {
        res.data.forEach(e =>{
          this.storesLocat.push(e)
        })
      })
  },
  mounted(){
    this.getHeatMapData()
  },
  methods:{
    reset() {
      this.qStoreId = ''
      this.qCarNo = ''
      this.qClientOrderId = ''
      // this.query()
    },
    getHeatMapData () {
      let google = window.google
      this.heatMapData = []
      this.$axios.get('https://cms.mofuntravel.com.tw/iot/api/getHeatmapData').then(
        (res) => {
          if (res.data.rs === 'success') {
            res.data.data.forEach(locat => {
              this.heatMapData.push(new google.maps.LatLng(locat.lat,locat.lng))
            })
            this.ggmap = new google.maps.Map(document.getElementById('map'), {
              center: {lat: 25.0335398, lng: 121.55620666},
              zoom: 10,
              mapTypeId: 'satellite'
              })
            var heatMap = new google.maps.visualization.HeatmapLayer({
              data: this.heatMapData})
            heatMap.setMap(this.ggmap)
          } else {
            console.log('iot端execption')
          }
        }
      ).catch(()=>{console.log('heatMapData測試中')})
    }
  },
  name: 'AdminProdHeatmap',
  components: {},
  computed: {}
}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 600px;
}
a.active {
  border-bottom: 3px solid #007bff;
}
</style>