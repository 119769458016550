<template>
  <div>
    <!-- search area -->
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="8">
        <el-col :span="14">
          日期區間
          <el-date-picker v-model="sDate" type="date" placeholder="起始日期" :clearable="false">
          </el-date-picker>
          ~<el-date-picker v-model="eDate" type="date" placeholder="結束日期" :clearable="false">
          </el-date-picker>
        </el-col>
        <el-col :span="8">
          訂單類別
            <el-select v-model="q.qRentDateType" clearable placeholder="">
              <el-option
                v-for="item in rentDateTypeOption"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top:15px;">
        <el-col :span="2">
          <el-button type="primary" @click="clickCard()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="showUploadCTRForm()">上傳</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="downloadCTRExcel()">下載</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="revenueClicked" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            近12月營收點擊數概況(營收(萬)：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            點擊數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="revenueExposure" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            近12月營收曝光數概況(營收(萬)：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            曝光數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="countClicked" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            近12月訂單數點擊數概況(訂單數：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            點擊數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="countExposure" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            近12月訂單數點曝光概況(訂單數：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            曝光數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="weekRevenueClicked" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            近12周營收點擊數概況(營收(萬)：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            點擊數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="weekRevenueExposure" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            近12周營收曝光數概況(營收(萬)：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            曝光數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="weekCountClicked" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            近12周訂單數點擊數概況(訂單數：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            點擊數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="weekCountExposure" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            近12周訂單數點曝光概況(訂單數：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            曝光數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="dayRevenueClicked" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            {{disSDate}}~{{disEDate}}營收點擊數概況(營收(萬)：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            點擊數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="dayRevenueExposure" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            {{disSDate}}~{{disEDate}}營收曝光數概況(營收(萬)：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            曝光數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="dayCountClicked" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            {{disSDate}}~{{disEDate}}訂單數點擊數概況(訂單數：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            點擊數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="dayCountExposure" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            {{disSDate}}~{{disEDate}}訂單數點曝光概況(訂單數：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            曝光數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>


    <el-dialog title="上傳關鍵字分析視窗" :visible.sync="dialogCTRVisible"  width="90%"  :close-on-click-modal="false">

      <el-input type="textarea" placeholder="日期	點擊	曝光	點閱率	排名(請從excel複製數值再貼上至此)" style="height:50vh"  rows="15" v-model="ctrDataInput"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCTRVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUploadCTR()">確 定</el-button>
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import * as moment from "moment/moment";
import * as d3 from "d3";

export default {
  data() {
    return {
      stores: [],
      storesMap: [],
      selectStores: [],
      sDate: "",
      eDate: "",
      nowDate: new Date().getTime(),
      labelPosition: "right",
      q: {
        qStoreId: null,
        qSDate: null,
        qEDate: null,
        qRentDateType: ''
      },
      formLabelWidth: "120px",
      svgWidth: 700,
      svgHeight: 500,
      disSDate:"",
      disEDate:'',
      storeProdRentingData:[],
      storeProdUnusedData:[],
      storeProdDateList:[],
      storeProdsList:[],
      ca: null,
      formData: undefined,
      dialogCTRVisible: false,
      ctrDataInput: '',
      lastDate:'',
      revenueDate:[],
      revenueSumData:[],
      revenueCounteData:[],
      exposureData:[],
      clickCount:[],
      revenueWeekDate:[],
      revenueWeekSumData:[],
      revenueWeekCounteData:[],
      exposureWeekData:[],
      clickWeekCount:[],
      revenueDayDate:[],
      revenueDaySumData:[],
      revenueDayCounteData:[],
      exposureDayData:[],
      clickDayCount:[],
      rentDateTypeOption:[{code:'',disName:'全部'},{code:'1',disName:'短租'},{code:'2',disName:'長租'}],
    };
  },
  created() {
    this.sDate = moment(new Date()).startOf("month");
    this.eDate = moment(new Date()).endOf("month");
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        res.data.authStores.forEach(e => {
          this.stores.push(e);
          this.storesMap[e.code] = e.disName;
          this.selectStores.push(e);
        });
        this.q.qStoreId = this.selectStores[0].code;
        this.q.qRentDateType = this.rentDateTypeOption[0].code
        this.clickCard();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  mounted() {
    this.clickCard();
  },
  name: "CTRAnalyze",
  components: {},
  computed: {},
  methods: {
    clickCard() {
      this.disSDate = moment(this.sDate).format("YYYY/MM/DD")
      this.disEDate = moment(this.eDate).format("YYYY/MM/DD")
      this.getGraphData();
    },
    reset() {
      this.qStoreId = null;
      this.sDate = moment(new Date()).startOf("month");
    this.eDate = moment(new Date()).endOf("month");
    //   this.getGraphData();
    },
    fter_store(row, column) {
      let storeId = row[column.property];
      if (storeId == undefined) return "";
      return this.$storeDisName(this.$store.state.stores, storeId);
    },
    trans(path, qType) {
      this.$emit("gotoMenu", path.substring(1));
      this.$router.push({ path: path, query: { qType: qType }});
    },
    fter_month_option(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYY 年 MM 月");
    },
    fter_month_option_value(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYYMM");
    },
    getGraphData() {
      let start_date_moment = moment(this.eDate).subtract(11, "months").startOf("month");
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      this.q.qGraphSDate = _qSdate;

      let weekStartDate = moment(this.eDate).subtract(11, "weeks");
      let qSdate_ = weekStartDate.isValid() ? weekStartDate.format("YYYYMMDD") : "";
      this.q.qWeekSDate = qSdate_;

      let sDate_moment = moment(this.sDate);
      let qSdate = sDate_moment.isValid() ? sDate_moment.format("YYYYMMDD") : "";
      this.q.qSDate = qSdate;

      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      this.q.qEDate = _qEdate;
      this.$axios
      .get("../api/CTRAnalyze/getCTRGraphData", { params: this.q })
      .then(res => {
        console.log(res.data.data)
        this.revenueDate = res.data.data.revenueDate,
        this.revenueSumData = res.data.data.revenueSumData,
        this.revenueCounteData = res.data.data.revenueCounteData;
        this.exposureData = res.data.data.exposure;
        this.clickCount = res.data.data.clickCount;

        this.revenueWeekDate = res.data.data.revenueWeekDate,
        this.revenueWeekSumData = res.data.data.revenueWeekSumData,
        this.revenueWeekCounteData = res.data.data.revenueWeekCounteData;
        this.exposureWeekData = res.data.data.weekExposure;
        this.clickWeekCount = res.data.data.weekClickCount;
        
        this.revenueDayDate = res.data.data.revenueDayDate,
        this.revenueDaySumData = res.data.data.revenueDaySumData,
        this.revenueDayCounteData = res.data.data.revenueDayCounteData;
        this.exposureDayData = res.data.data.dayExposure;
        this.clickDayCount = res.data.data.dayClickCount;

        this.revenueSumData.forEach(e => e / 1000);
        this.revenueWeekSumData.forEach(e => e / 1000);
        
        this.revenueClickedGraph()
        this.revenueExposureGraph()
        this.countClickedGraph()
        this.countExposureGraph()

        this.weekRevenueClickedGraph()
        this.weekRevenueExposureGraph()
        this.weekCountClickedGraph()
        this.weekCountExposureGraph()

        this.dayRevenueClickedGraph()
        this.dayRevenueExposureGraph()
        this.dayCountClickedGraph()
        this.dayCountExposureGraph()
      })
    },
    downloadCTRExcel(){
      let end_date_moment;
      end_date_moment = moment(this.sDate);
      let _qSDate = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';
      
      end_date_moment = moment(this.eDate);
      let _qEDate = end_date_moment.isValid() ? end_date_moment.format('YYYYMMDD'):'';

      window.open('../api/CTRAnalyze/downloadExcel?qSDate='+_qSDate
      +'&qEDate='+_qEDate)
    //   
    },
    showUploadCTRForm(){
      this.dialogCTRVisible = true
      this.ctrDataInput = ''
      this.$axios.get('../api/CTRAnalyze/getLastDate').then(res=>{
        this.lastDate = res.data.date
      })
    //   
    },
    confirmUploadCTR(){
      let dataSlot = []
      let textLine = this.ctrDataInput.split("\n")
      textLine.forEach( string => {
        if (string !== '' && string !== null) {
          let content = string.split('\t')
          let date_ = content[0].replace(/\D/g, '')
          if (date_ > this.lastDate || this.lastDate == null || this.lastDate == undefined){
            dataSlot.push({date: date_,clickedCount: content[1], exposure: content[2], ranking: content[4]})
          } 
        }
      })
      this.$confirm('是否確定上傳點擊率資料','注意',{confirmButtonText:'確定',cancelButtonText:'取消'}).then(()=>{
        this.fullscreenLoading = true
        let param = { CTRData: dataSlot}
        this.$axios.post('../api/CTRAnalyze/importCTRData',param).then((res)=>{
          this.fullscreenLoading = true
          if (res.data.rs ==='success'){
            this.$alert('上傳成功','結果',{confirmButtonText:'確定'})
            this.dialogCTRVisible = false
          } else {
            this.$alert('上傳失敗','結果',{confirmButtonText:'確定'})
          }
        }).catch(() =>{
          this.$alert('發生不知名錯誤','結果',{confirmButtonText:'確定'})
          this.fullscreenLoading = false;
        })
      })
    },
    revenueClickedGraph() {
      let data_ = Object.assign([], data, this.revenueSumData);
      let data = [];
      data_.forEach(e => data.push(e / 10));

      let barSvg = d3.select("#revenueClicked").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#revenueClicked")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.clickCount)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "萬"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDate[i]))
        .y((d, i) => yRScale(this.clickCount[i]));
      barSvg
        .append("path")
        .attr("d", line(this.clickCount))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.clickCount)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    revenueExposureGraph() {
      let data_ = Object.assign([], data, this.revenueSumData);
      let data = [];
      data_.forEach(e => data.push(e / 10));

      let barSvg = d3.select("#revenueExposure").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#revenueExposure")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.exposureData)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "萬"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDate[i]))
        .y((d, i) => yRScale(this.exposureData[i]));
      barSvg
        .append("path")
        .attr("d", line(this.exposureData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.exposureData)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    countClickedGraph() {
      let data = Object.assign([], data, this.revenueCounteData);

      let barSvg = d3.select("#countClicked").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#countClicked")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.clickCount)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "單"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDate[i]))
        .y((d, i) => yRScale(this.clickCount[i]));
      barSvg
        .append("path")
        .attr("d", line(this.clickCount))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.clickCount)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    countExposureGraph() {
      
      let data = Object.assign([], data, this.revenueCounteData);
      
      let barSvg = d3.select("#countExposure").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#countExposure")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.exposureData)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "單"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDate[i]))
        .y((d, i) => yRScale(this.exposureData[i]));
      barSvg
        .append("path")
        .attr("d", line(this.exposureData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.exposureData)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    weekRevenueClickedGraph() {
      let data_ = Object.assign([], data, this.revenueWeekSumData);
      let data = [];
      data_.forEach(e => data.push(e / 10));

      let barSvg = d3.select("#weekRevenueClicked").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#weekRevenueClicked")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueWeekDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.clickWeekCount)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "萬"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueWeekDate[i]))
        .y((d, i) => yRScale(this.clickWeekCount[i]));
      barSvg
        .append("path")
        .attr("d", line(this.clickWeekCount))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.clickWeekCount)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    weekRevenueExposureGraph() {
      let data_ = Object.assign([], data, this.revenueWeekSumData);
      let data = [];
      data_.forEach(e => data.push(e / 10));

      let barSvg = d3.select("#weekRevenueExposure").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#weekRevenueExposure")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueWeekDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.exposureWeekData)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "萬"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueWeekDate[i]))
        .y((d, i) => yRScale(this.exposureWeekData[i]));
      barSvg
        .append("path")
        .attr("d", line(this.exposureWeekData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.exposureWeekData)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    weekCountClickedGraph() {
      let data = Object.assign([], data, this.revenueWeekCounteData);

      let barSvg = d3.select("#weekCountClicked").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#weekCountClicked")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueWeekDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.clickWeekCount)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "單"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueWeekDate[i]))
        .y((d, i) => yRScale(this.clickWeekCount[i]));
      barSvg
        .append("path")
        .attr("d", line(this.clickWeekCount))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.clickWeekCount)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    weekCountExposureGraph() {
      
      let data = Object.assign([], data, this.revenueWeekCounteData);
      
      let barSvg = d3.select("#weekCountExposure").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#weekCountExposure")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueWeekDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.exposureWeekData)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "單"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueWeekDate[i]))
        .y((d, i) => yRScale(this.exposureWeekData[i]));
      barSvg
        .append("path")
        .attr("d", line(this.exposureWeekData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.exposureWeekData)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    dayRevenueClickedGraph() {
      let data_ = Object.assign([], data, this.revenueDaySumData);
      let data = [];
      data_.forEach(e => data.push(e / 10));

      let barSvg = d3.select("#dayRevenueClicked").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#dayRevenueClicked")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDayDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.clickDayCount)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "元"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDayDate[i]))
        .y((d, i) => yRScale(this.clickDayCount[i]));
      barSvg
        .append("path")
        .attr("d", line(this.clickDayCount))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.clickDayCount)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    dayRevenueExposureGraph() {
      let data_ = Object.assign([], data, this.revenueDaySumData);
      let data = [];
      data_.forEach(e => data.push(e / 10));

      let barSvg = d3.select("#dayRevenueExposure").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#dayRevenueExposure")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDayDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.exposureDayData)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "元"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDayDate[i]))
        .y((d, i) => yRScale(this.exposureDayData[i]));
      barSvg
        .append("path")
        .attr("d", line(this.exposureDayData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.exposureDayData)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    dayCountClickedGraph() {
      let data = Object.assign([], data, this.revenueDayCounteData);

      let barSvg = d3.select("#dayCountClicked").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#dayCountClicked")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDayDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.clickDayCount)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "單"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDayDate[i]))
        .y((d, i) => yRScale(this.clickDayCount[i]));
      barSvg
        .append("path")
        .attr("d", line(this.clickDayCount))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.clickDayCount)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    dayCountExposureGraph() {
      
      let data = Object.assign([], data, this.revenueDayCounteData);
      
      let barSvg = d3.select("#dayCountExposure").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#dayCountExposure")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDayDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.exposureDayData)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(680,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 60) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 6)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(3,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "單"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDayDate[i]))
        .y((d, i) => yRScale(this.exposureDayData[i]));
      barSvg
        .append("path")
        .attr("d", line(this.exposureDayData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.exposureDayData)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "次"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
  }
  
};
</script>

<style scoped>
.vert {
  height: 100%;
  display: flex;
  /* -webkit-align-items: center; */
}
.col-row {
  display: flex;
}
.selectit {
  background-color: yellowgreen;
}
.empty {
  background-color: white;
}
</style>
