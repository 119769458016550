<template>
  <div>
    <!-- search area -->
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="8">
        <el-col :span="6">
          <el-col :span="6">地區</el-col>
          <el-col :span="16">
            <el-select v-model="qAreaId" width="120">
              <el-option 
                v-for="item in areas"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
        </el-col>
        <el-col :span="8">
          <el-col :span="6">所屬店家</el-col>
          <el-col :span="16">
            <el-select v-model="q.qStoreId" filterable>
              <el-option
                width="120"
                v-for="item in selectStores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                v-show="item.areaId == qAreaId || qAreaId == ''"
              ></el-option>
            </el-select>
          </el-col>
        </el-col>
        <el-col :span="16">
          日期區間
          <el-date-picker v-model="sDate" type="date" placeholder="起始日期" :clearable="false">
          </el-date-picker>
          ~<el-date-picker v-model="eDate" type="date" placeholder="結束日期" :clearable="false">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-top:15px;">
        <el-col :span="2">
          <el-button type="primary" @click="clickCard()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="downloadPart(ca)">下載</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div style="height:500px;overflow:auto">
          <div id="analyze15minProd" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          </div>
          <div style="text-align:center">
            {{disSDate}}~{{disEDate}}每小時租借分析(出租：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            閒置：
            <svg style="background-color:red;width:20px;height:14px;"></svg>)
            平均利用率{{aveUtilization}}%
          </div>
        </el-card>
      </el-col>
    </el-row>
    
  </div>
</template>

<script>
import * as moment from "moment/moment";
import * as d3 from "d3";

export default {
  data() {
    return {
      stores: [],
      storesMap: [],
      selectStores: [],
      months: [],
      monthsMap: [],
      selectMonth: [],
      sDate: "",
      eDate: "",
      selectMonthsSub: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      nowDate: new Date().getTime(),
      labelPosition: "right",
      rentalRates:[],  //存放出租率
      areas: [],
      qAreaId: '',
      q: {
        qStoreId: null,
        qMonth: null,
        qProdStatus: null,
        qSDate: null,
        qEDate: null
      },
      formLabelWidth: "120px",
      svgWidth: 700,
      svgHeight: 1400,
      revenueDate: [],
      revenueSumData: [],
      revenueCounteData: [],
      disSDate:"",
      disEDate:'',
      revenueBarData:[],
      revenueBarDataType:[],
      storeProdRentingData:[],
      storeProdUnusedData:[],
      storeProdDateList:[],
      storeProdsList:[],
      storeProdDeactivate:[],
      storeProdRepair:[],
      ca: null,
      aveUtilization:0  //計算平均利用率
    };
  },
  created() {
    this.sDate = moment(new Date()).subtract(2,'day').startOf('day');
    this.eDate = moment(new Date()).startOf('day');
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        res.data.authStores.forEach(e => {
          this.stores.push(e);
          this.storesMap[e.code] = e.disName;
          this.selectStores.push(e);
        });
        if(res.data.areas.length>1){
          this.areas.push({code:'',disName:'全部'})
        }
        res.data.areas.forEach(e => {
          this.areas.push(e)
        });
        this.qAreaId = this.areas[0].code
        this.selectMonthsSub.forEach(e => {
          this.months.push(this.fter_month_option_value(e));
          this.storesMap[
            this.fter_month_option_value(e).code
          ] = this.fter_month_option_value(e).disName;
          this.selectMonth.push({
            code: this.fter_month_option_value(e),
            disName: this.fter_month_option(e)
          });
        });
        this.q.qStoreId = this.selectStores[0].code;
        this.q.qMonth = this.selectMonth[0].code;
        this.clickCard();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  mounted() {
    this.clickCard();
  },
  name: "RentingAnalyze",
  components: {},
  computed: {},
  methods: {
    clickCard() {
      this.disSDate = moment(this.sDate).format("YYYY/MM/DD")
      this.disEDate = moment(this.eDate).format("YYYY/MM/DD")
      this.getGraphData();
    },
    reset() {
      this.q.qCarNo = null;
      this.qStoreId = null;
      this.qProdStatus = null;
      this.sDate = moment(new Date()).subtract(2,'day');
      this.eDate = moment(new Date());
      this.getGraphData();
    },
    fter_store(row, column) {
      let storeId = row[column.property];
      if (storeId == undefined) return "";
      return this.$storeDisName(this.$store.state.stores, storeId);
    },
    trans(path, qType) {
      this.$emit("gotoMenu", path.substring(1));
      this.$router.push({ path: path, query: { qType: qType }});
    },

    fter_month_option(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYY 年 MM 月");
    },
    fter_month_option_value(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYYMM");
    },
    getGraphData() {
      let start_date_moment = moment(this.sDate);
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      if (_qSdate === ""){
        this.$alert('請選擇起始時間','注意',{confirmButtonText:'確定'})
        return
      }
      this.q.qSDate = _qSdate;
      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      if (_qEdate === ""){
        this.$alert('請選擇起始時間','注意',{confirmButtonText:'確定'})
        return
      }
      if (start_date_moment > end_date_moment) {
        this.$alert('請選擇正確時間區間','注意',{confirmButtonText:'確定'})
        return
      }
      if (moment(_qEdate) - moment(_qSdate) > 6 * 86400000) {
        this.$alert('時間區間不可大於七天','注意',{confirmButtonText:'確定'})
        return
      }
      this.q.qEDate = _qEdate;
      this.$axios
        .get("../api/rentingAnalyze/getRenting15MinData", { params: this.q })
        .then(res => {
          this.storeProdRentingData = res.data.renting
          this.storeProdUnusedData = res.data.unused
          this.storeProdsList = res.data.total
          this.storeProdDeactivate = res.data.deactivate
          this.storeProdRepair = res.data.repair
          this.storeProdDateList = Object.assign([])
          res.data.dateList.forEach(e=>{
            let e_ = moment(e,'YYYYMMDDHHmmss').format('MM/DD HH時')
            this.storeProdDateList.push(e_)
          })
          this.storeProdDateList.reverse()
          this.storeProd15MinChart()
        })
    },
    async storeProd15MinChart() {
      let data = "";
      data = Object.assign([], data, this.storeProdRentingData);

      let barSvg = d3.select("#analyze15minProd").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#analyze15minProd")
        .append("svg")
        .attr('id', 'analyzeChart')
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight)
        .append('g');

      //軸
      let xScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.storeProdsList)])
        .range([0, this.svgWidth - 90]);
      let x_axis = d3.axisBottom().scale(xScale).ticks(5);
      let yLScale = d3
        .scaleBand()
        .domain(this.storeProdDateList)
        .range([this.svgHeight - 30, 0])
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
      let gXAxis = barSvg.append("g").attr("transform", "translate(80,1380)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").style("font-size", "14px").attr("transform", "translate(80,10)");
      y_L_axis(gYLAxis);
      
      //長條
      var rectStep = (1400 - 30) / data.length;
      var g = barSvg.append("g")
      
      let renting =  g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", 80)
        .attr("y",(d, i) => 30 + i * rectStep)
        .attr("width", d =>  xScale(d))
        .attr("height", rectStep -5)
        .attr("transform", "translate(0,-18)").on("mouseleave", () => {
          showText.text("");
        })
        
      var g_ = barSvg.append("g");
      let unused = g_.selectAll("rect")
        .data(this.storeProdUnusedData)
        .enter()
        .append("rect")
        .attr("fill", "red")
        .attr("x", (d, i ) => 80 + xScale(this.storeProdRentingData[i]))
        .attr("y",(d, i) => 30 + i * rectStep)
        .attr("width", d => xScale(d))
        .attr("height", rectStep -5)
        .attr("transform", "translate(0,-18)").on("mouseleave", () => {
          showText.text("");
        })
      var gt = barSvg.append("g")
      let showText = gt.append("text").attr("text-anchor", "midden")
      showText.append("tspan")
              .attr("font-size", "3em")
              .text("");
      renting.on("mouseenter", (d ,i) => {
          showText.text("出租數輛："+this.storeProdRentingData[i].toFixed(0) + ",總車數：" + this.storeProdsList[i].toFixed(0) + ",出租率："+(this.storeProdRentingData[i]/this.storeProdsList[i]*100).toFixed(0) + "%"
          + ",停用："+ this.storeProdDeactivate[i].toFixed(1) + ",維修：" + this.storeProdRepair[i].toFixed(1))
          .attr("transform", "translate( 250 , "+(rectStep*i+30)+")").style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000").style("fill", "white");
          });
      unused.on("mouseenter", (d ,i) => {
          showText.text("出租數輛："+this.storeProdRentingData[i].toFixed(0) + ",總車數：" + this.storeProdsList[i].toFixed(0) + ",出租率："+(this.storeProdRentingData[i]/this.storeProdsList[i]*100).toFixed(0) + "%"
          + ",停用："+ this.storeProdDeactivate[i].toFixed(1) + ",維修：" + this.storeProdRepair[i].toFixed(1))
          .attr("transform", "translate( 250 , "+(rectStep*i+30)+")").style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000").style("fill", "white");
        });

        this.rentalRates = []
          for (let i = 0; i < this.storeProdRentingData.length; i++) {
          // 计算出租率并添加到数组
          let rentalRate = Math.round(this.storeProdRentingData[i] / this.storeProdsList[i] * 100);
          // 将 rentalRate 添加到 rentalRates 数组
          if(!isNaN(rentalRate)){
              this.rentalRates.push(rentalRate);
            }
        }
        this.aveUtilization = this.rentalRates.reduce((total, rate) => total + rate, 0) / this.rentalRates.length;

        await this.download()
    },
    async download(){
      const svg = document.querySelector('svg').cloneNode(true); 
      document.body.appendChild(svg); 
      const g = svg.querySelector('g')
      g.setAttribute('transform', '')
      svg.setAttribute('width', g.getBBox().width) 
      svg.setAttribute('height', g.getBBox().height)
      const svgAsXML = (new XMLSerializer).serializeToString(svg);
      document.body.removeChild(svg)
      const svgData = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`
      this.ca = document.createElement( "canvas" );
      let ctx = this.ca.getContext( "2d" );
      let img = new Image();
      img.setAttribute( "src", svgData );
      
      await this.drawImg(this.ca, img, ctx)
  
   },
    async drawImg(canvas, img, ctx){
      canvas.width = this.svgWidth;
        canvas.height = this.svgHeight;
        ctx.fillStyle = '#fff';
        await ctx.fillRect(0,0,10000,10000);
        await ctx.drawImage( img, 0, 0 ,this.svgWidth,this.svgHeight);
    },
    async downloadPart(canvas){
       let a = document.createElement("a");
       a.download =this.disSDate+'~'+this.disEDate+"每小時租借分析.png";
       a.href = canvas.toDataURL( "image/png" );
      a.click();
    }
  }
};
</script>

<style scoped>
.vert {
  height: 100%;
  display: flex;
  /* -webkit-align-items: center; */
}
.col-row {
  display: flex;
}
.selectit {
  background-color: yellowgreen;
}
.empty {
  background-color: white;
}
</style>
