<template>
<div>
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="5">
      <el-col :span="7">
        <el-row :gutter="1">
          <el-col :span="21" style="">
            <el-input placeholder="員工帳號" v-model="qAdminUserId">
              <template slot="prepend">員工帳號</template>
            </el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-row :gutter="1">
          <el-col :span="6">
            所屬店家
          </el-col>
          <el-col :span="18">
            <el-select v-model="qStoreId">
              <el-option width=120 v-for="item in selectStores" :key="item.code" :label="item.disName" :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-row :gutter="1">
          <el-col :span="6">
            狀態
          </el-col>
          <el-col :span="18">
            <el-select v-model="qStatus">
              <el-option width=120 v-for="item in selectStatus" :key="item.code" :label="item.disName" :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="query()">查詢</el-button>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="adminUserId" label="帳號"></el-table-column>
      <el-table-column prop="disName" label="名稱"> </el-table-column>
      <el-table-column prop="storeId" label="店家" :formatter="fter_store"> </el-table-column>
      <el-table-column prop="status" label="狀態" :formatter="fter_status"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
          <!-- <el-button  @click="deleteRow(scope.row)" type="danger" size="small">刪除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible">
    <el-form :model="form">
      <el-form-item label="帳號" :label-width="formLabelWidth">
        <el-input v-model="form.adminUserId" autocomplete="off" :readonly="form.mode=='update'"></el-input>
      </el-form-item>
      <el-form-item label="密碼" :label-width="formLabelWidth">
        <el-input v-model="form.pwd" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item label="再一次密碼" :label-width="formLabelWidth">
        <el-input v-model="form.pwd2" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item label="名稱" :label-width="formLabelWidth">
        <el-input v-model="form.disName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="狀態" :label-width="formLabelWidth">
        <el-select v-model="form.status" placeholder="狀態">
          <el-option width=120 v-for="item in statusList" :key="item.code" :label="item.disName" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所屬店家" :label-width="formLabelWidth">
        <el-select v-model="form.storeId" placeholder="狀態">
          <el-option width=120 v-for="item in stores" :key="item.code" :label="item.disName" :value="item.code"></el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="員工碼" :label-width="formLabelWidth">
        <el-input v-model="form.checkCode" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="到職日" :label-width="formLabelWidth">
        <el-date-picker v-model="form.createDate" type="date" placeholder="">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Email" :label-width="formLabelWidth">
        <el-input v-model="form.email" autocomplete="off"></el-input>
        <el-input v-show="false" v-model="form.mode"></el-input>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmEditStore">確 定</el-button>
    </div>

  </el-dialog>
</div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {
      pageTotal: 0,
      pageCurrent: 1,
      stores: [],
      statusList: [
      {
        code: '1',
        disName: '在職'
      },{
        code: '0',
        disName: '離職'
      },],
      selectStatus:[{code:'',disName:'全部'}],
      selectStores:[],
      labelPosition: 'right',
      qDisName: '',
      qStoreId: '',
      qStatus: '',
      qAdminUserId: '',
      tableData: [],
      dialogFormVisible: false,
      dialogAuthVisible: false,
      form: {
        disName: '',
        createDate: '',
        status: '',
      },
      formLabelWidth: '120px',
      mode: 'create'
    }
  },
  created() {
    this.selectStores.push({code:'',disName:'全部'})
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        res.data.authStores.forEach(e => {
          this.stores.push(e)
          this.selectStores.push(e)
        });
        
        this.statusList.forEach(e=>{
          this.selectStatus.push(e)
        })
        this.qStatus=this.selectStatus[0].code
        this.qStoreId=this.selectStores[0].code
        this.query()
      }).catch((res) => {
        console.log(res)
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  name: 'AdminLabor',

  methods: {
    query() {
      this.$axios
        .get('../api/adminLabor/getAdminLabors', {
          params: {
            page: this.pageCurrent,
            qStoreId: this.qStoreId,
            qStatus: this.qStatus,
            qAdminUserId: this.qAdminUserId
          }
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store => {
            this.tableData.push(store)
          })
          this.pageTotal = res.data.pageTotal
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    confirmEditStore() {
      let url = '../api/adminLabor/updateAdminLabor'
      let confirmText = '是否確定更新員工資料'
      if (this.form.mode == 'create') {
        url = '../api/adminLabor/createAdminLabor'
        confirmText = '是否確定新增員工'
      }
      if ( this.mode == 'create' && this.form.pwd === '') {
        this.$alert('密碼不得為空', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.form.pwd!=this.form.pwd2){
        this.$alert('密碼不一致', '結果', { confirmButtonText: '確定' });
        return
      }
      this.$confirm(confirmText,'注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.form.mode = undefined
        this.$axios
          .post(url,this.form)
          .then((res) => {
            if(res.data=='success'){
              this.$alert('更新完成', '結果', { confirmButtonText: '確定' });
              this.query()
              this.dialogFormVisible = false
            }else{
              this.$alert(res.data, '結果', { confirmButtonText: '確定' });
            }
            
          }).catch((res) => {
            console.log(res.data);
          });
      })
    },
    showCreateDetailForm() {
      this.form = Object.assign({}, this.form, {
        adminUserId: '',
        disName: '',
        pwd: '',
        pwd2: '',
        mode: 'create',
        status: '1',
        checkCode:'',
        email:'',
        storeId:this.stores[0].code,
        createDate : moment(new Date()).toDate()
      })
      this.mode = 'create'
      this.dialogFormVisible = true
    },
    showDetailForm(adminUser) {
      this.$axios
        .get('../api/adminLabor/getAdminLabor?adminUserId=' + adminUser.adminUserId)
        .then((res) => {
          res.data.mode = 'update'
          res.data.pwd = ''
          res.data.pwd2 = ''
          if (res.data.createDate.match(/\d{4}-\d{2}-\d{2}/)) {
            res.data.createDate = moment(res.data.createDate).toDate()
          } else {
            res.data.createDate = ''
          }
          this.form = Object.assign({}, this.form, res.data)
          this.dialogFormVisible = true
          this.mode = 'update'
          // res.data.forEach(store=>{this.tableData.push(store)})
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    deleteRow(index) {
      console.log(index)
    },
    fter_status(row, column) {
      let status = row[column.property];
      if (status == undefined) return "";
      for (var i in this.statusList) {
        if (this.statusList[i].code == status) {
          return this.statusList[i].disName
        }
      }
      return ""
    },
    fter_store(row, column) {
      let storeId = row[column.property];
      if (storeId == undefined) return "";
      for (var i in this.stores) {
        if (this.stores[i].code == storeId) {
          return this.stores[i].disName
        }
      }
      return ""
    }
  }
}
</script>
