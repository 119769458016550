<template>
<div>
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;" >
    <el-row :gutter="5">
      <el-col :span="6" v-show="false">
        <el-row :gutter="1">
          <el-col :span="6">
            所屬店家
          </el-col>
          <el-col :span="18">
            <el-select v-model="qStoreId">
              <el-option width=120 v-for="item in selectStores" :key="item.code" :label="item.disName" :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :gutter="20">
        <el-col :span="6" style="margin-right:20px;">
          <el-input placeholder="員工帳號" v-model="qAdminUserId">
            <template slot="prepend">員工帳號</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-row :gutter="1">
            <el-col :span="6">
              狀態
            </el-col>
            <el-col :span="18">
              <el-select v-model="qStatus">
                <el-option width=120 v-for="item in selectStatus" :key="item.code" :label="item.disName" :value="item.code">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="adminUserId" label="帳號"></el-table-column>
      <el-table-column prop="disName" label="名稱"> </el-table-column>
      <el-table-column prop="userType" label="角色" :formatter="fter_userType"> </el-table-column>
      <el-table-column prop="checkCode" label="員工碼"> </el-table-column>
      <el-table-column prop="status" label="狀態" :formatter="fter_status"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
          <!-- <el-button  @click="deleteRow(scope.row)" type="danger" size="small">刪除</el-button> -->
          <el-button @click="showDetailOwnStoreForm(scope.row)" type="warning" size="small">權限</el-button>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible">
    <el-form :model="form">
      <el-form-item label="帳號" :label-width="formLabelWidth">
        <el-input v-model="form.adminUserId" autocomplete="off" :readonly="mode=='update'"></el-input>
      </el-form-item>
      <el-form-item label="密碼" :label-width="formLabelWidth">
        <el-input v-model="form.pwd" show-password></el-input>
      </el-form-item>
      <el-form-item label="再一次密碼" :label-width="formLabelWidth">
        <el-input v-model="form.pwd2" show-password></el-input>
      </el-form-item>

      <el-form-item label="名稱" :label-width="formLabelWidth">
        <el-input v-model="form.disName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="角色" :label-width="formLabelWidth">
        <el-select v-model="form.userType" placeholder="狀態">
          <el-option width=120 v-for="item in userTypes" :key="item.code" :label="item.disName" :value="item.code"></el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="狀態" :label-width="formLabelWidth">
        <el-select v-model="form.status" placeholder="狀態">
          <el-option width=120 v-for="item in statusList" :key="item.code" :label="item.disName" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="員工碼" :label-width="formLabelWidth">
        <el-input v-model="form.checkCode" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="電話" :label-width="formLabelWidth">
        <el-input v-model="form.phone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="到職日" :label-width="formLabelWidth">
        <el-date-picker v-model="form.createDate" type="date" placeholder="">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Email" :label-width="formLabelWidth">
        <el-input v-model="form.email" autocomplete="off"></el-input>
        <el-input v-show="false" v-model="form.mode"></el-input>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmEditAdminUser">確 定</el-button>
    </div>
  </el-dialog>
  <el-dialog title="權限設定" :visible.sync="dialogAuthVisible">
    <el-transfer :titles="titles" filterable filter-placeholder="請數入店家名稱" v-model="formOwnStores" :data="authFormStores">
    </el-transfer>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogAuthVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmEditOwnStore">確 定</el-button>
    </div>
  </el-dialog>

</div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {
      mode:'update',
      pageTotal: 0,
      pageCurrent: 1,
      stores: [],
      selectStatus:[{code:'',disName:'全部'}],
      selectStores:[],

      statusList: [
      {
        code: '1',
        disName: '在職'
      },{
        code: '0',
        disName: '離職'
      },
      ],
      userTypes: [ {
        code: '1',
        disName: '區代理'
      }],
      labelPosition: 'right',
      form: {
        adminUserId: '',
        pwd: '',
        pwd2: '',
        disName: '',
        status: '',
        checkCode: '',
        userType: '',
        createDate: '',
        phone: '',
        email: ''
      },
      formOwnStores: [],
      authFormStores: [],
      titles: ['未授權店家', '已授權店家'],
      qDisName: '',
      qStoreId: '',
      qStatus: '',
      qUserType: '',
      qAdminUserId: '',
      tableData: [],
      dialogFormVisible: false,
      dialogAuthVisible: false,
      formLabelWidth: '120px',
    }
  },
  created() {
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        res.data.stores.forEach(e => {
          this.authFormStores.push({key:e.code,label:e.disName})
          this.selectStores.push(e)
        });
        this.statusList.forEach(e=>{
          this.selectStatus.push(e)
        })
        this.qStoreId=this.selectStores[0].code
        this.qStatus=this.selectStatus[0].code
        this.query()
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  name: 'AdminUser',
  methods: {
    query() {
      this.$axios
        .get('../api/adminUser/getAdminUsers',{
          params: {
            page: this.pageCurrent,
            qStoreId: this.qStoreId,
            qStatus: this.qStatus,
            qAdminUserId: this.qAdminUserId
          }
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store => {
            this.tableData.push(store)
          })
          this.pageTotal = res.data.pageTotal
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    confirmEditOwnStore() {
      // console.log(this.formOwnStores)
      // return;
      let url = '../api/adminUser/updateOwnStores'
      this.$confirm('是否確定編輯權限','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, {
            adminUserId: this.form.adminUserId,
            formOwnStores: this.formOwnStores
          })
          .then((res) => {
            if(res.data=='success'){
              this.dialogAuthVisible = false
              this.query()
            }else{
              this.$alert(res.data, '結果', { confirmButtonText: '確定' });
            }
          }).catch((res) => {
            console.log(res.data);
          });

      })
    },
    confirmEditAdminUser() {
      
      let url = '../api/adminUser/updateAdminUser'
      let confirmText = '是否確定修改區代資料'
      if(this.form.pwd!=this.form.pwd2){
        this.$alert('密碼不一致', '結果', { confirmButtonText: '確定' });
        return
      }
      if (this.form.mode == 'create') {
        url = '../api/adminUser/createAdminUser'
        confirmText = '是否確定新增區代'
        if(this.form.pwd==null||this.form.pwd==''){
          this.$alert('密碼不得為空', '結果', { confirmButtonText: '確定' });
          return
        }
      }
      
      if(this.form.createDate == null) {
        this.$alert('到職日不可為空', '結果', { confirmButtonText: '確定' });
        return
      }

      if(this.form.checkCode == null || this.form.checkCode.length <= 3) {
        this.$alert('員工碼必須為3個字元以上', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.form.email == null || this.form.email < 5) {
        this.$alert('請輸入信箱', '結果', { confirmButtonText: '確定' });
        return
      }
      
      if (this.form.email == null || !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.form.email)) {
        this.$alert('Email格式不對', '結果', { confirmButtonText: '確定' });
        return
      }

      this.form.mode = undefined
      if (this.form.createDate instanceof Date) {
        this.form.createDate = moment(this.form.createDate).format('YYYY-MM-DD')
      }

      this.$confirm(confirmText,'注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url,this.form)
          .then((res) => {
            if(res.data=='success'){
              this.$alert('更新完成', '結果', { confirmButtonText: '確定' });
              this.query()
              this.dialogFormVisible = false
            }else{
              this.$alert(res.data, '結果', { confirmButtonText: '確定' });
            }
  
          }).catch((res) => {
            console.log(res.data);
          });

      })
    },
    showCreateDetailForm() {
      this.mode='create'
      this.form = Object.assign(this.form, {
        adminUserId: '',
        disName: '',
        pwd: '',
        pwd2: '',
        mode: 'create',
        email: '',
        createDate:moment().toDate(),checkCode:'',phone:''
      })
      // this.form.createDate=''
      this.dialogFormVisible = true
    },
    showDetailForm(adminUser) {
      this.mode='update'
      this.$axios
        .get('../api/adminUser/getAdminUser?adminUserId=' + adminUser.adminUserId)
        .then((res) => {
          res.data.mode = 'update'
          res.data.pwd = ''
          res.data.pwd2 = ''
          if (res.data.createDate!=null && res.data.createDate.length >=10) {
            let temp =res.data.createDate.substring(0, 10)
            if(temp.match(/\d{4}-\d{2}-\d{2}/)){
              res.data.createDate = moment(temp).toDate()
            }
          } else {
            res.data.createDate = ''
          }
          this.form = Object.assign({}, this.form, res.data)
          this.dialogFormVisible = true
        }).catch((res) => {
          console.log(res)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    showDetailOwnStoreForm(adminUser) {
      this.$axios
        .get('../api/adminUser/getAdminUserOwnStore?adminUserId=' + adminUser.adminUserId)
        .then((res) => {
          this.formOwnStores.splice(0, this.formOwnStores.length)
          this.form.adminUserId = adminUser.adminUserId
          res.data.forEach(e => {
            this.formOwnStores.push(e.storeId)
          })
          this.dialogAuthVisible = true
        }).catch((res) => {
          console.log(res)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    fter_status(row, column) {
      let status = row[column.property];
      if (status == undefined) return "";
      for (var i in this.statusList) {
        if (this.statusList[i].code == status) {
          return this.statusList[i].disName
        }
      }
      return ""
    },
    fter_userType(row, column) {
      let userType = row[column.property];
      if (userType == undefined) return "";
      for (var i in this.userTypes) {
        if (this.userTypes[i].code == userType) {
          return this.userTypes[i].disName
        }
      }
      return ""
    }
  }
}
</script>
