<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="帳號" v-model="qClientUserId">
              <template slot="prepend">Email</template>
            </el-input>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary">新增</el-button>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="startSend">開始發送</el-button>
        </el-col>
      </el-row>      
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="receiverDisName"    label="姓名"></el-table-column>
        <el-table-column   prop="to" label="Email">  </el-table-column>
        <el-table-column   prop="status" label="狀態">  </el-table-column>
        <el-table-column   prop="rs" label="結果">  </el-table-column>
        <el-table-column   prop="createDate" label="新增時間">  </el-table-column>
        <el-table-column   prop="updateDate" label="更新時間">  </el-table-column>
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>

  </div>
</template>


<script>
// import * as moment from "moment/moment";
export default {
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      qDisName:'',qClientUserId:'',
      tableData:[]
    }
  },
 created () {
   
  },
  name: 'EmailSend',
  components: {
  },
  computed:{
  },
  methods :{    
    query(){
      this.$axios
        .get('../api/emailSend/getEmailQueue?page='+this.pageCurrent,{})
        .then((res)=>{
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store=>{this.tableData.push(store)})
          this.pageTotal=res.data.pageTotal
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
      });
    },
    startSend () {
      let url = '../api/emailSend/startSend'
      this.$axios
        .post(url, {})
        .then((res) => {
          if(res.data.rs=='success'){
            this.$alert('email發送中', '結果', { confirmButtonText: '確定' });
          }else{
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
        }).catch((res) => {
          if(res.data!=null){
            console.log(res.data);
          }else{
            console.log(res);
          }
        });
    }        
  }
}
</script>
<style scoped>
</style>