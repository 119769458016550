<template>
<div id="dd" ref="rr">
<!-- </el-card> -->
<p id="p">test</p>
<svg width="500" height="270">
        <g style="transform: translate(0, 10px)">
        <path :d="line" />
        </g> 
    </svg>
</div>

</template>
<script>
import * as d3 from 'd3';



export default {
    methods:{
        onclick(){
           d3.select('#test1').style('color','red')  
        },
        drow(){
            // var updateRect = svg.selectAll('rect')
            // .data(dataset)
        },
    },

    data() {
        return {
        dataset:[1,2,3,4,5],
        line: '',
        width:400,
        height:400,
        // padding:{top:20,right:20,bottom:20,left:20,},
        rectStep:35,
        rectWidth:30,
        // q:{qDate=0,}
        };
    },
    created(){
        
        this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        
        }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
  
    }); 
    },
    mounted(){
        //直條圖數據
        var dataset=[75,30,15,20,100]
        var padding={top:20 ,right:20,bottom:20,left:20}
        var svg=d3.select('#p')
        .append('svg').attr("width",this.width).attr("height",this.height)
        //添加svg畫布 attr 設定長寬
        var rect = svg.selectAll('rect')
        .data(dataset)
        .enter()
        //如果元素不夠 添加元素數量data.length
        .append('rect')
        .attr('fill',"steelblue")
        //填滿色
        .attr("x",(d,i)=> padding.left + (i * this.rectStep))
        //繪圖數據 x軸起點 d=data[i]
        .attr('y',d=> this.height - padding.bottom - d)
        //繪圖數據 y軸起點
        .attr('width',this.rectWidth)
        //繪圖數據 長條寬度
        .attr('height',d=>d);
        //繪圖數據 數據高度
        rect.select('#p')
        var text = svg.selectAll('text')
        .data(dataset)
        .enter()
        .append("text")
        .attr('fill','red')
        //文字填滿色彩
        .attr('text-anchor','middle')
        //文字對齊
        .attr('font-size','14px')
        //字體大小
        .attr('x',(d,i)=> padding.left + i * this.rectStep)
        //上文字的x座標
        .attr('y',d => this.height - padding.bottom - d)
        //上文字的y座標
        .attr('dx',this.rectWidth/2)
        //文字x軸間距離
        // .attr('dy','1em')
        .text(d=>d)
        text.select('text')
        //直條圖尾
        
        //比例尺+折線
        let someData=[0,40,29,67,33,82];
        let svgWidth=300,svgHight=300
        let svg2 = d3.select('#dd').append("svg")
        .attr("width",svgWidth).attr("height",svgHight)
        let xScale = d3.scaleLinear()
        .domain([0,someData.length])
        .range([0,svgWidth-30])
        let x_axis = d3.axisBottom().scale(xScale).ticks(someData.length)
        let yScale = d3.scaleLinear()
        .domain([0,d3.max(someData)])
        .range([svgWidth-30,0])
        let y_axis = d3.axisLeft().scale(yScale)
        let gAxis = svg2.append('g')
        .attr("transform","translate(20,280)")
        x_axis(gAxis)
        let gyAxis = svg2.append('g')
        .attr("transform","translate(20,10)")
        y_axis(gyAxis)

        let line = d3.line().x((d,i)=>xScale(i)).y(d=>yScale(d))
        svg2.append('path')
        .attr('d',line(someData))
        .attr("transform","translate("+((svgWidth-30)/someData.length+20)+",10)")
        .attr('stroke','black')
        .attr('stroke-width',2)
        .attr('fill','none')



        //圓餅圖
        let data=[{"platform":"payway1","percentage":40},
                        {"platform":"payway2","percentage":30},
                        {"platform":"payway3","percentage":30}]
        
        let criSvgWidth=500,criSvgHeight=300,radius = Math.min(criSvgWidth,criSvgHeight)/2
        let svg3=d3.select('#p')
            .append("svg")
            .attr("width",criSvgWidth)
            .attr("height",criSvgHeight)
        let g = svg3.append('g')
            .attr("transform","translate(" + radius + "," + radius + ")")
        let color = d3.scaleOrdinal(d3.schemeCategory10)
        
        let pie = d3.pie().value(d => d.percentage)
        
        let path = d3.arc()
            .outerRadius(radius)
            .innerRadius(0);
        let arc = g.selectAll("arc")
            .data(pie(data))
            .enter()
            .append("g")

        arc.append("path")
            .attr("d", path)
            .attr("fill", d => color(d.data.platform))
        
        let label = d3.arc()
            .outerRadius(radius)
            .innerRadius(0)

        arc.append("text")
            .attr("transform", d => `translate(${label.centroid(d)})`)
            .attr("text-anchor", "middle")
            .text(d => `${d.data.platform}:${d.data.percentage}%`);

    },
    
};
</script>
<style scoped>
.selectit{
  background-color: yellowgreen;
}
.empty{
  background-color: white;
}
.iyrm-label{
    text-align: right;
    padding-right: 20px;
}
.svg{
    margin:25px;
}
.path{
    
    fill: none;
    stroke: #76BF8A;
    stroke-width: 3px ;

}
</style>