<template>
<el-dialog title="" :close-on-click-modal="false" :visible.sync="modal" width="90%">
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; " v-loading.fullscreen.lock="fullscreenLoading">訂單明細
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td class="col-label">訂單號:</td>
          <td colspan="3" class="col-value">{{form.clientOrderId}}</td></tr>

          <tr><td class="col-label">客戶名稱:</td>
          <td colspan="3" class="col-value">{{form.clientUserDisName}}</td></tr>
          
          <tr><td class="col-label">客戶電話:</td>
          <td class="col-value">{{form.clientPhone}}</td>
          <td class="col-label">客戶出生年月日:</td>
          <td class="col-value"> {{form.clientBirthday}}</td></tr>
          
          <tr><td class="col-label">訂單取車時間:</td>
          <td class="col-value">{{form.sDate}}</td>
          <td class="col-label">車類:</td>
          <td class="col-value"> {{form.prodPriceDisName}}</td></tr>

          <tr><td class="col-label">車型:</td>
          <td class="col-value">{{form.catDisName}}</td>
          <td class="col-label">車號:</td>
          <td class="col-value"> {{form.carNo}}</td></tr>

          <tr><td class="col-label">訂單還車時間:</td>
          <td class="col-value">{{form.eDate}}</td>
          <td class="col-label">訂單管道:</td>
          <td class="col-value"> {{form.clientType | filter_clientType}}</td></tr>
          
          <tr><td class="col-label">類別:</td>
          <td class="col-value">{{(form.rentDateType==='2')?'長租':'短租'}}</td>
          <td class="col-label">訂單總金額:</td>
          <td class="col-value">{{form.price}}</td></tr>

          <tr><td class="col-label">訂金:</td>
          <td class="col-value">{{form.deposit}}</td>
            <td class="col-label">訂金付款方式:</td>
          <td class="col-value">{{form.depositPayWay}}</td></tr>

          <tr><td class="col-label">預付:</td>
          <td class="col-value">{{form.beforePrice}}</td>
          <td class="col-label">預付付款方式:</td>
          <td class="col-value">{{form.beforePricePayWay}}</td></tr>
          
          <tr><td class="col-label">尾款:</td>
          <td class="col-value"> {{form.prePrice}}</td>
          <td class="col-label">尾款付款方式:</td>
          <td class="col-value"> {{form.prePricePayWay}}</td></tr>

          <tr><td class="col-label">折扣碼:</td>
          <td class="col-value">{{form.promotionCode}}</td>
          <td class="col-label">折扣:</td>
          <td class="col-value"> {{form.discount}}</td></tr>
          <tr><td class="col-label">員工備註:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">{{form.reserveNote}}</td>
          </tr>

          <tr><td class="col-label">發票統編:</td>
          <td class="col-value">{{form.vatNo}}</td>
          <td class="col-label">發票抬頭:</td>
          <td class="col-value"> {{form.vatCompanyTitle}}</td></tr>

          <tr><td class="col-label">發票號碼:</td>
          <td class="col-value">{{form.invoiceId}}</td>
          <td class="col-label">當下開立日期:</td>
          <td class="col-value"> {{form.invoiceCreateDate}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單流程:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyFlow" :key="item.flow" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in clientOrderTrans" :key="item.updatedate">
                {{item.updateDate}}-{{item.moneyType}}-{{item.disName}}-({{item.amt}}元)
              </div>
            </td>
          </tr>
          <tr  v-show="form.rentDateType == '2'">
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">

            長租資料
            </td>
            <td colspan="3">
              <table>
                <tr><th >付款期數</th><th>操作</th></tr>
                <div v-for="(row,i) in form.payStageList" :key="i">
                  <tr>
                    <td style="color:red;">付款期數:({{row.seq}})</td>
                    <td>
                      <el-switch :disabled="row.realPay" v-model="row.canPay" inactive-color="red" active-value="Y" inactive-value="N" active-text="開放付款"></el-switch>
                    </td>
                    <td colspan="2">付款時間:
                      <el-date-picker style="width:150px;" :clearable="false" :editable="false" disabled v-model="row.chargeDate" type="date" placeholder="付款日期"></el-date-picker>
                    </td>
                    <td colspan="3">
                      完成時間:<el-input style="width:200px;" v-model="row.payDate" :disabled="true"></el-input>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>費用:<el-input style="width:80px;" disabled v-model="row.price"></el-input></td>
                    <td>起始里程:<el-input disabled style="width:80px;"  v-model="row.startDistance"></el-input></td>
                    <td>結束里程:<el-input style="width:80px;" disabled v-model="row.endDistance"></el-input></td>
                    <td>差異:{{row.diffDistance}}</td>
                  </tr>
                  <tr>
                    <td>前台費用說明({{row.seq}}):</td>
                    <td colspan="6"><el-input disabled v-model="row.note"></el-input></td>
                  </tr>
                  <tr>
                    <td>員工備註說明({{row.seq}}):</td>
                    <td colspan="5"><el-input disabled v-model="row.adminNote"></el-input></td>
                  </tr>
                  <tr v-show="row.payStatus == '成功' && row.stageIndex > 0" >
                    <td>
                      <el-button type="primary" @click="createLongRentInvoice(row)">補開發票({{row.seq}})</el-button>
                    </td>
                    
                  </tr>
                </div>
              </table>
            </td>
          </tr>
       </tbody>
    </table>

    </el-form>
    <div slot="footer" class="dialog-footer"  v-loading="loading">
      <el-button v-show="!showRequestInvoiceBtn" type="primary" :disabled="this.$store.getters.userType!='0'" @click="invalidInvoice()">發票作廢</el-button>
      <el-button v-show="showRequestInvoiceBtn && form.rentDateType != 2" type="primary" @click="createInvoice()">補開發票</el-button>
      <el-button @click="cancel()">退出</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'FinancialClientOrderDialog',
  props: ['comfirmModal', 'comfirmModelsContent', 'invoiceFunction'],
  data () {
    return {
      form:{
        clientOrderId: '',
        payStageList:[]
      },
      showRequestInvoiceBtn: false,
      kycHistory: [],
      historyFlow:[],
      clientOrderTrans:[],
      pic1:null,pic2:null,pic3:null,pic4:null,
      fullscreenLoading:false,
      modal: this.comfirmModal,
      modelsContent: this.comfirmModelsContent,
      loading: false,
      formLabelWidth: '139px',
    }
  },
  computed: {
      userInfo(){
        return {
          auth:{
              userType:this.$store.getters.userType
          }
        }
      },
  },
  watch: {
    invoiceFunction (bol) {
      if(bol !== undefined && bol) {
        this.showRequestInvoiceBtn = bol
      }else {
        this.showRequestInvoiceBtn = false
      }
    },
    comfirmModelsContent (content) {
      if(content != null) {
        this.fullscreenLoading = true
        this.form.clientOrderId = content.clientOrderId
        this.$axios
        .get('../api/clientOrderQuickGet/getClientOrderForQuickForm', {
          params: {clientOrderId: content.clientOrderId}
        })
        .then((res) => {
          this.form = Object.assign({}, this.form, res.data[0])
          this.fullscreenLoading = false
          if(this.form.rentDateType==='2'){
            this.isLongRent=true
          }else{
            this.isLongRent=false
          }
          if(res.data[0].invoiceId == '' || res.data[0].invoiceId == null) {
            this.form.invoiceId = content.invoiceId
          }
          this.form.plusInsurance = false
          this.historyFlow.splice(0, this.historyFlow.length)
          res.data[0].historyFlow.forEach(e=>{
            e.updateDate=this.$date_(e.updateDate)
            this.historyFlow.push(e)
          })
          this.clientOrderTrans.splice(0, this.clientOrderTrans.length)
          this.form.sDate = this.$date_(this.form.sDate)
          this.form.eDate = this.$date_(this.form.eDate)
          this.form.invoiceCreateDate = this.$getDateTime_('date', this.form.invoiceCreateDate)
          this.form.clientBirthday= this.$getDateTime_('date', this.form.clientBirthday+'000000')
          if(content.DEPOSITPayType !== undefined) {
            this.form.depositPayWay = content.DEPOSITPayType == 'CASH' ? this.$payTypeDisName(content.DEPOSITPayType):this.$payTypeDisName(content.DEPOSITPayType)+'('+content.DEPOSITPayTypeExtend+')'
          } else {
            this.form.depositPayWay = '--'
          }
          if(content.ENTIREPayType !== undefined) {
            this.form.prePricePayWay = content.ENTIREPayType == 'CASH' ? this.$payTypeDisName(content.ENTIREPayType):this.$payTypeDisName(content.ENTIREPayType)+'('+content.ENTIREPayTypeExtend+')'
          } else {
            this.form.prePricePayWay = '--'
          }
          if(content.PREPAYPayType !== undefined) {
            this.form.beforePricePayWay = content.PREPAYPayType == 'CASH' ? this.$payTypeDisName(content.PREPAYPayType):this.$payTypeDisName(content.PREPAYPayType)+'('+content.PREPAYPayTypeExtend+')'
          } else {
            this.form.beforePricePayWay = '--'
          }
          res.data[0].clientOrderTrans.forEach(e=>{
            if (e.moneyType == 'CASH') {
              e.moneyType = '現金'
            } else if (e.moneyType == 'CREDIT') {
              e.moneyType = '信用卡'
            } else if (e.moneyType == 'LINEPAY') {
              e.moneyType = 'LINE Pay'
            } else if (e.moneyType == 'ATM') {
              e.moneyType = 'ATM 轉帳'
            } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
            } else { e.moneyType = '未知付款方式' }
            e.updateDate=this.$date_(e.updateDate)
            if (e.status !== 'SUCCESS') {
              e.updateDate = e.updateDate + '(付款失敗)'
            }
            this.clientOrderTrans.push(e)
          })
          this.getClientRentDateLongPayStageList(this.form.clientOrderId)
        }).catch(() => {
          this.$alert('show data error', '結果', { confirmButtonText: '確定' });
        });
      }
      this.modelsContent = content
    },
    comfirmModal: function (bol) {
      this.modal = bol
    },
    modal: function (bol) {
      if (!bol) {
        this.$emit('setComfirmModalEvent', false,null)
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('comfirmModalCancelEvent', false)
    },
    createInvoice () {
      this.$confirm('確定要開立'+this.form.price+'元發票嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          let pa = {
            clientOrderId: this.form.clientOrderId
          };
          this.loading = true
          this.$axios.post('../api/financialInvoiceQuery/createInvoice', pa).then((res) => {
            this.loading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            this.$emit('query')
            this.modal = false
          }).catch(() => {
            this.loading = false
            this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
          });
        })
    },
    createLongRentInvoice(stage){
      this.$confirm('確定要開立'+stage.price+'元發票嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          let pa = {
            rowId: stage.rowId
          };
          this.loading = true
          this.$axios.post('../api/financialInvoiceQuery/createStageInvoice', pa).then((res) => {
            this.loading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            this.$emit('query')
            this.modal = false
          }).catch(() => {
            this.loading = false
            this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
          });
        })
    },
    invalidInvoice () {
      this.$confirm('確定要作廢'+this.form.invoiceId+'發票嗎？','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        let pa = {
            invoiceId: this.form.invoiceId
          };
          this.loading = true
          this.$axios.post('../api/financialInvoiceQuery/invalidInvoice', pa).then((res) => {
            this.loading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            this.$emit('query')
            this.modal = false
      }).catch(() => {
            this.loading = false
            this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
          });
        })
      },
    
    getClientRentDateLongPayStageList(clientOrderId){
      // 取得訂單資料
      this.$axios.get('../api/clientOrderQuickReturn/getClientOrderRentDateTypeLongStagePayList', {
        params: {clientOrderId}
      }).then((stagePayListRes) => {
        
        stagePayListRes.data.clientOrderStageTrans.forEach(e => {
          e.seq = e.stageIndex
          if(e.stageIndex == 0 ) {
            e.stageIndex = '押金'
            e.seq = '押金'
          }
          e.chargeDate = this.$date_(e.chargeDate)
          if(e.payDate !=null) {
            e.payDate = this.$date_(e.payDate)
          }
          if(!this.form.lockInvoiceInfo && e.payStatus == 'SUCCESS' && e.stageType == 'STAGE') {
            this.form.lockInvoiceInfo = true
          }
          if(e.payStatus == 'SUCCESS') {
            e.payStatus = '成功'
          }
          e.realPay = false
          e.canUpdate = null
          if(e.endDistance == null) {
            e.endDistance = e.startDistance
          }
        })
        for(let row of stagePayListRes.data.clientOrderStageTrans) {
          if(row.payDate == null && row.realPay == false && row.stageType != 'HOCK') {
            row.realPay = true
            break
          }
        }
        for(let row of stagePayListRes.data.clientOrderStageTrans) {
          if(row.payDate == null) {
            row.canUpdate = true
            row.realPay = false
          } else{
            row.realPay = true
          }
        }

        this.form.payStageList = stagePayListRes.data.clientOrderStageTrans
        this.form.plusBuyNote = stagePayListRes.data.note.plusBuyNote
        this.form.stagePriceNote = stagePayListRes.data.note.stagePriceNote
      })
    },
    
  },
  created () {
    //
  },
  mounted () {
    //
  }
}
</script>
<style scoped>
.col-label{
  background-color:#DDDDDD;
  border-style:solid;
  border-width:1px;
}
.col-value{
  border-style:solid;
  border-width:1px;
}
</style>
