<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-collapse v-model="activeName">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
        <el-collapse-item name="1">
          <template slot="title">
           <h2>長租分期查詢</h2>
          </template>
          <!-- -------訂單號、期數 --------- -->
          <el-row :gutter="50">  
            <el-col :span="10">
              <el-input placeholder="訂單號" v-model="qClientOrderId">
                <template slot="prepend">訂單號</template>
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-input placeholder="期數" v-model="qStageIndex">
                <template slot="prepend">期數</template>
              </el-input>
            </el-col>
          </el-row>
          <!-- -------查詢、重置 --------- -->
          <el-row :gutter="50" style="margin-top: 10px;">
            <el-col :span="20">      
               <el-button type="primary" @click="query()">查詢</el-button>
               <el-button type="primary" @click="reset()">重置</el-button>
            </el-col>   
          </el-row>
        </el-collapse-item>
      </el-card>

    <!-- -------顯示訂單數據 --------- -->
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column prop="clientOrderId" width="160px"  label="訂單號" ></el-table-column>
        <el-table-column prop="price" width="100px"  label="金額"></el-table-column>
        <el-table-column prop="stageIndex" width="100px"  label="期數"></el-table-column>
        <el-table-column prop="chargeDate" width="200px"  label="付款時間"></el-table-column>
        <el-table-column prop="payDate" width="200px"  label="完成時間"></el-table-column>
        <el-table-column   fixed="right"    label="操作"    width="200px">
          <template slot-scope="scope">
            <el-button @click="showUpdateForm(scope.row)" v-if="userInfo.auth.userType === '0' " type="primary" size="small">編輯</el-button>
          </template>
        </el-table-column>        
      </el-table> 
        <el-pagination 
          background
          layout="prev, pager, next"
          style="margin-top:10px"
          :total="pageTotal" 
          :current-page.sync="pageCurrent"
          @current-change="query">
        </el-pagination>                    
     </el-card>
    </el-collapse>
  
  <!-- -------編輯個別長租訂單內容------ -->
  <el-dialog title="訂單明細"  :visible.sync="updateFormVisible" :close-on-click-modal="false">
    <el-row :gutter="20">
      <el-form ref="editForm">
      <el-form-item label="訂單編號" :label-width="formLabelWidth">
        {{form.clientOrderId}}
      </el-form-item>
      <el-form-item el-form-item label="金額" :label-width="formLabelWidth">
        <el-input v-model.number="form.price" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="付款時間" :label-width="formLabelWidth">
        {{form.chargeDate | formatDate}}
        <!-- <el-date-picker type="datetime" v-model="form.chargeDate" :clearable="false"></el-date-picker> -->
      </el-form-item>
      <el-form-item label="完成日期" :label-width="formLabelWidth">
        <el-date-picker type="datetime" v-model="form.payDate" :clearable="false"></el-date-picker>
      </el-form-item>
    </el-form>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="updateFormVisible = false">取消</el-button>
      <el-button type="primary" @click="confirmUpdateTrans()">更新</el-button>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      qClientOrderId:'',
      qStageIndex:'',
      tableData:[],
      // clientOrderTrans: [], // 支付紀錄
      dialogFormVisible: false,
      form: {
        type: '',
        updateDate: '',
        note: ""
      },
      formLabelWidth: '120px',
      activeName: '1', // 搜尋手風琴預設
      updateFormVisible: false,
      fullscreenLoading: false,
      //
      stores: [],
      //
      storesMap: [],
      selectStores: [],
      flows:[],
      assignRowBankNo: '',
      assignRowReadFlag: 'Y',
      isHandle: false,

    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>=1){
          this.stores.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e)
        });
        this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
        }        
      });   
  },
  computed: {
      userInfo(){
        return {
          auth:{
              userType:this.$store.getters.userType
          }
        }
      },
  },

  filters: {
    formatDate(value) {
    if (value) {
      return moment(value, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss");
    }
    return "";
    }
  },


  name: 'AdminStageTrans',
  methods :{
    query(){
      this.fullscreenLoading = true
      let qForm= {page:this.pageCurrent,
       qClientOrderId:this.qClientOrderId,
       qStageIndex: this.qStageIndex
       }
      this.$axios
        .get('../api/BackSideAdminStageTrans/getClientOrderStageTrans', {params:qForm}) 
        .then((res)=>{
          this.fullscreenLoading = false
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store=>{
              this.tableData.push(store)
              store.payDate = this.$getDateTime_('_14', store.payDate)   
            })
          this.pageTotal=res.data.pageTotal
   
      }).catch(()=>{
        this.fullscreenLoading = false
          this.$alert('查詢錯誤1', '結果', { confirmButtonText: '確定' })
      });  
    },
    reset(){
      this.qClientOrderId=''
      this.qStageIndex=''
    },
    showUpdateForm(trans){
      this.form = Object.assign(trans)
      this.updateFormVisible = true
    },
    confirmUpdateTrans(){
      this.$confirm('確定要更新嗎?','注意',{ confirmButtonText: '確定', cancelButtonText: '取消', type: 'warning' }).then(()=>{
              this.fullscreenLoading = true
              let url = '../api/BackSideAdminStageTrans/updateOrderStageTrans'
              this.form.updateDate = moment(this.form.payDate).format('YYYYMMDDHHmmss')
              this.$axios.post(url,this.form).then((res) => {
                this.fullscreenLoading = false                
                if (res.data.rs === 'success') {
                  this.query()
                  this.updateFormVisible = false
                }
                this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
              }).catch(() => {
                this.fullscreenLoading = false
                this.$alert('服務器發生不知名錯誤', '結果', { confirmButtonText: '確定' });
              })
            }) 
    },
    setComfirmModalEvent (bol,clientOrder) {
      this.comfirmModal = bol
      if(clientOrder !== undefined && clientOrder !== null) {
        this.comfirmModelsContent = clientOrder
      }
    },
  }
}
</script>
