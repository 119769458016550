<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-collapse v-model="activeName" >
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">

        <el-collapse-item name="1">
          <template slot="title">
           <h2>訂單修改</h2>
          </template>

        <el-row :gutter="20">
          <el-col :span="10">
              <el-input placeholder="訂單號" v-model="qClientOrderId">
                <template slot="prepend">訂單號</template>
              </el-input>
          </el-col>
        <el-col :span="10">          
            分店&nbsp;&nbsp;<el-select v-model="qStoreId" filterable>
              <el-option 
                v-for="item in stores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
                >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="8">
            <el-button type="primary" @click="query()">查詢</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>       
          </el-col>
        </el-row>

        </el-collapse-item>
      </el-card>
      
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="clientOrderId" width="160px"   label="訂單號"></el-table-column>
        <el-table-column   prop="carNo"  width="100px"  label="車號"></el-table-column>
        <el-table-column   prop="clientUserDisName" label="客戶">  </el-table-column>
        <el-table-column   prop="storeDisName"  width="160px" label="店家">  </el-table-column>
        <el-table-column   prop="phone" width="110px" label="電話">  </el-table-column>
        <el-table-column   prop="sDate" width="160px" label="取車時間">  </el-table-column>
        <el-table-column   prop="flow" width="95px" label="狀態">
          <template slot-scope="scope">
           <el-tag :type="scope.row.flowType">
            {{scope.row.flow}}
          </el-tag>
          </template> 
        </el-table-column>
        <el-table-column   prop="price" align="right" label="金額">  </el-table-column>
        <el-table-column   fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
    </el-collapse>

  <el-dialog title=" " :close-on-click-modal="false" :visible.sync="dialogFormVisible" font-size="150%" width="90%" center>
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">訂單明細
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單號:</td>
          <td  style="border-style:solid; border-width:1px;">{{form.clientOrderId}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單狀態</td>
              <td  style="border-style:solid; border-width:1px;">{{form.flowType}}
                  <!-- <el-button  type="primary" size="small" @click="editFlow = !editFlow" v-show="form.flow != 1000 && form.flow != 1100">編輯訂單狀態</el-button> -->
              </td>
          </tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td colspan="3" v-show="!editFlow" style="border-style:solid; border-width:1px;"> {{form.clientUserDisName}}</td>
          <td  v-show="editFlow" style="border-style:solid; border-width:1px;"> {{form.clientUserDisName}}</td>
          <td colspan="2" v-show="editFlow" style="border-style:solid; border-width:1px;">訂單狀態
              <el-input type="text" placeholder="取消原因" v-model="form.cancelNote"></el-input>
              <el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('cancel')">取消訂單</el-button></td>
            </tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td style="border-style:solid; border-width:1px;">{{form.clientPhone}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">出生年月日:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.clientBirthday}}</td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單管道:</td>
          <td  style="border-style:solid; border-width:1px;"> {{form.clientType | filter_clientType}}
          </td>
              <td colspan="2" style="border-style:solid; border-width:1px;">
              <el-button  type="primary" size="small" @click="editClientType = !editClientType">編輯訂單管道</el-button>
          </td>
          </tr>
          <tr v-show="editClientType">
              <td colspan="4" style="border-style:solid; border-width:1px;">
                  訂單管道：
                <el-select v-model="form_.clientType" clearable placeholder="">
                <el-option
                  v-for="item in form.clientTypes"
                  :key="item.code"
                  :label="item.disName"
                  :value="item.code">
                </el-option>
              </el-select>
              <el-button style="margin-left:10px" type="primary" size="small"  @click="confirmEditClientOrder('clientType')">確定編輯</el-button>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">店家:</td>
          <td  style="border-style:solid; border-width:1px;"> {{form.storeDisName}}
          </td>
              <td colspan="2" style="border-style:solid; border-width:1px;">
              <el-button  type="primary" size="small" @click="editStoreId = !editStoreId" v-show="form.flow == 0 || form.flow == 10 || form.flow == 50">編輯店家</el-button>
          </td>
          </tr>
          <tr v-show="editStoreId">
              <td colspan="4" style="border-style:solid; border-width:1px;">
                  店家：
                <el-select v-model="form_.storeId" placeholder="" @change="getEmptyCats" style="width:200px">
                <el-option  v-for="item in storesList" :key="item.code" :label="item.disName" :value="item.code">
              </el-option>
              </el-select>
              車種：
              <el-select v-model="form_.prodStockRowId" placeholder=""  style="width:200px">
                <el-option  v-for="item in storeCats" :key="item.prodStockRowId" :label="item.descTitle" :value="item.prodStockRowId">
              </el-option>
              </el-select>
              <el-button style="margin-left:10px" type="primary" size="small"  @click="confirmEditClientOrder('storeId')">確定編輯</el-button>
            </td>
          </tr>
          
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車類:</td>
            <td style="border-style:solid; border-width:1px;"> {{form.prodPriceDisName}}</td>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車型:</td>
            <td style="border-style:solid; border-width:1px;">{{form.catDisName}}</td>
          </tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">車號:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.carNo}}
          </td>
          <td colspan="2" style="border-style:solid; border-width:1px;">
              <!-- 暫停不給用戶編輯 車型、車類、車號 -->
              <!-- <el-button  type="primary" size="small" @click="editProd = !editProd">編輯車型、車類、車號</el-button> -->
          </td></tr>
          <tr v-show="editProd">
            <td colspan="4" style="border-style:solid; border-width:1px;">
              <el-row>
                <el-col :span="12">
                車類：
                <el-select v-model="form_.prodStockId" clearable placeholder=""  @change="changeCarNos">
                <el-option
                  v-for="item in form.change_prodStockRowId"
                  :key="item.prodStockId"
                  :label="item.disName"
                  :value="item.prodStockId">
                  </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12" v-show="false">
                  車型：
                  {{form_.catDisName}}
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                車號：
                  <el-select v-model="form_.prodId" clearable placeholder="">
                  <el-option
                  v-for="item in form.carNos"
                  :key="item.prodId"
                  :label="item.carNo"
                  :value="item.prodId">
                  </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                <el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('prod')">確定編輯</el-button>
                </el-col>
            </el-row>
            </td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">出發里程</td>
          <td style="border-style:solid; border-width:1px;">{{form.startDistance}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">結束里程</td>
          <td style="border-style:solid; border-width:1px;">{{form.endDistance}}</td></tr>
          
          <tr v-show="editDistance">
              <td colspan="4" style="border-style:solid; border-width:1px;">
              出發里程<el-input-number v-model="form_.startDistance"></el-input-number>
              結束里程<el-input-number v-model="form_.endDistance"></el-input-number>
              <el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('distance')">確定編輯</el-button>
          </td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.sDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單還車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.eDate}}</td></tr>
          <tr v-show="editOrderDate">
            <td colspan="4" style="border-style:solid; border-width:1px;">
                <el-row>
                  訂單取車時間:
                  <el-date-picker style="width:160px" :clearable="false" :editable="false" 
                  v-model="form_.sDateDate" type="date" class="fs">
                  </el-date-picker> 
                  <el-time-select style="width:120px"
                  :clearable="false"
                  :editable="false"
                  v-model="form_.sDateHour" :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '23:30'
                    }" placeholder="" >
                  </el-time-select>
                    
                </el-row>
                  <el-row>
                    
                  訂單還車時間:
                  <el-date-picker style="width:160px" :clearable="false" :editable="false" 
                  v-model="form_.eDateDate" type="date" class="fs">
                  </el-date-picker> 
                  <el-time-select style="width:120px"
                  :clearable="false"
                  :editable="false"
                  v-model="form_.eDateHour" :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '23:30'
                    }" placeholder="" >
                  </el-time-select>
                  <el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('orderDate')">確定編輯</el-button>
                </el-row>
                  <el-row>
                    
                </el-row>
            </td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際取車時間:</td>
          <td style="border-style:solid; border-width:1px;">{{form.realStartDate}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">實際還車時間:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.realEndDate}}</td></tr>
          
          <tr v-show="editRealDate">
            <td colspan="4" style="border-style:solid; border-width:1px;">
                <el-row>

                  實際取車時間:
                  <el-date-picker style="width:160px" :clearable="false" :editable="false" 
                  v-model="form_.realSDateDate" type="date" class="fs">
                  </el-date-picker> 
                  <el-time-select style="width:120px"
                  :clearable="false"
                  :editable="false"
                  v-model="form_.realSDateHour" :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '23:30'
                    }" placeholder="" >
                  </el-time-select>
                </el-row>
                  <el-row>
                    
                  實際還車時間:
                  <el-date-picker style="width:160px" :clearable="false" :editable="false" 
                  v-model="form_.realEDateDate" type="date" class="fs">
                  </el-date-picker> 
                  <el-time-select style="width:120px"
                  :clearable="false"
                  :editable="false"
                  v-model="form_.realEDateHour" :picker-options="{
                      start: '00:00',
                      step: '00:30',
                      end: '23:30'
                    }" placeholder="" >
                  </el-time-select>
                  <el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('realDate')">確定編輯</el-button>
                </el-row>
            </td>
          </tr>
          

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">類別:</td>
          <td style="border-style:solid; border-width:1px;">{{form.rentDateTypeDisName}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">加購保障:</td>
          <td style="border-style:solid; border-width:1px;">{{form.plusInsuranceDisName}}</td></tr>
          <tr v-show="editOther"><td colspan="4" style="border-style:solid; border-width:1px;">
            類別
            <el-select v-model="form_.rentDateType" clearable placeholder="">
              <el-option
                v-for="item in rentDateTypeOption"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
            加購保障
            <el-select v-model="form_.plusInsurance" clearable placeholder="">
              <el-option
                v-for="item in insuranceOption"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
            <el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('other')">確定編輯</el-button>
            </td>
            </tr>
          <tr><td colspan="4" style="border-style:solid; border-width:1px;">
              <el-row>
                  <el-col :span="6">
                    <el-button  type="primary" size="small" @click="editDistance = !editDistance">編輯里程</el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button  type="primary" size="small" @click="editOrderDate = !editOrderDate">編輯訂單時間</el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button  type="primary" size="small" @click="editRealDate = !editRealDate">編輯實際時間</el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button  type="primary" size="small" @click="editOther = !editOther">編輯類別、加購保障</el-button>
                  </el-col>
              </el-row>
              
              </td>
              </tr>
          

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂金:</td>
          <td style="border-style:solid; border-width:1px;">{{form.deposit}}({{form.depositPayMethod}})</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">取車預付金額:</td>
          <td style="border-style:solid; border-width:1px;">{{form.beforePrice}}</td>
          
          </tr>
          <tr>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">加購金額:</td>
          <td style="border-style:solid; border-width:1px;">{{form.plusPrice}} </td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">尾款:</td>
          <td style="border-style:solid; border-width:1px;">{{form.prePrice}}</td>
          </tr>
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單總金額:</td>
            <td style="border-style:solid; border-width:1px;"> {{form.price}}</td>
            <td colspan="2" style="border-style:solid; border-width:1px;">
              <el-button  type="primary" size="small" @click="editPrice = !editPrice">編輯金額</el-button>
            </td>
          </tr>

          <tr v-show="editPrice">
              <td colspan="4" style="border-style:solid; border-width:1px;">
                  <el-row>
                      <el-col :span="4">
                        訂金
                      </el-col>
                      <el-col :span="8">    
                        <el-input-number v-model="form_.deposit"></el-input-number>
                      </el-col>
                      <el-col :span="4">
                        取車預付
                      </el-col>
                      <el-col :span="8">    
                        <el-input-number v-model="form_.beforePrice"></el-input-number>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="4">
                        加購金額
                      </el-col>
                      <el-col :span="8">    
                        <el-input-number v-model="form_.plusPrice"></el-input-number>
                      </el-col>
                      <el-col :span="4">
                        尾款
                      </el-col>
                      <el-col :span="8">    
                        <el-input-number v-model="form_.prePrice"></el-input-number>
                      </el-col>
                  </el-row>
                  <el-row>
                      訂單總金額{{form_.deposit+form_.beforePrice+form_.prePrice}}
                  <el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('price')">確定編輯</el-button>
                  </el-row>
                  
              </td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">折扣碼:</td>
          <td style="border-style:solid; border-width:1px;">{{form.promotionCode}}</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">折扣金額:</td>
          <td style="border-style:solid; border-width:1px;"> {{form.discount}}</td></tr>
          <tr v-show="editPromotion">
              <td colspan="4" style="border-style:solid; border-width:1px;">
                  <el-row>
                      <el-col :span="4">折扣碼</el-col>
                      <el-col :span="6">
                        <el-select v-model="form_.promotionTransRowId" placeholder="選擇優惠券" :disabled="!(form_.promotionCode == null || form_.promotionCode == '')">
                          <el-option v-for="item in form.promotionList" :key="item.promotionTransRowId" :value="item.promotionTransRowId" :label="item.disname"></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="6"><el-input v-model="form_.promotionCode" placeholder="輸入優惠碼" :disabled="!(form_.promotionCode == null || form_.promotionCode == '')"></el-input></el-col>
                      <el-col :span="4">折扣金額</el-col>
                      <el-col :span="6"><el-input-number v-model="form_.discount"></el-input-number></el-col>
                      <el-col :span="4"><el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('promotion')">確定編輯</el-button></el-col>
                  </el-row>
              </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">安全帽</td>
          <td style="border-style:solid; border-width:1px;">3/4：{{form.accessoriesHelmetFull}}頂，半罩：{{form.accessoriesHelmet}}頂</td>
          <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">雨衣：</td>
          <td style="border-style:solid; border-width:1px;">{{form.accessoriesRaincoat}}件</td></tr>
          <tr v-show="editAccessories">
              <td colspan="4" style="border-style:solid; border-width:1px;">
                  <el-row>
                      <el-col :span="5">3/4安全帽</el-col>
                      <el-col :span="7"><el-select v-model="form_.helmetFull">
                      <el-option key="0" value="0" label="0"></el-option>
                      <el-option key="1" value="1" label="1"></el-option>
                      <el-option key="2" value="2" label="2"></el-option>
                      <el-option key="3" value="3" label="3"></el-option>
                      <el-option key="4" value="4" label="4"></el-option>
                      <el-option key="5" value="5" label="5"></el-option>
                      </el-select></el-col>
                      <el-col :span="5">半罩安全帽</el-col>
                      <el-col :span="7"><el-select v-model="form_.helmet">
                      <el-option key="0" value="0" label="0"></el-option>
                      <el-option key="1" value="1" label="1"></el-option>
                      <el-option key="2" value="2" label="2"></el-option>
                      <el-option key="3" value="3" label="3"></el-option>
                      <el-option key="4" value="4" label="4"></el-option>
                      <el-option key="5" value="5" label="5"></el-option>
                      </el-select></el-col>
                      
                  </el-row>
                  <el-row>
                      <el-col :span="5">雨衣</el-col>
                      <el-col :span="7"><el-select v-model="form_.raincoat">
                      <el-option key="0" value="0" label="0"></el-option>
                      <el-option key="1" value="1" label="1"></el-option>
                      <el-option key="2" value="2" label="2"></el-option>
                      <el-option key="3" value="3" label="3"></el-option>
                      <el-option key="4" value="4" label="4"></el-option>
                      <el-option key="5" value="5" label="5"></el-option>
                      </el-select></el-col>
                      <el-col :span="4"><el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('accessories')">確定編輯</el-button></el-col>
                  </el-row>
              </td>
          </tr>
          <tr><td colspan="4" style="border-style:solid; border-width:1px;">
              <el-row>
                  <el-col :span="6">
                    <el-button  type="primary" size="small" v-show="form.promotionCode == null || form.promotionCode == ''" @click="editPromotion = !editPromotion">編輯折扣</el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button  type="primary" size="small" @click="editAccessories = !editAccessories">編輯隨車配件</el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-button  type="primary" size="small" @click="editInvoice = !editInvoice">編輯發票</el-button>
                  </el-col>           
              </el-row>
              
              </td>
              </tr>

          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">發票號碼:</td>
            <td style="border-style:solid; border-width:1px;">{{form.invoiceId}}</td>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">載具編號:</td>
            <td style="border-style:solid; border-width:1px;">{{form.carrierNum}}</td>
            <!-- <td colspan="2" style="border-style:solid; border-width:1px;"></td> -->
          </tr>
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">發票統編:</td>
            <td style="border-style:solid; border-width:1px;">{{form.vatNo}}</td>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">發票抬頭：</td>
            <td style="border-style:solid; border-width:1px;">{{form.vatCompanyTitle}}</td>
          </tr>

          <tr v-show="editInvoice">
              <td colspan="4" style="border-style:solid; border-width:1px;">
                  <el-row>               
                      <el-col :span="4">發票統編</el-col>
                      <el-col :span="6"><el-input v-model="form_.vatNo" type="number"></el-input></el-col>
                      <el-col :span="4">發票抬頭</el-col>
                      <el-col :span="6"><el-input v-model="form_.vatTitle"></el-input></el-col>                  
                  </el-row>
                  <el-row>
                    <el-col :span="4">載具編號</el-col>
                    <el-col :span="6"><el-input v-model="form_.carrierNum"></el-input></el-col>
                    <el-col :span="4"><el-button style="margin-left:10px" type="primary" size="small" @click="confirmEditClientOrder('invoice')">確定編輯</el-button></el-col>
                  </el-row>               
              </td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">歷史紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyBooking" :key="item.clientOrderId" :value="item.clientOrderId">
                下單時間:{{item.createDate}}-備註:{{item.reserveNote}}(訂單號:{{item.clientOrderId}})
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">付款紀錄:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="(item, key) in clientOrderTrans" :key="key">
                {{item.updateDate}}-{{item.moneyType}}-{{item.disName}}-({{item.amt}}元)
              </div>
            </td>
          </tr>
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">訂單流程:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">          
              <div v-for="item in historyFlow" :key="item.flow" :value="item.flow">
                {{item.updateDate}}-{{item.note}}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false, transName = '0'" style="font-size:150%">退  出</el-button>
    <!-- <el-button type="primary" @click="confirmEditStore">確 定</el-button> -->
  </div>
</el-dialog>

  </div>
</template>


<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
      pic1:null,pic2:null,pic3:null,pic4:null,
      pageTotal:0,
      pageCurrent:1,
      qClientOrderId:'',
      qStoreId:'',
      tableData:[],
      historyFlow:[],
      historyBooking:[],
      clientOrderTrans: [], // 支付紀錄
      clientOrderSignature: '', // 簽名檔路徑
      dialogFormVisible: false,
      fullscreenLoading: false,
      form: {
        deposit:0,
        depositPayMethod: '',
        promotionCode: '',
        discount: 0,
        accessoriesHelmetFull:0,
        accessoriesHelmet:0,
        accessoriesRaincoat:0,
        prodPrices: [],
        prodCats:[],
        prods:[]
      },
      form_:{
        flow:'',
        clientType: '',
        prodPriceId: '',
        prodCatId: '',
        prodStockId:'',
        prodId: '',
        deposit: 0,
        prePrice: 0,
        beforePrice: 0,
        plusPrice: 0,
        promotionCode:'',
        discount: 0,
        helmet: '',
        helmetFull: '',
        raincoat: '',
        rentDateType: '',
        plusInsurance:'',
        realSDateDate:'',
        realSDateHour:'',
        realEDateDate:'',
        realEDateHour:'',
        cancelNote:'',
        storeId: '',
        prodStockRowId:'',
        vatNo:'',
        vatTitle:'',
        carrierNum:'',
        promotionTransRowId: ''
      },
      formLabelWidth: '120px',
      cats: [],
      stores: [], // 出租分店
      prodPrices: [],
      catsMap: [],
      storesList: [],
      selectStores: [],
      returnStoreId: [], // 還車分店
      flows: [], // 狀態
      clientTypes: [], // 訂單管道
      storeCats:[],
      activeName: '1', // 搜尋手風琴預設
      rentDateTypeOption:[{code:'1',disName:'短租'},{code:'2',disName:'長租'}],
      insuranceOption:[{code:'1',disName:'是'},{code:'0',disName:'否'}],
      editClientType: false,
      editProd: false,
      editDistance: false,
      editOrderDate: false,
      editRealDate: false,
      editOther: false,
      editPrice: false,
      editPromotion: false,
      editAccessories: false,
      editFlow: false,
      editStoreId: false,
      editInvoice: false
    }
  },
 created () {
    this.$axios
      .get('../api/params/getParams',{})
      .then((res)=>{
        this.$store.dispatch('login',res.data)
      if(res.data.authStores.length>=1){
        this.stores.push({code:'',disName:'全部'})
        this.returnStoreId.push({code:'',disName:'全部'})
      }
      res.data.authStores.forEach(e => {
        this.stores.push(e)
        this.storesList.push(e)
        this.returnStoreId.push(e)
      });
        this.query()
    }).catch((res)=>{
        if(res.response.status==401){
          window.location = '/';
        }        
    });   
    let flowCombo=this.$flow_combobox();
    this.flows.push({code:'',disName:'全部'})
    flowCombo.forEach(e=>{
      this.flows.push(e)
    });
    let clientTypeCombo=this.$clientType_combobox();
    this.clientTypes.push({code:'',disName:'全部'})
    clientTypeCombo.forEach(e=>{
      this.clientTypes.push(e)
    });

  },
  name: 'AdminAlterClientOrder',
  methods :{
    query(){
      this.fullscreenLoading = true
      
      
      let qForm= {
        page: this.pageCurrent,
        qClientOrderId: this.qClientOrderId,
        qStoreId: this.qStoreId,
        qOrderType:'訂單成立⇡'
      }
      this.$axios.get('../api/clientOrder/getClientOrders', {params:qForm}).then((res)=>{
        this.tableData.splice(0, this.tableData.length)
        res.data.data.forEach(store=>{
          store.sDate=this.$date_(store.sDate);
          if (store.flow == '100') {
            store.flowType=''
          } else if (store.flow == '500' || store.flow == '600' ||  store.flow == '1000' || store.flow == '1100') {
            store.flowType='info'
          } else if (store.flow == '50') {
            store.flowType='success'
          } else if (store.flow == '10') {
            store.flowType='warning'
          } else if (store.flow == '400' || store.flow == '0' ) {
            store.flowType='danger'
          }
          store.flow=this.$flow_(store.flow);
          this.tableData.push(store)
        })
        this.pageTotal=res.data.pageTotal
        this.fullscreenLoading = false
      }).catch(()=>{
          this.fullscreenLoading = false
          this.$alert('發生不知名錯誤', '結果', { confirmButtonText: '確定' });
      });  
    },
    reset(){
      this.qClientOrderId=''
      this.qStoreId=this.stores[0].code
    },
    showDetailForm(clientOrder){
      this.editClientType= false,
      this.editProd= false,
      this.editDistance= false,
      this.editOrderDate= false,
      this.editRealDate= false,
      this.editOther= false,
      this.editPrice= false,
      this.editPromotion= false,
      this.editAccessories= false,
      this.editFlow= false
      this.editStoreId= false
      this.editInvoice = false
      this.fullscreenLoading = true
      this.$axios.get('../api/alterOrder/getClientOrderForm?clientOrderId='+clientOrder.clientOrderId).then((res)=>{
        this.transName = '0'
        this.dialogFormVisible = true
        this.form = Object.assign({}, this.form, res.data[0])
        if(this.form.plusPrice=='0'||this.form.plusPrice==''){
          this.form.plusPrice = '0'
        }
        this.form.flowType = this.$flow_(this.form.flow)
        this.form.accessoriesRaincoat = this.form.accessoriesRaincoat === null? 0: this.form.accessoriesRaincoat
        this.form.accessoriesHelmet = this.form.accessoriesHelmet === null? 0: this.form.accessoriesHelmet
        this.form.accessoriesHelmetFull = this.form.accessoriesHelmetFull === null? 0: this.form.accessoriesHelmetFull
        this.form.clientBirthday = moment(this.form.clientBirthday).format('YYYY/MM/DD')
        this.form.eDate = this.$date_(this.form.eDate)
        this.form.sDate = this.$date_(this.form.sDate)
        this.form.realStartDate = this.$date_(this.form.realStartDate)
        this.form.realEndDate = this.$date_(this.form.realEndDate)
        this.form.nowDistance = (parseInt(this.form.startDistance) + 1).toString()
        this.form.plusInsuranceDisName = this.form.plusInsurance == '1' ? '是' : '否'
        this.form.rentDateTypeDisName= this.form.rentDateType == '2'? '長租' : '短租'
        this.form.catDisName = this.$catDisName(this.$store.state.cats, this.form.catId)
        this.form.depositPayMethod = this.$payTypeDisName(this.form.depositPayMethod)

        this.form.clientTypes = this.$clientType_combobox()
        this.form_.deposit = this.form.deposit
        this.form_.beforePrice = this.form.beforePrice
        this.form_.plusPrice = this.form.plusPrice
        this.form_.prePrice = this.form.prePrice
        this.form_.clientType = this.form.clientType
        this.form_.prodStockId = this.form.prodStockRowId
        this.form_.catDisName = this.form.catDisName
        this.form_.storeId=this.form.storeId
        this.form.carNos.forEach(e =>{
            if(e.carNo === this.form.carNo){
                this.form_.prodId = e.prodId
            }
        })
        this.form_.startDistance = this.form.startDistance
        this.form_.endDistance = this.form.endDistance
        this.form_.rentDateType = this.form.rentDateType
        this.form_.plusInsurance = this.form.plusInsurance
        this.form_.discount = this.form.discount
        this.form_.promotionCode = this.form.promotionCode
        this.form_.helmet = this.form.accessoriesHelmet
        this.form_.helmetFull = this.form.accessoriesHelmetFull
        this.form_.raincoat = this.form.accessoriesRaincoat
        this.form_.realSDateDate=''
        this.form_.realSDateHour=''
        this.form_.realEDateDate=''
        this.form_.realEDateHour=''
        this.historyFlow.splice(0, this.historyFlow.length)
        res.data[0].historyFlow.forEach(e=>{
          e.updateDate=this.$date_(e.updateDate)
          this.historyFlow.push(e)
        })
        this.historyBooking.splice(0, this.historyBooking.length)
        res.data[0].historyBooking.forEach(e=>{
          e.createDate=this.$date_(e.createDate)
          this.historyBooking.push(e)
        })

        this.clientOrderTrans.splice(0, this.clientOrderTrans.length)
        res.data[0].clientOrderTrans.forEach(e=>{
          if (e.moneyType == 'CASH') {
            e.moneyType = '現金'
          } else if (e.moneyType == 'CREDIT') {
            e.moneyType = '信用卡'
          } else if (e.moneyType == 'LINEPAY') {
            e.moneyType = 'LINE Pay'
          } else if (e.moneyType == 'ATM') {
            e.moneyType = 'ATM 轉帳'
          } else if (e.moneyType == 'ICP') {
              e.moneyType = 'iCash pay'
          } else { e.moneyType = '未知付款方式' }
          e.updateDate = this.$date_(e.updateDate)
          if (e.status !== 'SUCCESS') {
            e.updateDate = e.updateDate + '(付款失敗)'
          }
          this.clientOrderTrans.push(e)
        })
        this.fullscreenLoading = false
      }).catch(()=>{
          this.$alert('發生不知名錯誤', '結果', { confirmButtonText: '確定' })
      });
    },
    confirmEditClientOrder(confirmType) {
      let url = ''
      let param = {}
      let confirmText = ''
      if (confirmType === 'flow') {
          url = '../api/alterOrder/setflow'
          param = {flow: this.form.flow,newFlow: this.form_.flow,clientOrderId: this.form.clientOrderId}
          confirmText = ''
      } else if (confirmType === 'cancel') {
          url = '../api/alterOrder/setFlow'
          param = {flow: this.form.flow,clientOrderId: this.form.clientOrderId,cancelNote: this.form.cancelNote}
          confirmText = '取消訂單後不可再更動訂單狀態，是否確定取消訂單?'
          if (!(this.form.invoiceId == null || this.form.invoiceId == undefined || this.form.invoiceId == '')){
              confirmText = '此訂單已開發票，取消訂單後不可再更動訂單狀態，是否確定取消訂單?'
          }
      } else if (confirmType === 'clientType') {
          url = '../api/alterOrder/setClientType'
          param = {clientType: this.form.clientType,newClientType: this.form_.clientType,clientOrderId: this.form.clientOrderId}
          confirmText = '是否確定更改訂單管道?'
      } else if (confirmType === 'prod') {
        if(this.form_.prodStockId === '') {
          this.$alert('請填寫車類','注意',{confirmButtonText:'確定'})
          return
        }
        if(this.form_.prodId === '' & (this.form.flow == '100'||this.form.flow == '400'||this.form.flow == '500')) {
          this.$alert('請填寫車號','注意',{confirmButtonText:'確定'})
          return
        }
          url = '../api/alterOrder/setProd'
          let prodCatId_ = ''
          let prodStockId_ = ''
          let prodPriceId_ = ''
          this.form.change_prodStockRowId.forEach(e =>{
            if (e.prodStockId === this.form_.prodStockId) {
              prodCatId_ = e.catId
              prodStockId_=e.prodStockId
              prodPriceId_ = e.prodPriceRowId
            }
          })
          let carNo_ = ''
          this.form.carNos.forEach(e => {
              if(e.prodId == this.form_.prodId) {
                  carNo_ = e.carNo
              }
          })
          param = {prodPriceId: this.form.prodPriceRowId,newProdPriceId: prodPriceId_,
          prodStockId:this.form.prodStockRowId, newProdStockId: prodStockId_,
          prodCatId:this.form.catId, newProdCatId: prodCatId_,
          prodId: this.form.prodId, newProdId: this.form_.prodId,
          carNo: this.form.carNo, newCarNo: carNo_,clientOrderId: this.form.clientOrderId}
          confirmText = '是否確定更改車輛資料'
      } else if (confirmType === 'distance') {
          url = '../api/alterOrder/setDistance'
          param = {startDistance:this.form.startDistance,newStartDistance: this.form_.startDistance,
          endDistance: this.form.endDistance, newEndDistance: this.form_.endDistance,clientOrderId: this.form.clientOrderId}
          confirmText = '是否確定修改訂單里程'
      } else if (confirmType === 'orderDate') {
        if(this.form_.sDateDate === undefined ||this.form_.sDateHour === undefined|| this.form_.eDateDate === undefined ||this.form_.sDateDate === undefined) {
              this.$alert('請填寫完整訂單時間','注意',{confirmButtonText:'確定'})
              return
          }
          url = '../api/alterOrder/setOrderDate'
          let sDate_ = moment(this.form.sDate).format('YYYYMMDDHHmmss')
          let eDate_ = moment(this.form.eDate).format('YYYYMMDDHHmmss')
          let newSDate_ = moment(this.form_.sDateDate).format('YYYYMMDD')+this.form_.sDateHour.replace(/\D/gi,'') + '00'
          let newEDate_ = moment(this.form_.eDateDate).format('YYYYMMDD')+this.form_.eDateHour.replace(/\D/gi,'') + '00'
          param = {sDate: sDate_,newSDate: newSDate_ ,
          eDate:eDate_, newEDate: newEDate_,clientOrderId: this.form.clientOrderId}
          confirmText = '是否確定修改訂單租車時間'
      } else if (confirmType === 'realDate') {
        if(this.form.flow == '0' ||this.form.flow == '10'|| this.form.flow == '50') {
          this.$alert('此訂單尚未取車，無法修改實際租借時間','注意',{confirmButtonText:'確定'})
          return
        }
        if(this.form.flow =='100' &(this.form_.realSDateDate === '' ||this.form_.realSDateHour === '')) {
          this.$alert('請填寫完整實際租車時間','注意',{confirmButtonText:'確定'})
              return
          }
          if((this.form.flow =='400'||this.form.flow == '500') &(this.form_.realSDateDate === '' ||this.form_.realSDateHour === ''|| this.form_.realEDateDate === '' ||this.form_.realEDateHour === '')) {
            this.$alert('請填寫完整實際租車還車時間','注意',{confirmButtonText:'確定'})
              return
          }
          url = '../api/alterOrder/setRealDate'
            let sDate_ = moment(this.form.realStartDate).format('YYYYMMDDHHmmss')
            let eDate_ = moment(this.form.realEndDate).format('YYYYMMDDHHmmss')
            let newSDate_ = moment(this.form_.realSDateDate).format('YYYYMMDD')+this.form_.realSDateHour.replace(/\D/gi,'') + '00'
            let newEDate_ = moment(this.form_.realEDateDate).format('YYYYMMDD')+this.form_.realEDateHour.replace(/\D/gi,'') + '00'
          if(this.form.flow == '100') {
            eDate_ = ''
            newEDate_ = ''
          }
          param = {realStartDate: sDate_, newRealSDate: newSDate_,
          realEndDate: eDate_, newRealEDate: newEDate_,clientOrderId: this.form.clientOrderId}
          confirmText = '是否確定修改實際租車時間'
      } else if (confirmType === 'other') {
          url = '../api/alterOrder/setOther'
          param = {rentDateType: this.form.rentDateType,newRentDateType: this.form_.rentDateType,
          plusInsurance:this.form.plusInsurance, newPlusInsurance: this.form_.plusInsurance,clientOrderId: this.form.clientOrderId}
          confirmText = '是否確定修改類別與保障'
      } else if (confirmType === 'price') {
          url = '../api/alterOrder/setPrice'
          param = {deposit: this.form.deposit, newDeposit: this.form_.deposit,
          beforePrice: this.form.beforePrice, newBeforePrice: this.form_.beforePrice,
          plusPrice: this.form.plusPrice, newPlusPrice: this.form_.plusPrice,
          prePrice: this.form.prePrice, newPrePrice: this.form_.prePrice,clientOrderId: this.form.clientOrderId}
          confirmText = '是否確定修改價格'
          if (!(this.form.invoiceId == null || this.form.invoiceId == undefined || this.form.invoiceId == '')){
              confirmText = '此訂單已開發票，是否確定修改價格?'
          }
      } else if (confirmType === 'promotion') {
          url = '../api/alterOrder/setPromotion'
          param = {
            promotion: this.form.promotionCode, newPromotion: this.form_.promotionCode,
            discount: this.form.discount, newDiscount:this.form_.discount,
            clientOrderId: this.form.clientOrderId, promotionTransRowId: this.form_.promotionTransRowId
          }
          confirmText = '是否確定修改折扣'
      }else if (confirmType === 'accessories') {
          url = '../api/alterOrder/setAccessories'
          param = {helmet: this.form.accessoriesHelmet,newHelmet: this.form_.helmet,
          helmetFull: this.form.accessoriesHelmetFull, newHelmetFull: this.form_.helmetFull,
          reincoat: this.form.accessoriesRaincoat, newRaincoat:this.form_.raincoat,clientOrderId: this.form.clientOrderId}
          confirmText = '是否確定修改隨車配件'
      } else if (confirmType === 'storeId') {
        if (this.form_.prodStockRowId === '') {
          this.$alert('請先選擇車種','注意',{confirmText:'確定'})
          return
        }
        url = '../api/alterOrder/setStoreId'
        param = {oldStoreId: this.form.storeId,newStoreId: this.form_.storeId,
        newProdStockId: this.form_.prodStockRowId,clientOrderId: this.form.clientOrderId}
        confirmText = '是否確定修改店家'
      } else if (confirmType === 'invoice') {
        url = '../api/alterOrder/setInvoice'
        param = {
          oldVatNo: this.form.vatNo,
          newVatNo: this.form_.vatNo,
          oldVatTitle: this.form.vatCompanyTitle,
          newVatTitle: this.form_.vatTitle,
          oldCarrierNum:this.form.carrierNum,
          newCarrierNum:this.form_.carrierNum,
          clientOrderId: this.form.clientOrderId
        }
        confirmText = '是否確定修改發票資訊'
      }
      this.$confirm(confirmText,'警告',{
          confirmButtonText:'確定',
          cancelButtonText:'取消'
      }).then(() =>{
          this.$axios.post(url,param).then((res)=>{
              this.$alert(res.data.rsMsg,'結果',{confirmButtonText:'確定'})
              this.showDetailForm(this.form)
              this.query()
          })
      })
    },
    changeCarNos() {
      this.form.carNos.splice(0, this.form.carNos.length)
      this.$axios
      .get('../api/alterOrder/changeCarNos?prodStockId='+this.form_.prodStockId,{})
      .then((res)=>{
        res.data.data.forEach(e =>{
          this.form.carNos.push(e)
        })
        this.form_.prodId = ''
      })
    },
    getEmptyCats(){
      this.form.emptyCat=null;
      this.form_.prodStockRowId = ''
      let param={
          // areaId:this.form.areaId,
          storeId:this.form_.storeId,
          sd: moment(this.form.sDate).format('YYYYMMDDHHmmss'),
          ed: moment(this.form.eDate).format('YYYYMMDDHHmmss')};
      this.$axios
            .get('../api/clientOrder/getEmptyCats',{params:param})
            .then((res)=>{
              console.log(res.data)
              this.storeCats.splice(0, this.storeCats.length)
              // this.prodStocks.splice(0, this.prodStocks.length)
              res.data.forEach(e=>{
                if(e.stockQty >= e.bookingQty){
                  this.storeCats.push(e)
                }
              });
          }).catch((res)=>{
              console.log(res)
              this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' });
          });  
    }
  }
}
</script>
