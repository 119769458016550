<template>
<div>
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="20">
      <el-col :span="9">
        廣告投放類型&nbsp;&nbsp;&nbsp;
        <el-select v-model="qType" placeholder="" style="width:60%;">
            <el-option
            v-for="item in adtype"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
            </el-option>
        </el-select>
      </el-col>
      <el-col :span="9">
          廣告名稱&nbsp;&nbsp;&nbsp;
          <el-input v-model="qDisName"
           clearable placeholder="請輸入廣告名稱" style="width:60%;"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="query()">查詢</el-button>
        <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
      </el-col>

    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="disName" label="廣告名稱" width="180px"> </el-table-column>
      <el-table-column prop="seq" label="順序" width="100px"></el-table-column>
      <el-table-column prop="link" label="超連結" width="280px"> </el-table-column>
      <el-table-column prop="_type" label="類型" width="140px"> </el-table-column>
      <el-table-column prop="_updateDate" label="更新時間" width="150px"> </el-table-column>
      <el-table-column prop="updator" label="更新人" width="150px"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
          <el-button @click="showPicDetailForm(scope.row)" type="warning" size="small">上傳照片</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
    <el-form :model="form" :rules="rules" ref="editForm">
      <el-form-item label="廣告名稱" :label-width="formLabelWidth" prop="disName">
        <el-input v-model="form.disName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="類型" :label-width="formLabelWidth" prop="addr" >
        <el-select v-model="form.type" autocomplete="off">
            <el-option
            v-for="item in types"
            v-show=" formType == 'edit' || !(item.code =='AT'||item.code == 'PI')"
            :disabled="formType == 'edit'"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="順序" :label-width="formLabelWidth" prop="seq" v-show="form.type == '會員首頁輪播廣告'">
        <el-input v-model="form.seq" autocomplete="off" placeholder="請輸入數字" type="number" @blur=checkSeq()></el-input>
        <!-- 限制輸入數字 -->
      </el-form-item>
      <el-form-item label="超連結" :label-width="formLabelWidth">
        <el-input v-model="form.link" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="更新時間" :label-width="formLabelWidth" prop="addr">
        <el-date-picker :editable="false" v-model="form.updateDate" type="date" :readonly="true">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmEditStore()" v-loading.fullscreen.lock="fullscreenLoading">確 定</el-button>
    </div>
  </el-dialog>

  <el-dialog title="照片設定" :visible.sync="dialogPicVisible" style="width:107%;"> 
    <el-row :gutter="20">
      <h1>&nbsp;&nbsp;&nbsp;廣告照片</h1>
      <a v-if="pic1" target="_blank" :href="pic1">(開啟大圖)</a>
      <img :src="pic1" style="width: 640px; height:480px;"/>
      <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange">
          <el-button type="primary" @click="uploadFile('1')" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(255, 255, 255, 0.2)">
          上傳</el-button>
      </form>
    </el-row>
  </el-dialog>
</div>
</template>

<script>
import * as moment from "moment/moment";
import imageCompression from 'browser-image-compression'
export default {
  data() {
    return {
      rules: {
        name:[{
            required: true, message: '不得為空值'
        }],
        // seq: [{
        //     required: true,
        //     message: '不得為空值',
        //     trigger: 'blur'
        //   }
        // ],
         disName: [
          {required: true,message: '不得為空值', trigger: 'blur'}
        ]
      },
      pageTotal: 0,
      pageCurrent: 1,
      url: '',
      labelPosition: 'right',
      qDisName: '',
      qType: '',
      tableData: [],
      adtype: [{code:'', disName: '全部'},{code:'AD', disName: '會員首頁輪播廣告'},{code:'AT', disName: '會員首頁旅遊資訊'},{code:'PI', disName: '會員首頁優惠資訊'}],//外層選單用
      types:[{code:'AD', disName: '會員首頁輪播廣告'},{code:'AT', disName: '會員首頁旅遊資訊'},{code:'PI', disName: '會員首頁優惠資訊'}],//編輯室窗用
      dialogFormVisible: false,
      dialogPicVisible: false,
      pic1: undefined,
      form: {
        rowId: '',
        disName: '',
        seq: '',
        type: '',
        link:'',
        updateDate:'',
        updator:''
      },
      formLabelWidth: '120px',
      fullscreenLoading: false,
      imageOption: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      },
      formType:'create'
    }
  },
  created() {
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.qType = this.adtype[0].code
        this.$store.dispatch('login', res.data)
        this.query()
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  components: {
  },
  computed: {
  },
  methods: {
    query() {
    // console.log(this.qDisName+'!!'+this.qType)
    this.$axios
        .get('../api/memberCenterAd/getMemberCenterAds', {
          params: {
            page: this.pageCurrent,
            qDisName: this.qDisName,
            qType: this.qType
          }
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(memberCenterAd => {
            memberCenterAd._updateDate = this.format_date(memberCenterAd.updateDate)
            memberCenterAd._type = this.format_type(memberCenterAd.type)
            this.tableData.push(memberCenterAd)
          })
          this.pageTotal = res.data.pageTotal
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    confirmEditStore() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          let url = '../api/memberCenterAd/updateAd'
          let confirmText = '是否確定修改廣告投放設定'
          if (this.form.rowId == null||this.form.rowId == '自動產生') {
            this.form.rowId = undefined;
            url = '../api/memberCenterAd/createAd'
            confirmText = '是否確定新增廣告投放設定'
          }
          let postData = Object.assign({}, this.form)
          if(this.form.rowId == undefined) {
            delete postData.rowId
          }
          
          postData.updateDate = moment(this.form.updateDate).format('YYYYMMDD')
          if(postData.seq === '' && postData.type ==='AD'){
            this.$alert('順序不可為空', '結果', { confirmButtonText: '確定' });
            return
          }else if(postData.disName === ''){
            this.$alert('名稱', '結果', { confirmButtonText: '確定' });
            return
          }

          if (postData.type == '會員首頁輪播廣告') {
              postData.type = 'AD'
          }else if (postData.type == '會員首頁旅遊資訊') {
            postData.type = 'AT'
          }else if (postData.type == '會員首頁優惠資訊') {
            postData.type = 'PI'
          }else if (postData.type == '未知' ) {
              postData.type = 'un'
          }

          this.$confirm(confirmText,'注意',{
            confirmButtonText:'確定',
            cancelButtonText:'取消'
          }).then(() => {
            this.fullscreenLoading = true
            this.$axios
              .post(url,postData)
              .then((res) => {
                this.fullscreenLoading = false
                this.$alert(res.data.rsMsg,'注意',{confirmButtonText:'確定'})
                this.query()
                this.dialogFormVisible = false
              }).catch((res) => {
                this.fullscreenLoading = false
                console.log(res)
              });
          })
        }
      });
    },
    showCreateDetailForm() {
      this.formType='create'
      this.form = Object.assign({}, this.form, {
        rowId: null,
        disName: '',
        seq: '',
        type: this.types[0].disName,
        link:'',
        updateDate: new Date(),
        updator: ''
      })
      this.dialogFormVisible = true
    },

    showDetailForm(memberCenterAd) {
      this.formType='edit'
      this.$axios
        .get('../api/memberCenterAd/getMemberCenterAd?rowId=' + memberCenterAd.rowId)
        .then((res) => {
          this.dialogFormVisible = true
          this.form = Object.assign({}, this.form, res.data[0])
          this.form.disName = res.data[0].disName
          this.form.seq = res.data[0].seq
          this.form.link = res.data[0].link
          this.form.type = this.format_type(res.data[0].type)
          this.form.updateDate = moment(res.data[0].updateDate,'YYYYMMDD')
          // res.data.forEach(store=>{this.tableData.push(store)})
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    deleteRow(index) {
      console.log(index)
    },

    async fileChange(e) {
      this.formData=new FormData();
      //放進上傳的檔案
      try {
        const compressedFile = await imageCompression(e.target.files[0], this.imageOption)
        this.formData.append('file', compressedFile)
      } catch (error) {
        console.log(error)
      }
    },
    uploadFile(picType){
      if(this.form.type == 'AT'){
        picType ='AT'
      } else if (this.form.type == 'PI'){
        picType ='PI'
      }
      let tUploadPath = this.uploadPath+'/' + picType
      let tType = picType;
      let tPath = '../api/memberCenterAd/getAdPic/memberCenterAd/';
      this.$confirm('是否確定上傳照片','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(()=>{
        this.fullscreenLoading = true
        this.$axios
        .post(tUploadPath, this.formData).then((res) => {
          switch(tType){
            case "1":
              this.pic1 = tPath + res.data.img
              break;
          }
        this.fullscreenLoading = false
        this.query();
        }).catch((res) => {
          this.fullscreenLoading = false
          console.log(res)
        });
      })
    },
    showPicDetailForm(memberCenterAd){
      this.form.type = memberCenterAd.type
      if (memberCenterAd.pic1 === null || memberCenterAd.pic1 === undefined ) { memberCenterAd.pic1 = 'memberCenterAd/null'}
      this.$axios
        .get('../api/memberCenterAd/getAdPic/memberCenterAd/null')
        .then(()=>{                    
          this.pic1 = '../api/memberCenterAd/getAdPic/' + memberCenterAd.pic1
        }).catch((err)=>{
          console.log(err)
          this.$alert('error', '結果', { confirmButtonText: '確定' });
        })
      this.uploadPath= '../api/memberCenterAd/uploadAdPic/' + memberCenterAd.rowId
      this.dialogPicVisible=true
    },
    format_date(val){
      if(val!=null && val.length == 14){
        return val.substring(0,4) + '/' + val.substring(4,6) + '/' + val.substring(6, 8) + " " + val.substring(8, 10) + ":" + val.substring(10, 12)
        //  + ":" + val.substring(12, 14)
      }else{
        return val
      }      
    },
    format_type(val){
      if(val == 'AD'){
        return '會員首頁輪播廣告'
      }else if(val == 'AT'){
        return '會員首頁旅遊資訊'
      }else if(val == 'PI'){
        return '會員首頁優惠資訊'
      }else {
        return '未知'
      }
    },
    checkSeq(){
      if(this.form.type == 'AT' ||this.form.type == 'PI'){
        return
      }
      this.$axios
          .get('../api/memberCenterAd/checkSeq?seq=' + this.form.seq)
          .then((res)=>{
            if(res.data.seq != undefined || res.data.seq != null || res.data.rowId == '自動產生'){
              this.$alert("順序重複", '結果', { confirmButtonText: '確定' });
              this.form.seq = ''
            }
          }).catch(()=>{
            console.log('檢查順序重複')
          })
    }
  }
}
</script>

<style scoped>
</style>
