<template>
  <el-container>
    <el-aside width="300px">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
        <!-- 查詢空間 -->

        <el-row :gutter="20">
          <el-col>所屬店家 </el-col
          ><el-col>
            <el-select v-model="qStoreId" filterable @change="query">
              <el-option
                width="120"
                v-for="item in selectStores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-col> </el-row
        ><el-row :gutter="20">
          <el-col>車號</el-col
          ><el-col>
            <el-select v-model="q.qTrackerId" filterable>
              <el-option
                width="120"
                v-for="item in cars"
                :key="item.carNo"
                :label="item.carNo"
                :value="item.trackerId"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col>
            路徑圖日期區間
            <el-date-picker v-model="sDate" type="date" :clearable="false" placeholder="起始日期">
            </el-date-picker>
            <el-date-picker v-model="eDate" type="date" :clearable="false" placeholder="結束日期">
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:15px;margin-bottom:5px">
          <el-col>
            <el-button type="primary" @click="getProdPathData()"
              >查詢</el-button
            >
            <el-button type="primary" @click="reset()">重置</el-button>
          </el-col>
        </el-row>
      </el-card>
    </el-aside>
    <el-main>
      <div id="map" class="google-map"></div>
    </el-main>
  </el-container>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {
      iconUrl: require("../../assets/gmap-circle.png"),
      ggmap: null,
      qStoreId: "",
      qCarNo: "",
      qClientOrderId: "",
      cars: [],
      selectStores: [],
      storesMap: [],
      stores: [],
      centerLat: "",
      centerLng: "",
      activeName: "1",
      ggservice: null,
      storesLocat: [],
      counter: [0, 0, 0],
      trackerMap: {},
      prodPathData: [
        { lat: 37.772, lng: -122.214 },
        { lat: 21.291, lng: -157.821 },
        { lat: -18.142, lng: 178.431 },
        { lat: -27.467, lng: 153.027 }
      ],
      sDate: "",
      eDate: "",
      q: {
        qSDate: "",
        qEDate: "",
        qTrackerId: ""
      },
      selectedCar: null,
      prev_info: null
    };
  },
  created() {
    this.sDate = moment(new Date())
      .subtract(2, "days")
      .format("YYYY-MM-DD");
    this.eDate = moment(new Date()).format("YYYY-MM-DD");
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        if (res.data.authStores.length > 1) {
          this.selectStores.push({ code: "", disName: "全部" });
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e);
          this.storesMap[e.code] = e.disName;
          this.selectStores.push(e);
        });
        this.qStoreId = this.selectStores[0].code;
        this.query();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
    this.$axios.get("../api/adminProdLocation/getStoreLocat").then(res => {
      res.data.forEach(e => {
        this.storesLocat.push(e);
      });
    });
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      let centerLat = 23.82;
      let centerLng = 120.7;

      let google = window.google;
      let map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: centerLat, lng: centerLng },
        zoom: 8
      });
      this.ggmap = map;
    },
    reset() {
      this.sDate = moment(new Date())
        .subtract(2, "days")
        .format("YYYY-MM-DD");
      this.eDate = moment(new Date()).format("YYYY-MM-DD");
      this.qStoreId = "";
      this.q.qTrackerId = "";
    },
    getProdPathData() {
      if (this.sDate == null || this.eDate == null) {
        this.$alert("時間不可為空", '結果', { confirmButtonText: '確定' });
        return;
      }
      if (this.q.qTrackerId === "") {
        this.$alert("請選擇車輛", '結果', { confirmButtonText: '確定' });
        return;
      }
      this.cars.forEach(car => {
        if (car.trackerId === this.q.qTrackerId) {
          this.selectedCar = car;
        }
      });
      let start_date_moment = moment(this.sDate);
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      this.q.qSDate = _qSdate;
      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      this.q.qEDate = _qEdate;
      if (start_date_moment.diff(end_date_moment, "day") < -6 || start_date_moment.diff(end_date_moment, "day") > 0) {
        this.$alert("日期區間需小於七天", '結果', { confirmButtonText: '確定' });
        return;
      }
      let params = {
        trackerIds: this.q.qTrackerId,
        sDate: this.q.qSDate,
        eDate: this.q.qEDate
      };

      this.$axios
        .get("https://cms.mofuntravel.com.tw/iot/api/getProdPathData", {
          params
        })
        .then(res => {
          if (res.data.rs === "success") {
            let centerLat = 23;
            let centerLng = 120;
            this.storesLocat.forEach(store => {
              if (store.code === this.selectedCar.storeId) {
                let _centerLat = parseFloat(store.gpsLat);
                let _centerLng = parseFloat(store.gpsLng);
                if (_centerLat > _centerLng) {
                  centerLat = _centerLng;
                  centerLng = _centerLat;
                } else {
                  centerLat = _centerLat;
                  centerLng = _centerLng;
                }
              }
            });
            let pathData = [];
            let tempLocat = null;
            let tempTime = null;
            let pathDataList = [];
            res.data.data.forEach(path => {
              let updateDate = moment(path.updateDate, "YYMMDDHHmmss");
              if (tempTime !== null) {
                if (updateDate.diff(tempTime, "hour") < 8) {
                  let lng = parseFloat(path.lng);
                  let lat = parseFloat(path.lat);
                  if (tempLocat !== null) {
                    let distance = Math.sqrt(
                      Math.pow(tempLocat.lat - lat, 2) +
                        Math.pow(tempLocat.lng - lng, 2)
                    );
                    if (distance > 0.00001) {
                      //距離過近不列入
                      pathData.push({
                        lng: lng,
                        lat: lat,
                        updateDate: updateDate
                      });
                    }
                  }
                  tempLocat = { lng: lng, lat: lat };
                } else {
                  pathDataList.push(pathData);
                  pathData = [];
                  let lng = parseFloat(path.lng);
                  let lat = parseFloat(path.lat);
                  if (tempLocat !== null) {
                    let distance = Math.sqrt(
                      Math.pow(tempLocat.lat - lat, 2) +
                        Math.pow(tempLocat.lng - lng, 2)
                    );
                    if (distance > 0.000003) {
                      //距離過近不列入
                      pathData.push({
                        lng: lng,
                        lat: lat,
                        updateDate: updateDate
                      });
                    }
                  }
                  tempLocat = { lng: lng, lat: lat };
                }
              }
              tempTime = moment(path.updateDate, "YYMMDDHHmmss");
            });
            pathDataList.push(pathData);
            this.drawPath(centerLat, centerLng, pathDataList);

            //
          }
        });
    },
    drawPath(centerLat, centerLng, pathDataList) {
      let google = window.google;
      const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 10,
        center: { lat: centerLat, lng: centerLng },
        mapTypeId: "terrain"
      });
      pathDataList.forEach(pathData => {
        let flightPath = new google.maps.Polyline({
          path: pathData,
        });
        pathData.forEach(locat => {
          let marker = new window.google.maps.Marker({
            position: {
              lat: locat.lat,
              lng: locat.lng
            },
            map: map,
            title: this.qCarNo,
            icon: this.iconUrl,
          });
          let carDetails =
            "<div>時間：<br/>" +
            locat.updateDate.format("YYYY-MM-DD HH:mm:ss") +
            "</div>";
          let info = new google.maps.InfoWindow({ content: carDetails });
          marker.addListener("click", () => {
            if (this.prev_info !== null) {
                this.prev_info.close()
            }
            info.open({
              anchor: marker,
              map: map,
              shouldFocus: false
            });
            this.prev_info = info
          });
        });
        flightPath.setMap(map);
      });
    },
    query() {
      this.q.qTrackerId = "";
      this.$axios
        .get("../api/adminProdLocation/getProdList", {
          params: {
            qStoreId: this.qStoreId,
            qCarNo: this.qCarNo,
            qClientOrderId: this.qClientOrderId
          }
        })
        .then(res => {
          if (res.data.rs === "success") {
            this.counter[0] = res.data.counter[0];
            this.counter[1] = res.data.counter[1];
            this.counter[2] = res.data.counter[2];

            this.cars.splice(0, this.cars.length);
            res.data.data.forEach(e => {
              this.cars.push(e);
            });
          } else {
            this.$alert("查詢錯誤", '結果', { confirmButtonText: '確定' });
          }
        })
        .catch(res => {
          console.log(res);
        }); //車輛清單
    },
    changeQProdCarNo(car) {
      this.qCarNo = car.carNo;
      this.q.qTrackerId = car.trackerId;
      this.selectedCar = car;
    }
  },
  name: "AdminProdPath",
  components: {},
  computed: {}
};
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 600px;
}
a.active {
  border-bottom: 3px solid #007bff;
}
</style>