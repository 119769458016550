<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
  <el-form :model="form">
    <el-form-item label="帳號" :label-width="formLabelWidth">
      <el-input v-model="form.adminUserId" autocomplete="off" :readonly="true"></el-input>
    </el-form-item>
    <el-form-item label="類型" :label-width="formLabelWidth" >
      {{form.userTypeDisName}} 
    </el-form-item>
    <el-form-item label="密碼" :label-width="formLabelWidth">
      <el-input v-model="form.pwd" autocomplete="off" show-password></el-input>
    </el-form-item>
    <el-form-item label="再一次密碼" :label-width="formLabelWidth">
      <el-input v-model="form.pwd2" autocomplete="off" show-password></el-input>
    </el-form-item>
    <el-form-item label="名稱" :label-width="formLabelWidth">
      <el-input v-model="form.disName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="狀態" :label-width="formLabelWidth">
      <el-select v-model="form.status" placeholder="狀態">
        <el-option width=120 v-for="item in statusList" :key="item.code" :label="item.disName" :value="item.code"></el-option>        
      </el-select>
    </el-form-item>
    <el-form-item label="所屬店家" :label-width="formLabelWidth"> 
      {{form.storeDisName}}
    </el-form-item>

    <el-form-item label="員工碼" :label-width="formLabelWidth">
      <el-input v-model="form.checkCode" autocomplete="off" ></el-input>
    </el-form-item>
    <el-form-item label="到職日" :label-width="formLabelWidth">
          <el-date-picker
            v-model="form.createDate"
            type="date"
            placeholder="">
          </el-date-picker>
    </el-form-item>        
    <el-form-item label="Email" :label-width="formLabelWidth">
      <el-input v-model="form.email" autocomplete="off"></el-input>
      <el-input v-show="false" v-model="form.mode"></el-input>
    </el-form-item>    

  </el-form>    
    <el-button type="primary" @click="confirmEditStore">確 定</el-button>

    </el-card>

  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
      stores:[],storeMap:{},
      statusList:[{code:'0',disName:'離職'},{code:'1',disName:'在職'}],
      labelPosition: 'right',
        form: {
          disName: '',
          createDate:'',
          status:'',
        },
        formLabelWidth: '120px',
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          res.data.stores.forEach(e=>{
            this.stores.push(e)
            this.storeMap[e.code]=e.disName         
          });
          this.showDetailForm()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
      });   
  },
  name: 'AdminSelf',
  methods :{
    confirmEditStore(){
      if(this.form.pwd!=this.form.pwd2){
        this.$alert('密碼不一致', '結果', { confirmButtonText: '確定' });
        return;
      }
      this.$confirm('是否確定更新資料','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        let url='../api/adminLabor/updateAdminSelf'
        this.$axios
          .post(url,this.form)
          .then((res)=>{
            if(res.data=='success'){
              this.$alert('更新完成', '結果', { confirmButtonText: '確定' });
              window.location = '/';
            }else{
              // console.log()
              this.$alert(res.data, '結果', { confirmButtonText: '確定' });
            }
        }).catch((res)=>{
            console.log(res.data);
        });      
      })
    },
    showDetailForm(){
      this.$axios
        .get('../api/adminLabor/getAdminSelf')
        .then((res)=>{
          res.data.mode='update'
          // console.log(res.data.userType)
          let userType=res.data.userType;
          if(userType=='0'){
            res.data.userTypeDisName='平台管理員';
          }else if(userType=='1'||userType=='2'){
            res.data.userTypeDisName='店家管理員';
          }else if(userType=='3'){
            res.data.userTypeDisName='一般員工';
          }
          if(userType=='2'){
            res.data.storeDisName=this.storeMap[res.data.storeId]
          }else if(userType!='0'){
            res.data.storeDisName='';
            res.data.authStores.forEach(e=>{
              res.data.storeDisName+=e.disName+','
            })
          }else{
            res.data.storeDisName='所有店家'
          }
          res.data.pwd=''
          res.data.pwd2=''
          if(res.data.createDate.match(/\d{4}-\d{2}-\d{2}/)){
            res.data.createDate=moment(res.data.createDate).toDate()
          }else{
            res.data.createDate=''
          }
          this.form = Object.assign({}, this.form, res.data)
      }).catch((res)=>{
        this.$alert('login error', '結果', { confirmButtonText: '確定' });
        console.log(res)
      });
    }
  }
}
</script>
