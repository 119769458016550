<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <!-- <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="帳號" v-model="qClientUserId">
              <template slot="prepend">帳號</template>
            </el-input>
        </el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="名稱" v-model="qDisName">
              <template slot="prepend">名稱</template>
            </el-input>
        </el-col>
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="電話" v-model="qPhone">
              <template slot="prepend">電話</template>
            </el-input>
        </el-col> -->
      </el-row>
      <el-row :gutter="20" style="margin-top:10px">
        <el-col :span="2">
          <el-button type="primary" @click="showDemoForm()">Line Message</el-button>
        </el-col>
        <!-- <el-col :span="2">
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col> -->
        <!-- <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col> -->
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <!-- <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="clientUserId" label="帳號" width="150"></el-table-column>
        <el-table-column   prop="disName" label="名稱" width="150"></el-table-column>
        <el-table-column   prop="phone" label="電話" width="150"></el-table-column>
        <el-table-column   prop="registerDate" label="註冊日期" width="180"></el-table-column>
        <el-table-column   prop="adminNote" label="客戶喜好" width="200"></el-table-column>
        <el-table-column   prop="ban_" label="黑名單" width="80"></el-table-column>
        <el-table-column   prop="card4No" label="信用卡末四碼" width="120"></el-table-column>
        <el-table-column   prop="lineId_" label="Line綁定" width="80"></el-table-column>
        <el-table-column   prop="motoLicenseType" label="駕照類型" width="250"></el-table-column>
        <el-table-column   fixed="right"    label="操作"    width="220">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
          </template>
        </el-table-column>
      </el-table>  -->
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>
    </el-card>

<el-dialog title="編輯視窗" :visible.sync="dialogFormVisible">
  <el-form :model="form">
    <el-form-item label="帳號" :label-width="formLabelWidth">
      <el-input v-model="form.clientUserId" autocomplete="off" :readonly="form.mode=='update'"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditStore" v-loading.fullscreen.lock="fullscreenLoading">確 定</el-button>
  </div>
</el-dialog>
<el-dialog title="編輯視窗" :visible.sync="lineMessageVisible">
  <el-button type="primary" @click="sendLineMessage" v-loading.fullscreen.lock="fullscreenLoading">送出</el-button>
  <el-form :model="form">
    <el-form-item label="帳號" :label-width="formLabelWidth">
      <el-input v-model="lineMessageForm.clientUserId"></el-input>
    </el-form-item>
    <el-form-item label="內文" :label-width="formLabelWidth">
      <el-input type="textarea" v-model="lineMessageForm.msg"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="lineMessageVisible = false">取 消</el-button>
  </div>
</el-dialog>
  </div>
</template>


<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      statusList:[
        {code:'0',disName:'啟用'},
        {code:'1',disName:'停用'}
      ],      
      qDisName:'',qClientUserId:'',qPhone:'',
      tableData:[],
      dialogFormVisible: false,
      form: {
        sex:'',birthday:'',boundleCreditCard:'F',adminNote:'',card4No: '',banNote:'',motoLicenseType:'',removeLine:'T',removeUser:'T'
      },
      formLabelWidth: '120px',
      fullscreenLoading: false,
      lineMessageForm: { clinetUserId: null, msg: null },
      lineMessageForm_: { clinetUserId: null, msg: null },
      lineMessageVisible: false
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
      });   
   
  },
  name: 'LineMessage',
  components: {
  },
  computed:{
  },
  methods :{
    query(){
      // this.$axios
      //   .get('../api/adminClientUser/getClientUsers?page='+this.pageCurrent,{
      //     params: {
      //       clientUserId: this.qClientUserId,
      //       disName: this.qDisName,
      //       phone: this.qPhone,
      //       orderType: this.qOrderType
      //     }})
      //   .then((res)=>{
      //     this.tableData.splice(0, this.tableData.length)
      //     this.pageTotal=res.data.pageTotal
      // }).catch(()=>{
      //     this.$alert('服務器連線錯誤', '結果', { confirmButtonText: '確定' });
      // })
    },
    confirmEditStore(){
      let url='../api/adminClientUser/updateClientUser'
      let confirmText = '是否確定更新會員資料'
      if(this.form.mode=='create'){
        url='../api/adminClientUser/createClientUser'
        confirmText = '是否確定新增會員'
        this.form.card4No = undefined
      }
      this.form.mode=undefined
      if(this.form.birthday instanceof Date){
        this.form.birthday=moment(this.form.birthday).format('YYYYMMDD')
      }
      this.$confirm(confirmText,'注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.form.clientUserId = this.form.clientUserId.toUpperCase()
        this.fullscreenLoading = true
        this.$axios
          .post(url,
            this.form)
          .then(()=>{
            this.fullscreenLoading = false
            this.query()
            this.dialogFormVisible=false
        }).catch((res)=>{
            this.fullscreenLoading = false
            console.log(res.data);
        });      

      })
    },
    showCreateDetailForm(){
      this.form = Object.assign({}, this.form, {boundleCreditCard:'F',clientUserId:'',disName:'',pwd:'',pwd2:'',mode:'create',birthday:null,adminNote:''})
      this.form.boundleCreditCardDisName=''
      this.dialogFormVisible=true
    },   
    showDemoForm(){
      this.lineMessageForm = Object.assign({}, this.lineMessageForm, this.lineMessageForm_)
      this.lineMessageVisible=true
    },
    sendLineMessage(){
      this.fullscreenLoading = true
      this.$axios.post('../api/lineMessage/sendLineMessage', this.lineMessageForm).then((res) => {
        this.fullscreenLoading = false
        if (res.data.rs != 'success') {
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })  
        } else {
          this.$alert('發送成功', '結果', { confirmButtonText: '確定' })  
        }
      }).catch(err => {
        this.$alert(err, '結果', { confirmButtonText: '確定' })
        this.fullscreenLoading = false
      })
    },
    showDetailForm(){
      // this.$axios
      //   .get('../api/adminClientUser/getClientUser?clientUserId='+clientUser.clientUserId)
      //   .then((res)=>{
      //     this.dialogFormVisible = true
      // }).catch(()=>{
      //     this.$alert('服務器連線錯誤', '結果', { confirmButtonText: '確定' });
      // });
    },
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #ad9393;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #2e2e2e;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 250px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 400px;
    height: 300px;
    display: block;
  }
  .pic{
  width: 640px;
  height:480px;
}
</style>