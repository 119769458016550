<template>
<div>
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="20">
      <el-col :span="7">
        店家&nbsp;&nbsp;<el-select v-model="qStoreId">
          <el-option 
            v-for="item in storeIds"
            :key="item.code"
            :label="item.disName"
            :value="item.code"
            >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:1vh;">
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="query()">查詢</el-button>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="storeDisName" label="店家" width="120"> </el-table-column>
      <el-table-column prop="holidayDis" label="日期" width="120"> </el-table-column>
      <el-table-column prop="holidayIndex" label="連續假期第N天" > </el-table-column>
      <el-table-column prop="isHolidayPrice" label="連假計價" >
        <template slot-scope="scope">
          {{scope.row.isHolidayPrice == 'T' ? '是' : '否'}}
          </template></el-table-column>
      <!-- <el-table-column prop="stock" label="可預約數量" > </el-table-column>
      <el-table-column prop="orderCount" label="已付訂數量" > </el-table-column> -->
      <el-table-column prop="rentable" label="當日是否可租借" >
        <template slot-scope="scope">
          {{scope.row.rentable == 'T' ? '是' : '否'}}
          </template></el-table-column>
      <el-table-column prop="basicRentHours" label="最少預約時數" > </el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button @click="deleteRow(scope.row)" type="danger" size="small">刪除</el-button>
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible" :close-on-click-modal="false" style="width:120vw;">
    <el-form :model="form" ref="editForm">
      <!-- disabled鎖住 readonl只可讀取 -->
      
      <el-form-item label="店家" :label-width="formLabelWidth">
        <el-select v-model="form.storeId" placeholder="店家">
          <el-option width="120"
            v-for="item in stores"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期" :label-width="formLabelWidth" >
        <el-date-picker v-model="form.holiday" type="date" :clearable="false"  style="width:17vw;">
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="連續假期第N天" :label-width="formLabelWidth">
        <el-input v-model="form.holidayIndex" style="width:17vw;"></el-input>
      </el-form-item>
      <el-form-item label="可預約數量" :label-width="formLabelWidth">
        <el-input v-model.number="form.stock" style="width:17vw;"></el-input>
      </el-form-item> -->
      <el-form-item label="是否連假計價" :label-width="formLabelWidth">
        <el-select v-model="form.isHolidayPrice" style="width:17vw;">
          <el-option value="T" label="是"></el-option>
          <el-option value="F" label="否"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="當日是否可預約" :label-width="formLabelWidth">
        <el-select v-model="form.rentable" style="width:17vw;">
          <el-option value="T" label="是"></el-option>
          <el-option value="F" label="否"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最低預約時數" :label-width="formLabelWidth">
        <el-input v-model.number="form.basicRentHours" style="width:17vw;" :disabled="form.rentable == 'F'"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button v-show="form.storeContinueHolidayId == null" type="primary" @click="confirmEditStore">新 增</el-button>
      <el-button v-show="form.storeContinueHolidayId != null" type="primary" @click="confirmEditStore">修 改</el-button>
      <el-button @click="dialogFormVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data() {
    return {     
      pageTotal: 0,
      pageCurrent: 1,
      qStoreId: '',
      storeIds: [], // 搜尋店家選單
      stores: [], // 編輯店家選單
      storeId: [], // 資料店家
      authStoreIds: [],
      allStore: [],
      tableData: [],
      dialogFormVisible: false,
      form: {
        storeContinueHolidayId: null,
        holiday: '',
        storeId: '',
        holidayIndex: 1,
        stock: 0,
        orderCount: 0,
        rentable: 'T',
        isHolidayPrice: 'T',
        basicRentHours: 10,
      },
      defultForm: {
        storeContinueHolidayId: null,
        holiday: '',
        storeId: '',
        holidayIndex: 1,
        stock: 0,
        orderCount: 0,
        rentable: 'T',
        isHolidayPrice: 'T',
        basicRentHours: 10,
      },
      formLabelWidth: '120px'
    }
  },
  created() {
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        if(res.data.authStores.length>=1){
          this.storeIds.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.storeIds.push(e)
          this.authStoreIds.push(e.code)
          this.stores.push(e)
        })
        this.qStoreId = this.storeIds[0].code
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
    
      this.query()
    
  },
  name: 'AdminContinueHoliday',
  components: {},
  computed: {
  },
  methods: {
    query() {
      let qParams={
        page: this.pageCurrent,
        qStoreId: this.qStoreId
      }
      this.$axios
        .get('../api/adminContinueHoliday/getContinueHolidays', {
          params: qParams
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(holiday => {
            holiday.holidayDis = moment(holiday.holiday,'YYYYMMDD').format('YYYY-MM-DD')
            this.tableData.push(holiday)
          })
          this.pageTotal = res.data.pageTotal
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    confirmEditStore() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          let msg = '是否確定更新'
          let url = '../api/adminContinueHoliday/updateHoliday'
          if (this.form.storeContinueHolidayId == null||this.form.storeContinueHolidayId == '自動產生') {
            this.form.storeContinueHolidayId = undefined;
            url = '../api/adminContinueHoliday/createHoliday'
            msg = '是否確定新增'
          }
          let postData= Object.assign({},this.form)
          if(postData.storeContinueHolidayId == undefined) {
            delete postData.storeContinueHolidayId
          }
          if (postData.storeId === ''||postData.storeId === null) {
              this.$alert('店家不可為空','注意',{confirmButtonText:'確定'})
              return
          }

          postData.holiday=moment(this.form.holiday).format('YYYYMMDD')
          this.$confirm(msg,'注意',{
              confirmButtonText:'確定',
              cancelButtonText:'取消'
          }).then(()=>{
              this.$axios.post(url,postData)
                .then((res) => {
                  this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
                  if(res.data.rs === 'success') {
                    this.query()
                    this.dialogFormVisible = false
                  }
                }).catch((res) => {
                  console.log(res)
                })
          })
        }
      })
    },
    showCreateDetailForm() {
      this.storeId.splice(0, this.storeId.length)
      this.form = Object.assign({}, this.form, this.defultForm)
      this.dialogFormVisible = true
    },

    showDetailForm(holiday) {
      this.$axios
        .get('../api/adminContinueHoliday/getHoliday?storeContinueHolidayId=' + holiday.storeContinueHolidayId)
        .then((res) => {
          this.storeId.splice(0, this.storeId.length)
          this.dialogFormVisible = true
          this.form = Object.assign({}, this.form, res.data.data)
          this.form.holiday = moment(this.form.holiday,'YYYYMMDD')
          // res.data.forEach(store=>{this.tableData.push(store)})
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    deleteRow(row) {
      this.$confirm('是否確定要刪除','注意',{
          confirmButtonText:'確定',
          cancelButtonText:'取消'
      }).then(()=>{
        let postData= {storeContinueHolidayId: row.storeContinueHolidayId}
        this.$axios.post('../api/adminContinueHoliday/createHoliday',postData).then(res =>{
            if (res.data.rs === 'success'){
                this.$alert('刪除成功','結果',{confirmButtonText:'確定'})
                this.query()
            } else {
               this.$alert(res.data.rsMsg,'結果',{confirmButtonText:'確定'}) 
            }
        })
      })
    },
  }
}
</script>

<style scoped>
</style>
