<template>
<!-- 零用金 -->
<div v-loading.fullscreen.lock="fullscreenLoading">
  <!-- kanban -->
  <el-row :gutter="10">
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  class="empty" @click.native="clickCard('a')">
        門市現金
        <span style="color:green;font-size:40px;margin-left:10px;">{{board.a}}</span>元
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  class="empty" @click.native="clickCard('a')">
        收銀台
        <span style="color:green;font-size:40px;margin-left:10px;">{{board.b}}</span>元
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  class="empty" @click.native="clickCard('a')">
        店長備用金
        <span style="color:green;font-size:40px;margin-left:10px;">{{board.c}}</span>元
      </el-card>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  class="empty" @click.native="clickCard('a')">
        支出
        <span style="color:green;font-size:40px;margin-left:10px;">{{board.d}}</span>元
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  class="empty" @click.native="clickCard('a')">
        額外收入
        <span style="color:green;font-size:40px;margin-left:10px;">{{board.e}}</span>元
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  class="empty" @click.native="clickCard('a')">
        總公司轉入
        <span style="color:green;font-size:40px;margin-left:10px;">{{board.f}}</span>元
      </el-card>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="8">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  class="empty" @click.native="clickCard('a')">
        轉入收銀台
        <span style="color:green;font-size:40px;margin-left:10px;">{{board.g}}</span>元
      </el-card>
    </el-col>
    <el-col :span="8">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  class="empty" @click.native="clickCard('a')">
        轉入店長備用金
        <span style="color:green;font-size:40px;margin-left:10px;">{{board.h}}</span>元
      </el-card>
    </el-col>
  </el-row>

  <!-- search area -->
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-row :gutter="1" class="col-row">
          <el-col :span="6"><div class="vert">{{$t('adminProdStatusStoreId')}}</div></el-col> <!-- 所屬店家 -->
          <el-col :span="18">
            <el-select v-model="qStoreId_" multiple placeholder="全部">
              <el-option width=120
                v-for="item in qstores"
                :key="item.storeId"
                :label="item.storeDisName"
                :value="item.storeId">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
          <span @click="showClose++">日期區間</span>
          <el-date-picker v-model="sDate" type="date" placeholder="起始日期" :clearable="false">
          </el-date-picker>
          ~<el-date-picker v-model="eDate" type="date" placeholder="結束日期" :clearable="false">
          </el-date-picker>
      </el-col>
      <el-col :span="8">
        <el-row :gutter="1" class="col-row">
          <el-col :span="6"><div class="vert">項目</div></el-col>
          <el-col :span="18">
            <el-select v-model="q.type1" placeholder="" @change="chgType1">
              <el-option width=120
                v-for="item in qtype1"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-row :gutter="1" class="col-row">
          <el-col :span="6"><div class="vert">類別</div></el-col>
          <el-col :span="18">
            <el-select v-model="q.type2" placeholder="" >
              <el-option width=120
                v-for="item in qtype2"
                :key="item.code"
                :label="item.disName"
                :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-row :gutter="1" class="col-row">
          <el-col :span="6"><div class="vert">是否審核</div></el-col>
          <el-col :span="18">
            <el-select v-model="q.manager" placeholder="" >
              <el-option width=120 label="全部" value=""></el-option>
              <el-option width=120 label="是" value="Y"></el-option>
              <el-option width=120 label="否" value="N"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row :gutter="1" class="col-row">
          <el-col :span="6"><div class="vert">序號</div></el-col>
          <el-col :span="18">
            <el-input v-model="q.no" placeholder=""/>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" v-show="showClose > 2">
        <el-input v-model="closeMonth"></el-input>
        <el-button @click="closeWallet">確定結轉</el-button>
      </el-col> 
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" @click="clickCard(null)">查詢</el-button>
        <el-button type="primary" @click="downloadExcel()">匯出Excel</el-button>
        <el-button type="primary" @click="reset()">重置</el-button>

        <el-button type="primary" @click="showCreate('type1',0)">新增費用</el-button>
        <el-button type="primary" @click="showCreate('type2',1)">額外收入新增</el-button>
        <el-button type="primary" @click="showCreate('type3',2)">備用金移轉</el-button>
        <el-button type="primary" @click="downloadPDF()">匯出PDF</el-button> 
      </el-col>
    </el-row>
  </el-card>
  <!-- table -->
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="no" label="序號" width="80"></el-table-column>
      <el-table-column prop="type1" label="項目"  width="150"></el-table-column>
      <el-table-column prop="storeDisName" label="店家"  width="180"></el-table-column>
      <el-table-column prop="createDate" label="日期"  width="100"></el-table-column>
      <el-table-column prop="type2" label="類別"  width="250"></el-table-column>
      <el-table-column prop="dropCashDisName" label="是否扣現金"  width="150"></el-table-column>
      <el-table-column prop="isCertificate" label="是否有憑證"  width="100"></el-table-column> 
      <el-table-column prop="amt" label="金額"  width="70"></el-table-column>
      <el-table-column prop="creator" label="經手人"  width="130"></el-table-column>
      <el-table-column prop="note" label="說明"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button @click="showDetailForm(scope.row,'update')" 
            type="primary" size="small">財務修改</el-button>
          <el-button @click="showDetailForm(scope.row,'update')" v-show="scope.row.manager== null" 
            type="primary" size="small">主管審核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="" :close-on-click-modal="false" :visible.sync="dialogStartRentVisible" width="90%">
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">{{typeDisName}}
      <input type="hidden" v-model="form.rowId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px; width:90%">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">店家:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-select v-model="form.storeId" placeholder="">
              <el-option width=120
                v-for="item in stores"
                :key="item.storeId"
                :label="item.storeDisName"
                :value="item.storeId">
              </el-option>
            </el-select>
          </td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">日期:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-date-picker
            :clearable="false"
            :editable="false"
              v-model="form.createDate"
              type="date"
              placeholder="" 
              :disabled="form.rowId != null">
            </el-date-picker>
          </td></tr>

          
          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">項目類別:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-select v-model="form.type2" placeholder="" @change="noInvoice">
              <el-option width=120
                v-for="item in type2Select"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            </td>
          </tr>

          <tr v-show="form.type1 == '費用新增'"><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">是否扣除現金:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-switch v-model="form.dropCash" active-color="#13ce66" inactive-color="#ff4949" 
            active-value="1" inactive-value="0">
            </el-switch>
          </td></tr>

          <tr v-show="form.type1"><td  style="background-color:#DDDDDD; border-style:solid; border-width:1px;">說明:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">
              <el-input v-model="form.note" style="width:100%; font-size:100%;min-width:230px;"></el-input>
            </td>
          </tr>
          
          <tr v-show="form.type1"><td  style="background-color:#DDDDDD; border-style:solid; border-width:1px;">是否有憑證:</td>
            <td colspan="3" style="border-style:solid; border-width:1px;">
              <el-select 
              v-model="form.isCertificate"
              placeholder=""
              size="large"
              style="width:20%; font-size:100%; min-width:220px;"
              >
              <el-option
                v-for="item in type3"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            </td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">金額 :</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input v-model="form.amt" style="width:100%; font-size:100%;min-width:230px;"></el-input></td>
          </tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">員工確認碼:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input v-model="form.adminCode" type="password" style="width:100%; font-size:100%;min-width:230px;"></el-input></td>
          </tr>
        </tbody>
      </table>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogStartRentVisible = false" style="font-size:150%;">取 消</el-button>
      <el-button type="primary" v-show="form.rowId == null" @click="createStoreWalletTrans()" style="font-size:150%;">新 增</el-button>
      <el-button type="primary" v-show="form.rowId != null && form.canUpdate" @click="updateStoreWalletTrans()" style="font-size:150%;">修 改</el-button>
      <el-button type="primary" v-show="form.rowId != null && form.manager==null" @click="checkByManager()" style="font-size:150%;">審 核</el-button>
    </div>
  </el-dialog>
  
</div>
</template>

<script>
import moment from 'moment';
export default {

  data() {
    return {
      lastb:0,
      lastc:0,
      closeMonth:'',
      showClose:0,
      sDate:'',
      eDate:'',
      type1Map:{},
      qtype1:[{code:'', disName:'全部'}],
      qtype2:[{code:'', disName:'全部'}],
      type1:['費用新增','額外收入新增','備用金移轉',],
      type2:[
        ['成本-電池資費','成本-車輛保養維修', '成本-加油',
         '成本-保險費(第三人)', '成本-保險費(強制險)','成本-門店耗材費', 
         '成本-合作店家分潤',   '成本-車輛攤提', '成本-裝潢攤提', 
        '租金支出',
        '薪資費用',
        '廣告費',
        '文具用品',
        '車輛運費',
        '郵資',
        '交際費',
        '出差費',
        '停車費',       
        '電話費',
        '保險費-勞保',
        '保險費-健保',
        '保險費-勞退',
        '水電費',
        '手續費',
        '勞務費',
        '罰單',      
        '雜項費用',
        '個案金額',
        '長租押金退回',
        '房客押金退回',
        ],
        [
          '長租押金',
          '短租訂金',
          '其他收入',
          '總公司匯入'
        ],
        ['轉入收銀台','轉入店長備用金']],
      type3:[
        { label: '是', value: 'T' },
        { label: '否', value: 'F' },
      ],
      dialogStartRentVisible:false,
      stores:[],
      qstores:[],
      form:{
        rowId:null,
        createDate:null,
        storeId:null,
        note:null,
        type1:null,
        type2:null,
        amt:'1',
        adminCode:null,
        dropCash:'0',
        // 以下編輯才會有
        updateDate:null,
        updator:null,
        managerDisName:null,
        managerCheckDate:null,
        canUpdate: false,
        isCertificate:null
      },
      board:{a:0,b:0,c:0,d:0,e:0,f:0,g:0,h:0},
      // usedQty: '0',
      // emptyQty: '0',
      pageTotal: 0,
      pageCurrent: 1,
      cats: [],
      carNos: [],
      catsMap: [],
      storesMap: [],
      labelPosition: 'right',
      q:{storeId:'',sdate: null,edate: null,type1:'',type2:'',manager:'', no: ''},
      qStoreId_:[],
      tableData: [],
      fullscreenLoading: false,
      selectStores:[],
      typeDisName:'',
      type2Select:[],
      isCertificateSelect:[]
    }
  },
  created() {
    for(let d of this.type1) {
      this.qtype1.push({code:d,disName:d})
    }

    this.sDate = moment(new Date()).startOf("month").toDate();
    this.eDate = moment(new Date()).endOf("month").toDate();
    for(let index =0;index <this.type1.length;index++){
      this.type1Map[this.type1[index]] = index
    }
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        this.clickCard(null)
      }).catch((res) => {
        console.log(res)
        // }
      });
    this.$axios
      .get('../api/financialwallet/getFinancialStores', {})
      .then((res) => {
        this.stores = res.data
        for(let s of this.stores) {
          this.qstores.push(s)
        }
        if (this.stores.length > 0) {
          this.form.storeId = this.stores[0].storeId;
        }
        this.q.storeId = ''
      }).catch((res) => {
        console.log(res)
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  name: 'FinancialWallet',
  components: {},
  computed: {},
  methods: {
    downloadExcel(){
      let params = {}
      Object.assign(params, {}, this.q)
      params.sdate = moment(this.sDate).format('YYYYMMDD')
      params.edate = moment(this.eDate).format('YYYYMMDD')
      window.open('../api/financialwallet/export/excel?storeId=' + params.storeId
        +'&sdate=' + params.sdate
        +'&edate=' + params.edate
        +'&type1=' + params.type1
        +'&type2=' + params.type2
        +'&manager=' + params.manager)
    },
    downloadPDF() {
      let params = {}
      Object.assign(params, {}, this.q)

      if (this.sDate) {
        params.sdate = moment(this.sDate).format('YYYYMMDD');
      }
      if (this.eDate) {
        params.edate = moment(this.eDate).format('YYYYMMDD')
      }

      window.open('../api/financialwallet/pdf/pdf?storeId=' 
        + params.storeId
        +'&sdate=' + params.sdate
        +'&edate=' + params.edate
        +'&no=' + params.no
        +'&type1=' + params.type1
        +'&type2=' + params.type2
        +'&manager=' + params.manager
      )
    },


    chgType1(newV){
      this.q.type2 = ''
      this.qtype2 = [{code:'',disName:'全部'}]
      for(let i =0;i<this.type1.length;i++) {
        if(newV == this.type1[i]) {
          let p = i++
          for(let s of this.type2[p]){
            this.qtype2.push({code:s,disName:s})
          }
          break
        }
      }
    },
    closeWallet(){
      let params = {closeMonth:this.closeMonth}
      this.$axios.post('../api/financialwallet/closeWallet', params).then((res) => {
        this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
      }).catch(() => {
        this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
      });
    },
    showDetailForm(row,mode){
      if(mode == 'update') {
        let index = this.type1Map[row.type1]
        this.type2Select = this.type2[index]
        Object.assign(this.form, {}, row)
        this.form.adminCode = ''
        this.form.createDate = moment(row.createDate).toDate()
        this.typeDisName = row.type1
        this.dialogStartRentVisible = true
        let p = parseInt(moment(this.form.createDate).format('YYYYMMDD'))
        let now = parseInt(moment().format('YYYYMMDD'))
        if(p >= now) {
          this.form.canUpdate = true
        }else{
          this.form.canUpdate = false
        }
      }
    },
    showCreate(type, index){
      const validValues = ['長租押金', '短租訂金', '租金支出']
      this.form.rowId = null
      this.form.note = ''
      this.form.amt=1
      this.form.createDate=null
      this.form.adminCode= '' 
      this.form.storeId = null
      this.form.dropCash = '1'
      this.form.type1 = this.type1[index]
      this.type2Select = this.type2[index]
      this.typeDisName = this.type1[index]
      this.form.type2 = this.type2Select[0]
      this.dialogStartRentVisible = true
      this.form.isCertificate = validValues.includes(this.form.type2) ? this.type3[1].value : this.type3[0].value
    },
    isGoodForm(){
      if(/^\D+$/.test(this.form.amt)) {
        this.$alert('金額', '結果', { confirmButtonText: '確定' });
        return false
      }
      if(this.form.createDate == null) {
        this.$alert('日期未輸入', '結果', { confirmButtonText: '確定' });
        return false
      }
      if(this.form.note == null || this.form.note.length == 0) {
        this.$alert('說明未輸入', '結果', { confirmButtonText: '確定' });
        return false
      }
      if(this.form.adminCode == null || this.form.adminCode.length == 0) {
        this.$alert('員工碼未輸入', '結果', { confirmButtonText: '確定' });
        return false
      }
      if(this.form.storeId == null) {
        this.$alert('未選擇店家', '結果', { confirmButtonText: '確定' });
        return false
      }
      return true
    },
    checkByManager(){
      this.fullscreenLoading = true
      this.$confirm('確定要審核嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          let params = {}
          Object.assign(params, {}, this.form)
          this.loading = true
          this.$axios.post('../api/financialwallet/checkByManager', params).then((res) => {
            if(res.data.rs == 'success') {
              this.dialogStartRentVisible = false
              this.query()
            }
            this.fullscreenLoading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }).catch(() => {
            this.fullscreenLoading = false
            this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
          });
        }).catch((err) => {
          console.log(err)
          this.fullscreenLoading = false
        })
    },
    updateStoreWalletTrans(){
      if(!this.isGoodForm()){
        return
      }
      this.fullscreenLoading = true
      this.$confirm('確定要修改嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          let params = {}
          Object.assign(params, {}, this.form)
          params.createDate =  this.get14D(this.form.createDate);
          this.loading = true
          this.$axios.post('../api/financialwallet/updateStoreWalletTrans', params).then((res) => {
            if(res.data.rs == 'success') {
              this.dialogStartRentVisible = false
              this.query()
            }
            this.fullscreenLoading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }).catch(() => {
            this.fullscreenLoading = false
            this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
          });
        }).catch((err) => {
          console.log(err)
          this.fullscreenLoading = false
        })
    },
    isBeforeDate(){
      if(this.form.createDate!= null ) {
        let p = parseInt(moment(this.form.createDate).format('YYYYMMDD'))
        let now = parseInt(moment().format('YYYYMMDD'))
        if(p >= now) {
          return true
        } else {
          return false
        }
      }
    },
    createStoreWalletTrans(){
      if(!this.isBeforeDate()){
        this.$alert('不可新增今日之前的日期', '結果', { confirmButtonText: '確定' });
        return false
      }
      if(!this.isGoodForm()){
        return
      }

      this.fullscreenLoading = true
      this.$confirm('確定要新增'+this.form.amt+'元金額交易嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          let params = {}
          Object.assign(params, {}, this.form)
          params.createDate =  this.get14D(this.form.createDate);
          this.loading = true
          this.$axios.post('../api/financialwallet/createStoreWalletTrans', params).then((res) => {
            if(res.data.rs == 'success') {
              this.dialogStartRentVisible = false
              this.query()
            }
            this.fullscreenLoading = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }).catch(() => {
            this.fullscreenLoading = false
            this.$alert('服務器錯誤', '結果', { confirmButtonText: '確定' });
          });
        }).catch(() => {
            this.fullscreenLoading = false
          })
    },
    get14D(date){
      let eDate=date;
      let str=eDate.getFullYear().toString()
      let month=(eDate.getMonth()+1).toString();
      if(month.length==1)
        month = '0' + month
      let day=(eDate.getDate()).toString();
      if(day.length==1)
        day = '0' + day

      str=str+month+day;
      return str;
    },
    clickCard(qProdStatus){
      this.q.qProdStatus=qProdStatus;

      this.q.storeId = ''
      this.qStoreId_.forEach(e=>{
        this.q.storeId = this.q.storeId + e +','
      })
      if (this.qStoreId_.length > 0){
        this.q.storeId = this.q.storeId.substring(0,this.q.storeId.length -1)
      }
      
      this.query()
    },
    query() {
      this.q.page=this.pageCurrent
      for(let k of Object.keys(this.board)){
          this.board[k] = 0
      }
      let params = {}
      Object.assign(params, {}, this.q)
      params.sdate = moment(this.sDate).format('YYYYMMDD')
      params.edate = moment(this.eDate).format('YYYYMMDD')
      
      params.sdateMonth = moment(this.sDate).startOf("month").format('YYYYMMDD')
      params.edateMonth = moment(this.eDate).endOf("month").format('YYYYMMDD')
      // if(params!=null){
      //   return
      // }
      this.$axios.get('../api/financialwallet/getStoreWallets', { params }).then((res) => {
        this.tableData.splice(0, this.tableData.length)
        res.data.data.forEach(prod => {
          prod.dropCashDisName = prod.dropCash == '0'?'否':'是'
          prod.isCertificate = prod.isCertificate == 'F'?'否':'是'
          this.tableData.push(prod)
        })

        res.data.data2.forEach(prod => {
          if(prod.type1 == '費用新增' && prod.dropCash != '0') {
            this.board.d += prod.amt
          }else if(prod.type1 =='額外收入新增') {
            if(/^(長租押金)|(短租訂金)|(其他收入)$/.test(prod.type2)){
              this.board.e += prod.amt
            } else if(/^總公司匯入$/.test(prod.type2)){
              this.board.f += prod.amt
            }
          }else if(prod.type1 =='備用金移轉') {
            if(prod.type2 =='轉入收銀台'){
              this.board.g += prod.amt
            } else if(prod.type2 =='轉入店長備用金'){
              this.board.h += prod.amt
            }
          }
        })
        this.lastB = 0
        if(res.data.b != null){
          this.lastB = res.data.b
        }
        this.lastC = 0
        if(res.data.c != null){
          this.lastC = res.data.c
        }
        this.board.b = this.lastB + this.board.e + this.board.f + this.board.g - this.board.d - this.board.h
        this.board.c = this.lastC - this.board.g + this.board.h
        this.board.a = this.board.b + this.board.c
        for(let k of Object.keys(this.board)) {
            this.board[k] = (this.board[k]).toLocaleString('en-US', { currency: 'USD' ,minimumFractionDigits: 0})
          if(this.board[k].indexOf('$')!=-1) {
            this.board[k] = this.board[k].substring(1)
          }
        }
        this.pageTotal = res.data.pageTotal
      }).catch((res) => {
        console.log(res)
        this.$alert('login error','結果',{confirmButtonText:'確定'})
      });
    },
    reset(){
      this.sDate = moment(new Date()).startOf("month").toDate();
      this.eDate = moment(new Date()).endOf("month").toDate();
      this.q.storeId=''
      this.q.type1=''
      this.q.type2=''
      this.q.manager = ''
    },
    noInvoice(){
      const options = this.form.type2 // 項目類別
      const value = (options === '長租押金' || options === '短租訂金' || options === '租金支出') ? this.type3[1].value : this.type3[0].value
      this.form.isCertificate = value.toString() //切換,"是""否"
    }
  }
}
</script>

<style scoped>
.vert{
  height: 100%;
  display: flex;
  /* -webkit-align-items: center; */
}
.col-row{
  display: flex;
}
.selectit{
  background-color: yellowgreen;
}
.empty{
  background-color: white;
}
.title > :first-child{
   font-size: 80%;
 }
.amt-red {
  color:red;
}
.amt {
  color:#6e757c;
}
</style>
