<template>
  <div>
    <!-- search area -->
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="8">
        <el-col :span="6">
          <el-col :span="6">地區</el-col>
          <el-col :span="16">
            <el-select v-model="qAreaId" width="120">
          <el-option 
            v-for="item in areas"
            :key="item.code"
            :label="item.disName"
            :value="item.code"
            >
          </el-option>
        </el-select>
          </el-col>
        </el-col>
        <el-col :span="8">
          <el-col :span="6">所屬店家</el-col>
          <el-col :span="16">
            <el-select v-model="qStoreId_" multiple width="120">
          <el-option 
            v-for="item in selectStores"
            :key="item.code"
            :label="item.disName"
            :value="item.code"
            v-show="item.areaId == qAreaId || qAreaId == ''">
          </el-option>
        </el-select>
          </el-col>
        </el-col>
        <el-col :span="16">
          日期區間
          <el-date-picker v-model="sDate" type="date" placeholder="起始日期" :clearable="false">
          </el-date-picker>
          ~<el-date-picker v-model="eDate" type="date" placeholder="結束日期" :clearable="false">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-top:15px;">
        <el-col :span="2">
          <el-button type="primary" @click="clickCard()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
      </el-row>
    </el-card>
    
    <!-- 看板 -->
    <el-row :gutter="10">
      <el-col :span="5">
        <el-card shadow="always" style="margin:10px 0 10px 0; text-align:center;">
          平均預約訂單處理時間<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ (isFinite(board.reactionMin / board.reactionOrderCount) ?
              board.reactionMin / board.reactionOrderCount / 60 : 0).toFixed(1) | filter_commaFormate}}
          </span>小時
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card shadow="always" style="margin:10px 0 10px 0; text-align:center;">
          短租訂金支付比例<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ (isFinite(board.payedDeposit / board.limitedOrderCount) ? board.payedDeposit / board.limitedOrderCount *100 : 0).toFixed(0)| filter_commaFormate}}
          </span>%
        </el-card>
      </el-col>
    </el-row>

    <!-- 預約訂單百分比 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="clientTypePie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!-- clientType -->
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 預約訂單百分比 (訂單數)</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 訂金支付方式圓餅圖-->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="depositPayWayPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!--有幾種支付方式-->
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 消費者訂金支付方式 (訂單數)<br/>
          短租訂單實際還車時間於日期區間中，且排除訂單管道『現場預約、OTA預約』之訂單的訂金支付方式</div>
        </el-card>
      </el-col>
    </el-row>
    
    <!-- 取消原因分析 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="cancelOrderReasonPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!--取消訂單-->
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 取消原因分析 (訂單數)</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 線上預約管道 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="onlineStockPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 線上預約管道 (訂單數)</div>
        </el-card>
      </el-col>
    </el-row>
    
    <!--支付方式變化長條疊圖-->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="payWayChange" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!--x:當日(-11月~0月)y:0~100%-->
          </div>
          <div style="text-align:center">
            <!-- key: ["CASH", "CREDIT", "LINEPAY", "EZCARD","ATM","ICP", "OTHER"], -->
            消費者支付方式變化<br /> (現金
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            ，信用卡
            <svg style="background-color:#ff7f0e;width:20px;height:14px;"></svg>
            ，LINE Pay
            <svg style="background-color:#2ca02c;width:20px;height:14px;"></svg>
            ，悠遊卡
            <svg style="background-color:#d62728;width:20px;height:14px;"></svg>
            ，匯款
            <svg style="background-color:#9467bd;width:20px;height:14px;"></svg>
            ，iCashPay
            <svg style="background-color:#8c564b;width:20px;height:14px;"></svg>
            ， 其他
            <svg style="background-color:#e377c2;width:20px;height:14px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 營收概況 -->
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="revenueBar" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">
            {{disSDate}}~{{disEDate}}營收概況
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 使用時間分析 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="rentDatePie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 使用時間分析 (訂單數)</div>
          <div style="text-align:center">從圖表了解消費者租借時間特性</div>
          <div style="text-align:center">邏輯說明 : 還車日期-租車日期(0天代表當天租還)</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 預約前置時間分析 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="createSDateTable" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 預約前置時間分析 (X：天數，Y：訂單數)</div>
          <div style="text-align:center">從圖表掌握消費者預約的習慣 出發前多久會預約</div>
          <div style="text-align:center">邏輯說明 : (預約取車時間 – 訂單成立時間) 與訂單的關係</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 訂金支付時間差 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="createDepositDateTable" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 訂金支付時間差 (X：小時，Y：訂單數)</div>
          <div style="text-align:center">從圖表掌握預約的消費者都多久完成訂金支付</div>
          <div style="text-align:center">邏輯說明 : (訂金完成支付時間 – 訂單成立時間) 與訂單關係 (針對線上庫存)</div>
        </el-card>
      </el-col>
    </el-row>
     <!-- 【得知我們】統計分析 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="clientHowToKnowPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!-- 來源管道種類  -->
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 來源管道統計分析 </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import * as d3 from "d3";
export default {
  data() {
    return {
      fullscreenLoading: false,
      areas: [],
      stores: [],
      storesMap: [],
      selectStores: [],
      months: [],
      monthsMap: [],
      selectMonth: [],
      sDate: "",
      eDate: "",
      selectMonthsSub: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      nowDate: new Date().getTime(),
      labelPosition: "right",
      q: {
        qStoreId: '',
        qMonth: null,
        qProdStatus: null,
        qSDate: null,
        qEDate: null,
      },
      qAreaId: '',
      qStoreId_: null,
      tableData: [],
      pic1ViewDialog: false,
      formLabelWidth: "120px",
      svgWidth: 700,
      svgHeight: 500,
      clientTypePieData:[],
      depositPayWayCriData: [],
      cancelOrderReasonData: [],
      payWayChangeData: [],
      clientTypeChangeData: [],
      rentDateTypeData: [],
      depositKey: [],
      clientTypeChangeDate: [],
      clientHowToKnowData:[],
      key: ["CASH", "CREDIT", "LINEPAY", "EZCARD","ATM","ICP", "OTHER"],
      clientTypeKey:["1","2","3","4","5","6","500"],
      disSDate:"",
      disEDate:'',
      revenueBarData:[],
      revenueBarDataType:[],
      qWeeks: 12,
      board: {
        reactionMin: 0,
        reactionOrderCount: 0,
        payedDeposit: 0,
        limitedOrderCount: 0
      }
    };
  },
  created() {
    this.sDate = moment(new Date()).startOf("month");
    this.eDate = moment(new Date()).endOf("month");
    this.updateDate =

    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        if(res.data.areas.length>1){
          this.areas.push({code:'',disName:'全部'})
        }
        res.data.areas.forEach(e => {
          this.areas.push(e)
        });
        this.qAreaId = this.areas[0].code
        res.data.authStores.forEach(e => {
          this.stores.push(e);
          this.storesMap[e.code] = e.disName;
          this.selectStores.push(e);
        });

        this.selectMonthsSub.forEach(e => {
          this.months.push(this.fter_month_option_value(e));
          this.storesMap[
            this.fter_month_option_value(e).code
          ] = this.fter_month_option_value(e).disName;
          this.selectMonth.push({
            code: this.fter_month_option_value(e),
            disName: this.fter_month_option(e)
          });
        });
        this.q.qMonth = this.selectMonth[0].code;
        this.clickCard();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  mounted() {
    this.clickCard();
  },
  name: "AdminRevenueAnalyze",
  components: {},
  computed: {},
  methods: {

    clickCard() {
      this.disSDate = moment(this.sDate).format("YYYY/MM/DD")
      this.disEDate = moment(this.eDate).format("YYYY/MM/DD")
      this.q.qStoreId = ''
      this.qStoreId_.forEach(e=>{
        this.q.qStoreId = this.q.qStoreId + e +','
      })
      if (this.qStoreId_.length > 0){
        this.q.qStoreId = this.q.qStoreId.substring(0,this.q.qStoreId.length -1)
      }
      this.getGraphData();
      this.getBoards();
      this.fullscreenLoading = true
    },

    reset() {
      this.q.qCarNo = null;
      this.q.qStoreId = '';
      this.qStoreId_ = [];
      this.qProdStatus = null;
      this.sDate = moment(new Date()).startOf("month");
      this.eDate = moment(new Date()).endOf("month");
      // this.getGraphData();
    },

    async getBoards(){
      this.fullscreenLoading = true
      let start_date_moment = moment(this.sDate);
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      this.q.qSDate = _qSdate;
      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      this.q.qEDate = _qEdate;
      await this.$axios
        .get("../api/adminRevenueAnalyze/getBoards", { params:this.q})
        .then(res =>{
          if (res.data.rs == "success"){

            // 平均預約訂單處理時間
            this.board.reactionMin = res.data.data.reactionMin
            this.board.reactionOrderCount = res.data.data.reactionOrderCount

            // 短租訂金支付比例
            this.board.payedDeposit = res.data.data.payedDeposit
            this.board.limitedOrderCount = res.data.data.limitedOrderCount

          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
        })
        
    },

    trans(path, qType) {
      this.$emit("gotoMenu", path.substring(1));
      this.$router.push({ path: path, query: { qType: qType }});
    },

    fter_month_option(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYY 年 MM 月");
    },
    fter_month_option_value(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYYMM");
    },
    getGraphData() {
      let start_date_moment = moment(this.sDate);
      let qSdate_ = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      this.q.qSDate = qSdate_;

      let start_date_year = moment(this.eDate).subtract(11, "months").startOf("month");
      let _qSdate = start_date_year.isValid() ? start_date_year.format("YYYYMMDD") : "";
      this.q.qGraphSDate = _qSdate;
      let start_week_date = moment(this.eDate).subtract(this.qWeeks-1, "weeks");
      this.q.qWeekSDate = start_week_date.isValid() ? start_week_date.format("YYYYMMDD") : "";
      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      this.q.qEDate = _qEdate;
      this.$axios
        .get("../api/adminRevenueAnalyze/getGraphData", { params: this.q })
        .then(res => {
          console.log(res.data)
          this.revenueDate = res.data.revenueDate
          this.clientTypePieData = res.data.clientTypePieData
          this.clientTypePieChart()
          this.depositKey = res.data.depositPayWayKey;
          this.depositPayWayCriData = res.data.depositPayWayTotal;
          this.depositPieChart();
          this.cancelOrderReasonData = res.data.cancelOrderReasonData
          this.cancelReasonPieChart();
          this.payWayChangeData = res.data.payWayChang;
          this.payWayChangeChart();
          this.onlineStockPieData = res.data.onlineStockPieData;
          this.onlineStockPieChart();
          this.revenueBarData = res.data.revenueBarData
          this.revenueBarDataType = res.data.revenueBarDataType
          this.revenueBarChart();
          this.rentDatePieData = res.data.rentDatePieData
          this.rentDatePieChart();
          this.createSDateData = res.data.createSDateList
          this.createSDateChart();
          this.onlineStockDepositDateData = res.data.onlineStockDepositDateData
          this.createDepositDateChart();
          this.clientHowToKnowData = res.data.clientHowToKnowList
          this.clientHowToKnowChart();
        })
        .catch(res => {
          console.log(res.response);
          this.$alert("login error", '結果', { confirmButtonText: '確定' });
        });
    },
    clientTypePieChart() {
      let pieSvg = d3.selectAll("#clientTypePie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#clientTypePie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr("transform", "translate(" + this.svgWidth / 2 + "," + radius + ")");
      let showText = g.append("text").attr("text-anchor", "middle")
                      .attr("transform", "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")");
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.clientTypePieData))
        .enter()
        .append("g")
        .on("mouseleave", () => { showText.text(""); });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.clientTypePieData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    depositPieChart() {
      let pieSvg = d3.selectAll("#depositPayWayPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#depositPayWayPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr("transform", "translate(" + this.svgWidth / 2 + "," + radius + ")");
      let showText = g.append("text").attr("text-anchor", "middle")
                      .attr("transform", "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")");
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.depositPayWayCriData))
        .enter()
        .append("g")
        .on("mouseleave", () => { showText.text(""); });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.depositPayWayCriData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    
    payWayChangeChart() {
      let payWayChangeSvg = d3.select("#payWayChange").select("svg");
      payWayChangeSvg.remove();
      payWayChangeSvg = d3
        .select("#payWayChange")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      let xScale = d3
        .scaleBand()
        .domain(this.revenueDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let gXAxis = payWayChangeSvg
        .append("g")
        .attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let yScale = d3
        .scaleLinear()
        .domain([0, 1])
        .range([this.svgHeight - 40, 0]);
      let y_axis = d3.axisLeft().scale(yScale);

      let gYAxis = payWayChangeSvg
        .append("g")
        .attr("transform", "translate(40,20)");
      y_axis(gYAxis);

      let series = d3
        .stack()
        .keys(this.key)
        .offset(d3.stackOffsetExpand)(this.payWayChangeData)
        .map(d => (d.forEach(v => (v.key = d.key)), d));
      let color = d3.scaleOrdinal(d3.schemeCategory10);

      payWayChangeSvg
        .append("g")
        .selectAll("g")
        .data(series)
        .enter()
        .append("g")
        .attr("fill", d => color(d.key))
        .selectAll("rect")
        .data((d, i) => series[i])
        .enter()
        .append("rect")
        .attr("x", (d, i) => xScale(this.revenueDate[i]))
        .attr("y", d => yScale(d[1]))
        .attr("height", d => yScale(d[0]) - yScale(d[1]))
        .attr("width", 50)
        .attr("transform", "translate(45,20)");
    },
    revenueBarChart(){
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let data = this.revenueBarData;
      let barSvg = d3.select("#revenueBar").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#revenueBar")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueBarDataType)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      //長條
      var rectStep = (700 - 70) / this.revenueBarData.length;
      var g = barSvg.append("g")
      g
        .selectAll("rect")
        .data(this.revenueBarData)
        .enter()
        .append("rect")
        .attr("fill",(d,i) => color(i))
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 5)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(10,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "元"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");
    },
    cancelReasonPieChart() {
      let pieSvg = d3.selectAll("#cancelOrderReasonPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#cancelOrderReasonPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.cancelOrderReasonData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.cancelOrderReasonData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    onlineStockPieChart() {
      let pieSvg = d3.selectAll("#onlineStockPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#onlineStockPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.onlineStockPieData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.onlineStockPieData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    rentDatePieChart() {
      let pieSvg = d3.selectAll("#rentDatePie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#rentDatePie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.rentDatePieData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.rentDatePieData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    createSDateChart() {
      let xList = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14+']
      let insSvg = d3.select("#createSDateTable").select("svg");
      insSvg.remove();
      insSvg = d3
        .select("#createSDateTable")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let xScale = d3
        .scaleBand()
        .domain(xList)
        .range([0, this.svgWidth - 50]);
      let x_axis = d3.axisBottom().scale(xScale);
      let gXAxis = insSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let yScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.createSDateData)])
        .range([this.svgHeight - 30, 0]);
      let y_axis = d3.axisLeft().scale(yScale);
      let gYAxis = insSvg.append("g").attr("transform", "translate(40,10)");
      y_axis(gYAxis);
      let line = d3.line()
        .x((d, i) => xScale(xList[i]))
        .y((d, i) => yScale(this.createSDateData[i]));
      var rectStep = (700 - 70) / xList.length;
      let g = insSvg.append("g");
      insSvg
        .append("path")
        .attr("d", line(this.createSDateData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",10)")
        .attr("stroke", "steelBlue")
        .attr("stroke-width", 2)
        .attr("fill", "none");
      g.selectAll("text")
        .data(this.createSDateData)
        .enter()
        .append("text")
        .text((d,i) => this.createSDateData[i] +'單')
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", (d, i) => yScale(this.createSDateData[i]))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",13)")
        .style("fill", "red");
    },
    createDepositDateChart() {
      let xList = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
      let insSvg = d3.select("#createDepositDateTable").select("svg");
      insSvg.remove();
      insSvg = d3
        .select("#createDepositDateTable")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let xScale = d3
        .scaleBand()
        .domain(xList)
        .range([0, this.svgWidth - 50]);
      let x_axis = d3.axisBottom().scale(xScale);
      let gXAxis = insSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let yScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.onlineStockDepositDateData)])
        .range([this.svgHeight - 30, 0]);
      let y_axis = d3.axisLeft().scale(yScale);
      let gYAxis = insSvg.append("g").attr("transform", "translate(40,10)");
      y_axis(gYAxis);
      let line = d3.line()
        .x((d, i) => xScale(xList[i]))
        .y((d, i) => yScale(this.onlineStockDepositDateData[i]));
      var rectStep = (700 - 70) / xList.length;
      let g = insSvg.append("g");
      insSvg
        .append("path")
        .attr("d", line(this.onlineStockDepositDateData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",10)")
        .attr("stroke", "steelBlue")
        .attr("stroke-width", 2)
        .attr("fill", "none");
      g.selectAll("text")
        .data(this.onlineStockDepositDateData)
        .enter()
        .append("text")
        .text((d,i) => this.onlineStockDepositDateData[i] +'單')
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", (d, i) => yScale(this.onlineStockDepositDateData[i]))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",13)")
        .style("fill", "red");
    },
    clientHowToKnowChart() {

      let pieSvg = d3.selectAll("#clientHowToKnowPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#clientHowToKnowPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height",this.svgHeight)
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle")
      .attr("transform","translate(" + this.svgWidth / 3+ ",-"+(radius-20)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.clientHowToKnowData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.clientHowToKnowData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");   
    },

  }
};
</script>

<style scoped>
.vert {
  height: 100%;
  display: flex;
  /* -webkit-align-items: center; */
}
.col-row {
  display: flex;
}
.selectit {
  background-color: yellowgreen;
}
.empty {
  background-color: white;
}
</style>
