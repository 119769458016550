<template>
<el-dialog title="" :close-on-click-modal="false" :visible.sync="modal" width="90%">
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; " v-loading.fullscreen.lock="fullscreenLoading">資料查證視窗
      <input type="hidden" v-model="form.clientOrderId" autocomplete="off" readonly/>
      <input type="hidden" v-model="form.catId" autocomplete="off"/>
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px;">
        <tbody style=" text-align:left">
          <tr><td class="col-label">訂單號:</td>
          <td colspan="3" class="col-value">{{form.clientOrderId}}</td></tr>

          <tr><td class="col-label">客戶名稱:</td>
          <td colspan="3" class="col-value">{{form.clientUserDisName}}</td></tr>
          
          <tr><td class="col-label">客戶電話:</td>
          <td class="col-value">{{form.clientPhone}}</td>
          <td class="col-label">客戶出生年月日:</td>
          <td class="col-value"> {{form.clientBirthday}}</td></tr>
          
          <tr><td class="col-label">訂單取車時間:</td>
          <td class="col-value">{{form.sDate}}</td>
          <td class="col-label">車類:</td>
          <td class="col-value"> {{form.prodPriceDisName}}</td></tr>

          <tr><td class="col-label">車型:</td>
          <td class="col-value">{{form.catDisName}}</td>
          <td class="col-label">車號:</td>
          <td class="col-value"> {{form.carNo}}</td></tr>

          <tr><td class="col-label">訂單還車時間:</td>
          <td class="col-value">{{form.eDate}}</td>
          <td class="col-label">訂單管道:</td>
          <td class="col-value"> {{form.clientType | filter_clientType}}</td></tr>

          <tr><td class="col-label">訂金:</td>
          <td class="col-value">{{form.deposit}}</td>
          <td class="col-label">訂單總金額:</td>
          <td class="col-value"> {{form.price}}</td></tr>

          <tr><td class="col-label">客戶喜好:</td>
          <td class="col-value">{{form.adminNote}}</td>
          <td class="col-label">類別:</td>
          <td class="col-value">{{(form.rentDateType==='2')?'長租':'短租'}}</td></tr>
          <tr @click="showClientPic"><td colspan="4" class="col-label">客戶證件:<el-button size="small" v-show="!picShow" type="primary">顯示</el-button><el-button size="small" v-show="picShow" type="primary">隱藏</el-button></td></tr>

          <tr v-show="picShow">
            <td class="col-value"><a target="_blank" :href="pic1"><img :src="pic1" style="width:250px;height:180px;"/></a></td>
            <td class="col-value"><a target="_blank" :href="pic2"><img :src="pic2" style="width:250px;height:180px;"/></a></td>
            <td class="col-value"><a target="_blank" :href="pic3"><img :src="pic3" style="width:250px;height:180px;"/></a></td>
            <td class="col-value"><a target="_blank" :href="pic4"><img :src="pic4" style="width:250px;height:180px;"/></a></td>
          </tr>
          <tr>
            <td class="col-label">證件辨識:</td>
            <td class="col-value" colspan="3" v-if="form.recognizeResult=='T'">與註冊資料相符</td>
            <td class="col-value" colspan="3" v-else-if="form.recognizeResult=='N'" style="color:red">與註冊資料不相符</td>
            <td class="col-value" colspan="3" v-else-if="form.recognizeResult=='F'" style="color:red">證件無法辨識</td>
            <td class="col-value" colspan="3" v-else>尚未驗證證件</td>
          </tr>
          <tr>
            <td class="col-label">評分日期:</td>
            <td colspan="3" class="col-value">
              <div style='font-size:14px;' v-for="history in kycHistory" :key="history.rowId">
                <el-tag  :type="history.statusType">
                   {{history.status}}
                 </el-tag>
                時間:{{history.updateDate}}評分:{{history.resultRank}}
                <el-tag  :type="info" v-show="userInfo.auth.userType === '0'" @click="history.show = !history.show">明細</el-tag>
                <div v-show="history.show"><h3>交通判決</h3><span v-for="(v,idx) in history.vs" :key="idx">{{v.disName}} = {{v.value}}<br/></span></div>
                <div v-show="history.show"><h3>司法判決</h3><span v-for="(c,idx) in history.cs" :key="idx">{{c.disName}} = {{c.value}}<br/></span></div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="col-label">KYC評分:</td>
            <td colspan="3" class="col-value">
              <el-row style="display: flex;">
                <el-col :span="24" style="display: flex;">
                  <div class="grid-content bg-purple">
                    <span style="color:black;">{{ratio}}</span>
                    <span :style="historyLabelColor">{{historyLabel}}</span>
                  </div>
                </el-col>
              </el-row>
            </td>
            </tr>
       </tbody>
    </table>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="kycCredentials()" type="primary"  class="bs">證件辨識</el-button>
      <el-button @click="startGetKyc()" type="primary"  class="bs">開始驗證</el-button>
      <el-button @click="cancel()">退出</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'KycDialog',
  props: ['comfirmModal', 'comfirmModelsContent'],
  data () {
    return {
      form:{
        clientOrderId: ''
      },
      kycStatus: null,
      kycHistory: [],
      pic1:null,pic2:null,pic3:null,pic4:null,
      fullscreenLoading:false,
      modal: this.comfirmModal,
      modelsContent: this.comfirmModelsContent,
      statusMap: {
        '0': '等待驗證',
        '1': '驗證完成',
        '2': '驗證失敗'
      },
      statusTypeMap: {
        '0': 'warning',
        '1': 'success',
        '2': 'danger'
      },
      ratio: '尚未驗證過',
      historyLabel: '',
      historyLabelColor: '',
      picShow:false
    }
  },
  computed: {
      userInfo(){
        return {
          auth:{
              userType:this.$store.getters.userType
          }
        }
      },
  },
  watch: {
    comfirmModelsContent (content) {
      this.pic1 = null
      this.pic2 = null
      this.pic3 = null
      this.pic4 = null
      this.picShow = false
      this.kycStatus = null
      this.ratio = '尚未驗證過'
      this.historyLabel = ''
      this.historyLabelColor = ''
      if(content != null) {
        this.fullscreenLoading = true
        this.form.clientOrderId = content.clientOrderId
        this.$axios
        .get('../api/clientOrderQuickGet/getClientOrderForQuickForm', {
          params: {clientOrderId: content.clientOrderId}
        })
        .then((res) => {
          this.form = Object.assign({}, this.form, res.data[0])
          this.fullscreenLoading = false
          if(this.form.rentDateType==='2'){
            this.isLongRent=true
          }else{
            this.isLongRent=false
          }
          this.form.eDate = this.$date_(this.form.eDate)
          this.form.sDate = this.$date_(this.form.sDate)
          this.form.plusInsurance = false
          let _tempClientUserId = this.form.clientUserId
          this.$axios
            .get('../api/kycDialog/gethistory', {
              params: {clientUserId: _tempClientUserId}
            })
            .then((res) => {
              if (res.data.rs === 'success') {
                let list = res.data.data.historys
                this.kycHistory.splice(0, this.kycHistory.length)
                this.ratio = res.data.data.ratio
                if (this.ratio!== undefined && this.ratio !== '') {
                  this.ratio = this.ratio + '分'
                  let color = ['#25CD00','#B0CF00','#805500','#CF7D00','#CF0000']
                  let ratioInt = parseInt(this.ratio)
                  this.historyLabelColor = ''
                  if (ratioInt === 100) {
                    this.historyLabelColor = 'color:' + color[0] + ''
                    this.historyLabel = '優良駕駛'
                  } else if (ratioInt >= 80 && ratioInt < 100) {
                    this.historyLabelColor = 'color:' + color[1] + ''
                    this.historyLabel = '普通駕駛'
                  } else if (ratioInt >= 60 && ratioInt < 80) {
                    this.historyLabelColor = 'color:' + color[2] + ''
                    this.historyLabel = '少量違規駕駛，需稍加注意'
                  } else if (ratioInt >= 40 && ratioInt < 60) {
                    this.historyLabelColor = 'color:' + color[3] + ''
                    this.historyLabel = '中量違規駕駛，不建議租借'
                  } else if (ratioInt < 40) {
                    this.historyLabelColor = 'color:' + color[4] + ''
                    this.historyLabel = '大量違規駕駛，不建議租借'
                  }
                } else {
                  this.ratio = '尚未驗證過'
                }
                list.forEach(e => {
                  e.updateDate = this.$date_(e.updateDate)
                  e.statusType = this.statusTypeMap[e.status]
                  e.status = this.statusMap[e.status]
                  e.show = false

                  if (e.vehicleJudgement != null) {
                    let vs = JSON.parse(e.vehicleJudgement).data
                    e.vs = []
                    for (var v in vs) {
                      if(v !== 'mvdis_tickets'){
                        e.vs.push({disName: v, value :vs[v]})
                      } else {
                        if(vs[v]['可線上支付']!=null){
                          let vs1=vs[v]['可線上支付']
                          for(var v1 in vs1){
                            e.vs.push({disName: '可線上支付', value :vs1[v1]})
                          }
                        }
                        if(vs[v]['不可線上支付']!=null){
                          let vs2=vs[v]['不可線上支付']
                          for(var v2 in vs2){
                            e.vs.push({disName: '不可線上支付', value :vs2[v2]})
                          }
                        }
                      }
                    }
                  }
                  if (e.commonJudgement != null) {
                    let cs = JSON.parse(e.commonJudgement).data
                    e.cs = []
                    for (var c in cs) {
                        e.cs.push({disName: c, value :cs[c]})
                    }
                  }
                  this.kycHistory.push(e)
                  if (e.status === '0') {
                    this.kycStatus = e.status
                  }
                })
              } else {
                this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
              }
            })
        }).catch(() => {
          this.$alert('show data error', '結果', { confirmButtonText: '確定' });
        });
      }
      this.modelsContent = content
    },
    comfirmModal: function (bol) {
      this.modal = bol
    },
    modal: function (bol) {
      if (!bol) {
        this.$emit('setComfirmModalEvent', false,null)
      }
    }
  },
  methods: {
    startGetKyc () {
      this.$confirm('請注意，驗證資料將酌收查詢費用，是否確定開始執行KYC','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        if (this.kycStatus === '0') {
          this.$alert('爬蟲正在執行中請等待', '結果', { confirmButtonText: '確定' });
        } else {
          let pa = {clientUserId: this.form.clientUserId}
          let url = '../api/kycDialog/startKYC'
          this.$axios.post(url, pa)  
            .then((res) => {
              this.fullscreenLoading = false
              if (res.data.rs === 'success') {
                this.$alert('與KYC連線中過程需要1分鐘', '結果', { confirmButtonText: '確定' });
                this.modal = false
              } else {
                this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
              }
            }).catch(() => {
              this.fullscreenLoading = false
              this.$alert('與KYC連線錯誤', '結果', { confirmButtonText: '確定' });
            });
        }
      })
      // this.$emit('comfirmModalOkEvent', false)
    },
    async kycCredentials () {
      if (this.form.pic1 === null || this.form.pic2 === null) {
        this.$alert('此用戶尚未上傳身分證', '結果', { confirmButtonText: '確定' });
        return
      }
      let pa = {frontPic: this.form.pic1,
        backPic: this.form.pic2,
        clientUserId: this.form.clientUserId,
        clientBirthday: this.form.clientBirthday}
      this.$confirm('是否確定開始辨識證件','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.fullscreenLoading = true
        this.$axios.post('../api/kycDialog/kycCredentials',pa)
          .then((res) => {
            this.fullscreenLoading = false
            if (res.data.rs === 'success') {
              this.form.recognizeResult = 'T'
            } else if (res.data.rs === 'fail') {
              if (res.data.rsMsg === '資料不符') {
                this.form.recognizeResult = 'N'
              } else {
                this.form.recognizeResult = 'F'
              }
            } else if (res.data.rs === 'error'){
              this.form.recognizeResult = 'F'
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            } else {
              this.$alert('未知的錯誤', '結果', { confirmButtonText: '確定' });
            }
          }).catch((res)=>{
            this.fullscreenLoading = false
            console.log(res)
          })
      })
    },
    cancel () {
      this.$emit('comfirmModalCancelEvent', false)
    },
    showClientPic() {
      this.picShow = !this.picShow
      if(this.picShow) {
        if (this.form.pic1 === null) { this.form.pic1 = 'clientUser/null'}
        if (this.form.pic2 === null) { this.form.pic2 = 'clientUser/null'}
        if (this.form.pic3 === null) { this.form.pic3 = 'clientUser/null'}
        if (this.form.pic4 === null) { this.form.pic4 = 'clientUser/null'}

        this.pic1 = '../api/adminClientUser/getUserPic/'+this.form.pic1
        this.pic2 = '../api/adminClientUser/getUserPic/'+this.form.pic2
        this.pic3 = '../api/adminClientUser/getUserPic/'+this.form.pic3
        this.pic4 = '../api/adminClientUser/getUserPic/'+this.form.pic4
      }
    }
  },
  created () {
    //
  },
  mounted () {
    //
  }
}
</script>
<style scoped>
.col-label{
  background-color:#DDDDDD;
  border-style:solid;
  border-width:1px;
}
.col-value{
  border-style:solid;
  border-width:1px;
}
</style>
