<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="名稱" v-model="qDisName">
              <template slot="prepend">名稱</template>
            </el-input>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col>

      </el-row>      
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="storeGroupId"    label="ID"    width="180">    </el-table-column>
        <el-table-column   prop="groupName"    label="名稱" width="200"  >    </el-table-column>
        <el-table-column   prop="storeGroupIdUrl"   label="客戶端連結" > 
          <template slot-scope="scope"><span>{{ determineActiveProfile()+scope.row.storeGroupId}}</span>
        </template> </el-table-column>
        <el-table-column    fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
            <!-- <el-button  @click="deleteRow(scope.row)" type="danger" size="small">刪除</el-button> -->
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>

<el-dialog title="編輯視窗" :visible.sync="dialogFormVisible"  >
  <el-form :model="form" ref="editForm" :rules="rules">
    <el-form-item label="ID" :label-width="formLabelWidth">
      <el-input v-model="form.storeGroupId" autocomplete="off" readonly></el-input>
    </el-form-item>
    <el-form-item label="名稱" :label-width="formLabelWidth" prop="groupName">
      <el-input v-model="form.groupName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="照片上傳" :label-width="formLabelWidth" v-show="form.storeGroupId != '自動產生'">
      <el-button type="primary" @click.native="uploadImage">上傳</el-button>
      <input type="file" ref="files" @change="selectImage" accept="image/*">
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditStore">確 定</el-button>
  </div>
</el-dialog>

  </div>
</template>


<script>
export default {
  data () {
    return {  
      pageTotal:0,
      pageCurrent:1,
      labelPosition: 'right',
      qDisName:'',
      tableData:[],
      dialogFormVisible: false,
      dialogAuthVisible: false,
      form: {
        groupName: '',
        storeGroupId: '',
      },
      formLabelWidth: '120px',
      rules:{
        groupName: [{
        required: true,
        message: '無法為空值',
        trigger: 'blur'
        }],
      }
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
      });
  },
  name: 'AdminStoreGroup',
  components: {
  },
  computed:{
  },
  methods :{
    query(){
      this.$axios
        .get('../api/adminStoreGroup/getStoreGroups?page='+this.pageCurrent,{})
        .then((res)=>{
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(storeGroup=>{this.tableData.push(storeGroup)
          })
          this.pageTotal=res.data.pageTotal
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
      });
    },
    confirmEditStore(){
      this.$refs['editForm'].validate((valid) => {
          if (valid) {
            let url='../api/adminStoreGroup/updateStoreGroup'
            if(this.form.storeGroupId=='自動產生'){
              this.form.storeGroupId=undefined;
              url='../api/adminStoreGroup/createStoreGroup'
            }
            this.$axios.post(url,this.form).then(()=>{
                this.query()
                this.dialogFormVisible=false
            }).catch((res)=>{
                console.log(res.data);
            });      
          } else {
            console.log('error submit!!');
            return false;
          }
      });  
    },

    determineActiveProfile() {
      if (window.location.href.indexOf('cms.mofuntravel.com.tw') === -1) {
        return 'https://test.mofuntravel.com.tw/client/#/outsideStoreGroupReservation?linkStoreGroup=';
      } else {
        return 'https://client.mofuntravel.com.tw/client/#/outsideStoreGroupReservation?linkStoreGroup=';
      }

    },


    uploadImage() {
      //
      if(this.$refs.files.value === '' ||this.$refs.files.value === null|| this.$refs.files.value === undefined){
        this.$alert('請先選擇要上傳的檔案','注意',{
          confirmButtonText:'確定'
        })
        return
      }
      let uploadPath = '../api/adminStoreGroup/uploadImg/'+this.form.storeGroupId
      this.$confirm('是否確定上傳檔案','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(()=>{
        this.fullscreenLoading = true
        this.$axios.post(uploadPath,this.formData).then((res) => {
          this.fullscreenLoading = false
          if(res.data.rs === 'success'){
            this.dialogFormVisible=false
            this.$alert('上傳成功','結果',{
              confirmButtonText:'確定'
            })
          } else {
            this.$alert('上傳失敗','結果',{
              confirmButtonText:'確定'
            })
          }
        }).catch(() =>{
          this.fullscreenLoading = false
          this.$alert('上傳失敗','結果',{
              confirmButtonText:'確定'
            })
        })
      })
    },
    selectImage(e) {
      this.formData = new FormData();
      this.formData.append('file',e.target.files[0])
    },
    showCreateDetailForm(){
      this.form = Object.assign({}, this.form, {storeGroupId:'自動產生',disName:''})
      this.dialogFormVisible=true
    },
    showDetailForm(storeGroup){
      this.$axios
        .get('../api/adminStoreGroup/getStoreGroup?storeGroupId='+storeGroup.storeGroupId)
        .then((res)=>{
          this.dialogFormVisible=true

          this.form = Object.assign({}, this.form, res.data)
          // res.data.forEach(store=>{this.tableData.push(store)})
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
      });
    },
    deleteRow(index) {
      console.log(index)
    }
  }
}
</script>
