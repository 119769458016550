<template>
  <div class="connect" id='websocket'>
    <el-input v-model="message"></el-input>
    <el-button @click="sendDataToServer">connect</el-button>
  </div>
</template>
<script>
  
  export default {
    data(){
      return{
        wsIsRun:false,
        webSocket:null,
        ws:'',
        wsTimer:null,  
        message:"",
        userName:'',

        stompClient:null,
      }
    },
    methods:{
      wsInit(){
        const wsurl='ws://localhost:8081/websocket/'+this.userName
        this.ws = wsurl
        if(!this.wsIsRun) return
        this.wsDestory()
        this.webSocket = new WebSocket(this.ws)
        this.webSocket.addEventListener('open',this.wsOpenHanler)
        this.webSocket.addEventListener('message',this.wsMessageHanler)
        this.webSocket.addEventListener('error',this.wsErrorHanler)
        this.webSocket.addEventListener('close',this.wsCloseHanler)

        clearInterval(this.wsTimer)
        this.wsTimer = setInterval(()=>{
          if(this.webSocket.readyState === 1){
            clearInterval(this.wsTimer)
          }else{
            console.log("connect fail")
            this.wsInit()
          }
        },3000)
      },
      sendDataToServer(){
        if(this.webSocket.readyState === 1){
          this.webSocket.send(this.message)
        }else{
          throw Error('cannot conect to server')
        }
      },
      wsOpenHanler(){
        console.log("connect succes")
      },
      wsErrorHanler(event){
        console.log(event+" connect error")
        this.wsInit()
      },
      wsCloseHanler(event){
        console.log(event+"ws close")
        this.wsInit
      },
      wsMessageHanler(e){
        console.log('wsMessageHanler')
        console.log(e)
      },
      wsDestory(){
         if (this.webSocket !== null) {
          this.webSocket.removeEventListener('open', this.wsOpenHanler)
          this.webSocket.removeEventListener('message', this.wsMessageHanler)
          this.webSocket.removeEventListener('error', this.wsErrorHanler)
          this.webSocket.removeEventListener('close', this.wsCloseHanler)
          this.webSocket.close()
          this.webSocket = null
          clearInterval(this.wsTimer)
         }
      }
    },
    created(){
      
        
      this.$axios
          .get('../api/params/getParams', {})
          .then((res) => {
            this.$store.dispatch('login', res.data)
            this.userName=res.data.adminUser.disName
            }).catch((res) => {
            if (res.response.status == 401) {
              window.location = '/';
            }
  
    }); 

    },
    mounted(){
      this.wsIsRun=true
      this.wsInit()
    },
  }
</script>
<style scoped>
.selectit{
  background-color: yellowgreen;
}
.empty{
  background-color: white;
}
.iyrm-label{
    text-align: right;
    padding-right: 20px;
}
.svg{
    margin:25px;
}
.path{
    
    fill: none;
    stroke: #76BF8A;
    stroke-width: 3px ;

}
</style>