<template>
<div v-loading.fullscreen.lock="fullscreenLoading">
  <!-- kanban -->
  <el-row :gutter="10">
    <el-col :span="5">
      <!-- 目前全部 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  :class="q.qProdStatus=='prod1'?'selectit':'empty'" @click.native="clickCard('prod1')">
        {{$t('adminProdStatusAllcurrently')}}<span style="color:red;font-size:40px;margin-left:10px;">{{board.prod1}}</span>{{$t('adminProdStatusFew')}}
      </el-card>
    </el-col>
    <el-col :span="5">
      <!-- 目前出租中 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qProdStatus=='prod2'?'selectit':'empty'" @click.native="clickCard('prod2')">
        {{$t('adminProdStatusCurrentlyrenting')}}<span style="color:red;font-size:40px;margin-left:10px;">{{board.prod2}}</span>{{$t('adminProdStatusFew')}}
      </el-card>
    </el-col>
    <el-col :span="6">
      <!-- 目前閒置數量 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qProdStatus=='prod3'?'selectit':'empty'" @click.native="clickCard('prod3')">
        {{$t('adminProdStatusCurrentlyidle')}}<span style="color:green;font-size:40px;margin-left:10px;">{{board.prod3}}</span>{{$t('adminProdStatusFew')}}
      </el-card>
    </el-col>
    <el-col :span="6">
      <!-- 停用/維修車輛 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qProdStatus=='prod4'?'selectit':'empty'" @click.native="clickCard('prod4')">
        停用/維修車輛<span style="color:red;font-size:40px;margin-left:10px;">{{board.prod4}}</span>台
      </el-card>
    </el-col>
  </el-row>
<!-- kanban -->
  <el-row :gutter="10" class="vol-row">
    <el-col :span="5" >
      <!-- 預約待確認 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;"  @click.native="trans('/adminQuickGet','get1')">
        {{$t('adminProdStatusTobeconfirmed')}}<span style="color:green;font-size:40px;margin-left:10px;">{{board.get1}}</span>{{$t('adminProdStatusOrders')}}
      </el-card>
    </el-col>
    <el-col :span="5">
      <!-- 預約待付款 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminQuickGet','get2')">
        {{$t('adminProdStatusPendingpayment')}}<span style="color:green;font-size:40px;margin-left:10px;">{{board.get2}}</span>{{$t('adminProdStatusOrders')}}
      </el-card>
    </el-col>
    <el-col :span="6">
      <!-- 當日待取車 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminQuickGet','get3')">
        {{$t('adminProdStatusCarpickup')}}<span style="color:green;font-size:40px;margin-left:10px;">{{board.get3}}</span>{{$t('adminProdStatusOrders')}}
      </el-card>
    </el-col>
    <el-col :span="6">
      <!-- 累積逾期未取車 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminQuickGet','get4')">
        {{$t('adminProdStatusPickedupoverdue')}}<span style="color:green;font-size:40px;margin-left:10px;">{{board.get4}}</span>{{$t('adminProdStatusOrders')}}
      </el-card>
    </el-col>
  </el-row>
<!-- kanban -->
  <el-row :gutter="10" class="vol-row">
    <el-col :span="5">
      <!-- 當日待還車 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminQuickReturn','return1')">
        {{$t('adminProdStatusCarreturned')}}<span style="color:green;font-size:40px;margin-left:10px;">{{board.return1}}</span>{{$t('adminProdStatusOrders')}}
      </el-card>
    </el-col>
    <el-col :span="5">
      <!-- 累積超時租車 -->
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminQuickReturn','return2')">
        {{$t('adminProdStatusOvertime')}}<span style="color:green;font-size:40px;margin-left:10px;">{{board.return2}}</span>{{$t('adminProdStatusOrders')}}
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/clientUserApplyCancelForm','refund')">
        等待退款訂單<span style="color:red;font-size:40px;margin-left:10px;">{{board.refund}}</span>單
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminQuickReturn','return4')">
        已還車等待扣款<span style="color:green;font-size:40px;margin-left:10px;">{{board.return4}}</span>單
      </el-card>
    </el-col>
    <!-- <el-col :span="3">
      <el-card shadow="always" style="margin-top:5px;">
        <el-button  type="primary" style="margin-top:5px;" @click="getProdStatusBaord()">重新整理</el-button>
      </el-card>
    </el-col>     -->
  </el-row>
  <el-row :gutter="10" class="vol-row">
    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminQuickReturn','longRentDue')">
        長租待付款<span style="color:red;font-size:40px;margin-left:10px;">{{board.longRentDue}}</span>單
      </el-card>
    </el-col>
    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminQuickReturn','longRentOverdue')">
        長租付款逾期<span style="color:green;font-size:40px;margin-left:10px;">{{board.longRentOverdue}}</span>單
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" :class="q.qProdStatus=='longRenting'?'selectit':'empty'" @click.native="clickCard('longRenting')">
        長租租車中<span style="color:red;font-size:40px;margin-left:10px;">{{board.longRenting}}</span>單
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminProd','isMaintain')">
        需要保養車輛<span style="color:red;font-size:40px;margin-left:10px;">{{board.isMaintain}}</span>單
      </el-card>
    </el-col>

    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminClientOrder','autoCancel')"> 
        自動取消<span style="color:red;font-size:40px;margin-left:10px;">{{board.autoCancel}}</span>單
      </el-card>
    </el-col>
    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminAlert','meterMove')"> 
        非租借期間移動<span style="color:red;font-size:40px;margin-left:10px;">{{board.meterMove}}</span>單
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminAlert','lowRatio')"> 
        低評價通知<span style="color:red;font-size:40px;margin-left:10px;">{{board.lowRatio}}</span>單
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminAlert','prodFix')"> 
        車輛報修警示<span style="color:red;font-size:40px;margin-left:10px;">{{board.prodFix}}</span>單
      </el-card>
    </el-col>

    <el-col :span="5">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;" @click.native="trans('/adminProd','carOwner')"> 
        摩拓自有的車輛<span style="color:green;font-size:40px;margin-left:10px;">{{board.carOwnerCount}}</span>輛
      </el-card>
    </el-col>
  </el-row>
  <!-- search area -->
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="10">
      <el-col :span="6">
        <el-row :gutter="1" class="col-row">
          <el-col :span="6"><div class="vert">店家狀態</div></el-col>
          <el-col :span="18">
            <el-select v-model="q.qStoreStatus" clearable placeholder="" >
              <el-option width=120 v-for="item in qStoresStatusList" :key="item.code" :label="item.disName" :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="6">
        <el-row :gutter="1" class="col-row">
          <!-- 所屬店家 -->
          <el-col :span="6"><div class="vert">{{$t('adminProdStatusStoreId')}}</div></el-col>
          <el-col :span="18">
            <el-select v-model="qStoreId_" multiple filterable clearable> <!--複數選擇  修改-->
              <el-option width=120 v-for="item in selectStores" :key="item.code" :label="item.disName" :value="item.code" v-show="q.qStoreStatus == '' || item.status ==  q.qStoreStatus">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <el-row :gutter="1" class="col-row">
          <!-- 車型 -->
          <el-col :span="6"><div class="vert">{{$t('prodCatId')}}</div></el-col>
          <el-col :span="18">
            <el-select v-model="q.qCatId" filterable clearable>
              <el-option width=120 v-for="item in cats" :key="item.code" :label="item.disName" :value="item.code">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple"></div>
        <!-- 車號 -->
        <el-input :placeholder="$t('carNo')" v-model="q.qCarNo" clearable>
          <template slot="prepend">{{$t('carNo')}}</template> 
        </el-input>
      </el-col>      
    </el-row>
    <el-row :gutter="10" style="margin-top:10px;" >
      <el-col :span="8">
        <el-row :gutter="5" class="col-row">
          <el-col :span="5"><div class="vert">車輛狀態</div></el-col>
          <el-col :span="19">
            <el-radio-group v-model="q.qStatus" size="medium">
              <el-radio-button label="全部">全部</el-radio-button>
              <el-radio-button label="啟用">啟用</el-radio-button>
              <el-radio-button label="停用">停用</el-radio-button>
              <el-radio-button label="維修">維修</el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row :gutter="5" class="col-row">
          <!-- 排序 -->
          <el-col :span="5"><div class="vert">{{$t('Sequence')}}</div></el-col>
          <el-col :span="19">
            <el-radio-group v-model="q.qOrderType" size="medium">
              <el-radio-button :label="orderType_1">{{$t('Minmileage')}}</el-radio-button>
              <el-radio-button :label="orderType_2">{{$t('Maxmileage')}}</el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="2">
        <!-- 查詢 -->
        <el-button type="primary" @click="clickCard(null)">{{$t('Inquire')}}</el-button>
      </el-col>
      <el-col :span="2">
        <!-- 重置 -->
        <el-button type="primary" @click="reset()">{{$t('Reset')}}</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="carNo" :label="$t('carNo')" width="95%"></el-table-column> <!--車號 -->
      <el-table-column prop="storeId" :label="$t('storeId')" width="150" :formatter="fter_store"> </el-table-column><!-- 店家 -->
      <el-table-column prop="prodCatId" :label="$t('prodCatId')" width="150" :formatter="fter_cat"> </el-table-column><!-- 車型 -->
      <el-table-column prop="statusChangeMsg" width="200" label="狀態變更備註"> </el-table-column><!-- 狀態變更備註 -->
      <el-table-column prop="statusChangeAuthor" width="100" label="狀態更新者"> </el-table-column><!-- 狀態變更者 -->
      <el-table-column prop="statusChangeDateDis" width="150" label="狀態變更日期"> </el-table-column> <!-- 狀態變更日期 -->
      <el-table-column prop="realStartDate" :label="$t('realStartDate')" width="150"> </el-table-column><!-- 取車時間 -->
      <el-table-column prop="eDate" :label="$t('eDate')" width="150"> </el-table-column><!-- 預計還車時間 -->
      <el-table-column prop="monthDistance" align="right" width="150" :label="$t('monthDistance')"> </el-table-column><!-- 本期里程數 -->
      <el-table-column prop="nowDistance" align="right" width="150" :label="$t('nowDistance')"> </el-table-column><!-- 總里程數 -->
      <el-table-column prop="ableMileage" align="right" width="150" label="本月剩餘里程"> </el-table-column><!-- 本月剩餘里程 -->
      <el-table-column prop="contractMileage" align="right" width="150" label="合約里程"> </el-table-column><!-- 合約里程 -->
      <el-table-column prop="clientUserDisName" :label="$t('clientUserDisName')"> </el-table-column><!-- 使用客戶 -->
      <el-table-column prop="clientUserPhone" :label="$t('clientUserPhone')" width="110"> </el-table-column><!-- 客戶連絡電話 -->
      <el-table-column prop="isMaintain" label="需要保養" width="110"> </el-table-column> <!-- 需要保養 -->
      <!-- <el-table-column prop="clientOrderId" label="訂單單號"> </el-table-column> -->
      <el-table-column fixed="right" :label="$t('operating')" width="220"> <!-- 操作 -->>
        <template slot-scope="scope">
          <el-button v-if="scope.row.clientOrderId!=null" @click="showDetailForm(scope.row)" 
            type="primary" size="small">{{$t('checkorder')}} <!-- 查看訂單 -->
          </el-button>
          <el-button v-if="scope.row.clientOrderId==null && scope.row.status == 1" @click="showStartRentDialog(scope.row)" 
            type="success" size="small">{{$t('onsiteCarrental')}} <!-- 現場租車 -->
          </el-button>
          <el-button v-if="scope.row.clientOrderId==null && scope.row.status != 1"
            type="danger" size="small">{{scope.row.status == 0 ? '未啟用':'維修中'}}
          </el-button>
          <el-button type="primary" size="small" @click="showStatusChageVisibleForm(scope.row)">編輯狀態</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <!-- -----發現會員已有訂單----- -->
  <el-dialog title="發現會員已有訂單" :visible.sync="clientOrdersDialog" :close-on-click-modal="false" font-size="150%" width="500px" center>
      
  <el-row>
    <el-col :span="8"> 會員：{{ form.clientUserId }} </el-col>
    <el-col :span="8"> 姓名：{{ form.clientUserDisName }} </el-col>
    <el-col :span="8"> 電話：{{ form.phone }} </el-col>
  </el-row>

  <div style="margin-top: 4px; padding:5px" v-for="co in clientOrders" :key="co.clientOrderId" :ref="co.clientOrderId">
    <el-card>
      <div>
        <h3>現有訂單編號 : {{co.clientOrderId}}</h3>
        
        <ul>
          <li>
            <span>租借起始時間 : {{co.sDate }}</span>
          </li>
          <li>
            <span>預估還車時間 : {{co.eDate }}</span>
          </li>
          <li>
            <span>員工訂單備註 : {{co.reserveNote}}</span>
          </li>
          <li>
            <span>訂單狀態 : {{co.flow_}}</span>
          </li>
        </ul>
        <div style="margin-top:4px">
          <el-button @click="selectOrder(co)">選擇</el-button> 
          <span gutter="5px" v-show="co.flow != '50'">此訂單{{ co.flow_ }}，選擇此訂單將不收取訂金</span>     
        </div>
      </div>
      </el-card>
    </div>  
  </el-dialog>

<!-- ----現場租車>>取車視窗---- -->
  <el-dialog title="" :close-on-click-modal="false" :visible.sync="dialogStartRentVisible" width="90%">
    <el-form :model="form" style=" border-spacing:0px; font-size:200%; text-align:center; ">取車視窗
      <input type="hidden" v-model="form.storeId" autocomplete="off"/>
      <!-- <el-form-item label="店家:" :label-width="formLabelWidth">
        {{form.storeId}}
      </el-form-item> -->

      <table style="margin:auto; border-spacing:0px; border-style:solid; border-width:5px; width:90%">
        <tbody style=" text-align:left">
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">會員帳號:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input  @change="check_num(form.clientUserId)" v-model="form.clientUserId" @blur="checkClientUserId()" autocomplete="off" style="width:38%;min-width:230px; font-size:100%;"></el-input>            
          </td></tr>
      
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶名稱:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input v-model="form.clientUserDisName" style="width:38%;min-width:230px; font-size:100%;"> </el-input>
          </td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶電話:</td>
          <td colspan="3" style="border-style:solid; border-width:1px;">
            <el-input v-model="form.phone" style="width:100%; font-size:100%;min-width:230px;"></el-input></td></tr>
          
          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶出生年月日:</td>
          <td colspan="3" style="border-style:solid; border-width:1px; padding-left:15px; font-size:110%;"> {{form.birthday}}</td></tr>

          <tr><td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶喜好:</td>
          <td colspan="3" style="border-style:solid; border-width:1px; padding-left:15px; font-size:110%;">{{form.adminNote}}</td></tr>

          <tr @click="showClientPic"><td colspan="4" style="background-color:#DDDDDD; border-style:solid; border-width:1px;">客戶證件:<el-button size="small" v-show="!showPic" type="primary">顯示</el-button><el-button size="small" v-show="showPic"  type="primary">隱藏</el-button></td></tr>
          <tr v-show="showPic">
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="form.pic1"><img :src="form.pic1" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="form.pic2"><img :src="form.pic2" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="form.pic3"><img :src="form.pic3" style="width:250px;height:180px;"/></a></td>
            <td style="border-style:solid; border-width:1px;"><a target="_blank" :href="form.pic4"><img :src="form.pic4" style="width:250px;height:180px;"/></a></td>
          </tr>

          <tr>
            <td style="background-color:#DDDDDD; border-style:solid; border-width:1px;">現有訂單編號:</td>
            <td colspan="3" style="border-style:solid; border-width:1px; padding-left:15px; font-size:110%;">{{form.clientOrderId}}</td>
          </tr>          
        <!-- </tbody>
      </table> :label-width="formLabelWidth"-->
      <tr><td colspan="4" style=" border-style:solid; border-width:1px; ">
        <el-row style="margin-top:10px;" >
          <el-form-item label="車號:" label-width="20%" class="title">
            <span style="font-size:150%;">{{form.carNo}}</span>
          </el-form-item> 
        </el-row>
        <el-form-item label="員工訂單備註:" label-width="20%" class="title">
          <el-input v-model="form.reserveNote" autocomplete="off" style="width:50%; font-size:150%;"></el-input>
        </el-form-item>
        <el-form-item label="預估還車日期:" label-width="20%" class="title">
          <el-col :span="12">
            <el-date-picker style=" font-size:150%;" :editable="false" v-model="form.eDateDate" type="date" placeholder="預估還車日期">
            </el-date-picker>
          </el-col>
          <el-col :span="12">
            <el-time-select  style=" font-size:150%;" v-model="form.eDateHour" 
            :editable="false"
            :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }" placeholder="">
            </el-time-select>
          </el-col>
        </el-form-item>
        <el-form-item label="隨車配件:" label-width="20%"  class="title">
          <!-- v-for="co in clientOrders" :key="co.clientOrderId" :ref="co.clientOrderId" -->
            <el-col :span="6">雨衣：
              <el-select v-model="form.raincoat" style="width:70px">
                <el-option key="0" value="0" label="0"></el-option>
                <el-option key="1" value="1" label="1"></el-option>
                <el-option key="2" value="2" label="2"></el-option>
                <el-option key="3" value="3" label="3"></el-option>
                <el-option key="4" value="4" label="4"></el-option>
                <el-option key="5" value="5" label="5"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">半罩安全帽：
              <el-select v-model="form.helmet"  style="width:70px">
                <el-option key="0" value="0" label="0"></el-option>
                <el-option key="1" value="1" label="1"></el-option>
                <el-option key="2" value="2" label="2"></el-option>
                <el-option key="3" value="3" label="3"></el-option>
                <el-option key="4" value="4" label="4"></el-option>
                <el-option key="5" value="5" label="5"></el-option>
              </el-select>
            </el-col>
            <el-col :span="8">3/4安全帽：
              <el-select v-model="form.helmetFull" style="width:70px">
                <el-option key="0" value="0" label="0"></el-option>
                <el-option key="1" value="1" label="1"></el-option>
                <el-option key="2" value="2" label="2"></el-option>
                <el-option key="3" value="3" label="3"></el-option>
                <el-option key="4" value="4" label="4"></el-option>
                <el-option key="5" value="5" label="5"></el-option>
              </el-select>
            </el-col>
        </el-form-item>
        <!-- <el-form-item label="訂金:" label-width="20%" class="title">
          <el-switch v-model="form.depositSwitch" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item> -->

        <el-form-item label="長租:" label-width="20%" class="title">
          <el-switch v-model="form.rentDateType" active-color="#13ce66" inactive-color="#ff4949"  active-value="2" inactive-value="1" @change="switchRentDateType">
          </el-switch>
        </el-form-item>
        <el-form>
        <el-form-item v-show="form.saleInsuranceAbled === 'T'" label="加購保障:" label-width="20%" class="title">
          <el-switch v-model="form.plusInsurance" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" @change="switchPlusInsurance">
          </el-switch>
        </el-form-item>
        <el-form-item label="" label-width="20%">
          <el-button @click="getPrice" type="primary" size="small" style=" font-size:150%;" :loading="isCal">{{isCal?'金額計算中':'計算金額'}}</el-button>
        </el-form-item>
        <!-- <el-form-item  label="費用明細:" label-width="20%" class="title">
          <div style="font-size:150%;" v-for="item in form.priceList" :key="item.item">
            {{item}}
          </div>
        </el-form-item> -->
        <el-form-item  label="費用明細:" label-width="20%" class="title">
          <div style="font-size:150%;">&emsp;費用小計:<span style="margin-left:20px;">{{this.form.price}}元</span></div>
          <div v-for="(priceDetail,index) in form.priceList" :key="index">
            <span v-show="index === 1" style="margin-left:20px;"></span>
            {{priceDetail.name}}
            <span :class="priceDetail.class" style="">{{priceDetail.amt}}元</span>
          </div>
        </el-form-item>
        <el-form-item label="預估總金額:" label-width="20%" class="title">
          <span style="font-size:150%;">{{form.detailPrice}}元</span>
        </el-form-item>
        </el-form>
        <!-- <el-row  v-if="islongrent===true" >
          <el-form-item label="金額:" label-width="20%" class="title">
            <el-input style="width:50%; font-size:150%;" type="text" v-model="form.detailPrice"></el-input>
          </el-form-item>
        </el-row> -->
        <el-form-item v-show="form.beforePay === 'T'" label="預付金額(元):" label-width="20%" class="title">
          <el-input style="width:50%; font-size:150%;" v-model="form.beforePrice" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="目前里程(KM):" label-width="20%" class="title" v-show="form.enabledDistance == 'T'">
          <el-input style="width:50%; font-size:150%;" v-model="form.nowDistance" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="員工碼:" label-width="20%" class="title">
          <el-input style="width:50%; font-size:150%;" v-model="form.checkCode" autocomplete="off"  show-password></el-input>
        </el-form-item>
          </td>
          </tr>
        </tbody>
      </table>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogStartRentVisible = false" style="font-size:150%;">取 消</el-button>
      <el-button type="primary" v-show="form.beforePay === 'F'" @click="confirmStartRent()" style="font-size:150%;">開始租車</el-button>
      <el-button type="primary" v-show="form.beforePay === 'T'" @click="confirmStartRentAndBeforePay()" style="font-size:150%;">會員現金預付和開始租車</el-button>
    </div>
  </el-dialog>
  
  <!-- 編輯狀態 -->
  <el-dialog title="編輯狀態" :visible.sync="dialogStatusChageVisible" width="60%" :close-on-click-modal="false">
      <el-row :gutter="20">
        <label>車輛所屬店家:</label>
          <br/>
        <el-input v-model="statusChangeForm.prodStoreDisName" autocomplete="off" readonly></el-input>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        <label>車號:</label>
          <br/>
        <el-input v-model="statusChangeForm.carNo" autocomplete="off" readonly></el-input>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        <label>狀態:</label>
        <br/>
        <el-select v-model="statusChangeForm.shortProdStatus">
          <el-option v-for="item in shortProdStatusList" :value="item.code" :label="item.disName" :key="item.code"></el-option>
        </el-select>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px">
        <label>備註:</label>
        <br/>
        <el-input v-model="statusChangeForm.statusChangeMsg" autocomplete="off" ></el-input>
      </el-row>
      <el-row :gutter="20" style="margin-top: 50px">
        <label>輸入員工碼:</label>
        <br/>
        <el-input v-model="statusChangeForm.adminCheckCode" type="password" autocomplete="off" style="width:50%"></el-input>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click='dialogStatusChageVisible = false'>退出</el-button>
        <el-button type="primary" @click="sentStatusChangeModel()">確定</el-button>
      </div>
  </el-dialog>
</div>
</template>

<script>
import moment from 'moment';
export default {

  data() {
    return {
      dialogStartRentVisible:false,
      dialogStatusChageVisible:false,
      clientOrdersDialog: false,
      clientOrders: [],
      form:{
        pic1:null,
        pic2:null,
        pic3:null,
        pic4:null,
        eDateDate:new Date(),
        eDateHour:'',
        deposit:0,
        depositSwitch: false,
        plusInsurance: '0',
        reserveNote:'',
        detailPrice:0,
        nowDistance:0,
        checkCode:'',
        clientUserId:'',
        clientOrderId:'',
        priceList:[],
        clientUserDisName:'',
        phone:'',
        birthday:'',
        rentDateType: '1',
        raincoat: 1,
        helmet: 1,
        helmetFull: 0 ,
        beforePay: 'F',
        beforePrice: 0,
        enabledDistance: 'T'
      },
      board:{
        prod1:0,
        prod2:0,
        prod3:0,
        prod4:0, //看板數量
        get1:0,
        get2:0,
        get3:0,
        get4:0,
        get5:0,
        return1:0,
        return2:0,
        return4:0,
        longRentOverdue:0,
        longRentDue:0,
        longRenting:0,
        isMaintain:0,
        autoCancel:0,
        lowRatio:0,
        prodFix:0,
        meterMove:0
      },
      // 編輯狀態form
      statusChangeForm:{
        prodStoreDisName:'', // 車輛所屬店家 
        prodStoreId:'', // 車輛店家所屬id
        carNo:'', // 車號
        shortProdStatus:'', // 狀態
        statusChangeMsg:'', // 備註
        adminCheckCode:'', // 輸入員工碼
        prodId:'' // 車輛ID
      },
      orderType_1:"最少里程⇡",
      orderType_2:"最多里程⇣",
      prodStatus:[{code:null,disName: '全部'},{code: '1',disName: '出租中'},{code: '2',disName: '閒置'}], // 長租
      // 短租 
      shortProdStatusList:[ 
        {code: '1', disName: '啟用'},
        {code: '0', disName: '停用'},
        {code: '3', disName: '維修'}
      ],
      // usedQty: '0',
      // emptyQty: '0',
      pageTotal: 0,
      pageCurrent: 1,
      cats: [],
      carNos: [],
      stores: [],
      catsMap: [],
      storesMap: [],
      selectStores: [],
      qStoresStatusList:[
        {code:'', disName: '全部'},
        {code:'1', disName:'營業中'},
        {code:'0', disName:'停業'}
      ],
      statusList: [{
          code: '1',
          disName: '啟用'
        },
        {
          code: '0',
          disName: '停用'
        },
        {
          code: '3',
          disName: '維修'
        }
      ],
      rules: {
        carNo: [{
          required: true,
          message: '不得為空值'
        }],
        nowDistance: [{
            required: true,
            message: '不得為空值'
          },
          {
            type: 'integer',
            message: '必須為數值'
          }
        ],
        buyDistance: [{
            required: true,
            message: '不得為空值'
          },
          {
            type: 'integer',
            message: '必須為數值'
          }
        ]
      },            
      labelPosition: 'right',
      q:{qCarNo:null,qStoreId:'', qStoreStatus:'', qCatId:null,qOrderType:null,qStartPreReturnDate:'',qEndPreReturnDate:'',qProdStatus:null,qStatus:'全部'},
      qStoreId_: null,
      tableData: [],
      pic1ViewDialog: false,
      formLabelWidth: '120px',
      islongrent:false,
      isPlusInsurance:false,
      isCal:false,
      fullscreenLoading: false,
      showPic: false,
      pic1_:'',
      pic2_:'',
      pic3_:'',
      pic4_:''
    }
  },
  created() {
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        /* if(res.data.authStores.length>1){
          this.selectStores.push({
            code: null,
            disName: '全部'
          })     
        }  */ 
        res.data.authStores.forEach(e => {
          this.stores.push(e)
          this.storesMap[e.code] = e.disName
          this.selectStores.push({
            code: e.code,
            disName: e.disName,
            status: e.status 
          })
        })
        this.q.qStoreId = this.selectStores[0].code  
        this.q.qOrder = this.orderType_1
        this.cats.push({
          code: null,
          disName: '全部'
        })
        res.data.cats.forEach(e => {
          this.cats.push(e)
          this.catsMap[e.code] = e.disName
        });
        this.q.qCatId = this.cats[0].code
        this.q.qProdStatus=this.prodStatus[0].code

        this.q.qStoreStatus = '1' // 預設為營業中
        // res.data.storeStatus.forEach(e =>{ // 查後端店家狀態，未來擴展用
        //   let disName = ''
        //   if(e.status === 0){
        //     disName = '停業'
        //   } else if (e.status === 1){
        //     disName = '營業中'
        //   }
        //   this.qStoresStatusList.push({
        //     code: e.status,
        //     disName:disName
        //   })
        // })
        this.clickCard(null)
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  name: 'AdminProdStatus',
  components: {},
  computed: {},
  methods: {
    // 限制輸入數字英文
    check_num(clientUserId) {        
          this.form.clientUserId = clientUserId.replace(/[^a-z/A-Z/0-9]/, ""); //非^a-z/A-Z/0-9
        },
    checkClientUserId(){
      if(this.form.clientUserId == ''|| this.form.clientUserId==null){
        this.$alert('請輸入有效會員帳號','結果',{confirmButtonText:'確定'})
        return
      }

      this.form.clientUserId = this.form.clientUserId.toUpperCase()
      this.$axios
        .get('../api/booking/checkClientUserId?clientUserId='+this.form.clientUserId,{})
        .then((res)=>{
          if (res.data.disName==undefined) {
            this.$alert('沒有註冊過','結果',{confirmButtonText:'確定'})
            this.form.clientUserDisName='';
            this.form.phone='';
            this.form.adminNote='';
            this.form.birthday='';
            this.pic1_= null
            this.pic2_ = null
            this.pic3_ = null
            this.pic4_ = null
          } else {
            if(res.data.ban != 'T'){
            this.form.clientUserDisName=res.data.disName;
            this.form.phone=res.data.phone;
            this.form.adminNote=res.data.adminNote;
            this.form.birthday= moment(res.data.birthday).format('YYYY/MM/DD')
            this.form.clientOrderId= '';

            this.checkClientUserOrder();   

            if (res.data.pic1_ === null) { this.pic1_ = 'clientUser/null'}
            else {this.pic1_ = res.data.pic1}
            if (res.data.pic2_ === null) { this.pic2_ = 'clientUser/null'}
            else {this.pic2_ = res.data.pic2}
            if (res.data.pic3_ === null) { this.pic3_ = 'clientUser/null'}
            else {this.pic3_ = res.data.pic3}
            if (res.data.pic4_ === null) { this.pic4_ = 'clientUser/null'}
            else {this.pic4_ = res.data.pic4}

          }else {
              this.$alert('該帳號已被停權暫時無法租借與預約,詳細請聯繫客服','結果',{confirmButtonText: '確定'})
              this.form.clientUserId = ''
            }
        }
      })
    },
    // 列出已有訂單
    checkClientUserOrder() {
          this.clientOrders = Object.assign([]);
          this.clientOrderId = "";
          
          let pa = {
            clientUserId: this.form.clientUserId,
            storeId: this.form.storeId
          };
          this.$axios
            .get("../api/adminProdStatus/getClientOrders", { params: pa })
            .then(res => {
              if (res.data.data.length > 0) {
                res.data.data.forEach(e => {
                  e.sDate = this.$date_(e.sDate);
                  e.eDate = this.$date_(e.eDate);
                  e.flow_ = this.$flow_(e.flow);
              
                  this.clientOrders.push(e);
                });
                this.clientOrdersDialog = true;
              }
            });
        },

    // 選擇現有訂單  
    selectOrder(order) {
          this.clientOrdersDialog = false;  
          this.form.clientOrderId = order.clientOrderId;
          this.form.reserveNote = order.reserveNote;
          // -------------預估還車日期格式轉換------------
          const stringEDateDate = moment(order.eDate, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DDTHH:mm:ss");  
          const specificDate = new Date(stringEDateDate);
          this.form.eDateDate = specificDate
          this.form.eDateHour  = moment(order.eDate,'YYYY-MM-DD HH:mm:ss').format("HH:mm");
          // -------------隨車配件----------------------
          this.form.raincoat = order.accessoriesRaincoat;
          this.form.helmet = order.accessoriesHelmet;
          this.form.helmetFull = order.accessoriesHelmetFull;
          this.form.rentDateType = order.rentDateType;
          this.form.plusInsurance = order.plusInsurance;  
          // -------------長租--------------------------
          if(this.form.rentDateType === '2'){
            this.islongrent=true;
          }else if(this.form.rentDateType === '1'){
            this.islongrent=false;
          }
          // -----------加購保險------------------------
          if(this.form.plusInsurance === '1'){
            this.isPlusInsurance=true;
          }else if(this.form.plusInsurance === '0'){
            this.isPlusInsurance=false;
          }
        },

    confirmStartRentAndBeforePay () {
      if(this.form.clientUserId==''||this.form.clientUserId==null){
        this.$alert('請輸入有效會員帳號', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.form.detailPrice ==null || this.form.detailPrice.toString() =='' ){
        this.$alert('請先計算金額', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.form.beforePrice == '' || /\D/g.test(this.form.beforePrice) ){
        this.$alert('預付金額格式不對', '結果', { confirmButtonText: '確定' });
        return
      }

      this.$confirm(this.form.carNo + '確定會員預付'+this.form.beforePrice+'元，出租車號' + this.form.carNo + '嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.startRent()
        })
    },

    // 編輯狀態-視窗
    showStatusChageVisibleForm(prodCar){
      this.$axios.get('../api/prod/getCarStatus?carNo=' + prodCar.carNo)
      .then((res) => {
        console.log(res)
        this.dialogStatusChageVisible = true
        // this.statusChangeForm = Object.assign({}, this.statusChangeForm, res.data)
        this.statusChangeForm.prodStoreDisName = res.data.prodStoreDisName
        this.statusChangeForm.prodStoreId = res.data.prodStoreId
      })
      this.statusChangeForm.carNo = prodCar.carNo
      this.statusChangeForm.shortProdStatus = prodCar.status
      this.statusChangeForm.statusChangeMsg = prodCar.statusChangeMsg
      this.statusChangeForm.prodId = prodCar.prodId
      this.statusChangeForm.adminCheckCode = ''
    },
    
    // 編輯狀態-送出
    sentStatusChangeModel(){
      if(this.statusChangeForm.statusChangeMsg === ''){
        this.$confirm('備註為空請補上', '注意', {
          confirmButtonText: '確定',
          showCancelButton: false,
          type: 'warning',
        })
          return
      }
      
      if(this.statusChangeForm.adminCheckCode === ''){
        this.$confirm('員工碼必填', '注意', {
          confirmButtonText: '確定',
          showCancelButton: false,
          type: 'warning',
        })
          return
      }

      // 判斷員工碼是否存在
      this.axios.get('../api/adminUser/checkAdminCheckCodeExists', {
        params: {
          adminCheckCode: this.statusChangeForm.adminCheckCode
        }
      }).then((res) => {
        this.fullscreenLoading = true
          let checkCodeStatus = res.data.status
          if (checkCodeStatus !== 'success') {
            this.$alert(res.data.rsMsg, '注意', {confirmButton: '確定'}) // 此員工碼不存在
            this.statusChangeForm.adminCheckCode = ''
            this.fullscreenLoading = false
            return
          }
          // 檢查員工碼所屬店家
          this.axios.get('../api/adminUser/getAdminCheckCodeStore', {
            params: {
              adminCheckCode: this.statusChangeForm.adminCheckCode,
              storeId: this.statusChangeForm.prodStoreId
            }
          }).then((res) => {
            this.fullscreenLoading = false
            if(res.data.status == 'notfound') {
              this.$alert(res.data.message, '注意', {confirmButton: '確定'}) // 員工碼不符合該店家
              this.statusChangeForm.adminCheckCode = ''
              return
            }
            this.$confirm("是否確定變更", "注意", {
              confirmButtonText: "確定",
              cancelButtonText: "取消"}
            ).then(()=> {
              this.$axios.post('../api/adminProdStatus/updateStatusChangeMsg', {
                prodId: this.statusChangeForm.prodId, 
                statusChangeMsg: this.statusChangeForm.statusChangeMsg,
                shortProdStatus: this.statusChangeForm.shortProdStatus
              }).then(() => {
                this.$notify({
                  title: '結果',
                  message: '已成功修改',
                  type: 'success',
                  duration: 4500,
                  position: 'top-right',
                  showClose: true
                })
                this.statusChangeForm.adminCheckCode = ''
                this.dialogStatusChageVisible = false
                this.query()
              }).catch(() => {
                this.$alert("更新狀態失敗", "結果", {confirmButtonText: "確定" })
                this.statusChangeForm.adminCheckCode = ''
                this.fullscreenLoading = false
              })
            })
          })
      }).catch((res) => {
        if(res.data.status == 'error') {
          this.$alert(res.data.rsMsg, '注意', {confirmButton: '確定'}) // 後端查詢參數有錯，請通知資訊人員
          this.statusChangeForm.adminCheckCode = ''
        }
      })
      this.fullscreenLoading = false
    },
    showStartRentDialog(row){
      this.showPic = false
      this.form.pic1 = null
      this.form.pic2 = null
      this.form.pic3 = null
      this.form.pic4 = null
      this.pic1_ = null
      this.pic2_ = null
      this.pic3_ = null
      this.pic4_ = null
      this.dialogStartRentVisible = true
      this.$nextTick(function () {
        this.form.carNo = row.carNo
        this.form.storeId = row.storeId
        this.form.checkCode = ''
        this.form.eDateDate = new Date()
        this.form.eDateHour = '19:00'
        this.form.reserveNote = ''
        this.form.clientUserDisName = ''
        this.form.adminNote = ''
        this.form.phone = ''
        this.form.birthday = ''      
        this.form.clientUserId = ''
        this.form.detailPrice = null
        this.form.deposit = 0
        this.form.depositSwitch = false
        this.form.plusInsurance = false
        this.form.rentDateType = '1'
        this.form.nowDistance = 0
        this.islongrent = false
        this.isPlusInsurance = false
        this.form.priceList = [''],
        this.form.raincoat = 1
        this.form.helmet = 1
        this.form.helmetFull = 0 
        this.form.beforePay = row.payEndPriceAtRent
        this.form.beforePayPrice = 0
        this.form.enabledDistance = row.enabledDistance
        this.form.saleInsuranceAbled = row.saleInsuranceAbled
      });
    },
    confirmStartRent(){
      if(this.form.clientUserId==''||this.form.clientUserId==null){
        this.$alert('請輸入有效會員帳號', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.form.detailPrice ==null || this.form.detailPrice.toString() =='' ){
        this.$alert('請先計算金額', '結果', { confirmButtonText: '確定' });
        return
      }
      if(this.form.clientOrderId == ''){
        this.$confirm(this.form.carNo + '確定出租嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.startRent()
        })
      }
      if(this.form.clientOrderId !== ''){
        this.$confirm(this.form.carNo + '確定出租嗎?(現有訂單)','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.startRentAlready()
        })
      }
    },
    //開始租車
    startRent(){
      this.form.clientUserId = this.form.clientUserId.toUpperCase()
      let eDate = this.get14D(this.form.eDateDate,this.form.eDateHour);
      let params = {
        clientUserId:this.form.clientUserId,
        disName:this.form.clientUserDisName,
        phone:this.form.phone,
        birthday:this.form.birthday,
        storeId:this.form.storeId,
        carNo:this.form.carNo,
        nowDistance:this.form.nowDistance,
        price:this.form.detailPrice,
        plusInsurance:this.form.plusInsurance?'1':'0',
        deposit:this.form.deposit,
        eDate:eDate,
        checkCode:this.form.checkCode,
        reserveNote:this.form.reserveNote,
        rentDateType: this.form.rentDateType,
        helmet: this.form.helmet,
        helmetFull: this.form.helmetFull,
        raincoat: this.form.raincoat,
        beforePay: this.form.beforePay
      }
      if (this.form.beforePay === 'T') {
        params.beforePrice = this.form.beforePrice
        params.beforePayMethod = 'CASH'
      }
      let url = '../api/adminProdStatus/confirmStartRent'
      this.fullscreenLoading = true
      this.$axios.post(url, params)
        .then((res) => {
          this.fullscreenLoading = false
          if(res.data.rs == 'success'){
            this.query()
            this.dialogStartRentVisible = false
          }
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert('租車失敗，請洽系統管理員', '結果', { confirmButtonText: '確定' });
        });
    },

    //開始租車(發現會員已有訂單)
    startRentAlready(){
      this.form.clientUserId = this.form.clientUserId.toUpperCase()
      let eDate = this.get14D(this.form.eDateDate,this.form.eDateHour);
      let params = {
        clientUserId:this.form.clientUserId,
        clientOrderId:this.form.clientOrderId,
        disName:this.form.clientUserDisName,
        phone:this.form.phone,
        birthday:this.form.birthday,
        storeId:this.form.storeId,
        carNo:this.form.carNo,
        nowDistance:this.form.nowDistance,
        price:this.form.detailPrice,
        plusInsurance:this.form.plusInsurance?'1':'0',
        deposit:this.form.deposit,
        eDate:eDate,
        checkCode:this.form.checkCode,
        reserveNote:this.form.reserveNote,
        rentDateType: this.form.rentDateType,
        helmet: this.form.helmet,
        helmetFull: this.form.helmetFull,
        raincoat: this.form.raincoat,
        beforePay: this.form.beforePay
      }
      if (this.form.beforePay === 'T') {
        params.beforePrice = this.form.beforePrice
        params.beforePayMethod = 'CASH'
      }
      let url = '../api/adminProdStatus/confirmStartRentAlready'
      this.fullscreenLoading = true
      this.$axios.post(url, params)
        .then((res) => {
          this.fullscreenLoading = false
          if(res.data.rs == 'success'){
            this.query()
            this.dialogStartRentVisible = false
          }
          this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
        }).catch(() => {
          this.fullscreenLoading = false
          this.$alert('租車失敗，請洽系統管理員', '結果', { confirmButtonText: '確定' });
        });
    },

    get14D(date,hour){
      let eDate=date;
      let str=eDate.getFullYear().toString()
      let month=(eDate.getMonth()+1).toString();
      if(month.length==1)
        month = '0' + month
      let day=(eDate.getDate()).toString();
      if(day.length==1)
        day = '0' + day

      str=str+month+day;
      str=str+hour.replace(/\D/g, '') + '00'
      return str;
    },
    getPrice(){
      if (this.form.enabledDistance === 'F') {
        this.form.nowDistance = '0'
      }
      if (this.form.clientUserId == '' || this.form.clientUserId == null) {
        this.$alert('請輸入有效會員帳號', '結果', { confirmButtonText: '確定' })
        return
      } 
      this.isCal=true     
      let eDate = this.get14D(this.form.eDateDate,this.form.eDateHour);
      let deposit = 0
      let param={
                carNo: this.form.carNo,
                deposit: deposit,
                eDate:eDate,
                plusInsurance: this.form.plusInsurance ? '1':'0'
          };
      this.$axios
            .get('../api/adminProdStatus/getClientOrderPriceForNowRent',{params:param})
            .then((res)=>{
              this.isCal=false
              this.form.priceList.splice(0,this.form.priceList.length)
              let ps = res.data.priceList
              this.form.price = 0
              this.form.deposit = 0
              for (var key in ps) {
                if (key === 'rideHour') {
                  this.rideHour = ps[key]
                } else {
                  if (key === 'ridePrice') {
                    // ps[key] = 3
                    this.form.price = this.form.price + ps[key]
                    this.costPrice = this.costPrice + ps[key]
                    this.form.priceList.push({ name: '車資費用:', amt: ps[key] })
                    // 超額里程說明(單純額外備註顯示用，後端已經把金額列入計算)
                    if (res.data.additionNote !== undefined) {
                      this.form.priceList.push({ name: '(含超額里程費):', amt: res.data.additionNote.overDistance })
                    }
                  } else if (key === 'isrPrice') {
                    if (ps['ridePrice'] === 0) {
                      ps[key] = 0
                    }
                    this.form.price = this.form.price + ps[key] 
                    this.costPrice = this.costPrice + ps[key] //車資
                    this.form.priceList.push({ name: '保障費用:', amt: ps[key] })
                  } else if (key === 'deposit') {
                    if (ps[key] > 0) {
                      this.form.deposit = ps[key]
                      this.form.priceList.push({ name: '預付訂金:', amt: (ps[key] * -1), class: 'amt-red' })
                    }
                  }
                }
              }
              this.form.detailPrice = this.form.price
              this.form.beforePrice = this.form.price
          }).catch((res)=>{
              console.log(res)
              this.$alert('login error_1','結果',{confirmButtonText:'確定'})
          });
    },
    clickCard(qProdStatus){
      this.q.qProdStatus=qProdStatus;
      this.query()
      this.getProdStatusBaord()
      this.q.qStoreId = ''
      this.qStoreId_.forEach(e=>{
        this.q.qStoreId = this.q.qStoreId + e +','
      })
      if (this.qStoreId_.length > 0){
        this.q.qStoreId = this.q.qStoreId.substring(0,this.q.qStoreId.length -1)
      }
    },    
    getProdStatusBaord() {
      this.$axios
        .get('../api/adminProdStatus/getProdStatusBaord', {
          params: this.q
        })
        .then((res) => {
          this.board=Object.assign({},this.form,  res.data)
        }).catch((res) => {
          console.log(res.response)
          this.$alert('login error_2','結果',{confirmButtonText:'確定'})
        });
    },
    query() {
      this.q.page=this.pageCurrent
      this.$axios
        .get('../api/adminProdStatus/getProdStatus', {
          params: this.q
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(prod => {
            prod.eDate = this.$date_(prod.eDate)
            prod.realStartDate = this.$date_(prod.realStartDate)
            prod.statusChangeDateDis = this.$date_(prod.statusChangeDate)
            prod.isMaintain = ''
            if (prod.lastMaintainDistance != 0 && prod.lastMaintainDistance != null && prod.maintainDistance != 0 && prod.maintainDistance != null) {
              if (prod.nowDistance - prod.lastMaintainDistance >= prod.maintainDistance ) {
                prod.isMaintain = 'V'
              }
            }
            if (prod.maintainDays != null && prod.maintainDays != '' && prod.maintainDays != 0 ) {
              if (prod.lastMaintainDate != null && prod.lastMaintainDate != '') {
                let lastMaintainDate = moment(prod.lastMaintainDate,'YYYYMMDD')
                let todate = moment(new Date())
                let lastMaintainDate_ = lastMaintainDate.add(parseInt(prod.maintainDays),'days')
                // let todate_ = todate.subtract(parseInt(prod.maintainDays),'days')
                // if (todate_.isAfter(lastMaintainDate)) {
                //   prod.isMaintain = 'V'
                // }
                if(lastMaintainDate_.isBefore(todate)) {
                  prod.isMaintain = 'V'
                }
              }
            }
            this.tableData.push(prod)
          })
          this.pageTotal = res.data.pageTotal
        }).catch((res) => {
          console.log(res.response)
          this.$alert('login error_3','結果',{confirmButtonText:'確定'})
        });
    },
    reset(){
      this.q.qOrderType=null
      this.q.qStartPreReturnDate=null
      this.q.qEndPreReturnDate=null
      this.q.qCarNo=null
      this.q.qStoreStatus = ''
    },
    showDetailForm(clientOrder) { //取車頁面 顯示可以取車的列表
      if (clientOrder.clientOrderId == null) {
        this.$alert('沒有訂單可以查看','結果',{confirmButtonText:'確定'})
        return;
      }
      this.$router.push({
        path:'/adminQuickReturn',
        query: { clientOrderId:clientOrder.clientOrderId }
      });
    },
    fter_store(row, column) {
      let storeId = row[column.property];
      if (storeId == undefined)
        return "";
      return this.$storeDisName(this.$store.state.stores, storeId);
    },
    fter_cat(row, column) {
      let prodCatId = row[column.property];
      if (prodCatId == undefined)
        return "";
      return this.$catDisName(this.$store.state.cats, prodCatId);
    },
    fter_status(row, column) {
      let status = row[column.property];
      if (status == undefined) return "";
      for (var i in this.statusList) {
        if (this.statusList[i].code == status) {
          return this.statusList[i].disName
        }
      }
      return ""
    },
    trans (path,qType) {
      this.q.qStoreId = ''
      this.qStoreId_.forEach(e=>{
        this.q.qStoreId += e +','
      })
      if (this.qStoreId_.length > 0){
        this.q.qStoreId = this.q.qStoreId.substring(0,this.q.qStoreId.length -1)
      }
      this.$emit('gotoMenu', path.substring(1)); 

      let routerQuery = {
        path: path,
        query:{
          qType:qType
        }
      }
      const qTypeFromStoreId =[
         // 跳轉 AdminQuickGet
        'get1', 'get2', 'get3', 'get4', 

         // 跳轉 AdminQuickReturn
        'return1', 'return2', 'return4', 'longRentDue', 'longRentOverdue', 

        // 跳轉 AdminProd
        'isMaintain', 'carOwner', 

         // 跳轉 ClientUserApplyCancelForm
        'refund', 

        // 跳轉 AdminClientOrder
        'autoCancel'
      ]
      if(qTypeFromStoreId.includes(qType)){
        routerQuery.query.qStoreId = this.q.qStoreId
      }

      this.$router.push(routerQuery)

      // if(qType === 'refund' || qType === 'isMaintain' || qType === 'autoCancel' || qType === 'longRentDue' || qType === 'longRentOverdue') {
      //   this.$router.push({
      //     path:path,
      //     query: { qType:qType, qStoreId:this.q.qStoreId }
      //   })
      // } else {
      //   this.$router.push({
      //     path:path,
      //     query: { qType:qType }
      //   })
      // }
    },
    switchRentDateType(){
      if(this.form.rentDateType === '2'){
        this.islongrent=true
      }else if(this.form.rentDateType === '1'){
        this.islongrent=false
        }
    },
    switchPlusInsurance(){
      if(this.form.rentDateType === '1'){
        this.isPlusInsurance=true
      }else if(this.form.rentDateType === '0'){
        this.isPlusInsurance=false
        }
    },
    showClientPic() {
      this.showPic = !this.showPic
      if(this.showPic) {
        this.form.pic1 = '../api/adminClientUser/getUserPic/'+this.pic1_
        this.form.pic2 = '../api/adminClientUser/getUserPic/'+this.pic2_
        this.form.pic3 = '../api/adminClientUser/getUserPic/'+this.pic3_
        this.form.pic4 = '../api/adminClientUser/getUserPic/'+this.pic4_
      }
    }
  }
}
</script>

<style scoped>
.vert{
  height: 100%;
  display: flex;
  /* -webkit-align-items: center; */
}
.col-row{
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectit{
  background-color: yellowgreen;
}
.empty{
  background-color: white;
}
.title > :first-child{
   font-size: 80%;
 }
.amt-red {
  color:red;
}
.amt {
  color:#6e757c;
}
</style>
