<template>
<div>
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="20">
      <el-col :span="9">
          車機號碼:&nbsp;&nbsp;&nbsp;
          <el-input v-model="qTrackerId"
           clearable placeholder="請輸入車機號碼" style="width:60%;"></el-input>
      </el-col>
      <el-col :span="9">
          車牌:&nbsp;&nbsp;&nbsp;
          <el-input v-model="qCarNo"
           clearable placeholder="請輸入車牌" style="width:60%;"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="query()">查詢</el-button>
        <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        <span style="color:red;">刪除請找RD團隊</span>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="trackerId" label="車機號碼" width="180px"> </el-table-column>
      <el-table-column prop="carNo" label="車號" width="280px"> </el-table-column>
      <el-table-column prop="dueDate" label="電信合約到期日" width="280px"> </el-table-column>
      <el-table-column prop="updateDate" label="更新時間" width="200px"> </el-table-column>
      <el-table-column prop="updator" label="更新人" > </el-table-column>
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
    <el-form :model="form" ref="editForm">
      <el-form-item label="車機背面號碼:">
        <el-input v-model="form.trackerId" autocomplete="off" :readonly="mode === 'update'"></el-input>
      </el-form-item>
      <el-form-item label="車號:(填入空白代表tracker目前沒有裝在車上)" >
        <el-input v-model="form.carNo" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="電信合約到期日:格式必須為 yyyy/mm/dd(若沒安裝SIM請填空白)" >
        <el-input v-model="form.dueDate" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="更新時間">
        {{form.updateDate}}
      </el-form-item>
      <el-form-item label="更新時間">
        {{form.updator}}
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmEditStore()" v-loading.fullscreen.lock="fullscreenLoading">確 定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
// import * as moment from "moment/moment";
export default {
  data() {
    return {
      pageTotal: 0,
      pageCurrent: 1,
      url: '',
      qTrackerId: '',
      qCarNo: '',
      tableData: [],
      dialogFormVisible: false,
      mode: 'create', // [create , update]
      form: {
        trackerId: '',
        carNo: '',
        dueDate: '',
        updateDate:'',
        updator:''
      },
      fullscreenLoading: false
    }
  },
  created() {
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        this.query()
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  components: {
  },
  computed: {
  },
  methods: {
    query() {
      this.fullscreenLoading = true
      this.$axios
        .get('../api/tracker/getTrackers', {
          params: {
            page: this.pageCurrent,
            qCarNo: this.qCarNo,
            qTrackerId: this.qTrackerId
          }
        })
        .then((res) => {
          
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(e => {
            this.tableData.push(e)
            e.updateDate = this.$date_(e.updateDate)
          })
          this.pageTotal = res.data.pageTotal
          this.fullscreenLoading = false
        }).catch(() => {
          this.$alert('不知名的錯誤', '結果', { confirmButtonText: '確定' })
          this.fullscreenLoading = false
        });
    },
    confirmEditStore() {
      if(this.form.trackerId === '') {
        this.$alert('車機號碼不可為空', '結果', { confirmButtonText: '確定' })
        return
      }
      
      if(this.form.dueDate !== ''  && !/\d{4}\/\d{2}\/\d{2}/g.test(this.form.dueDate)) {
        this.$alert('日期格式不對請填寫yyyy/mm/dd', '結果', { confirmButtonText: '確定' })
        return
      }

      let confirmText = '是否確定更改車機資料'
      let url = '../api/tracker/updateTracker'
      if (this.mode === 'create') {
        url = '../api/tracker/createTracker'
        confirmText = '是否確定新增車機號碼'
      }
      this.$confirm(confirmText,'注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(()=>{
        this.fullscreenLoading = true
        let params = this.form
        this.$axios.post(url, params)
          .then((res) => {
            this.fullscreenLoading = false
            if (res.data.rs ==='success') {
              this.query()
              this.dialogFormVisible = false
            } else{
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
            }
          }).catch(() => {
            this.fullscreenLoading = false
            this.$alert('更新失敗', '結果', { confirmButtonText: '確定' })
          });
      })
    },
    showCreateDetailForm() {
      this.mode = 'create'
      this.form = Object.assign({}, this.form, {
        trackerId: '',
        carNo: '',
        dueDate:'',
        updateDate:'',
        updator:''
      })
      this.dialogFormVisible = true
    },
    showDetailForm(tracker) {
      this.mode = 'update'
      this.dialogFormVisible = true
      this.form = Object.assign({}, this.form, tracker)
    }
  }
}
</script>

<style scoped>
</style>
