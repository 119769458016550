<template>
<div>
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="20">
      <el-col :span="9">
        信用類型&nbsp;&nbsp;&nbsp;
        <el-select v-model="qType" placeholder="" style="width:60%;">
            <el-option
            v-for="item in qTypes"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
            </el-option>
        </el-select>
      </el-col>
      <el-col :span="9">
          關鍵字&nbsp;&nbsp;&nbsp;
          <el-input v-model="qKeyword"
           clearable placeholder="請輸入廣告名稱" style="width:60%;"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="query()">查詢</el-button>
        <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="typeDisName" label="信用類型" width="120px"> </el-table-column>
      <el-table-column prop="opposite" label="反向排除" width="120px"></el-table-column>
      <el-table-column prop="assignKey" label="指定指標"></el-table-column>
      <el-table-column prop="ratio" label="評分" width="280px"> </el-table-column>
      <el-table-column prop="keyword" label="關鍵字"></el-table-column>
      <el-table-column prop="enabled" label="狀態"></el-table-column>
      <el-table-column prop="updateDate" label="更新時間" width="180px"> </el-table-column>
      <el-table-column prop="updator" label="更新人" width="150px"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next" style="margin-top:10px" :total="pageTotal" :current-page.sync="pageCurrent" @current-change="query">
    </el-pagination>
  </el-card>

  <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
    <el-form :model="form" ref="editForm">
      <el-form-item label="類型" :label-width="formLabelWidth" prop="addr" >
        <el-select v-model="form.type" autocomplete="off">
            <el-option
            v-for="item in types"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="指定指標" :label-width="formLabelWidth" prop="disName">
        <el-input v-model="form.assignKey" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="反向排除" :label-width="formLabelWidth" prop="disName">
        <el-select v-model="form.opposite" autocomplete="off">
            <el-option
            v-for="item in opposites"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
            </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="關鍵字" :label-width="formLabelWidth" prop="disName">
        <el-input v-model="form.keyword" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="評分" :label-width="formLabelWidth">
        <el-input v-model="form.ratio" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="狀態" :label-width="formLabelWidth" prop="enabled" >
        <el-select v-model="form.enabled" autocomplete="off">
            <el-option
            v-for="item in enableds"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="更新時間" :label-width="formLabelWidth" prop="addr">
        {{form.updateDate}}
      </el-form-item>
      <el-form-item label="更新時間" :label-width="formLabelWidth" prop="addr">
        {{form.updator}}
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmEditStore()" v-loading.fullscreen.lock="fullscreenLoading">確 定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
// import * as moment from "moment/moment";
export default {
  data() {
    return {
      pageTotal: 0,
      pageCurrent: 1,
      url: '',
      qKeyword: '',
      qType: '',
      tableData: [],
      enableds:[{code:'T',disName:'T'},{code:'F',disName:'F'}],
      types:[{code:'1',disName:'司法判決'},{code:'2',disName:'交通違規'}],
      opposites:[{code:'F',disName:'F'},{code:'T',disName:'T'}],
      qTypes:[{code:'',disName:'全部'}],
      typeFilter:{},
      
      dialogFormVisible: false,
      form: {
        assignKey: '',
        opposite: 'F',
        rowId: '',
        keyword: '',
        ratio: '',
        type: '',
        enabled: '',
        updateDate:'',
        updator:''
      },
      formLabelWidth: '120px',
      fullscreenLoading: false
    }
  },
  created() {
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.types.forEach(e => {
          this.qTypes.push(e)
          this.typeFilter[e.code] = e.disName
        })

        this.$store.dispatch('login', res.data)
        this.query()
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });
  },
  components: {
  },
  computed: {
  },
  methods: {
    query() {
    this.$axios
        .get('../api/clientUserCreditRule/getClientUserCreditRules', {
          params: {
            page: this.pageCurrent,
            qKeywrod: this.qKeywrod,
            qType: this.qType
          }
        })
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(e => {
            this.tableData.push(e)
            e.typeDisName = this.typeFilter[e.type]
            e.updateDate = this.$date_(e.updateDate)
          })
          this.pageTotal = res.data.pageTotal
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
    },
    confirmEditStore() {
      let url = '../api/clientUserCreditRule/updateClientUserCreditRule'
      if (this.form.rowId == null) {
        url = '../api/clientUserCreditRule/createClientUserCreditRule'
      }
      this.$confirm('是否確定更改信用規則','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() =>{
        this.fullscreenLoading = true
        let params = this.form
        this.$axios.post(url, params)
          .then((res) => {
            this.fullscreenLoading = false
            if (res.data.rs ==='success') {
              this.query()
              this.dialogFormVisible = false
            } else {
              this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
            }
          }).catch((res) => {
            this.fullscreenLoading = false
            console.log(res)
          });
      })
    },
    showCreateDetailForm() {
      this.form = Object.assign({}, this.form, {
        rowId: null,
        keyword: '',
        ratio: '',
        type: this.types[0].code
      })
      this.dialogFormVisible = true
    },
    showDetailForm(e) {
      this.$axios
        .get('../api/clientUserCreditRule/getClientUserCreditRule?rowId=' + e.rowId)
        .then((res) => {
          this.dialogFormVisible = true
          this.form = Object.assign({}, this.form, res.data[0])
        }).catch((res) => {
          console.log(res.response.status)
          this.$alert('新增錯誤', '結果', { confirmButtonText: '確定' });
        });
    }
  }
}
</script>

<style scoped>
</style>
