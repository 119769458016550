<template>
<div  v-loading.fullscreen.lock="fullscreenLoading">
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;" v-if="notify!=''">
    {{notify}}
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="5">
          <el-col :span="18">所屬店家&nbsp;&nbsp;&nbsp;
            <el-select v-model="qStoreId" filterable>
              <el-option width=120 v-for="item in selectStores" :key="item.code" :label="item.disName" :value="item.code">
              </el-option>
            </el-select>
          </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple"></div>
        <el-button type="primary" @click="query()">查詢</el-button>
      </el-col>
    </el-row>
  </el-card>
  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="storeDisName" label="店家"></el-table-column>
      <el-table-column prop="descTitle" label="價位(標題)"></el-table-column>
      <el-table-column prop="catId" label="車型" :formatter="fter_cat"> </el-table-column>
      <el-table-column prop="disName" label="方案名稱"></el-table-column>
      <el-table-column prop="isrDisName" label="保障名稱"></el-table-column>
      <el-table-column prop="realQty" label="真實數"></el-table-column>
      <el-table-column prop="qty" label="可預約數"></el-table-column>
      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
          <el-button @click="showPicDetailForm(scope.row)" type="warning" size="small">照片</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
    <el-form :model="form" :rules="rules" ref="editForm">
      <el-form-item label="所屬店家" :label-width="formLabelWidth">
        {{form.storeDisName}}
      </el-form-item>
      <el-form-item label="所屬車型" :label-width="formLabelWidth">
        {{form.catDisName}}
      </el-form-item>
      <el-form-item label="價位(標題)" :label-width="formLabelWidth" prop="descTitle">
        <el-input v-model="form.descTitle" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="是否上架啟用" :label-width="formLabelWidth">
        <el-switch
            v-model="form.enabled"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="T"
            inactive-value="F">
          </el-switch>
      </el-form-item>
      <el-form-item label="方案名稱" :label-width="formLabelWidth" prop="prodPriceRowId">
        <el-select v-model="form.prodPriceRowId">
          <el-option width=120 v-for="item in prodPrices" :key="item.rowId" :label="item.disName" :value="item.rowId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="真實車類"  :label-width="formLabelWidth" >
        <el-select v-model="form.realCatId" >
          <el-option width=120
            v-for="item in catList"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="目前真實數量" :label-width="formLabelWidth">
        {{form.realQty}}
      </el-form-item>
      <el-form-item label="可預約數" :label-width="formLabelWidth" prop="qty">
        <el-input v-model.number="form.qty" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirmEditStore">確 定</el-button>
    </div>
  </el-dialog>
  <el-dialog title="照片設定" :visible.sync="dialogPicVisible" :close-on-click-modal="false">
    <el-row :gutter="20">
      官網照片<a v-if="pic1" target="_blank" :href="pic1">(開啟大圖)</a>
      <img :src="pic1" class="pic"/>
      <br/>
      <input type="file" accept="image/*" @change="fileChange">
      <el-button type="primary" @click="uploadPic">上傳</el-button>
      <!-- <el-upload :action="url1" class="avatar-uploader" :show-file-list="false" :on-success="handlePictureCardPreview1">
        <img v-if="pic1" :src="pic1" class="avatar" style="width: 300px;height: auto;">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload> -->
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogPicVisible = false">退出</el-button>
    </div>
  </el-dialog>

</div>
</template>

<script>
import imageCompression from 'browser-image-compression'
export default {
  data() {
    return {
      fullscreenLoading: false,
      formData:null,
      notify: '',
      qStoreId: null,
      stores: {},
      prodPrices: [],
      selectStores: [],
      catList: [],
      cats: {},
      pic1: '',
      url1: null,
      qIsrDisName: '',
      qDisName: '',
      qProdCatId: '',
      tableData: [],
      dialogFormVisible: false,
      dialogPicVisible: false,
      form: {enabled: 'T',realCatId: null},
      formLabelWidth: '120px',
      rules: {
        qty: [{
            required: true,
            message: '不得為空值'
          },
          {
            type: 'integer',
            message: '必須為數值'
          }
        ],
        prodPriceRowId: [{
          required: true,
          message: '不得為空值'
        }]
      },
      imageOption: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      }
    }

  },
  created() {
    this.$axios
      .get('../api/params/getParams', {})
      .then((res) => {
        this.$store.dispatch('login', res.data)
        res.data.authStores.forEach(e => {
          this.stores[e.code] = e.disName;
          this.selectStores.push(e)
        });
        this.qStoreId = this.selectStores[0].code
        this.query()
        res.data.cats.forEach(e => {
          this.cats[e.code] = e.disName;
        })
      }).catch((res) => {
        if (res.response.status == 401) {
          window.location = '/';
        }
      });

  },
  name: 'AdminProdPriceCatRel',
  methods: {
    query() {
      this.$axios
        .get('../api/prodStock/getProdStocks?qStoreId=' + this.qStoreId, {})
        .then((res) => {
          this.tableData.splice(0, this.tableData.length)
          let newCats = [];

          res.data.data.forEach(pp => {
            pp.storeDisName = this.stores[pp.storeId]
            this.tableData.push(pp)
            newCats.push(pp.catId);
          })
          //顯示車型 的數量  依據後端的server來對應
          for (var i in this.cats) {
            let noExist = true;
            for (var j in newCats) {
              if (newCats[j] == i) {
                noExist = false;
                break;
              }
            }
            if (noExist) {
              this.tableData.push({
                catId: i,
                catDisName: this.cats[i],
                storeId: this.qStoreId,
                storeDisName: this.stores[this.qStoreId]
              })
            }
          }
          this.pageTotal = res.data.pageTotal
        }).catch((res) => {
          console.log(res.response)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
      this.$axios.get("../api/prodStock/getStoreCats", {params: {storeId: this.qStoreId}})
          .then(res => {
            console.log(res)
            this.catList = []
            res.data.cats.forEach(e => {
              this.catList.push(e);
            });
          })
          .catch(res => {
            if (res.response.status == 401) {
              window.location = "/";
            }
            this.fullscreenLoading = false
          });
    },
    confirmEditStore() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          this.$confirm('確定要更新嗎?','注意',{
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(()=>{
              this.fullscreenLoading = true
              let url = '../api/prodStock/updateProdStock'
              this.$axios.post(url,this.form).then((res) => {
                this.fullscreenLoading = false                
                if (res.data.rs === 'success') {
                  this.query()
                  this.dialogFormVisible = false
                }
                this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
              }).catch(() => {
                this.fullscreenLoading = false
                this.$alert('服務器發生不知名錯誤', '結果', { confirmButtonText: '確定' });
              })
            })          
        } else {
          return false;
        }
      });
    },

    showPicDetailForm(prodStock) {
      if (prodStock.prodStockId == null) {
        this.$alert('請先指定車類', '結果', { confirmButtonText: '確定' });
        return
      }
      this.url1 = '../api/prodStock/upload/' + prodStock.prodStockId
      this.$axios.get('../api/prodStock/getPic?prodStockId=' + prodStock.prodStockId).
      then((res) => {
        if (prodStock.pic != undefined & prodStock.pic != null) {
          this.pic1 = '/imgs/zocha/' + res.data.pic
        } else {
          this.pic1 = '/imgs/zocha/fontProd/none_fontProdPhoto.png'
        }
        this.dialogPicVisible = true
      }).catch((res) => {
        console.log(res.response)
        this.$alert('login error', '結果', { confirmButtonText: '確定' });
      });

    },
    handlePictureCardPreview1(file) {
      this.pic1 = '/imgs/zocha/fontProd/' + file.img
    },
    showDetailForm(prodPrice_) {
      if (prodPrice_.prodStockId == undefined || prodPrice_.prodStockId == null) {
        let prodPrice = prodPrice_;
        this.$axios.get('../api/prodStock/getProdPriceCatRelDetailByEmptyRowId?' +
          'catId=' + prodPrice.catId + '&storeId=' + prodPrice.storeId).then((res) => {
          //方案refresh
          let prodPriceList = res.data.dataProdPrices;
          this.prodPrices.splice(0, this.prodPrices.length)
          prodPriceList.forEach(e => {
            this.prodPrices.push(e)
          })
          this.dialogFormVisible = true
          this.form.qty = null;
          this.form.prodPriceRowId = null
          this.form.realCatId = prodPrice.catId
          this.form = Object.assign({}, this.form, prodPrice)
          this.form.realQty = res.data.realQty
        }).catch((res) => {
          console.log(res.response)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
      } else {
        this.$axios.get('../api/prodStock/getProdPriceCatRelDetail?prodStockId=' + prodPrice_.prodStockId +
          '&catId=' + prodPrice_.catId + '&storeId=' + prodPrice_.storeId).then((res) => {
          //方案refresh
          let prodPriceList = res.data.dataProdPrices;
          this.prodPrices.splice(0, this.prodPrices.length)
          prodPriceList.forEach(e => {
            this.prodPrices.push(e)
          })
          this.dialogFormVisible = true
          res.data.data.catDisName = this.$catDisName(this.$store.state.cats, res.data.data.catId);
          res.data.data.storeDisName = this.stores[res.data.data.storeId]
          this.form = Object.assign({}, this.form, res.data.data)
          this.form.realQty = res.data.realQty
        }).catch((res) => {
          console.log(res.response)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
        });
      }
    },
    async fileChange(e) {
      this.formData=new FormData();
      //放進上傳的檔案
      try {
        const compressedFile = await imageCompression(e.target.files[0], this.imageOption)
        this.formData.append('file', compressedFile)
      } catch (error) {
        console.log(error)
      }
    },
    uploadPic() {
      this.$confirm('是否確定上傳照片','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios.post(this.url1, this.formData).then((res) => {
          this.pic1 = '/imgs/zocha/fontProd/' + res.data.img
        }).catch((res) => {
          console.log(res)
        });
      })
    },
    backUploadDialog() {},
    fter_cat(row, column) {
      let prodCatId = row[column.property];
      if (prodCatId == undefined)
        return "";
      return this.$catDisName(this.$store.state.cats, prodCatId);
    },
  }

}
</script>

<style scoped>

.pic{
  width: 600px;
  height:450px;
}
</style>