<template>
  <div class="login" v-loading.fullscreen.lock="fullscreenLoading"  > 
    <!-- <img src="@/assets/backSideLoginBackGround.jpg" alt="hi" class="img-fluid">    -->
    <el-card shadow="always" style="margin:0 auto;width:400px;margin-top:50px;">      
      <el-form style="" label-position="top" label-width="50px" label-suffix="  :  " :model="loginClientUser" >  
        <img src="@/assets/backSideLoginLogo.png" alt="hi" class="img-fluid" width="350px">      
      <!-- <el-button type="text" @click="changeLang">{{$t('lang')}}</el-button> -->
      <el-form-item :label="$t('loginClientUserAccount')">
        <el-input v-model="loginClientUser.account" ref="username" @keydown.enter.native="tabTo('password')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('loginClientUserPassword')">
        <el-input v-model="loginClientUser.password" show-password  ref="password" @keydown.enter.native="tabTo('valid')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('loginClientUserCpty')">
        <img src="/api/register/captcha.jpg" alt="">
        <el-input v-model="loginClientUser.validateCode" ref="valid" @keydown.enter.native="login"></el-input>
      </el-form-item>
      <el-button @click="login">{{$t('login')}}</el-button>
    </el-form>
    
    </el-card>
<!-- <com-client-orderHeader :client-order="clientOrder"></com-client-orderHeader> -->
  </div>
</template>

<script>
// import Cookies from 'js-cookie';
// import ComClientOrderHeader from '../compenents/ComClientOrderHeader.vue'
// import i18n from 'i18n'

export default {
  
  data () {
    return {
      fullscreenLoading: false,
      clientOrder:{
        clientOrderId:'testid'
      },
      labelPosition: 'right',
      loginClientUser: {
        name: '',
        region: '',
        type: '',
        validateCode: ''
      },      
      bg:{
        'background-color':'white'
      }
    }
  },
  name: 'Login',
  created () {
  // this.$store.dispatch('logout')
  },
  components: {
    // ComClientOrderHeader
  },
  methods:{
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
      this.$refs.signaturePad2.fromDataURL(data)
    },
    lock(){
      this.$refs.signaturePad.lockSignaturePad();
    },
    trans  (path) {
      this.$router.push(path);  
    },
    changeLang() {
       if ( this.lang === 'tw' ) {
          this.lang = 'en';          
          this.$i18n.locale = this.lang;
       } else {
          this.lang = 'tw';
          this.$i18n.locale = this.lang;
       }
    },
    login(){
      if (this.loginClientUser.account === undefined || this.loginClientUser.account === '') {
        this.$alert('帳號不可為空', '結果', { confirmButtonText: '確定' });
        return
      }
      if (this.loginClientUser.password === undefined || this.loginClientUser.password === '') {
        this.$alert('密碼不可為空', '結果', { confirmButtonText: '確定' });
        return
      }
      if (this.loginClientUser.validateCode === '') {
        this.$alert('驗證碼不可為空', '結果', { confirmButtonText: '確定' });
        return
      }
      this.fullscreenLoading = true
      this.$axios
        .post('/api/login',this.loginClientUser)
        .then((res)=>{
          this.fullscreenLoading = false
          if(res.data.authority!=null){
            // Cookies.set('adminUser', res.data.adminUser);
            // Cookies.set('stores', res.data.stores);
            this.$store.dispatch('login',res.data)
            if(this.$store.state.loginNextPage!=''){
              this.$router.push('/adminProdStatus');
            }else{
              this.$router.push('/adminProdStatus');
            }
          }
      }).catch((res)=>{
        console.log(res)
        this.fullscreenLoading = false
        if(res.data!=null){
          this.$alert(res.data, '結果', { confirmButtonText: '確定' });
        }else{
          if (res.response.status==502){
            this.$alert('服務器目前無回應', '結果', { confirmButtonText: '確定' });
          } else if (res.response.status==404) {
            this.$alert('帳號、密碼或驗證碼錯誤', '結果', { confirmButtonText: '確定' });
          }
          else {
            this.$alert('不知名錯誤請洽管理員', '結果', { confirmButtonText: '確定' });
          }
        }
      });
    },
    tabTo(data) {
      // console.log(data)
      switch (data) {
        case 'password':
          this.$nextTick(() => this.$refs.password.focus())
          break
        case 'valid':
          this.$nextTick(() => this.$refs.valid.focus())
          break
        case 'login':
          this.login()
          break
      }
    }
  }
}
</script>
<style scoped>
/* .login /deep/ .el-input{
  width: 120px;
} */

</style>
