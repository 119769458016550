<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="5">
        <el-col :span="9">所屬店家&nbsp;&nbsp;&nbsp;
          <el-select v-model="qStoreId" filterable>
            <el-option width=120 v-for="item in selectStores" :key="item.code" :label="item.disName" :value="item.code">
            </el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="9">計價類型&nbsp;&nbsp;&nbsp;
          <el-select v-model="qPriceType" @change="query()">
            <el-option label="Mofun每小時計價" value="MOFUN"></el-option>
            <el-option label="平假日二階段" value="TWOMODE"></el-option>
            <el-option label="鼎峰收費" value="TF"></el-option>
            <el-option label="一流租車收費" value="ONEFLOW"></el-option>
            <el-option label="欣興租車收費" value="SS"></el-option>
            <el-option label="明正租車收費" value="MG"></el-option>
            <el-option label="台中松江收費" value="TCSC"></el-option>
          </el-select>
        </el-col> -->
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col>
      </el-row>    
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="storeDisName"    label="店家"></el-table-column>
        <el-table-column   prop="disName"    label="方案名稱"></el-table-column>
        <el-table-column   prop="rentDateTypeDisName" label="長租短租"></el-table-column>
        <el-table-column   prop="isrDisName"    label="保障名稱" v-if="qPriceType=='MOFUN'"></el-table-column>
        <el-table-column   prop="overTimeMinutes" align="right" label="超過x分鐘以1小時計算" width="170">  </el-table-column>
        <el-table-column   prop="unitPrice" align="right" label="每小時計價"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="todayPrice" align="right" label="上限-當日還車"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="over24Price" align="right" label="上限-元/每24小時"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="enableOverDistance" label="超額限制狀態"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="oneDayDistance" align="right" label="超額里程-每24小時包含"  v-if="qPriceType=='MOFUN'"  width="170">  </el-table-column>
        <el-table-column   prop="overXDistance" align="right" label="超額里程-每X公里"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="overXDistanceUnitPrice" align="right" label="超額里程-每Y單位元"  v-if="qPriceType=='MOFUN'"  width="170">  </el-table-column>
        <el-table-column   prop="isrBasicHour" align="right" label="保障-最低X小時"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="isrBasicPrice" align="right" label="保障-最低基本費"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="isrHourPrice" align="right" label="保障-超過每小時x元"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="isrFroceBuy" label="強制購買"  v-if="qPriceType=='MOFUN'">  </el-table-column>
        <el-table-column   prop="twoHolidayPrice" label="假日計價(天)"  v-if="qPriceType=='TWOMODE'">  </el-table-column>
        <el-table-column   prop="twoOrdinaryHourPrice" label="平日計價(小時)"  v-if="qPriceType=='TWOMODE'">  </el-table-column>
        <el-table-column   prop="two24HourLimit" label="平日24小時上限金額"  v-if="qPriceType=='TWOMODE'">  </el-table-column>

        <el-table-column   prop="ord24HourPrice" label="24小時平日上限費用"  v-if="qPriceType=='TF'" width="150">  </el-table-column>
        <el-table-column   prop="holi24HourPrice" label="24小時假日上限費用"  v-if="qPriceType=='TF'" width="150">  </el-table-column>
        <el-table-column   prop="ord1HourPrice" label="平日1小時費用"  v-if="qPriceType=='TF'">  </el-table-column>
        <el-table-column   prop="holi1HourPrice" label="假日1小時費用"  v-if="qPriceType=='TF'">  </el-table-column>
        <el-table-column   prop="ordTodayPrice" label="平日當天租還價格"  v-if="qPriceType=='TF'" width="140">  </el-table-column>
        <el-table-column   prop="holiTodayPrice" label="假日當天租還價格"  v-if="qPriceType=='TF'" width="140">  </el-table-column>
        <el-table-column   prop="oneDayDistance" label="1日免收費里程"  v-if="qPriceType=='TF'">  </el-table-column>
        <el-table-column   prop="overXDistanceUnitPrice" label="每1KM超額費用"  v-if="qPriceType=='TF'">  </el-table-column>

        <el-table-column   prop="ord12HourPrice" label="12小時平日上限費用"  v-if="qPriceType=='ONEFLOW'" width="150">  </el-table-column>
        <el-table-column   prop="holi12HourPrice" label="12小時假日上限費用"  v-if="qPriceType=='ONEFLOW'" width="150">  </el-table-column>
        <el-table-column   prop="ord24HourPrice" label="24小時平日上限費用"  v-if="qPriceType=='ONEFLOW'">  </el-table-column>
        <el-table-column   prop="holi24HourPrice" label="24小時假日上限費用"  v-if="qPriceType=='ONEFLOW'">  </el-table-column>
        <el-table-column   prop="ordOver1HourPrice" label="超時1小時平日費用"  v-if="qPriceType=='ONEFLOW'" width="140">  </el-table-column>
        <el-table-column   prop="holiOver1HourPrice" label="超時1小時假日費用"  v-if="qPriceType=='ONEFLOW'" width="140">  </el-table-column>

        <el-table-column   prop="ord24HourPrice" label="24小時平日上限費用"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="holi24HourPrice" label="24小時假日上限費用"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="ordTodayPrice" label="平日當天租還費用"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="holiTodayPrice" label="假日當天租還費用"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="ordBasic3HourPrice" label="平日3小時最低起步費用"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="holiBasic3HourPrice" label="假日3小時最低起步費用"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="ordOver1HourPrice" label="平日每小時超額費用"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="holiOver1HourPrice" label="假日每小時超額費用"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="ordTodayPriceLimit" label="平日當日超額上限"  v-if="qPriceType=='SS'">  </el-table-column>
        <el-table-column   prop="holiTodayPriceLimit" label="假日當日超額上限"  v-if="qPriceType=='SS'">  </el-table-column>
        
        <el-table-column   prop="ord24HourPrice" label="24小時平日上限費用"  v-if="qPriceType=='MG'">  </el-table-column>
        <el-table-column   prop="holi24HourPrice" label="24小時假日上限費用"  v-if="qPriceType=='MG'">  </el-table-column>
        <el-table-column   prop="unitPrice" label="每小時超額"  v-if="qPriceType=='MG'">  </el-table-column>

        <el-table-column   prop="over24Price" label="24小時平假日上限"  v-if="qPriceType=='MINGZHENG'">  </el-table-column>
        <el-table-column   prop="unitPrice" label="每小時收費"  v-if="qPriceType=='MINGZHENG'">  </el-table-column>

        <el-table-column   prop="over24Price" label="24小時平日上限"  v-if="qPriceType=='MINGZHENGV2'">  </el-table-column>
        <el-table-column   prop="holi24HourPrice" label="24小時假日上限費用"  v-if="qPriceType=='MINGZHENGV2'">  </el-table-column>
        <el-table-column   prop="unitPrice" label="超時費用"  v-if="qPriceType=='MINGZHENGV2'">  </el-table-column>

        <el-table-column   prop="todayPrice" label="6小時內基本費"  v-if="qPriceType=='TCSC'">  </el-table-column>
        <el-table-column   prop="unitPrice" label="超過6小時每小時費用"  v-if="qPriceType=='TCSC'">  </el-table-column>

        <el-table-column   prop="basicPrice" label="前2小時起步價格"  v-if="qPriceType=='GNB'">  </el-table-column>
        <el-table-column   prop="todayPrice" label="當日最高上限"  v-if="qPriceType=='GNB'">  </el-table-column>
        <el-table-column   prop="unitPrice" label="每小時費用"  v-if="qPriceType=='GNB'">  </el-table-column>

        <el-table-column   prop="todayPrice" label="當日最高上限"  v-if="qPriceType=='ES'">  </el-table-column>
        <el-table-column   prop="over24Price" label="24小時最高上限"  v-if="qPriceType=='ES'">  </el-table-column>
        <el-table-column   prop="unitPrice" label="每小時費用"  v-if="qPriceType=='ES'">  </el-table-column>
        <el-table-column   prop="ordOver1HourPrice" label="平日每小時超額費用"  v-if="qPriceType=='ES'">  </el-table-column>
        <el-table-column   prop="holiOver1HourPrice" label="假日每小時超額費用"  v-if="qPriceType=='ES'">  </el-table-column>

        <el-table-column   fixed="right"    label="操作"    width="350">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
            <el-button  @click="showFrontDescForm(scope.row)" type="primary" size="small">編輯計費說明</el-button>
            <el-button  @click="showtestPriceForm(scope.row)" type="primary" size="small">測試價格表(需30秒)</el-button>
          </template>
        </el-table-column>  
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>              
    </el-card>

<el-dialog title="定價編輯視窗" :visible.sync="dialogFormVisible"  width="90%" :close-on-click-modal="false">
  <el-form :model="form" :rules="rules" ref="editForm">
    <el-form-item label="所屬店家" :label-width="formLabelWidth" prop="storeRowId" >
      <el-select v-model="form.storeRowId" :disabled="editMode == 'update'">
          <el-option width=120  v-for="item in selectStores"  :key="item.code"  :label="item.disName"  :value="item.code">
          </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="計價模式" :label-width="formLabelWidth">
      <el-select v-model="form.priceType" placeholder="狀態">
        <el-option label="Mofun每小時計價" value="MOFUN"></el-option>
        <el-option label="Mofun 連假以天計費模式" value="MOFUNV2"></el-option>
        <el-option label="平假日二階段" value="TWOMODE"></el-option>
        <el-option label="平假日同價" value="MINGZHENG"></el-option>
        <el-option label="鼎峰收費" value="TF"></el-option>
        <el-option label="一流租車收費" value="ONEFLOW"></el-option>
        <el-option label="欣興租車收費" value="SS"></el-option>
        <el-option label="明正租車收費" value="MINGZHENGV2"></el-option>
        <el-option label="台中松江收費" value="TCSC"></el-option>
        <el-option label="東勳輕旅" value="GNB"></el-option>
        <el-option label="乙順" value="ES"></el-option>
        <el-option label="元培" value="UP"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="租任類型" :label-width="formLabelWidth" v-show="form.priceType === 'MOFUN'">
      <el-select v-model="form.rentDateType" placeholder="租任類型">
        <el-option label="短租" value="1"></el-option>
        <el-option label="長租" value="2"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="超額里程" :label-width="formLabelWidth" prop="enableOverDistance" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-select v-model="form.enableOverDistance" placeholder="狀態">
        <el-option label="啟用" value="1"></el-option>
        <el-option label="停用" value="0"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="方案名稱" :label-width="formLabelWidth" prop="disName" >
      <el-input v-model="form.disName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="保障名稱" :label-width="formLabelWidth" prop="isrDisName" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-input v-model="form.isrDisName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="定價:" :label-width="formLabelWidth" prop="overTimeMinutes" v-show="form.rentDateType == '1' && form.priceType !=='UP'">
      <el-col :span="8">超過x分鐘以1小時計算(分鐘):</el-col>
      <el-col :span="4">
        <el-input v-model="form.overTimeMinutes" autocomplete="off" ></el-input>
      </el-col>
    </el-form-item>
    <el-form-item label="" :label-width="formLabelWidth" prop="unitPrice" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="8">每小時計價:</el-col>
      <el-col :span="4">
        <el-input v-model="form.unitPrice" autocomplete="off" ></el-input>
      </el-col>
    </el-form-item>
    <el-row>
  <!-- 第一个 el-form-item -->
    <el-col :span="9">   
    <el-form-item label="定價上限:" :label-width="formLabelWidth" prop="todayPrice" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="14">當日還車:</el-col>
      <el-col :span="10">
        <el-input v-model="form.todayPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="over24Price" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="8">元/每24小時:</el-col>
      <el-col :span="6">
        <el-input v-model="form.over24Price" autocomplete="off"></el-input>
        </el-col>
    </el-form-item></el-col>
  </el-row>
  <el-row style="background-color: #bce6f3;">
    <el-col :span="8">
    <el-form-item label="連續假期:" :label-width="formLabelWidth" prop="cholidayPrice" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'" style="background-color:#bce6f3;">
      <el-col :span="12">連續假期全天價格:</el-col>
      <el-col :span="12">
        <el-input v-model="form.cholidayPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="cholidayMoreThenDay" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'" style="background-color:#bce6f3;">
      <el-col :span="12">連續租滿X天給予折扣 x=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.cholidayMoreThenDay" autocomplete="off"></el-input>
      </el-col>   
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="cholidayStartDays" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'" style="background-color:#bce6f3;"> 
      <el-col :span="12">起租日第X,Y,Z天開始給予折扣=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.cholidayStartDays" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="cholidayDiscountPercent" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'" style="background-color:#bce6f3;"> 
      <el-col :span="12">符合上述條件給予x%(1~100)折扣 X=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.cholidayDiscountPercent" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
  </el-row>
  <el-row>
    <el-col :span="8">
    <el-form-item label="超額里程:" :label-width="formLabelWidth" prop="oneDayDistance" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="12">每24小時包含:</el-col>
      <el-col :span="12">
        <el-input v-model="form.oneDayDistance" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="overXDistance" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="12">每X公里 x=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.overXDistance" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="overXDistanceUnitPrice" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="12">每Y單位元 y=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.overXDistanceUnitPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
  </el-row>
  <el-row>
    <el-col :span="8">
    <el-form-item label="保障:" :label-width="formLabelWidth" prop="isrBasicHour" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="12">最低X小時 x=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.isrBasicHour" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="isrBasicPrice" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="12">最低基本費:</el-col>
      <el-col :span="12">
        <el-input v-model="form.isrBasicPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="isrHourPrice" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-col :span="12">超過每小時X元 x=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.isrHourPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
  </el-row>
    <el-form-item label="保障強制購買" :label-width="formLabelWidth" prop="isrFroceBuy" v-show="form.priceType === 'MOFUN' && form.rentDateType == '1'">
      <el-select v-model="form.isrFroceBuy" placeholder="">
        <el-option label="啟用" value="1"></el-option>
        <el-option label="停用" value="0"></el-option>
      </el-select>
    </el-form-item>
    <el-row>
    <el-col :span="8">
    <el-form-item label="定價:" :label-width="formLabelWidth" prop="twoHolidayPrice" v-show="form.priceType === 'TWOMODE'">
      <el-col :span="8" style="text-align:right">假日計價(天):</el-col>
      <el-col :span="14">
        <el-input v-model="form.twoHolidayPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="twoOrdinaryHourPrice" v-show="form.priceType === 'TWOMODE'">
      <el-col :span="8" style="text-align:right">平日計價(小時):</el-col>
      <el-col :span="14">
        <el-input v-model="form.twoOrdinaryHourPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="two24HourLimit" v-show="form.priceType === 'TWOMODE'">
      <el-col :span="8" style="text-align:right">24小時最高上限:</el-col>
      <el-col :span="14">
        <el-input v-model="form.two24HourLimit" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
  </el-row>
    <el-row v-show="form.priceType === 'TF'">
      <el-col :span='12'>
        <el-form-item label="24小時平日上限費用(元):" label-width="200px" prop="ord24HourPrice" >
            <el-input v-model="form.ord24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="24小時假日上限費用(元):" label-width="200px" prop="holi24HourPrice" >
            <el-input v-model="form.holi24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'TF'">
      <el-col :span='12'>
        <el-form-item label="平日1小時費用(元):" label-width="200px" prop="ord1HourPrice" >
            <el-input v-model="form.ord1HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="假日1小時費用(元):" label-width="200px" prop="holi1HourPrice" >
            <el-input v-model="form.holi1HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'TF'">
      <el-col :span='12'>
        <el-form-item label="平日當天租還價格(元):" label-width="200px" prop="ordTodayPrice" >
            <el-input v-model="form.ordTodayPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="假日當天租還價格(元):" label-width="200px" prop="holiTodayPrice" >
            <el-input v-model="form.holiTodayPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'TF'">
      <el-col :span='12'>
        <el-form-item label="1日免收費里程(KM公里):" label-width="200px" prop="oneDayDistance" >
            <el-input v-model="form.oneDayDistance" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="每1KM超額費用(元):" label-width="200px" prop="overXDistanceUnitPrice" >
            <el-input v-model="form.overXDistanceUnitPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-show="form.priceType === 'ONEFLOW'">
      <el-col :span='12'>
        <el-form-item label="12小時平日上限費用(元):" label-width="200px" prop="ord12HourPrice" >
            <el-input v-model="form.ord12HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="12小時假日上限費用(元):" label-width="200px" prop="holi12HourPrice" >
            <el-input v-model="form.holi12HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'ONEFLOW'">
      <el-col :span='12'>
        <el-form-item label="24小時平日上限費用(元):" label-width="200px" prop="ord24HourPrice" >
            <el-input v-model="form.ord24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="24小時假日上限費用(元):" label-width="200px" prop="holi24HourPrice" >
            <el-input v-model="form.holi24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'ONEFLOW'">
      <el-col :span='12'>
        <el-form-item label="平日超時1小時費用(元):" label-width="200px" prop="ordOver1HourPrice" >
            <el-input v-model="form.ordOver1HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="假日超時1小時費用(元):" label-width="200px" prop="holiOver1HourPrice" >
            <el-input v-model="form.holiOver1HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-show="form.priceType === 'SS'">
      <el-col :span='12'>
        <el-form-item label="24小時平日上限費用(元):" label-width="200px" prop="ord24HourPrice" >
            <el-input v-model="form.ord24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="24小時假日上限費用(元):" label-width="200px" prop="holi24HourPrice" >
            <el-input v-model="form.holi24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'SS'">
      <el-col :span='12'>
        <el-form-item label="平日當天租還費用(元):" label-width="200px" prop="ordTodayPrice" >
            <el-input v-model="form.ordTodayPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="假日當天租還費用元):" label-width="200px" prop="holiTodayPrice" >
            <el-input v-model="form.holiTodayPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'SS'">
      <el-col :span='12'>
        <el-form-item label="平日最低3小時起步費用(元):" label-width="200px" prop="ordBasic3HourPrice" >
            <el-input v-model="form.ordBasic3HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="假日最低3小時起步費用(元):" label-width="200px" prop="holiBasic3HourPrice" >
            <el-input v-model="form.holiBasic3HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'SS'">
      <el-col :span='12'>
        <el-form-item label="平日超額每小時費用(元):" label-width="200px" prop="ordOver1HourPrice" >
            <el-input v-model="form.ordOver1HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="假日超額每小時費用(元):" label-width="200px" prop="holiOver1HourPrice" >
            <el-input v-model="form.holiOver1HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'SS'">
      <el-col :span='12'>
        <el-form-item label="平日當日超額上限(元):" label-width="200px" prop="ordTodayPriceLimit" >
            <el-input v-model="form.ordTodayPriceLimit" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="假日當日超額上限(元):" label-width="200px" prop="holiTodayPriceLimit" >
            <el-input v-model="form.holiTodayPriceLimit" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-show="form.priceType === 'MG'">
      <el-col :span='12'>
        <el-form-item label="24小時假日上限費用(元):" label-width="200px" prop="ord24HourPrice" >
            <el-input v-model="form.ord24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="24小時假日上限費用(元):" label-width="200px" prop="holi24HourPrice" >
            <el-input v-model="form.holi24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'MG'">
      <el-col :span='12'>
        <el-form-item label="超額每小時費用(元):" label-width="200px" prop="unitPrice" >
            <el-input v-model="form.unitPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-show="form.priceType === 'MINGZHENG'">
      <el-col :span='12'>
        <el-form-item label="超額每小時費用(元):" label-width="200px" prop="unitPrice" >
            <el-input v-model="form.unitPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="24小時假日上限費用(元):" label-width="200px" prop="over24Price" >
            <el-input v-model="form.over24Price" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-show="form.priceType === 'MINGZHENGV2'">
      <el-col :span='12'>
        <el-form-item label="超額每小時費用(元):" label-width="200px" prop="unitPrice" >
            <el-input v-model="form.unitPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="24小時平日上限費用(元):" label-width="200px" prop="over24Price" >
            <el-input v-model="form.over24Price" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="24小時假日費用(元):" label-width="200px" prop="holi24HourPrice" >
            <el-input v-model="form.holi24HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-show="form.priceType === 'TCSC'">
      <el-col :span='12'>
        <el-form-item label="每6小時基本費用(元):" label-width="200px" prop="todayPrice" >
            <el-input v-model="form.todayPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="超過6小時每小時費用(元):" label-width="200px" prop="unitPrice" >
            <el-input v-model="form.unitPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'GNB'">
      <el-col :span='12'>
        <el-form-item label="前2小時起步價格:" label-width="200px" prop="basicPrice" >
            <el-input v-model="form.basicPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="當日最高上限(元):" label-width="200px" prop="todayPrice" >
            <el-input v-model="form.todayPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="每小時費用(元):" label-width="200px" prop="unitPrice" >
            <el-input v-model="form.unitPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <!-- 元培 -->
    <el-row v-show="form.priceType === 'UP'">
      <el-col :span='12'>
        <el-form-item label="前X分鐘起步價格:" label-width="200px" prop="basicPrice" >
            <el-input v-model="form.basicPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="前X分鐘 X=" label-width="200px" prop="basicMin" >
            <el-input v-model="form.basicMin" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="每分鐘費用(元):" label-width="200px" prop="unitPrice" >
            <el-input v-model="form.unitPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <!-- 乙順 -->
    <el-row v-show="form.priceType === 'ES'">
      <el-col :span='12'>
        <el-form-item label="每小時費用:" label-width="200px" prop="unitPrice" >
            <el-input v-model="form.unitPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="當日最高上限:" label-width="200px" prop="todayPrice" >
            <el-input v-model="form.todayPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'ES'">
      <el-col :span='12'>
        <el-form-item label="24小時最高上限:" label-width="200px" prop="over24Price" >
            <el-input v-model="form.over24Price" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-show="form.priceType === 'ES'">
      <el-col :span='12'>
        <el-form-item label="平日1小時(元):" label-width="200px" prop="ord1HourPrice" >
            <el-input v-model="form.ord1HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item label="假日1小時(元):" label-width="200px" prop="holi1HourPrice" >
            <el-input v-model="form.holi1HourPrice" autocomplete="off" width='200'></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <!-- MOFUNV2 -->
    <el-row>
       <el-form-item label="租任類型" :label-width="formLabelWidth" v-show="form.priceType === 'MOFUNV2'">
      <el-select v-model="form.rentDateType" placeholder="租任類型">
        <el-option label="短租" value="1"></el-option>
        <el-option label="長租" value="2"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="超額里程" :label-width="formLabelWidth" prop="enableOverDistance" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-select v-model="form.enableOverDistance" placeholder="狀態">
        <el-option label="啟用" value="1"></el-option>
        <el-option label="停用" value="0"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="保障名稱" :label-width="formLabelWidth" prop="isrDisName" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-input v-model="form.isrDisName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="平日" :label-width="formLabelWidth" prop="unitPrice" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="8">每小時計價:</el-col>
      <el-col :span="4">
        <el-input v-model="form.unitPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item> 
    <el-row>
  <!-- 第一个 el-form-item -->
    <el-col :span="9">   
    <el-form-item label="定價上限:" :label-width="formLabelWidth" prop="todayPrice" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="14">當日還車:</el-col>
      <el-col :span="10">
        <el-input v-model="form.todayPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="over24Price" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="8">元/每24小時:</el-col>
      <el-col :span="6">
        <el-input v-model="form.over24Price" autocomplete="off"></el-input>
        </el-col>
    </el-form-item></el-col>
  </el-row>
  <el-row style="background-color: #bce6f3;">
    <el-col :span="8">
    <el-form-item label="假日定價:" :label-width="formLabelWidth" prop="holiTodayPrice" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'" style="background-color:#bce6f3;">
      <el-col :span="12">假日全天價格:</el-col>
      <el-col :span="12">
        <el-input v-model="form.holiTodayPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="holiOver1HourPrice" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'" style="background-color:#bce6f3;">
      <el-col :span="12">假日超時1小時費用:</el-col>
      <el-col :span="12">
        <el-input v-model="form.holiOver1HourPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
  </el-row>
  <el-row>
    <el-col :span="8">
    <el-form-item label="超額里程:" :label-width="formLabelWidth" prop="oneDayDistance" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="12">每24小時包含:</el-col>
      <el-col :span="12">
        <el-input v-model="form.oneDayDistance" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="overXDistance" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="12">每X公里 x=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.overXDistance" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="overXDistanceUnitPrice" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="12">每Y單位元 y=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.overXDistanceUnitPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
  </el-row> 
  <el-row>
    <el-col :span="8">
    <el-form-item label="保障:" :label-width="formLabelWidth" prop="isrBasicHour" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="12">最低X小時 x=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.isrBasicHour" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="isrBasicPrice" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="12">最低基本費:</el-col>
      <el-col :span="12">
        <el-input v-model="form.isrBasicPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
    <el-col :span="8">
    <el-form-item label="" :label-width="formLabelWidth" prop="isrHourPrice" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-col :span="12">超過每小時X元 x=:</el-col>
      <el-col :span="12">
        <el-input v-model="form.isrHourPrice" autocomplete="off"></el-input>
      </el-col>
    </el-form-item></el-col>
  </el-row>
    <el-form-item label="保障強制購買" :label-width="formLabelWidth" prop="isrFroceBuy" v-show="form.priceType === 'MOFUNV2' && form.rentDateType == '1'">
      <el-select v-model="form.isrFroceBuy" placeholder="">
        <el-option label="啟用" value="1"></el-option>
        <el-option label="停用" value="0"></el-option>
      </el-select>
    </el-form-item>
    </el-row>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditProdPrice()">確 定</el-button>
  </div>
</el-dialog>
<el-dialog title="計費說明編輯視窗" :visible.sync="dialogFrontDescVisible"  width="90%" :close-on-click-modal="false">
  <el-input type="textarea" placeholder="計費說明" rows="10" v-model="form.frontDesc" maxlength="500" show-word-limit />
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFrontDescVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditFrontDesc()">確 定</el-button>
  </div>
</el-dialog>
<el-dialog title="計費表範例" :visible.sync="dialogPriceTestVisible"  width="90%" :close-on-click-modal="false">
  <el-alert
    title="請正確設定該店家平日假日定義"
    type="error"
    effect="dark">
  </el-alert>
  <div class="priceList" v-html="priceList"></div>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogPriceTestVisible = false">退 出</el-button>
  </div>
</el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fullscreenLoading: false,
      pageTotal:0,
      pageCurrent:1,
      stores:{},
      selectStores:[],
      labelPosition: 'right',
      qStoreId:'',
      qPriceType: 'NONE',
      tableData:[],
      dialogFormVisible: false,
      dialogFrontDescVisible: false,
      dialogPriceTestVisible: false,
      rules: {
        storeRowId: [{ required: true, message: "不得為空值" }], //所屬店家
        disName: [{ required: true, message: "不得為空值" }], //方案名稱
        overTimeMinutes: [{ required: true, message: "不得為空值" }], //超小時定價
        enableOverDistance: [{ required: true, message: "不得為空值" }], //超額里程
        isrDisName: [{ required: true, message: "不得為空值" }], //保險名稱
        isrBasic: [{ required: true, message: "不得為空值" }], //保險
        isrFroceBuy: [{ required: true, message: "不得為空值" }], //保險強制購買
        ord24HourPrice: [{ required: true, message: "不得為空值" }], //24小時平日上限費用
        holi24HourPrice: [{ required: true, message: "不得為空值" }], // 24小時假日上限費用
        ord1HourPrice: [{ required: true, message: "不得為空值" }], // 平日1小時費用
        holi1HourPrice: [{ required: true, message: "不得為空值" }], // 假日1小時費用
        ordTodayPrice: [{ required: true, message: "不得為空值" }], // 平日當天租還價格
        holiTodayPrice: [{ required: true, message: "不得為空值" }], // 假日當天租還價格
        oneDayDistance: [{ required: true, message: "不得為空值" }], // 1日免收費里程
        overXDistanceUnitPrice: [{ required: true, message: "不得為空值" }], // 每1KM超額費用
        ord12HourPrice: [{ required: true, message: "不得為空值" }], // 12小時平日上限費用
        holi12HourPrice: [{ required: true, message: "不得為空值" }], // 12小時假日上限費用
        ordBasic3HourPrice: [{ required: true, message: "不得為空值" }], // 平日最低3小時起步費用
        holiBasic3HourPrice: [{ required: true, message: "不得為空值" }], // 假日最低3小時起步費用
        ordOver1HourPrice: [{ required: true, message: "不得為空值" }], // 平日超額每小時費用
        holiOver1HourPrice: [{ required: true, message: "不得為空值" }], // 假日超額每小時費用
        ordTodayPriceLimit: [{ required: true, message: "不得為空值" }], // 平日當日超額上限
        holiTodayPriceLimit: [{ required: true, message: "不得為空值" }], // 假日當日超額上限
        unitPrice: [{ required: true, message: "不得為空值" }], // 超額每小時費用
        over24Price: [{ required: true, message: "不得為空值" }], // 24小時假日(平日)上限費用
        todayPrice: [{ required: true, message: "不得為空值" }], // 每6小時基本費用
        basicPrice: [{ required: true, message: "不得為空值" }], // 前2小時起步價格oneDayDistance
        isrBasicHour: [{ required: true, message: "不得為空值" }],
        isrBasicPrice: [{ required: true, message: "不得為空值" }],
        isrHourPrice: [{ required: true, message: "不得為空值" }],
        twoHolidayPrice: [{ required: true, message: "不得為空值" }],
        twoOrdinaryHourPrice: [{ required: true, message: "不得為空值" }],
        two24HourLimit: [{ required: true, message: "不得為空值" }],
        overXDistance: [{ required: true, message: "不得為空值" }],
        cholidayPrice: [{ required: true, message: "不得為空值" }],// 連續假期全天價格
        cholidayMoreThenDay: [{ required: true, message: "不得為空值" }],// 連續租滿X天給予折扣
        cholidayStartDays: [{ required: true, message: "不得為空值" }],// 起租日第X,Y,Z天開始給予折扣
        cholidayDiscountPercent: [{ required: true, message: "不得為空值" }],// 符合上述條件給予x
        basicMin: [{ required: true, message: "不得為空值" }],
          },
      form: {
        rentDateType: '1',
        disName: '',
        isrDisName: '',
        name: '',
        date1: '',
        date2: '',
        type: [],
        resource: '',
        desc: '',
        rowId: '',
        priceType: 'TWOMODE',
        frontDesc: ''
      },
      formLabelWidth: '120px',
      editMode: '',
      dialogTitle: '',
      priceList: ''
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          res.data.authStores.forEach(e=>{
            this.stores[e.code]=e.disName;
            this.selectStores.push(e)
          });

        this.qStoreId = this.selectStores[0].code          
          this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
          // console.log(res.response.status)
          // alert('login error')
      });
  },
  name: 'AdminProdPrice',
  components: {
  },
  computed:{
      disName:{
        get(){
          if(this.$store.newClientUser==null)
            return 'xx'
          return this.$store.newClientUser.disName
        }
      }
  },
  methods :{

    getVisibleProps() {
  const formItems = this.$refs.editForm.$children;
  const visibleProps = [];

  const findVisibleProps = (elements) => {
    elements.forEach(child => {
      try {
        if (child.$el && child.$el.style && child.$el.style.display !== 'none') {
          if (child.prop) {
            visibleProps.push(child.prop); // 添加 prop
          }
          if (child.$children && child.$children.length > 0) {
            const childVisibleProps = getChildVisibleProps(child.$children);
            visibleProps.push(...childVisibleProps);
          }
        }
      } catch (error) {
        console.error('Error while trying to access display property:', error);
      }
    });
  };

  const getChildVisibleProps = (children) => {
    const props = [];
    children.forEach(child => {
      try {
        if (child.$el && child.$el.style && child.$el.style.display !== 'none') {
          if (child.prop) {
            props.push(child.prop); // 添加 prop
          }
          if (child.$children && child.$children.length > 0) {
            const childProps = getChildVisibleProps(child.$children);
            props.push(...childProps);
          }
        }
      } catch (error) {
        console.error('Error while trying to access display property:', error);
      }
    });
    return props;
  };

  findVisibleProps(formItems);
  
  return visibleProps;
},

    query(){
      this.fullscreenLoading = true
      this.$axios.get('../api/prodPrice/getProdPrices',
        { params: {page: this.pageCurrent, qStoreId: this.qStoreId} })
        .then((res)=>{
          this.qPriceType = res.data.priceType
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(pp=>{
            pp.storeDisName=this.stores[pp.storeRowId]
            pp.rentDateTypeDisName = pp.rentDateType == '2' ? '長租/環島' : '日租'
            this.tableData.push(pp)
          })
          this.pageTotal=res.data.pageTotal
          this.fullscreenLoading = false
      }).catch((res)=>{
          console.log(res)
          this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' });
          this.fullscreenLoading = false
      });  
    },
    confirmEditProdPrice(){
      const visibleProps = this.getVisibleProps(); // 获取可见属性列表
      let isValid = true;
      this.$refs['editForm'].clearValidate();

      // 遍历可见属性列表，只验证可见属性
     visibleProps.forEach(prop => {
        if (this.rules[prop]) {
          const rule = this.rules[prop];
          rule.forEach(validation => {
            if (validation.required) {
              // 自定义验证逻辑：这里仅检查是否为空值
              if (this.form[prop] === null || this.form[prop] === undefined) {
                isValid = false;
                this.$refs['editForm'].validateField(prop);
                console.log(`字段 ${prop} 有空值`);
                return;
              }
            }
          });
        }
      });


// 根据可见属性列表，获取对应的验证规则
    if (isValid) {
        let dialogTitle = this.form.rowId=='自動產生' ? '確定要新增定價方案嗎?' : '確定要修改定價方案嗎?'
        this.$confirm(dialogTitle,'注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.fullscreenLoading = true
          let url='../api/prodPrice/updateProdPrice'
          if(this.form.rowId=='自動產生'){
            this.form.rowId=undefined;
            url='../api/prodPrice/createProdPrice'
          }
          // console.log(this.form)
          // 如果是長租方案其他金額都要預設 = 0
          if(this.form.rentDateType == '2') {
            this.form.enableOverDistance = '0'
            this.form.isrDisName = '長租自訂'
            this.form.overTimeMinutes = '0'
            this.form.unitPrice = '0'
            this.form.todayPrice = '0'
            this.form.over24Price = '0'
            this.form.cholidayMoreThenDay = '0'
            this.form.cholidayStartDays = '0'
            this.form.cholidayDiscountPercent = '0'
            this.form.oneDayDistance = '0'
            this.form.overXDistance = '0'
            this.form.overXDistanceUnitPrice = '0'
            this.form.isrBasicHour = '0'
            this.form.isrHourPrice = '0'
            this.form.isrFroceBuy = '0'
          }
          this.$axios.post(url, this.form).then((res)=>{
            this.fullscreenLoading = false
            if (res.data.rs === 'success') {
              this.query()
            }
            this.dialogFormVisible = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
          }).catch(()=>{
            this.fullscreenLoading = false
              this.$alert('不知名錯誤請洽系統管理員', '結果', { confirmButtonText: '確定' });
          })
        })
      }else{
        console.log('請填完空值');
        this.$alert('數值有空值', '結果', { confirmButtonText: '確定' });}
      },
    confirmEditFrontDesc(){
          this.$confirm('確定要更新嗎?','注意',{
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
          }).then(()=>{
          this.fullscreenLoading = true
          let url='../api/prodPrice/updateFrontDesc'
          this.$axios.post(url, this.form).then((res)=>{
            this.fullscreenLoading = false
            if (res.data.rs === 'success') {
              this.query()
            }
            this.dialogFrontDescVisible = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
            }).catch(()=>{
            this.fullscreenLoading = false
              this.$alert('不知名錯誤請洽系統管理員', '結果', { confirmButtonText: '確定' });
            })
        })
    },
    showCreateDetailForm(){
      this.dialogTitle = '新增視窗'
      this.editMode = 'create'
      this.form = Object.assign({}, {rowId: '自動產生', priceType: 'TWOMODE',rentDateType: '1'})
      this.dialogFormVisible = true
    },
    showDetailForm(prodPrice){
      this.dialogTitle = '編輯視窗'
      this.editMode = 'update'
      this.$axios.get('../api/prodPrice/getProdPrice?rowId='+prodPrice.rowId).then((res)=>{
          this.dialogFormVisible=true
          if(res.data.rentDateType == null) {
            res.data.rentDateType = '1'
          }
          this.form = Object.assign({}, this.form, res.data)
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('服務器回應失敗', '結果', { confirmButtonText: '確定' });
      })
    },
    showFrontDescForm (prodPrice) {
      this.$axios.get('../api/prodPrice/getProdPriceFrontDesc?rowId='+prodPrice.rowId).then((res)=>{
          this.dialogFrontDescVisible = true
          this.form = Object.assign({}, this.form, res.data)
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('服務器回應失敗', '結果', { confirmButtonText: '確定' });
      })
    },
    showtestPriceForm(prodPrice) {
      this.$axios.get('../api/prodPrice/getTestPriceList?prodPriceRowId='+prodPrice.rowId).then((res)=>{
          this.dialogPriceTestVisible = true
          this.priceList = res.data
          let html = '<table><tbody>'
          for(let sm = 1; sm <= 5 ; sm++ ) {
            let _1_1 = res.data[sm + '-1']
            let _1_2 = res.data[sm + '-2']
            let style="style='border: 1px solid black;border-collapse: collapse;'"
            for (let i=0 ; i<_1_1.length ; i++) {
              html += '<tr '+style+'>'+
                      '<td '+style+'>'+ (i===0 ? _1_1[i][0] : '') + '</td>'+
                      '<td '+style+'>'+ _1_1[i][1] + '</td>'+
                      '<td '+style+'>'+ _1_1[i][2] + '</td>'+
                      '<td '+style+'>'+ _1_1[i][3] + '</td>'+
                      '<td '+style+'>'+'---------</td>'+
                      '<td '+style+'>'+ (i===0 ? _1_2[i][0] : '') + '</td>'+
                      '<td '+style+'>'+ _1_2[i][1] + '</td>'+
                      '<td '+style+'>'+ _1_2[i][2] + '</td>'+
                      '<td '+style+'>'+ _1_2[i][3] + '</td>'+
                      '</tr>'
            }
            html += '<tr><td>--------------</td></tr>'
          }
          html += '</table></tbody>'
          this.priceList=html
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('服務器回應失敗', '結果', { confirmButtonText: '確定' });
      })
    },
  }
}
</script>

<style  scoped>
.priceList table, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>