<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
  <el-form :model="form">
    <el-form-item label="會員帳號:" :label-width="formLabelWidth">
      <el-input type="text" style="width:180px;" @change="check_user(form.clientUserId)" v-model="form.clientUserId" @blur="checkClientUserId()"></el-input>
    </el-form-item>
    <el-form-item label="會員名稱:" :label-width="formLabelWidth">
      <span style="width:190px;display:inline-block;">{{form.clientUserDisName}}</span>
      <span style="width:120px;display:inline-block;text-align: center;">會員手機:</span>
      {{form.phone}}
    </el-form-item>
    <el-form-item label="員工訂單備註:" :label-width="formLabelWidth">
          <el-input type="text" v-model="form.reserveNote" style="width:400px;"></el-input>
    </el-form-item>
    <el-form-item label="訂單管道:" :label-width="formLabelWidth">
      <el-select v-model="form.clientType" placeholder="" style="width:180px;">
          <el-option width=120
            v-for="item in clientTypes"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>
        <span style="width:120px;display:inline-block;text-align: center;">店家:</span>
        <el-select v-model="form.storeId" placeholder="請選擇店家" @change="changeStore">
          <el-option width=120
            v-for="item in stores"
            :key="item.code"
            :label="item.disName"
            :value="item.code">
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="訂單起始日期:" :label-width="formLabelWidth">
          <el-date-picker
            @change="resetPrice"
            :clearable="false"
            :editable="false"
            v-model="form.sDateDate"
            type="date"
            placeholder=""
            style="width:180px;">
          </el-date-picker>
          <span style="width:120px;display:inline-block;text-align: center;">時間</span>
          <el-time-select
          @change="resetPrice"
          :clearable="false"
          :editable="false"
            v-model="form.sDateHour"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            placeholder="">
          </el-time-select>
    </el-form-item>
    <el-form-item label="訂單結束日期:" :label-width="formLabelWidth">
          <el-date-picker
            @change="resetPrice"
            :clearable="false"
            :editable="false"
            v-model="form.eDateDate"
            type="date"
            placeholder=""
            style="width:180px;">
          </el-date-picker>
          <span style="width:120px;display:inline-block;text-align: center;">時間</span>
          <el-time-select
            @change="resetPrice"
            :clearable="false"
            :editable="false"
            v-model="form.eDateHour"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            placeholder="">
          </el-time-select>
    </el-form-item>
    <el-form-item label="訂金:" :label-width="formLabelWidth">
      <el-input v-model="form.deposit" style="width:180px;"></el-input>
    </el-form-item>
    <el-form-item label="加購金額:" :label-width="formLabelWidth">
      <el-input v-model="form.plusPrice" style="width:180px;"></el-input>
      <span style="width:150px;display:inline-block;text-align: center;">加購保障:</span>
      <el-switch v-model="form.plusInsurance" @change="resetPrice"></el-switch>
    </el-form-item>
    <el-form-item label="車號:" :label-width="formLabelWidth">
      <el-select v-model="form.prodId" style="width:180px;" @change="changeCar">
        <el-option v-for="car in carNos" :key="car.code" :label="car.disName" :value="car.code"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="起始里程:" :label-width="formLabelWidth" v-show="enabledDistance=='T'">
      <el-input v-model.number="form.startDistance" style="width:180px;" @change="resetPrice"></el-input>
      <span style="width:150px;display:inline-block;text-align: center;">結束里程:</span>
      <el-input v-model.number="form.endDistance" style="width:180px;" @change="resetPrice"></el-input>
    </el-form-item>
    <el-form-item label="長租:" :label-width="formLabelWidth">
      <el-switch v-model="form.rentDateType" active-color="#13ce66" inactive-color="#ff4949" 
      active-value="2" inactive-value="1" @change="switchRentDateType">
      </el-switch>
    </el-form-item>
    <el-form v-if="islongrent===false">
    <el-form-item label="" :label-width="formLabelWidth">
      <el-button style="" @click="getPrice" type="primary" size="small" :loading="isCal">{{isCal?'金額計算中':'計算金額'}}</el-button>
    </el-form-item>
    <el-form-item  label="費用明細:" :label-width="formLabelWidth" class="title">
      <div>&emsp;費用小計:<span style="margin-left:20px;">{{this.form.price}}元</span></div>
      <div v-for="(priceDetail,index) in form.priceList" :key="index">
        <span v-show="index === 1" style="margin-left:20px;"></span>
        {{priceDetail.name}}
        <span :class="priceDetail.class" style="">{{priceDetail.amt}}元</span>
      </div>
    </el-form-item>
    <el-form-item label="訂單總金額:" :label-width="formLabelWidth">
      <span style="font-size:20px;">{{form.detailPrice}}</span>
    </el-form-item>
    </el-form>
    <el-row  v-if="islongrent===true" >
      <el-col :span="10">
      <el-form-item label="金額" :label-width="formLabelWidth">
        <el-input type="text" v-model="form.detailPrice"></el-input>
      </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="" :label-width="formLabelWidth">
      <el-button  @click="createOrder" type="primary" size="small">新增訂單</el-button>
    </el-form-item>
  </el-form>
    
    </el-card>
   
  </div>
</template>


<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
        sysTime:'',
        form: {
          clientUserId:'',
          clientUserDisName:'',
          adminNote:'',
          phone:'',
          areaId:'',
          prodId:'',
          storeId:'',
          sDateDate:'',
          sDateHour:'',
          eDateDate:'',
          eDateHour:'',
          plusInsurance:true,
          plusPrice:0,
          detailPrice:0,
          priceList:[],
          reserveNote:'',
          clientType:'',
          deposit:0,
          startDistance:0,
          endDistance:0,
          rentDateType:1
        },
        formLabelWidth: '139px',
        clientTypes:[],
        stores:[],
        cats:[],
        areas:[],
        carNos:[],
        islongrent:false,
        isCal: false,
        fullscreenLoading: false,
        enabledDistance: 'T'
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
        this.$axios
          .get('../api/booking/getSysTime',{})
          .then((res)=>{
            this.sysTime=res.data
            this.init()
        }).catch(()=>{
        });
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }
      });   
  },
  name: 'adminAdditionalClientOrder',
  components: {
    
  },
  computed:{
  },
  methods :{
    check_user (clientUserId) { // 限制輸入數字英文
      this.form.clientUserId = clientUserId.replace(/[^a-z/A-Z/0-9]/,'');
    },
    changeStore(){
      this.carNos.splice(0, this.carNos.length)
      this.form.prodId = null
      this.$axios
        .get('../api/adminAdditionalClientOrder/getCars?storeId='+this.form.storeId,{})
        .then((res)=>{
          if (res.data.rs == 'success') {
            this.resetPrice()
            res.data.carNos.forEach( e => {
              this.carNos.push(e)
            })
          }else if (res.data.rs == 'error') {
            this.$alert(res.data.errMsg,'注意',{
              confirmButtonText:'確定'
            })
          }
        })
    },
    checkClientUserId(){
      if (this.form.clientUserId === '' || this.form.clientUserId == null) {
        this.$alert('請輸入有效會員帳號','注意',{
              confirmButtonText:'確定'
            })
        return
      }
      this.form.clientUserId = this.form.clientUserId.toUpperCase()
      this.$axios
        .get('../api/booking/checkClientUserId?clientUserId=' + this.form.clientUserId,{})
        .then((res)=>{
          if(res.data.disName==undefined){
            this.$alert('沒有註冊過，不可補單','注意',{
              confirmButtonText:'確定'
            })
            this.form.clientUserDisName='';
            this.form.phone='';
            this.form.adminNote='';
          }else{
            this.form.clientUserDisName=res.data.disName;
            this.form.phone=res.data.phone;
            this.form.adminNote=res.data.adminNote;
          }
      }).catch(()=>{

      });      
    },
    init(){
      let time=Date.parse(this.sysTime)
      let d=new Date()
      d.setTime(time)
      let min=d.getMinutes()>30 ?'30':'00';
      let h=d.getHours()>=10?d.getHours().toString():'0'+ d.getHours().toString();
      this.form.sDateDate=d;
      this.form.sDateHour=h+':'+ min;

      d=new Date(d.getTime()+24*1000*3600);
      this.form.eDateDate=d;
      this.form.eDateHour=h+':'+ min;
      let codes=this.$clientType_combobox();
      codes.forEach(e=>{
        this.clientTypes.push(e)
      });
      this.form.clientType=codes[0].code
      // this.form.clientType=this.clientTypes[0].code
      this.$axios
        .get('../api/store/getStoresForCombobox',{})
        .then((res)=>{
          res.data.forEach((e)=>{
            this.stores.push(e)
          });
      }).catch(()=>{

      });
      this.$store.state.cats.forEach(e=>{
        this.cats.push({code:e.code,disName:e.disName})
      })
      this.$store.state.areas.forEach(e=>{
        this.areas.push({code:e.code,disName:e.disName})
      })
    },
    get14D(date,hour){
      let eDate=date;
      let str=eDate.getFullYear().toString()
      let month=(eDate.getMonth()+1).toString();
      if(month.length==1)
        month='0'+month;
      let day=(eDate.getDate()).toString();
      if(day.length==1)
        day='0'+day;

      str=str+month+day;
      str=str+hour.replace(/\D/g, '') + '00'
      return str;
    },
    resetPrice(){
      this.form.detailPrice = null
      this.form.priceList.splice(0, this.form.priceList.length)
    },
    getPrice(){
      if (this.enabledDistance === 'F') {
        this.form.startDistance = 0
        this.form.endDistance = 0
      }
      if(this.form.clientUserDisName == null){
        this.$alert('請輸入有效的會員帳號','注意',{
              confirmButtonText:'確定'
            })
        return
      }
      if(this.form.prodId == null){
        this.$alert('請選擇車號',{
              confirmButtonText:'確定'
            })
        return
      }
      if(this.form.startDistance >= this.form.endDistance & this.enabledDistance === 'T'){
        this.$alert('起始里程不可大於結束里程','注意',{
              confirmButtonText:'確定'
            })
        return
      }

      let eDate=this.get14D(this.form.eDateDate,this.form.eDateHour);
      let sDate=this.get14D(this.form.sDateDate,this.form.sDateHour);
      if ( sDate >= eDate ) {
        this.$alert('起始時間不可大於結束時間','注意',{
              confirmButtonText:'確定'
            })
        return
      }
      this.isCal=true
      let distance = this.form.endDistance - this.form.startDistance
      let param={
                prodId: this.form.prodId,
                storeId: this.form.storeId,
                sDate: sDate,
                eDate: eDate,
                deposit: this.form.deposit,
                plusInsurance: this.form.plusInsurance ? '1' : '0',
                distance: distance };
      this.$axios
            .get('../api/adminAdditionalClientOrder/getClientOrderPriceForAdditional',{params:param})
            .then((res)=>{
              this.isCal=false
              this.form.priceList.splice(0,this.form.priceList.length)
              let ps = res.data.priceList
              this.form.price = 0
              this.form.deposit = 0
              for (var key in ps) {
                if (key === 'rideHour') {
                  this.rideHour = ps[key]
                } else {
                  if (key === 'ridePrice') {
                    // ps[key] = 3
                    this.form.price = this.form.price + ps[key]
                    this.costPrice = this.costPrice + ps[key]
                    this.form.priceList.push({ name: '車資費用:', amt: ps[key] })
                    // 超額里程說明(單純額外備註顯示用，後端已經把金額列入計算)
                    if (res.data.additionNote !== undefined) {
                      this.form.priceList.push({ name: '(含超額里程費):', amt: res.data.additionNote.overDistance })
                    }
                  } else if (key === 'isrPrice') {
                    if (ps['ridePrice'] === 0) {
                      ps[key] = 0
                    }
                    this.form.price = this.form.price + ps[key] 
                    this.costPrice = this.costPrice + ps[key] //車資
                    this.form.priceList.push({ name: '保障費用:', amt: ps[key] })
                  } else if (key === 'deposit') {
                    if (ps[key] > 0) {
                      this.form.deposit = ps[key]
                      this.form.priceList.push({ name: '預付訂金:', amt: (ps[key] * -1), class: 'amt-red' })
                    }
                  }
                }
              }
              this.form.detailPrice = this.form.price + this.form.plusPrice
          }).catch(()=>{
              this.isCal=false
              this.$alert('計算失敗請洽系統管理者', '結果', { confirmButtonText: '確定' })
          });        
    },
    createOrder(){
      if(this.form.clientUserId==''){
        this.$alert('請輸入會員帳號','注意',{
              confirmButtonText:'確定'
            })
        return
      }      
      if(this.form.detailPrice == null || this.form.detailPrice<=0){
        this.$alert('金額不對無法新增訂單','注意',{
              confirmButtonText:'確定'
            })
        return
      }
      if(this.form.startDistance >= this.form.endDistance && this.enabledDistance === 'T'){
        this.$alert('起始里程不可大於結束里程','注意',{
              confirmButtonText:'確定'
            })
        return
      }

      let plusInsurance = this.form.plusInsurance ? '1' : '0'
      let ed=this.get14D(this.form.eDateDate,this.form.eDateHour);
      let sd=this.get14D(this.form.sDateDate,this.form.sDateHour);
      let disEDate = moment(this.form.eDateDate).format('YYYY/MM/DD HH:mm')
      let disSDate = moment(this.form.sDateDate).format('YYYY/MM/DD HH:mm')
      let pa={
        storeId: this.form.storeId,
        prodId: this.form.prodId,
        clientUserId: this.form.clientUserId.toUpperCase(),
        sDate:sd,
        eDate:ed,
        price: this.form.detailPrice, //訂單總金額
        plusPrice: this.form.plusPrice,
        plusInsurance: plusInsurance,
        clientType: this.form.clientType,
        reserveNote: this.form.reserveNote,
        deposit: this.form.deposit,
        startDistance: this.form.startDistance,
        endDistance: this.form.endDistance,
        rentDateType: this.form.rentDateType
      };
      let carNo='';
      this.carNos.forEach((e)=>{
        if (e.code === this.form.prodId){
          carNo = e.disName
        }
      })
      this.$confirm('是否確定補單，用戶：'+this.form.clientUserId+'，車號：'+carNo+'，承租時間：'+disSDate+'~'+disEDate,
      '注意',{confirmButtonText:'確定',cancelButtonText:'取消',type: 'warning'}).then(()=>{
        this.fullscreenLoading = true
        this.$axios
          .post('../api/adminAdditionalClientOrder/createClientOrderForAdditional',pa)
          .then((res)=>{
            this.fullscreenLoading = false
            if (res.data.rs == 'success') {
              this.form.storeId = null
              this.form.reserveNote = ''
              this.form.startDistance = 0
              this.form.endDistance = 0
              this.form.plusPrice = 0
              this.changeStore()
              this.resetPrice()
              this.$alert('新增完成', '結果', {confirmButtonText: '確定',
                callback: () => {
                  location.reload()
                }
              });
            } else {
              this.$alert(res.data.errMsg,'注意',{
              confirmButtonText:'確定'
            })
            }
        }).catch((res)=>{
            this.fullscreenLoading = false
            console.log(res.response.status)
            this.$alert('新增失敗', '結果', {confirmButtonText: '確定',
              callback: () => {
                location.reload()
              }
            });
        });  
      })
    },
    switchRentDateType(){
      if(this.form.rentDateType ==2){
        this.islongrent=true
      }else{
        this.islongrent=false
        }

    },
    changeCar(){
      this.carNos.forEach((car) => {
        if (this.form.prodId === car.code) {
          this.enabledDistance = car.enabledDistance
        }
      })
      this.resetPrice()
      // 
    }
  }
}
</script>
<style scoped>
.amt-red {
  color:red;
}
.amt {
  color:#6e757c;
}
</style>
