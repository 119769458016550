<template>
  <div>
    <!--新增 -->
    <div v-show="mode == 'create'">
      店家
      <el-select v-model="cStoreId">
        <el-option width=120 
        v-for="item in stores" 
        :key="item.code" 
        :label="item.disName" 
        :value="item.code">
        </el-option>
      </el-select>
      排程名稱<el-input style="width:200px;" v-model="cDisName"></el-input>
      <br/>
      <br/>排程日期
      <el-date-picker
        v-model="sd"
        type="date"
        format="yyyy/MM/dd"
        placeholder="起始日期">
      </el-date-picker>
      ~
      <el-date-picker
        v-model="ed"
        type="date"
        format="yyyy/MM/dd"
        placeholder="結束日期">
      </el-date-picker>
      
      初始群組<el-input v-model="cGroupQty" style="width:120px;"/>組
      <br/><br/>
      <el-button @click="createBookingSchedule()" type="primary">確定新增</el-button>
      <el-button v-show="mode != 'view'" @click="mode='view'" type="primary">退出</el-button>
    </div>
    <!--主頁面 -->
    <div v-show="mode == 'view'">
      
      <br/><el-row >
        <el-col :span="7">店家
      <el-select v-model="q.storeId" filterable>
        <el-option width=120 
        v-for="item in stores" 
        :key="item.code" 
        :label="item.disName" 
        :value="item.code">
        </el-option>
      </el-select>
        </el-col>
      <el-col :span="10">
       
       
       <el-input placeholder="排程名稱" v-model='q.bsDisname'><template slot="prepend">排程名稱</template></el-input>
       </el-col>
<!--        
       <el-select v-model="q.bookingScheduleId">

        <el-option width=120 
        v-for="item in bookingSchedules" 
        :key="item.rowId" 
        :label="item.disName" 
        :value="item.rowId">
        </el-option>
       </el-select> -->

      <el-button style="margin-left:10px;" @click="query()" type="primary">搜尋</el-button>
      <el-button @click="mode='create'" type="primary">新增</el-button>
      </el-row>
      <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <!-- <el-table-column   prop="rowId"  label="rowId">  </el-table-column> -->
        <el-table-column   prop="storeDisName"  width="160px" label="店家">  </el-table-column>
        <el-table-column   prop="disName"  label="排程名稱">  </el-table-column>
        <el-table-column   prop="sDate" width="180px" label="起始日期">  </el-table-column>
        <el-table-column   prop="eDate" width="180px" label="結束日期">  </el-table-column>
        <el-table-column   fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
            <el-button  @click="getBookingScheduleDetail(scope.row)" type="primary" size="small">編輯</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
      </el-card>

    
    
    </div>
    <!--編輯-->
    <div v-show="mode == 'edit'">
      <br/>
      店家:{{q.storeDisName}} 起始時間:{{this.editBookingSchedule.sDate}}~ 結束時間:{{this.editBookingSchedule.eDate}} 
      群組數: {{editBookingSchedule.groupQty}}組
      
      <el-button style="margin-left:10px;" @click="editGroupDetail" type="primary">編輯群組名稱</el-button>  
      <br/>
      <br/>
      <el-radio-group v-model="sortType" style="margin-top:10px;" @change="refreshBookingSchedule">
        <el-radio-button label="訂車時間先"></el-radio-button>
        <el-radio-button label="取車時間先"></el-radio-button>
        <el-radio-button label="還車時間先"></el-radio-button>
        <el-radio-button label="出租最長時間先"></el-radio-button>
        <el-radio-button label="出租最短時間先"></el-radio-button>
      </el-radio-group>
      顏色表示: 
      &nbsp;&nbsp;&nbsp;<span class="exist-wait">未付訂金</span>
      &nbsp;&nbsp;&nbsp;<span class="exist">已付訂金</span>
      &nbsp;&nbsp;&nbsp;<span class="exist-ing">騎乘中</span>
      &nbsp;&nbsp;&nbsp;<span class="exist-finish">訂單完成</span>
      <table style="border-collapse: collapse;margin-top:10px;" v-show="clientOrderQty > 0">
        <thead>
          <tr style="border:1px black solid;"><td></td><td></td><td v-for="d in days" :key="d.key" colspan="24" style="border:1px black solid;"><span v-if="d.day">{{d.dayTitle}}</span></td></tr>
          <tr style="border:1px black solid;"><td colspan="2">姓名/時段</td><td v-for="d in cols" :key="d.key" class="date"><div style="width:10px;text-align:center;">{{d.title}}</div></td></tr>
        </thead>
        <tbody>
          <tr style="border:1px black solid;" v-for="d in rows" :key="d.clientOrderId" ref="rows" >
            <td style="border-right:1px black solid;"><div style="width:100px;">{{d.bookingScheduleGroupDisName}}</div></td>
            <td style="border-right:1px black solid;cursor: pointer;" @click="showClientOrder(d)"><div style="width:150px;color:blue;">{{d.disName}}</div></td>
            <td style="" v-for="(td,index) in d.tds" 
              :key="td.key" 
              @mouseenter="mouseEnter($event,index)" 
              @mouseleave="mouseLeave($event,index)"
              :ref="td.refId"
              :class="td.style">
              <!-- <span v-if="td.exist">-</span> -->
            </td>
          </tr>
          <tr style="border:1px black solid;" v-for="(d) in emptyGroupsRow" :key="d.rowId">
            <td style="border-right:1px black solid;" colspan="2"><div style="width:100px;">{{d.disName}}</div></td>
            <td  v-for="(td) in timeColQty" 
              :key="td">
            </td>
          </tr>

          <tr style="border:1px black solid;">
            <td colspan="2">總計:{{clientOrderQty}}張單</td>
            <td class="date" :style="total.style" v-for="total in totals" :key="total.key" :ref="total.refId">
              <span v-if="total.qty!=0">{{total.qty}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="text-align:right"><br><el-button style="margin-left:10px;" @click="mode='view'" type="primary">退出</el-button>   </div>
    </div>
    <!-- 訂單編輯 -->
    <el-dialog
      title="訂單"
      :close-on-click-modal='false'
      :visible.sync="dialogClientOrderVisible"
      width="50%">
      <el-row>
        <el-col :span="4">客戶帳號:</el-col>
        <el-col :span="8">{{this.editClientOrder.clientUserId}}</el-col>
        <el-col :span="4">客戶名稱:</el-col>
        <el-col :span="8">{{this.editClientOrder.disName}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">訂單號:</el-col>
        <el-col :span="8">{{this.editClientOrder.clientOrderId}}</el-col>
        <el-col :span="4">電話:</el-col>
        <el-col :span="8">{{this.editClientOrder.phone}}</el-col>        
      </el-row>
      <br/>
      新的群組名稱
      <el-select v-model="dialogNewGroup">
        <el-option v-for="d in gs" :key="d.rowId" :label="d.disName" :value="d.rowId"></el-option>
      </el-select>      
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogClientOrderVisible = false">退出</el-button>
      </span>
      <el-button type="primary" @click="moveGroupDisName()">移動群駔</el-button>
    </el-dialog>
    
    <!-- 群組編輯 -->
    <el-dialog
      title="編輯群組"
      :close-on-click-modal='false'
      :visible.sync="dialogVisible"
      width="50%">
      <el-divider content-position="left">新增群組名稱</el-divider>
      <div style="color:red;">目前不可刪除群組,只可新增群組</div>
      群組名稱<el-input v-model="newGroupDisName" style="width:200px;"></el-input>
      <el-button @click="createGroupDisName" style="margin-left:10px;" type="primary">新增群組</el-button>
      
      <el-divider content-position="left">編輯群組名稱</el-divider>
      <table>
        <tbody>
          <tr v-for="g in gs" :key="g.rowId">
            <td><el-input v-model="g.disName"/></td><td><el-button type="primary" @click="updateGroupDisName(g)">更新</el-button></td>
          </tr>
        </tbody>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">退出</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
      editClientOrder:{clientOrderId:'',disName:'',phone:'',storeDisName:''},
      dialogNewGroup:null,
      timeColQty:0,
      emptyGroupsRow:[],//未被指派的群組
      newGroupDisName:null,
      gs:[],
      dialogVisible:false,
      dialogClientOrderVisible:false,
      q:{storeId:null,bookingScheduleId:null,storeDisName:'',bookingScheduleDisName:'',bsDisname:''},
      cStoreId:'', //新增的店家
      cDisName:'', //新增的排成名稱
      cGroupQty:'1',
      stores:[],
      mode:'view',
      clientOrderQty:0,
      sortType:'訂車時間先',
      sd: new Date(),
      ed: new Date(),
      bookingSchedules:[],
      cols:[],
      days:[],
      pageTotal:0,
      pageCurrent:1,
      totals:[], //總數統計
      rows: [],
      ds:[],
      exists:{},
      tableData:[],
      editBookingSchedule:{sDate:null,eDate:null,storeDisName:null,rowId:null,groupQty:null,disName:null},
      
    }
  },
  name: 'BookingDate',
  created(){
    this.$axios
      .get('../api/params/getParams',{})
      .then((res)=>{
        this.$store.dispatch('login',res.data)
        this.stores.push({
          code:'',
          disName:'全部'
        })
        res.data.authStores.forEach(e => {
          this.stores.push(e)
        });
        this.q.storeId=this.stores[0].code
      }).catch((res)=>{
        if(res.response.status==401){
          window.location = '/';
        }
    });   

    this.sd=moment({hour: 0, minute: 0, seconds: 0, milliseconds: 0})
    this.ed=moment({hour: 0, minute: 0, seconds: 0, milliseconds: 0})
    let after=moment(this.ed).add(7, 'days')
    this.ed=after.toDate()
    this.query()

    // for test data
    // this.ds.push({clientOrderId:'網名頁(已取車)',sd:'2021030701',ed:'2021030702'})
    // this.ds.push({clientOrderId:'中文字',sd:'2021030701',ed:'2021031014'})
    // this.ds.push({clientOrderId:'姓名(待確認)',sd:'2021030705',ed:'2021030819'})
    // this.ds.push({clientOrderId:'4Tim',sd:'2021030708',ed:'2021030716'})
    // this.ds.push({clientOrderId:'ABC',sd:'2021030702',ed:'2021030719'})
    // this.ds.push({clientOrderId:'人名',sd:'2021030717',ed:'2021030810'})
    // this.ds.push({clientOrderId:'網小紅',sd:'2021030712',ed:'2021030901'})
    // end for test data
  },
  components: {

  },
  methods :{
    createBookingSchedule(){
      if (this.cDisName === '') {
        this.$alert('請輸入排程名稱','結果',{confirmButtonText:'確定'})
        return
      }
      if (this.cStoreId === '') {
        this.$alert('請選擇店家','結果',{confirmButtonText:'確定'})
        return
      }
      let url = '../api/bookingStatus/createBookingSchedule'
      let pa = {disName:this.cDisName,
      storeId:this.cStoreId,
      sDate:moment(this.sd).format('YYYYMMDD'),
      eDate:moment(this.ed).format('YYYYMMDD'),
      groupQty:this.cGroupQty}
      this.$axios
        .post(url, pa)
        .then((res) => {
          if(res.data.rs =='success'){
            this.mode = 'view'
            this.cDisName = ''
            this.query()
          }else{
            this.$alert(res.data.msg, '結果', { confirmButtonText: '確定' });
          }
          this.dialogFormVisible = false
        }).catch((res) => {
          this.$alert('傳送失敗', '結果', { confirmButtonText: '確定' });
          console.log(res.data);
        });
    },
    createGroupDisName(){
      let param={bookingScheduleId:this.editBookingSchedule.rowId,
        disName:this.newGroupDisName}
      let url = '../api/bookingStatus/createGroupDisName'
      this.$confirm('是否卻盯新增群組','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url, param)
          .then(() => {
              this.refreshBookingSchedule()
              this.dialogVisible=false
          }).catch((res) => {
            this.$alert('傳送失敗', '結果', { confirmButtonText: '確定' });
            console.log(res.data);
          });
      })
    },
    showClientOrder(clientOrder){
      this.dialogNewGroup =clientOrder.bookingScheduleGroupRowId
      this.editClientOrder.disName = clientOrder.disName
      this.editClientOrder.clientUserId = clientOrder.clientUserId
      this.editClientOrder.phone = clientOrder.phone
      this.editClientOrder.clientOrderId = clientOrder.clientOrderId
      this.editClientOrder.bookingScheduleGroupRowId =clientOrder.bookingScheduleGroupRowId
      this.stores.forEach(e=>{
        if(e.code == this.q.storeId){
          this.editClientOrder.storeDisName = e.disName
          return
        }
      })
      this.dialogClientOrderVisible = true
    },
    moveGroupDisName(){
      this.$confirm('是否確定移動群組','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        let group={}
        group.clientOrderId = this.editClientOrder.clientOrderId
        group.groupId =this.dialogNewGroup
        group.oldGroupId = this.editClientOrder.bookingScheduleGroupRowId == null ? '' : this.editClientOrder.bookingScheduleGroupRowId
        let url = '../api/bookingStatus/moveGroupDisName'
        this.$axios
          .post(url, group)
          .then((res) => {
            if(res.data.rs=='success'){
              this.refreshBookingSchedule()
              this.dialogClientOrderVisible=false
            }else{
              this.$alert(res.data.msg, '結果', { confirmButtonText: '確定' });
            }
          }).catch((res) => {
            this.$alert('傳送失敗', '結果', { confirmButtonText: '確定' });
            console.log(res.data);
          });
      })
    },
    updateGroupDisName(group){
      this.$confirm('是否確定更新','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        let url = '../api/bookingStatus/updateBookingScheduleGroupDisName'
        this.$axios
          .post(url, group)
          .then((res) => {
            if(res.data.rs=='success'){
              this.refreshBookingSchedule()
              this.dialogVisible=false
            }else{
              this.$alert(res.data.msg, '結果', { confirmButtonText: '確定' });
            }
          }).catch((res) => {
            this.$alert('傳送失敗', '結果', { confirmButtonText: '確定' });
            console.log(res.data);
          });
      })
    },

    changeStore(){
      if(this.q.storeId==null){
        return
      }
      
      this.q.bookingScheduleId=null
      this.bookingSchedules.splice(0,this.bookingSchedules.length)
      let form={qStoreId:this.q.storeId}
      this.$axios
        .get('../api/bookingStatus/getBookingSchedule',
          {params:form})
        .then((res) => {
          res.data.forEach(e=>{
            this.bookingSchedules.push(e)
            if(this.bookingSchedules.length>0){
              this.q.bookingScheduleId = this.bookingSchedules[0].rowId
            }
          })
        }).catch((res) => {
          console.log(res)
        });

    },
    mouseLeave($event,idx){
      let className = ''
      $event.currentTarget.className = className
      this.ds.forEach(e=>{
        if(this.exists[e.clientOrderId+'-'+idx] != undefined){
          this.$refs[e.clientOrderId+'-'+idx][0].className = this.exists[e.clientOrderId+'-'+idx]
        }else{
          this.$refs[e.clientOrderId+'-'+idx][0].className = className
        }
      })
      this.$refs['totalCell-'+idx][0].className = 'date'
      // console.log(row)
    },
    mouseEnter($event,idx){
      let className = 'move'
      $event.currentTarget.className = 'move'
      this.ds.forEach(e=>{
        this.$refs[e.clientOrderId+'-'+idx][0].className = className
      })
      this.$refs['totalCell-'+idx][0].className = 'move'
    },
    refreshBookingSchedule(){
      this.getBookingScheduleDetail(this.editBookingSchedule)
    },
    getBookingScheduleDetail (bookingSchedule) {
      this.editBookingSchedule=bookingSchedule
      this.q.qBookingScheduleId = bookingSchedule.rowId
      this.bookingSchedules.forEach(e=>{
        if(this.q.bookingScheduleId == e.rowId){
          this.editBookingSchedule = Object.assign(this.editBookingSchedule,{},e)
          return
        }
      })
      this.stores.forEach(e=>{
        if(e.code == bookingSchedule.storeId){
          this.q.storeDisName = e.disName
          return
        }
      })
      this.editBookingSchedule.sDate=bookingSchedule.sDate
      this.editBookingSchedule.eDate=bookingSchedule.eDate
      this.editBookingSchedule.groupQty=bookingSchedule.groupQty
      

      this.mode = 'edit'
      let form2={qSortType:this.sortType,qBookingScheduleId:bookingSchedule.rowId}
      this.$axios
        .get('../api/bookingStatus/getBookingScheduleDetail',
          {params:form2})
        .then((res) => {
          this.editBookingSchedule=res.data.bookingSchedule
          let cos=res.data.cos
          cos.sort((a,b)=>{
            if(a.bookingScheduleGroupRowId==null){
              return -1
            }else if(b.bookingScheduleGroupRowId==null){
              return 1
            }else{
              return a.bookingScheduleGroupRowId-b.bookingScheduleGroupRowId
            }
          })

          //判斷群組
          this.ds.splice(0,this.ds.length)
          let existGroup={}
          cos.forEach(e=>{
            e.sd=e.sDate.substring(0,10)
            e.ed=e.eDate.substring(0,10)
            this.ds.push(e)
            if(e.bookingScheduleGroupRowId!=null){
              existGroup[e.bookingScheduleGroupRowId]=true
            }
          })
          let bookingSchedule=res.data.bookingSchedule
          this.gs.splice(0,this.gs.length)
          this.emptyGroupsRow.splice(0,this.emptyGroupsRow.length)

          //計算框格欄位
          let start=moment(bookingSchedule.sDate,'YYYYMMDD')
          let colInx={}
          let day= moment(moment(bookingSchedule.eDate,'YYYYMMDD')).diff(start, 'days')
          this.cols.splice(0,this.cols.length)
          this.days.splice(0,this.days.length)
          this.totals.splice(0,this.totals.length)
          this.exists={}
          this.clientOrderQty=cos.length

          day++
          let maxRentQty=0;
          let colNum=day * 24 //小時數量
          this.timeColQty = colNum
          for(let i=0 ; i< colNum ; i++){
            this.totals.push({code: i ,qty: 0, refId: ('totalCell-'+i)})  
            let col={}
            let title=''
            let dayTitle=''
            if(i%24 ==0){
              if(i != 0){
                start.add(1,'day')
              }
              dayTitle=start.format('MM/DD')
              col.day=true
              col.dayTitle=dayTitle
              this.days.push(col)
            }else{
              col.hour=true
            }
            title=i%24
            col.title=title

            let key=start.format('YYYYMMDD')
            
            if(i%24<10){
              key=key+'0'+i%24
            }else{
              key=key+i%24
            }

            col.key= key
            colInx[i]=key

            this.cols.push(col)
          }
          this.rows.splice(0,this.rows.length)
          this.ds.forEach(d=>{
            // add row
            let sd=d.sd;
            let ed=d.ed;
            d.tds=[]
            for(let td=0;td<colNum;td++){
              let cellVal = colInx[td]
              let tdCell = {key: (d.clientOrderId+td)}
              // console.log(parseInt(sd) +'   '+ parseInt(cellVal) +'   '+ parseInt(ed))
              if(parseInt(sd) <= parseInt(cellVal) && parseInt(ed) >= cellVal) {
                tdCell.exist = true
                
                this.totals[td].qty = this.totals[td].qty + 1
                if(maxRentQty < this.totals[td].qty){
                  maxRentQty = this.totals[td].qty
                }
                //這邊用來判斷 訂單狀態 和顏色
                if (/^0|10$/.test(d.flow)) {
                  this.exists[d.clientOrderId + '-' + td] = 'exist-wait'
                  tdCell.style = ['exist-wait'];
                }else if (/^50$/.test(d.flow)) {
                  this.exists[d.clientOrderId + '-' + td] = 'exist'
                  tdCell.style = ['exist'];
                }else if (/^100$/.test(d.flow)) {
                  this.exists[d.clientOrderId + '-' + td] = 'exist-ing'
                  tdCell.style = ['exist-ing'];
                }else{
                  this.exists[d.clientOrderId + '-' + td] = 'exist-finish'
                  tdCell.style = ['exist-finish'];
                }
              }else{
                tdCell.style = {};
              }
              tdCell.refId = d.clientOrderId + '-' + td
              d.tds.push(tdCell)
            }
            this.rows.push(d)
          })
          
          //計算加總欄位
          // console.log(this.totals)
          let range= Math.round(  (255 / maxRentQty) )-1;
          this.totals.forEach(e=>{
            if(e.qty != 0){
              let color = 255 - range*e.qty
              e.style="border:1px black solid;background-color:rgb(255,"+color+","+color+")"
            }else{
              e.style='border:1px black solid;'
            }
            // e.style='border-left:1px black solid;'
          })
          
          //計算空群組資料
          bookingSchedule.groups.forEach(e=>{
            this.gs.push(e)
            if(!existGroup[e.rowId]){
              this.emptyGroupsRow.push(e)
            }
          })

          //
        }).catch((res) => {
          console.log(res)
        });
    },
    query(){
      let qParams = {
        page: this.pageCurrent,
        storeId: this.q.storeId,
        bookingScheduleId: this.q.bookingScheduleId,
        bsDisname: this.q.bsDisname
      }
      this.$axios
      .get('../api/bookingStatus/getBookingStatus',{params:qParams})
      .then((res)=>{
        this.tableData.splice(0,this.tableData.length)
        res.data.data.forEach(e=>{
          e.sDate=moment(e.sDate).format('YYYY/MM/DD')
          e.eDate=moment(e.eDate).format('YYYY/MM/DD')
          this.tableData.push(e)
        })
        this.pageTotal=res.data.pageTotal
      }).catch((res)=>{
        console.log(res.response.status)
        this.$alert('login error', '結果', { confirmButtonText: '確定' });
      })
    },
    editGroupDetail () {
      
      this.dialogVisible = true
      this.newGroupDisName = ''

    }
  }
}
</script>
<style >
.date {
  border-left:1px rgb(0, 0, 0) solid;
  font-size: 8px;
}
.move{
  /* font-size: 8px; */
  background-color:rgb(251, 169, 74);
}
.exist{
  background-color:rgb(255, 251, 0);
}
.exist-wait{
  background-color:rgb(255, 65, 65);
}
.exist-ing{
  background-color:rgb(109, 211, 61);
}
.exist-finish{
  background-color:rgb(86, 145, 255);
}

</style>
