<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="grid-content bg-purple"></div>
          <el-input placeholder="店號" v-model="qStoreId">
            <template slot="prepend">店號</template>
          </el-input>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple"></div>
          <el-input placeholder="店名" v-model="qDisName">
            <template slot="prepend">店名</template>
          </el-input>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-col :span="6">
          <span>區域: </span>
          <el-select v-model="qAreaId" placeholder="">
            <el-option
              v-for="item in qAreaIdSelect"
              :key="item.code"
              :label="item.label"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <span>狀態: </span>
          <el-select v-model="qStatus" placeholder="">
            <el-option
              v-for="item in qStatusSelect"
              :key="item.code"
              :label="item.label"
              :value="item.code">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column prop="storeId" label="店號" width="60"></el-table-column>
        <el-table-column prop="disName" label="店名"> </el-table-column>
        <el-table-column prop="frontDisName" label="前台顯示名稱"> </el-table-column>
        <el-table-column prop="areaId" label="區域" width="120" :formatter="fter_area"></el-table-column>
        <el-table-column prop="status" label="狀態" width="80" :formatter="fter_status"></el-table-column>
        <el-table-column prop="phone" label="電話"> </el-table-column>
        <!-- <el-table-column   prop="stockQty" label="車數">  </el-table-column> -->
        <el-table-column fixed="right" label="操作" width="380" >
          <template slot-scope="scope">
            <el-button @click="showQRCodeDetailForm(scope.row)" type="primary" size="mini" >QRCode</el-button>
            <el-button @click="showDetailForm(scope.row)" type="primary" size="mini">編輯</el-button>
            <el-button @click="showNotifySettingForm(scope.row)" type="primary" size="mini" >通知設定</el-button>
            <el-button @click="showServiceDateSettingForm(scope.row)" type="primary" size="mini" >營業時間設定</el-button>
            <div style="margin-top:3px"></div>
            <el-button @click="showHolidaySettingForm(scope.row)" type="primary" size="mini">假日設定</el-button>
            <el-button v-show="false" @click="showPicDetailForm(scope.row)" type="warning" size="mini">前台照片</el-button>
            <el-button  @click="showFrontInsuranceDescForm(scope.row)" type="primary" size="mini">編輯保障說明</el-button>
            <el-button  @click="showRefundRuleDescForm(scope.row)" type="primary" size="mini">編輯退費說明</el-button>
            <div style="margin-top:3px"></div>
            <el-button @click="showProdStockDaySettingForm(scope.row)" type="primary" size="mini" >庫存設定</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal"
        :current-page.sync="pageCurrent"
        @current-change="query"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="編輯視窗" :close-on-click-modal="false" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="editForm">
        <el-form-item label="店號" :label-width="formLabelWidth">
          {{ form.storeId }}
        </el-form-item>
        <el-form-item label="狀態" :label-width="formLabelWidth">
          <el-select v-model="form.status" placeholder="狀態">
            <el-option width="120" v-for="item in status" :key="item.code" :label="item.disName" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收費方式" :label-width="formLabelWidth">
          <el-select v-model="form.priceType" placeholder="收費方式">
            <el-option width="120" v-for="item in priceTypes" :key="item.code" :label="item.disName" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否為無人門市" :label-width="formLabelWidth">
          <el-switch v-model="form.isNoStaffStore" active-color="#13ce66" inactive-color="#ff4949" active-value="T" inactive-value="F"></el-switch>
        </el-form-item>
        <el-form-item v-show="form.isNoStaffStore === 'T'" label="是否開放外國人現場租車" :label-width="formLabelWidth">
          <el-switch v-model="form.isForeignRentOnSite" active-color="#13ce66" inactive-color="#ff4949" active-value="T" inactive-value="F"></el-switch>
        </el-form-item>
        <el-form-item label="是否可營業時間外還車" :label-width="formLabelWidth">
          <el-switch v-model="form.outsideServiceReturn" active-color="#13ce66" inactive-color="#ff4949" active-value="T" inactive-value="F"></el-switch>
        </el-form-item>
        <el-form-item label="取車檢查證件" :label-width="formLabelWidth">
          <el-radio v-model="form.checkOnlyClientLicense" label="L">只檢查駕照</el-radio>
          <el-radio v-model="form.checkOnlyClientLicense" label="ID">只檢查身分證</el-radio>
          <el-radio v-model="form.checkOnlyClientLicense" label="F">全部檢查</el-radio>
        </el-form-item>
        <el-form-item label="不委託摩拓開立電子發票" :label-width="formLabelWidth">
          <el-switch v-model="form.withoutMofunInvoiceSwitch" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item>
        <el-form-item label="取車時付全額款" :label-width="formLabelWidth">
          <el-switch v-model="form.payEndPriceAtRentSwitch" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item>
        <el-form-item label="客戶端檢查員工碼" :label-width="formLabelWidth">
          <el-switch v-model="form.checkAdminUserCodeSwitch" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item>
        <el-form-item label="是否販售保障" :label-width="formLabelWidth">
          <el-switch v-model="form.saleInsuranceAbled" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item>
        <el-form-item label="是否開放24小時內預約" :label-width="formLabelWidth">
          <el-switch v-model="form.oneDayRentAble" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item>
        <el-form-item label="是否可提供前台預約" :label-width="formLabelWidth">
          <el-switch v-model="form.isFrontRentable" active-color="#13ce66" inactive-color="#ff4949" active-value="T" inactive-value="F"></el-switch>
        </el-form-item>
        <el-form-item label="店名" :label-width="formLabelWidth" prop="disName">
          <el-input v-model="form.disName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="pdf顯示名稱" :label-width="formLabelWidth" prop="disName">
          <el-input v-model="form.pdfDisName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="前台顯示名稱" :label-width="formLabelWidth" prop="disName">
          <el-input v-model="form.frontDisName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="支付方式" :label-width="formLabelWidth">
          <el-checkbox v-model="form.payCash">現金支付</el-checkbox>
          <el-checkbox v-model="form.payCredit">信用卡</el-checkbox>
          <el-checkbox v-model="form.payLINE">LINE PAY</el-checkbox>
          <el-checkbox v-model="form.payIcp">iCash Pay</el-checkbox>
        </el-form-item>
        <el-form-item label="通知Email(空白則不寄送)" :label-width="formLabelWidth">
          <el-input v-model="form.alertEmail" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="聯絡電話" :label-width="formLabelWidth" prop="phone">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="地址" :label-width="formLabelWidth" prop="addr">
          <el-input v-model="form.addr" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="區域" :label-width="formLabelWidth">
          <el-select v-model="form.areaId" placeholder="區域">
            <el-option
              width="120"
              v-for="item in areas"
              :key="item.code"
              :label="item.label"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="群組" :label-width="formLabelWidth">
          <el-select v-model="form.storeGroupId" placeholder="群組">
            <el-option
              width="120"
              v-for="item in storeGroups"
              :key="item.code"
              :label="item.label"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="地點" :label-width="formLabelWidth">
          <el-col :span="2"> lat:</el-col>
          <el-col :span="6">
            <el-input v-model="form.gpsLat" autocomplete="off"></el-input>
          </el-col>
          <el-col :span="2"> lng:</el-col>
          <el-col :span="6">
            <el-input v-model="form.gpsLng" autocomplete="off"></el-input>
          </el-col>
        </el-form-item>
        <!-- -----------------------合約------------------------------ -->
      <div v-show="isShowContractUpload">
        <el-form-item label="合約" :label-width="formLabelWidth">
          <el-col :span="10">
            <el-button type="primary" @click.native="downloadContract">下載</el-button>
          </el-col>
          <el-col :span="10">
            <el-button type="primary" @click.native="uploadContract">上傳</el-button>
            <input type="file" ref="files" @change="selectContract" accept=".pdf">
          </el-col>
        </el-form-item>
        <el-form-item label="長租合約" :label-width="formLabelWidth">
          <el-col :span="10">
            <el-button type="primary" @click.native="downloadLongRentContract">下載</el-button>
          </el-col>
          <el-col :span="10">
            <el-button type="primary" @click.native="uploadLongRentContract">上傳</el-button>
            <input type="file" ref="lontRentfiles" @change="selectLongRentContract" accept=".pdf">
          </el-col>
        </el-form-item>
        <!-- <el-form-item label="官網描述" :label-width="formLabelWidth">
          <el-input v-model="form.descp" maxlength="200" show-word-limit type="textarea"></el-input>
        </el-form-item> -->
      </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditStore">確 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="假日編輯視窗" :visible.sync="dialogHolidayFormVisible"  width="90%" :close-on-click-modal="false">
      <div>
          <el-button type="primary" @click="selectDefaultHoliday()">套用六日</el-button>
        <table>
          <thead><tr><td class="holiday-th">一</td><td class="holiday-th">二</td><td  class="holiday-th">三</td><td  class="holiday-th">四</td><td  class="holiday-th">五</td><td  class="holiday-th">六</td><td  class="holiday-th">日</td></tr></thead>  
          <tbody>
            <tr v-for="(week,index) in storeDays" :key="index">
              <td :style="day.style" v-for="day in week" :key="day.key">
                <el-checkbox v-if="day.id!== undefined" :label="day.id" v-model="day.select"></el-checkbox>
              </td>
            </tr>
          </tbody>
        </table>  
      </div>      
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogHolidayFormVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmEditHoliday()">儲存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="線上庫存表" :visible.sync="dialogProdStockDayVisible"  width="90%" :close-on-click-modal="false">
      <div>
        <el-row>
        年<el-select v-model="stockYear">
          <el-option key="2022" value="2022" label="2022"></el-option>
          <el-option key="2023" value="2023" label="2023"></el-option>
          <el-option key="2024" value="2024" label="2024"></el-option>
          </el-select>
        月份<el-select v-model="stockMonth">
          <el-option key="01" value="01" label="1月份"></el-option>
          <el-option key="02" value="02" label="2月份"></el-option>
          <el-option key="03" value="03" label="3月份"></el-option>
          <el-option key="04" value="04" label="4月份"></el-option>
          <el-option key="05" value="05" label="5月份"></el-option>
          <el-option key="06" value="06" label="6月份"></el-option>
          <el-option key="07" value="07" label="7月份"></el-option>
          <el-option key="08" value="08" label="8月份"></el-option>
          <el-option key="09" value="09" label="9月份"></el-option>
          <el-option key="10" value="10" label="10月份"></el-option>
          <el-option key="11" value="11" label="11月份"></el-option>
          <el-option key="12" value="12" label="12月份"></el-option>
        </el-select>
        <el-button @click="getProdStockDayData()">查詢</el-button>
        <el-button type="danger" @click="resetOneYersStock()">載入實際庫存</el-button>
        </el-row>
        <table style="border-collapse: collapse;margin-top:10px;overflow-x:auto;white-space: nowrap;display: block;">
        <thead>
          <tr style="border:1px black solid;"><td style="position:sticky;left:0;z-index:1;background-color:white;"><div style="width:160px;">車種/日期</div></td>
          <td v-for="d in dateList" :key="d.key" style="border:1px black solid;">{{d}}</td></tr>
        </thead>
        <tbody>
          <tr style="border:1px black solid;" v-for="row in prodStockList" :key="row.prodStockId" >
            <td style="border:1px black solid;position:sticky;left:0;z-index:1;background-color:white;" >
              <div style="width:160px;">{{row.prodStockDisName}}</div>
              <el-button size="mini" type="primary" @click="changeAllStock(1,row.tds)">+1</el-button>
              <el-button size="mini" type="danger" @click="changeAllStock(-1,row.tds)">-1</el-button>
            </td>
            <td style="border-right:1px black solid;width:160px;" align="center" v-for="td in row.tds"  :key="td.day">
              {{td.orderQty}}/<el-input v-model="td.stock" type='number' style="width:70px" size="mini"></el-input>
            </td>
          </tr>

        </tbody>
      </table>
      </div>

 
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogProdStockDayVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmEditProdStockDay()">儲存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="照片設定" :visible.sync="dialogPicVisible">
      <el-row :gutter="20">
        官網照片<a v-if="pic" target="_blank" :href="pic">(開啟大圖)</a>
        <el-upload
          :action="url"
          class="avatar-uploader"
          :show-file-list="false"
          :on-success="handlePictureCardPreview"
        >
          <img v-if="pic" :src="pic" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="backUploadDialog">退出</el-button>
      </div>
    </el-dialog>
    <el-dialog title="保障說明編輯視窗" :visible.sync="dialogFrontDescVisible"  width="90%" :close-on-click-modal="false">
      <el-input type="textarea" placeholder="保障說明" rows="10" v-model="form.frontInsuranceDesc" maxlength="500" show-word-limit />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFrontDescVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditFrontInsuranceDesc()">確 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="退費說明編輯視窗" :visible.sync="dialogFrontRefundRuleDescVisible"  width="90%" :close-on-click-modal="false">
      <el-input type="textarea" placeholder="保障說明" rows="10" v-model="form.frontRefundRuleDesc" maxlength="500" show-word-limit />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFrontRefundRuleDescVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditRefundRuleDesc()">確 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="通知設定" :close-on-click-modal="false" :visible.sync="dialogNotifyVisible" width="90%">
      <el-form :model="form" ref="notifyForm">
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">
            訂單新增提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.create" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
          <el-col :span="6">
            訂單取消提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.cancel" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">
            訂金付款提醒(信用卡)
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.creditDeposit" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F">
            </el-switch>
          </el-col>
          <el-col :span="6">
            訂金付款提醒(匯款)
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.transDeposit" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
          
        </el-row>

        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">
            車輛出租提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.prodGet" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
          <el-col :span="6">
            車輛歸還提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.prodReturn" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">
            租借逾時30分鐘提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.timeout30Min" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
          <el-col :span="6">
            租借逾時48小時提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.timeout2Days" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
        </el-row>

        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">
            車輛保險到期提醒
          </el-col>
          <el-col :span="6">
            <el-switch
              v-model="notify.insuranceDue" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
          <el-col :span="6">
            定價方案更變提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.priceChange" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">
            車輛未租借使用提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.meterMove" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
          <el-col :span="6">
            遠距離使用車輛提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.overDistance" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">
            里程數異常提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.distanceAbnormal" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
          <el-col :span="6">
            逾期未取車提醒
          </el-col>
          <el-col :span="6">
            <el-switch
              v-model="notify.rentDelay" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top:10px;">
          <el-col :span="6">
            客戶低評價提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.lowRate" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
          <el-col :span="6">
            車輛保養提醒
          </el-col>
          <el-col :span="6">
            <el-switch v-model="notify.maintain" active-color="#13ce66" inactive-color="#ff4949"
              active-value="T" inactive-value="F"></el-switch>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" style="margin-top:10px;">
       <el-col :span="6">
          編輯金額提醒
        </el-col>
        <el-col :span="6">
          <el-switch v-model="notify.modifyPrice" active-color="#13ce66" inactive-color="#ff4949"
            active-value="T" inactive-value="F"></el-switch>
        </el-col> 
      </el-row>
      <el-row :gutter="20" style="margin-top:10px;">
       <el-col :span="6">
          單日取車數量提醒
        </el-col>
        <el-col :span="6">
          <el-switch v-model="notify.flow50Count" active-color="#13ce66" inactive-color="#ff4949"
            active-value="T" inactive-value="F"></el-switch>
        </el-col> 
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogNotifyVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditNotifySetting"
          >確 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="營業時間設定" :close-on-click-modal="false" :visible.sync="dialogServiceDateSettingVisible" width="90%">
      <el-form>
        <el-row :gutter="10">
          <el-col :span="4" v-html="'&nbsp'"></el-col>
          <el-col :span="8" style="text-align:center">營業起始時間</el-col>
          <el-col :span="8" style="text-align:center">營業結束時間</el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px;height:35px">
          <el-col :span="4" style="margin-top:8px;text-align:center;font-size:130%">星期一</el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek1"
            v-model="serviceDate.dayOfWeek1.serviceStartDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek1"
            v-model="serviceDate.dayOfWeek1.serviceEndDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="16" v-html="'&nbsp'" v-show="isOutOfService.dayOfWeek1"></el-col>
          <el-col :span="4" style="margin-top:10px"><el-checkbox v-model="isOutOfService.dayOfWeek1" >休假</el-checkbox></el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px;height:35px">
          <el-col :span="4" style="margin-top:8px;text-align:center;font-size:130%">星期二</el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek2"
            v-model="serviceDate.dayOfWeek2.serviceStartDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek2"
            v-model="serviceDate.dayOfWeek2.serviceEndDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="16" v-html="'&nbsp'" v-show="isOutOfService.dayOfWeek2"></el-col>
          <el-col :span="4" style="margin-top:10px"><el-checkbox v-model="isOutOfService.dayOfWeek2">休假</el-checkbox></el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px;height:35px">
          <el-col :span="4" style="margin-top:8px;text-align:center;font-size:130%">星期三</el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek3"
            v-model="serviceDate.dayOfWeek3.serviceStartDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek3"
            v-model="serviceDate.dayOfWeek3.serviceEndDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="16" v-html="'&nbsp'" v-show="isOutOfService.dayOfWeek3"></el-col>
          <el-col :span="4" style="margin-top:10px"><el-checkbox v-model="isOutOfService.dayOfWeek3">休假</el-checkbox></el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px;height:35px">
          <el-col :span="4" style="margin-top:8px;text-align:center;font-size:130%">星期四</el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek4"
            v-model="serviceDate.dayOfWeek4.serviceStartDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek4"
            v-model="serviceDate.dayOfWeek4.serviceEndDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="16" v-html="'&nbsp'" v-show="isOutOfService.dayOfWeek4"></el-col>
          <el-col :span="4" style="margin-top:10px"><el-checkbox v-model="isOutOfService.dayOfWeek4">休假</el-checkbox></el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px;height:35px">
          <el-col :span="4" style="margin-top:8px;text-align:center;font-size:130%">星期五</el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek5"
            v-model="serviceDate.dayOfWeek5.serviceStartDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek5"
            v-model="serviceDate.dayOfWeek5.serviceEndDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="16" v-html="'&nbsp'" v-show="isOutOfService.dayOfWeek5"></el-col>
          <el-col :span="4" style="margin-top:10px"><el-checkbox v-model="isOutOfService.dayOfWeek5">休假</el-checkbox></el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px;height:35px">
          <el-col :span="4" style="margin-top:8px;text-align:center;font-size:130%">星期六</el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek6"
            v-model="serviceDate.dayOfWeek6.serviceStartDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek6"
            v-model="serviceDate.dayOfWeek6.serviceEndDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="16" v-html="'&nbsp'" v-show="isOutOfService.dayOfWeek6"></el-col>
          <el-col :span="4" style="margin-top:10px"><el-checkbox v-model="isOutOfService.dayOfWeek6">休假</el-checkbox></el-col>
        </el-row>
        <el-row :gutter="10" style="margin-top:10px;height:35px">
          <el-col :span="4" style="margin-top:8px;text-align:center;font-size:130%">星期日</el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek7"
            v-model="serviceDate.dayOfWeek7.serviceStartDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="8"><el-time-select :clearable="false" :editable="false" v-show="!isOutOfService.dayOfWeek7"
            v-model="serviceDate.dayOfWeek7.serviceEndDate" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" placeholder="">
          </el-time-select></el-col>
          <el-col :span="16" v-html="'&nbsp'" v-show="isOutOfService.dayOfWeek7"></el-col>
          <el-col :span="4" style="margin-top:10px"><el-checkbox v-model="isOutOfService.dayOfWeek7">休假</el-checkbox></el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogServiceDateSettingVisible = false">取消</el-button>
        <el-button type="primary" @click="updateServiceDate()">儲存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      fullscreenLoading: false,
      rules: {
        disName: [{ required: true, message: "不得為空值" }],
        phone: [{ required: true, message: "不得為空值" }],
        addr: [{ required: true, message: "不得為空值" }]
      },
      pageTotal: 0,
      pageCurrent: 1,
      pic: undefined,
      stockItems: [],
      url: "",
      areas: [],
      storeGroups: [],
      status: [
        { code: "1", disName: "營運中" },
        { code: "0", disName: "停業" }
      ],
      labelPosition: "right",
      qStoreId: "",
      qDisName: "",
      qAreaId: "",
      qAreaIdSelect:[
        {code:'',label:'全部'}
      ],
      qStoreType:"",
      qStoreTypeSelect: [
        {code:'',label:'全部'}
      ],
      qStatus: "",
      qStatusSelect:[
        {code:'',label:'全部'},
        {code:'1',label:'營運中'},
        {code:'0',label:'停業'}
      ],
      tableData: [],
      storeDays: [],
      dialogFormVisible: false,
      dialogPicVisible: false,
      dialogNotifyVisible: false,
      dialogHolidayFormVisible: false,
      dialogProdStockDayVisible: false,
      dialogFrontDescVisible: false,
      dialogFrontRefundRuleDescVisible: false,
      dialogServiceDateSettingVisible: false,
      qrCodeUrl: "",
      form: {
        areaId: "",
        storeGroupId: null,
        disName: "",
        pdfDisName:"",
        descp: "",
        status: "",
        payCash: "",
        payCredit: "",
        payLINE: "",
        payIcp: '',
        withoutMofunInvoiceSwitch: false,
        saleInsuranceAbled: true,
        oneDayRentAble: true,
        withoutMofunInvoice: "F",
        payEndPriceAtRentSwitch: false,
        checkAdminUserCodeSwitch: true,
        payEndPriceAtRent: "F",
        alertEmail: "",
        priceType: 'MOFUN',
        frontInsuranceDesc: '',
        frontRefundRuleDesc: '',
        checkOnlyClientLicense: 'F',
        outsideServiceReturn: 'F',
        isFrontRentable: 'T',
        isNoStaffStore: 'F',
        isForeignRentOnSite: 'T'
      },
      priceTypes : [{code:'MOFUN',disName:'ZOCHA收費'},
        {code:'MOFUNV2',disName:'Mofun 連假以天計費模式'},
        {code:'TWOMODE',disName:'平假日收費'},
        {code:'TF',disName:'鼎峰收費'},
        {code:'ONEFLOW',disName:'一流收費'},
        {code:'SS',disName:'欣興收費'},
        {code:'MINGZHENG',disName:'平假日同價'},
        {code:'MINGZHENGV2',disName:'明正收費'},
        {code:'TCSC',disName:'台中松江收費'},
        {code:'GNB',disName:'東勳輕旅'},
        {code:'ES',disName:'乙順'},
        {code:'UP',disName:'元培'},
        ],
      notify: {
        storeId: "",
        create: "F",
        cancel: "F",
        creditDeposit: "F",
        transDeposit: "F",
        prodGet: "F",
        prodReturn: "F",
        timeout30Min: "F",
        timeout2Days: "F",
        insuranceDue: "F",
        priceChange: "F",
        meterMove: "F",
        overDistance: "F",
        distanceAbnormal: "F",
        rentDelay: "F",
        lowRate: "F",
        maintain: 'F',
        modifyPrice: 'F',
        flow50Count: 'F',
      },
      serviceDate: {
        dayOfWeek1: {serviceStartDate:'00:00',serviceEndDate:'23:30',isOutOfServiceDate:true},
        dayOfWeek2: {serviceStartDate:'00:00',serviceEndDate:'23:30',isOutOfServiceDate:true},
        dayOfWeek3: {serviceStartDate:'00:00',serviceEndDate:'23:30',isOutOfServiceDate:true},
        dayOfWeek4: {serviceStartDate:'00:00',serviceEndDate:'23:30',isOutOfServiceDate:true},
        dayOfWeek5: {serviceStartDate:'00:00',serviceEndDate:'23:30',isOutOfServiceDate:true},
        dayOfWeek6: {serviceStartDate:'00:00',serviceEndDate:'23:30',isOutOfServiceDate:true},
        dayOfWeek7: {serviceStartDate:'00:00',serviceEndDate:'23:30',isOutOfServiceDate:true}
        }
      ,
      isOutOfService: {
        dayOfWeek1: true,
        dayOfWeek2: true,
        dayOfWeek3: true,
        dayOfWeek4: true,
        dayOfWeek5: true,
        dayOfWeek6: true,
        dayOfWeek7: true
      },
      formLabelWidth: "180px",
      adminUsers: [
        {
          label: "132",
          key: "321"
        }
      ],
      adminUsersValue: [],
      adminUsersTitles: ["使用者帳號", "使用者帳號"],
      formData: null,
      prodStockList: {},
      prodStock: 0,
      sDate: null,
      eDate: null,
      stockYear: null,
      stockMonth: null,
      dateList:[],
      isFileUploaded: false,
      isShowContractUpload:''
    };
  },
  components: {
    //
  },
  created() {
    this.storeDays.push([])
    let row = 0
    let startW = 6
    let dayKey = 0
    for (let i = 1 ; i <= 5 ; i++) {
      this.storeDays[row].push({ key: (dayKey+'') })
      dayKey++
    }

    //(20220101 為週六)
    let big={1:true,3:true,5:true,7:true,8:true,10:true,12:true}
    for (let i = 2022 ; i <= 2025 ; i++) {
      for (let m = 1 ; m <= 12 ; m++) {
        let mcount = 30
        if (m === 2) {
          mcount = i%4 === 0 ? 29 : 28
        } else {
          mcount = big[m] ? 31 : 30
        }
        let style = (m%2 === 0)?'padding:10px;border: 1px solid black;background-color:#F0FFEA;':'padding:10px;border: 1px solid black'
        for (let c = 1 ; c <= mcount ; c++) {
          let date = i + '/' + (m<10 ? ('0'+m) : (m+'')) +'/'+ (c<10 ? ('0'+c) : (c+''))
          if(startW == 6 ||startW == 7) {
            this.storeDays[row].push({id: date, key: (dayKey+''), stock:0,weekend:true, select:false, style: style})
          } else {
            this.storeDays[row].push({id: date, key: (dayKey+''), stock:0,select:false, style:style})
          }
          startW ++
          dayKey++
          if (startW > 7 ) {
            startW = 1
            row ++
            this.storeDays.push([])
          }
        }
      }
    }
    this.$axios.get("../api/params/getParams", {})
      .then(res => {
        // if(res.data.areas.length>1){
        //   this.qAreaIdSelect.push({code:'全部'})
        // }
        this.$store.dispatch("login", res.data);
        res.data.areas.forEach(e => {
          this.areas.push({
            code: e.code,
            label: e.disName
          });
          this.qAreaIdSelect.push({
            code: e.code,
            label: e.disName
          });
        });
        this.query();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
    this.$axios.get('../api/adminStoreGroup/getStoreGroupForCombobox', {}).then(res => {
      res.data.forEach(e=> {
        this.storeGroups.push({
          code: e.code,
          label: e.disName
        })
      })
    })
  },
  name: "AdminStore",
  computed: {
    disName: {
      get() {
        if (this.$store.newClientUser == null) return "xx";
        return this.$store.newClientUser.disName;
      }
    },
    phone: {
      get() {
        if (this.$store.newClientUser == null) return "xx";
        return this.$store.newClientUser.phone;
      }
    }
  },
  methods: {
    reset(){
      this.qStoreId= "",
      this.qDisName= "",
      this.qAreaId= "",
      this.qStatus= ""
    },
    confirmEditFrontInsuranceDesc(){
      this.$confirm('確定要更新嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.fullscreenLoading = true
          let url='../api/store/updateFrontInsuranceDesc'
          this.$axios.post(url, this.form).then((res)=>{
            this.fullscreenLoading = false
            if (res.data.rs === 'success') {
              this.query()
            }
            this.dialogFrontDescVisible = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
          }).catch(()=>{
            this.fullscreenLoading = false
              this.$alert('不知名錯誤請洽系統管理員', '結果', { confirmButtonText: '確定' });
          })
        })
    },
    confirmEditRefundRuleDesc () {
      this.$confirm('確定要更新嗎?','注意',{
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.fullscreenLoading = true
          let url='../api/store/updateRefundRuleDesc'
          this.$axios.post(url, this.form).then((res)=>{
            this.fullscreenLoading = false
            if (res.data.rs === 'success') {
              this.query()
            }
            this.dialogFrontRefundRuleDescVisible = false
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' })
          }).catch(()=>{
            this.fullscreenLoading = false
              this.$alert('不知名錯誤請洽系統管理員', '結果', { confirmButtonText: '確定' });
          })
        })
    },
    showFrontInsuranceDescForm (store) {
      this.$axios.get('../api/store/getStore?storeId='+store.storeId).then((res)=>{
          this.dialogFrontDescVisible = true
          this.form.frontInsuranceDesc = ''
          this.form = Object.assign({}, this.form, res.data)
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('服務器回應失敗', '結果', { confirmButtonText: '確定' });
      })
    },
    showRefundRuleDescForm (store) {
      this.$axios.get('../api/store/getStore?storeId='+store.storeId).then((res)=>{
          this.dialogFrontRefundRuleDescVisible = true
          this.form.frontRefundRuleDesc = ''
          this.form = Object.assign({}, this.form, res.data)
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('服務器回應失敗', '結果', { confirmButtonText: '確定' });
      })
    },
    confirmEditHoliday(){
      let holidayIds=''
      for (let row in this.storeDays) {
        for(let col in this.storeDays[row]) {
          if(this.storeDays[row][col].select) {
            holidayIds += ',' +this.storeDays[row][col].id.replace(/\D/gi,'')
          }
        }
      }
      if(holidayIds.length >0 ){
        holidayIds = holidayIds.substring(1)
      }
      let param={ storeId:this.form.storeId,
        holiday: holidayIds
      }
      this.$confirm('確定要儲存嗎?', '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.fullscreenLoading = true
          let url = "../api/store/updateHoliday";
          this.$axios.post(url, param).then(res => {
              this.fullscreenLoading = false
              this.dialogHolidayFormVisible = false;
              if (res.data.rs === "success") {
                this.query();
              }
              this.$alert(res.data.rsMsg, "結果", {confirmButtonText: "確定"});
            })
            .catch(() => {
              this.fullscreenLoading = false
              this.$alert("不知名錯誤", "結果", { confirmButtonText: "確定" });
            });
        })
    },
    confirmEditProdStockDay(){
      let dayIds=''
      let stocks=''
      let qtys = ''
      let prodStockIds = ''
      let isError = false
      let errMsg = ''
      for(let item in this.prodStockList) {
        this.prodStockList[item].tds.forEach(td =>{
          let stock_ = parseInt(td.stock)
          if (stock_ < 0 ) {
            isError =true
            errMsg = '庫存不可為負數'
        }else if(td.stock < td.orderQty){
          isError =true
          errMsg = '庫存不可低於已預約訂單'
        }
          dayIds += ","+ td.day
          stocks += ","+ td.stock
          qtys += ","+ td.orderQty
          prodStockIds += "," +item 
        })
      }
      if (isError) {
        this.$alert(errMsg,'注意',{confirmButtonText:'確定'})
        return
      }
      dayIds = dayIds.substring(1)
      stocks = stocks.substring(1)
      qtys = qtys.substring(1)
      prodStockIds = prodStockIds.substring(1)
      let param={ storeId:this.form.storeId,
        day: dayIds,
        stock: stocks,
        qtys: qtys,
        prodStockIds: prodStockIds,
        sDate: this.sDate,
        eDate: this.eDate
      }
      this.$confirm('確定要儲存嗎?', '注意', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(()=>{
          this.fullscreenLoading = true
          let url = "../api/store/updateProdStockDay";
          this.$axios.post(url, param).then(res => {
              this.fullscreenLoading = false
              this.$alert(res.data.rsMsg, "結果", {confirmButtonText: "確定"});
            })
            .catch(() => {
              this.fullscreenLoading = false
              this.$alert("不知名錯誤", "結果", { confirmButtonText: "確定" });
            });
        })
    },
    selectDefaultHoliday(){
      for(let row in this.storeDays) {
        for (let col in this.storeDays[row]) {
          if (this.storeDays[row][col].weekend) {
            this.storeDays[row][col].select = true
          }
        }
      }
    },
    handlePictureCardPreview(file) {
      this.pic = "/imgs/zocha/store/" + file.img;
    },
    query() {
      this.$axios
        .get("../api/store/getStores", {
          params: {
            page: this.pageCurrent,
            qStoreId: this.qStoreId,
            qDisName: this.qDisName,
            qAreaId: this.qAreaId, 
            qStoreType: this.qStoreType, 
            qStatus: this.qStatus 
          }
        })
        .then(res => {
          this.tableData.splice(0, this.tableData.length);
          res.data.data.forEach(store => {
            this.tableData.push(store);
          });
          this.pageTotal = res.data.pageTotal;
        })
        .catch(() => {
          this.$alert("更新失敗", "結果", { confirmButtonText: "確定" });
        });
    },
    backUploadDialog() {
      this.dialogPicVisible = false;
      this.query();
    },
    confirmEditStore() {
      if (this.form.status == '1' && this.isFileUploaded == false) {
        this.$alert("合約未上傳", "結果", { confirmButtonText: "確定" });
        return
      }
      if (this.form.withoutMofunInvoiceSwitch && this.form.payCredit) {
        this.$alert("不委託Mofun開立電子發票，不可同時開啟信用卡付款", "結果", { confirmButtonText: "確定" });
        return
      }
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          let confirmTitle = this.form.storeId == '自動產生' ? '確定要新增嗎?' : '確定要修改嗎?'
          this.$confirm(confirmTitle, '注意', {
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(()=>{
              let url = "../api/store/updateStore";
              if (this.form.storeId == "自動產生") {
                this.form.storeId = undefined;
                url = "../api/store/createStore";
              }

              this.form.payCash = this.form.payCash ? 'T' : 'F' 
              this.form.payCredit = this.form.payCredit ? 'T' : 'F' 
              this.form.payLINE = this.form.payLINE ? 'T' : 'F' 
              this.form.payIcp = this.form.payIcp ? 'T' : 'F' 
              this.form.withoutMofunInvoice = this.form.withoutMofunInvoiceSwitch ? 'T' : 'F' 
              this.form.saleInsuranceAbled = this.form.saleInsuranceAbled ? 'T' : 'F' 
              this.form.oneDayRentAble = this.form.oneDayRentAble ? 'T' : 'F' 
              this.form.payEndPriceAtRent = this.form.payEndPriceAtRentSwitch ? 'T' : 'F' 
              this.form.checkAdminUserCode = this.form.checkAdminUserCodeSwitch ? 'T' : 'F'
    
              this.$axios.post(url, this.form)
                .then(res => {
                  this.dialogFormVisible = false;
                  if (res.data.rs === "success") {
                    this.query();
                  } else {
                    this.$alert(res.data.rsMsg, "結果", {
                      confirmButtonText: "確定"
                    });
                  }
                  this.$alert(res.data.rsMsg, "結果", { confirmButtonText: "確定" });
                })
                .catch(() => {
                  this.$alert("不知名錯誤", "結果", { confirmButtonText: "確定" });
                });
            })
        }
      });
    },
    showQRCodeDetailForm(store) {
      window.open("../api/store/pdf/" + store.storeId + ".pdf");
    },
    showCreateDetailForm() {
      this.isShowContractUpload = false
      this.form = Object.assign({}, this.form, {
        storeId: "自動產生",
        status: "0", // 預設狀態 "停業"
        disName: "",
        frontDisName: '',
        phone: "",
        addr: "",
        areaId: this.areas[0].code,
        storeGroupId: null,
        gpsLat: "",
        gpsLng: "",
        payCash: true,
        payCredit: true,
        payLINE: false,
        payIcp: false,
        withoutMofunInvoice: false,
        withoutMofunInvoiceSwitch: "F",
        saleInsuranceAbled:"T" ,
        oneDayRentAble:"T",
        payEndPriceAtRent: false,
        payEndPriceAtRentSwitch: "F",
        checkAdminUserCodeSwitch: 'T',
        checkOnlyClientLicense: 'F',
        outsideServiceReturn: 'F',
        isFrontRentable:'T',
        isNoStaffStore:'F',
        isForeignRentOnSite: 'T'
      });
      if (this.$refs.files && this.$refs.files.value) {
        this.$refs.files.value = '';
      }
      this.dialogFormVisible = true;
    },

    showDetailForm(store) {
      this.$axios
        .get("../api/store/getStore?storeId=" + store.storeId)
        .then(res => {
          this.dialogFormVisible = true;
          this.form = Object.assign({}, this.form, res.data);
          if (this.form.payCash == "T") {
            this.form.payCash = true;
          } else {
            this.form.payCash = false;
          }
          if (this.form.payCredit == "T") {
            this.form.payCredit = true;
          } else {
            this.form.payCredit = false;
          }
          if (this.form.payLINE == "T") {
            this.form.payLINE = true;
          } else {
            this.form.payLINE = false;
          }
          if (this.form.storeGroupId != null) {
            this.form.storeGroupId = parseInt(this.form.storeGroupId)
          }
          this.form.payIcp = this.form.payIcp == "T"
          this.form.withoutMofunInvoiceSwitch = this.form.withoutMofunInvoice === "T";
          this.form.payEndPriceAtRentSwitch = this.form.payEndPriceAtRent === "T";
          this.form.checkAdminUserCodeSwitch = this.form.checkAdminUserCode === 'T'
          this.form.saleInsuranceAbled = this.form.saleInsuranceAbled === "T";
          this.form.oneDayRentAble = this.form.oneDayRentAble === "T";
          this.checkContract(store.storeId);
          this.isShowContractUpload = true
        })
        .catch(() => {
          this.$alert("查詢失敗", "結果", { confirmButtonText: "確定" });
        })
    },
    showPicDetailForm(store) {
      this.url = "../api/store/upload/" + store.storeId;
      if (store.pic == undefined || store.pic == null || store.pic == "") {
        this.pic = null;
      } else {
        this.pic = "/imgs/zocha/" + store.pic;
      }
      this.dialogPicVisible = true;
    },
    showHolidaySettingForm(row) {
      this.form.storeId=row.storeId
      this.dialogHolidayFormVisible = true
      this.fullscreenLoading = true
      this.$axios
        .get("../api/store/getHoliday?storeId=" + row.storeId)
        .then(res => {
          if (res.data !== undefined) {
            this.fullscreenLoading = false
            for(let row in this.storeDays) {
              for (let col in this.storeDays[row]) {
                if(this.storeDays[row][col].id !== undefined) {
                  let id = this.storeDays[row][col].id.replace(/\D/gi,'')
                  if (res.data[id]) {
                    this.storeDays[row][col].select = true
                  } else {
                    this.storeDays[row][col].select = false
                  }
                }
              }
            }         
          }
        })
        .catch((e) => {
          console.log(e)
          this.fullscreenLoading = false
          this.$alert("查詢失敗", "結果", { confirmButtonText: "確定" });
        });
    },
    showProdStockDaySettingForm(row) {
      this.fullscreenLoading = true
      this.form.storeId=row.storeId
      
      let date = moment(new Date());
      this.stockYear = date.format("YYYY")
      this.stockMonth = date.format("MM")
      this.getProdStockDayData()
      
    },
    getProdStockDayData(){
      this.fullscreenLoading = true
      this.dateList = []
      let yearMont = this.stockYear+this.stockMonth
      this.sDate = moment(yearMont).startOf("Month").format("YYYYMMDD")
      this.eDate = moment(yearMont).endOf("Month").format("YYYYMMDD")
      let qSDate_ = this.sDate
      while(moment(qSDate_) <= moment(this.eDate)){
        this.dateList.push(moment(qSDate_).format("DD"))
        qSDate_ = moment(qSDate_).add(1,"days")
      }
      this.$axios.get("../api/store/getProdStockDay",{params:{sDate: this.sDate,eDate: this.eDate,storeId: this.form.storeId}}).then(res =>{
        this.dialogProdStockDayVisible = true
        this.prodStockList = res.data.data
        this.fullscreenLoading = false
        // this.prodStock = this.prodStockList[0].code
      })
    },
    showNotifySettingForm(store) {
      this.$axios
        .get("../api/store/getStoreNotify?storeId=" + store.storeId)
        .then(res => {
          this.dialogNotifyVisible = true;
          if (res.data.rs === "success") {
            this.notify = Object.assign({}, this.notify, res.data.data);
            this.notify.storeId = store.storeId;
            //
          }
        })
        .catch(() => {
          this.$alert("查詢失敗", "結果", { confirmButtonText: "確定" });
        });
    },
    showServiceDateSettingForm(store){
      this.$axios.get('../api/store/getStoreServiceDate?storeId=' + store.storeId)
      .then(res =>{
        this.dialogServiceDateSettingVisible = true
        this.serviceDate = Object.assign({},this.serviceDate,res.data.data)
        this.serviceDate.storeId = store.storeId
        if(res.data.data.dayOfWeek1 === undefined){
          this.isOutOfService.dayOfWeek1=true
        } else {
          this.isOutOfService.dayOfWeek1=false
        }
        if(res.data.data.dayOfWeek2 === undefined){
          this.isOutOfService.dayOfWeek2=true
        } else {
          this.isOutOfService.dayOfWeek2=false
        }
        if(res.data.data.dayOfWeek3 === undefined){
          this.isOutOfService.dayOfWeek3=true
        } else {
          this.isOutOfService.dayOfWeek3=false
        }
        if(res.data.data.dayOfWeek4 === undefined){
          this.isOutOfService.dayOfWeek4=true
        } else {
          this.isOutOfService.dayOfWeek4=false
        }
        if(res.data.data.dayOfWeek5 === undefined){
          this.isOutOfService.dayOfWeek5=true
        } else {
          this.isOutOfService.dayOfWeek5=false
        }
        if(res.data.data.dayOfWeek6 === undefined){
          this.isOutOfService.dayOfWeek6=true
        } else {
          this.isOutOfService.dayOfWeek6=false
        }
        if(res.data.data.dayOfWeek7 === undefined){
          this.isOutOfService.dayOfWeek7=true
        } else {
          this.isOutOfService.dayOfWeek7=false
        }
      })
    },
    updateServiceDate() {
      let pa = []
      if(this.isOutOfService.dayOfWeek1===false || this.isOutOfService.dayOfWeek1===undefined) {
        this.serviceDate.dayOfWeek1.dayOfWeek = 1
        pa.push(this.serviceDate.dayOfWeek1)
      }
      if(this.isOutOfService.dayOfWeek2===false || this.isOutOfService.dayOfWeek2===undefined) {
        this.serviceDate.dayOfWeek2.dayOfWeek = 2
        pa.push(this.serviceDate.dayOfWeek2)
      }
      if(this.isOutOfService.dayOfWeek3===false || this.isOutOfService.dayOfWeek3===undefined) {
        this.serviceDate.dayOfWeek3.dayOfWeek = 3
        pa.push(this.serviceDate.dayOfWeek3)
      }
      if(this.isOutOfService.dayOfWeek4===false || this.isOutOfService.dayOfWeek4===undefined) {
        this.serviceDate.dayOfWeek4.dayOfWeek = 4
        pa.push(this.serviceDate.dayOfWeek4)
      }
      if(this.isOutOfService.dayOfWeek5===false || this.isOutOfService.dayOfWeek5===undefined) {
        this.serviceDate.dayOfWeek5.dayOfWeek = 5
        pa.push(this.serviceDate.dayOfWeek5)
      }
      if(this.isOutOfService.dayOfWeek6===false || this.isOutOfService.dayOfWeek6===undefined) {
        this.serviceDate.dayOfWeek6.dayOfWeek = 6
        pa.push(this.serviceDate.dayOfWeek6)
      }
      if(this.isOutOfService.dayOfWeek7===false || this.isOutOfService.dayOfWeek7===undefined) {
        this.serviceDate.dayOfWeek7.dayOfWeek = 7
        pa.push(this.serviceDate.dayOfWeek7)
      }
      let param = {serviceDateData: pa,storeId: this.serviceDate.storeId}
      this.$confirm("是否確定更改營業時間","注意",{
        confirmButtonText:'確定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        this.$axios.post('../api/store/updateStoreServiceDate',param)
        .then(res =>{
          if(res.data.rs === 'success'){
            this.$alert('更新成功','注意',{confirmButtonText:'確定'})
            this.dialogServiceDateSettingVisible = false
          }else {
            this.$alert(res.data.rsMsg,'注意',{confirmButtonText:'確定'})
          }
        })
      })
    },
    confirmEditNotifySetting() {
      this.$confirm("是否確定更改提醒設定?", "注意", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios
          .post("../api/store/setStoreNotify", this.notify)
          .then(res => {
            if (res.data.rs === "success") {
              this.$alert("更新成功", "注意", { confirmButtonText: "確定" });
              this.dialogNotifyVisible = false;
            } else {
              this.$alert("更新失敗", "注意", { confirmButtonText: "確定" });
            }
          })
          .catch(() => {
            this.$alert("更新失敗", "注意", { confirmButtonText: "確定" });
          });
      });
    },
    changeAllStock(amount,rowData){
      rowData.forEach( e=>{
        e.stock += amount
        if (e.stock < 0 ) {
          this.$alert('庫存不可為負數','注意',{confirmButtonText:'確定'})
          e.stock -= amount
          return
        }
        if(e.stock < e.orderQty){
          this.$alert('庫存不可低於已預約訂單','注意',{confirmButtonText:'確定'})
          e.stock -= amount
          return
        }
      }) 
    },
    fter_area(row, column) {
      let areaId = row[column.property];
      if (areaId == undefined) return "";
      return this.$areaDisName(this.$store.state.areas, areaId);
    },
    fter_status(row, column) {
      let status = row[column.property];
      if (status == undefined) return "";
      for (var i in this.status) {
        if (this.status[i].code == status) {
          return this.status[i].disName;
        }
      }
      return "";
    },
    uploadContract() {
      //
      if(this.$refs.files.value === '' ||this.$refs.files.value === null|| this.$refs.files.value === undefined){
        this.$alert('請先選擇要上傳的PDF檔案','注意',{
          confirmButtonText:'確定'
        })
        return
      }
      let uploadPath = '../api/store/uploadContract/'+this.form.storeId
      this.$confirm('是否確定上傳合約','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(()=>{
        this.fullscreenLoading = true
        this.$axios.post(uploadPath,this.formData).then((res) => {
          this.fullscreenLoading = false
          if(res.data.rs === 'success'){
            this.$alert('上傳成功','結果',{
              confirmButtonText:'確定'
            })
          } else {
            this.$alert('上傳失敗','結果',{
              confirmButtonText:'確定'
            })
          }
        }).catch(() =>{
          this.fullscreenLoading = false
          this.$alert('上傳失敗','結果',{
            confirmButtonText:'確定'
          })
        })
      })
      this.checkContract()
    },
    checkContract(){
      this.$axios.get('../api/store/checkContract/'+this.form.storeId).then((res)=>{
        if(res.data.rs === 'success'){
          this.isFileUploaded = true
        } else if (res.data.rsError === 'error') {
          this.isFileUploaded = false
        }
      })
    },
    selectContract(e) {
      this.formData = new FormData();
      this.formData.append('file',e.target.files[0])
    },
    downloadContract() {
      this.$axios.get('../api/store/contract/'+this.form.storeId).then((res)=>{
        if(res.data.rs === 'success') {
          window.open('../api/store/download/'+this.form.storeId)
        } else {
          this.$alert(res.data.rsMsg,'注意',{
            confirmButtonText:'確定'
          })
        }
      })
    },
    uploadLongRentContract() {
      //
      if(this.$refs.lontRentfiles.value === '' ||this.$refs.lontRentfiles.value === null|| this.$refs.lontRentfiles.value === undefined){
        this.$alert('請先選擇要上傳的PDF檔案','注意',{
          confirmButtonText:'確定'
        })
        return
      }
      let uploadPath = '../api/store/uploadLongRentContract/'+this.form.storeId
      this.$confirm('是否確定上傳合約','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(()=>{
        this.fullscreenLoading = true
        this.$axios.post(uploadPath,this.formData).then((res) => {
          this.fullscreenLoading = false
          if(res.data.rs === 'success'){
            this.$alert('上傳成功','結果',{
              confirmButtonText:'確定'
            })
          } else {
            this.$alert('上傳失敗','結果',{
              confirmButtonText:'確定'
            })
          }
        }).catch(() =>{
          this.fullscreenLoading = false
          this.$alert('上傳失敗','結果',{
              confirmButtonText:'確定'
            })
        })
      })
    },
    selectLongRentContract(e) {
      this.formData = new FormData();
      this.formData.append('file',e.target.files[0])
    },
    downloadLongRentContract() {
      this.$axios.get('../api/store/longRentContract/'+this.form.storeId).then((res)=>{
        if(res.data.rs === 'success') {
          window.open('../api/store/downloadLongRent/'+this.form.storeId)
        } else {
          this.$alert(res.data.rsMsg,'注意',{
            confirmButtonText:'確定'
          })
        }
      })
    },
    resetOneYersStock() {
      let yearMont = this.stockYear+this.stockMonth
      let eDate_ = moment(yearMont).add(11,'months').endOf('Month').format('YYYYMMDD');
      let param={ storeId:this.form.storeId,
        sDate: this.sDate,
        eDate: eDate_
      }
      this.$confirm('根據實際登錄的車輛數載入庫存，並且複製到未來12個月的庫存表中。連續假日如有需要關閉，請手動調整個別日期庫存','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(()=>{
        this.fullscreenLoading = true
          let url = "../api/store/resetOneYersStock";
          this.$axios.post(url, param).then(res => {
              this.fullscreenLoading = false
              if (res.data.rs == 'success') {
                this.$alert(res.data.rsMsg, "結果", {confirmButtonText: "確定"});
              } else if (res.data.rs == 'danger'){
                this.$alert('修改成功，以下日期已有訂單請注意'+res.data.reDetail, "警告", {confirmButtonText: "確定"});
              }
            })
            .catch(() => {
              this.fullscreenLoading = false
              this.$alert("不知名錯誤", "結果", { confirmButtonText: "確定" });
            });
      }) 
    }
  }
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #ad9393;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #2e2e2e;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 250px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 400px;
  height: 300px;
  display: block;
}
.holiday-th{
  border: 1px solid black;
  text-align:center;
}
</style>
