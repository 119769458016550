<template>
<!-- 文中 -->
<!-- FinancialWSTP -->
<div v-loading.fullscreen.lock="fullscreenLoading">

  <!-- -------------------------------------------上方搜尋欄位------------------------------------------------- -->

  <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
    <el-row :gutter="10">
      <el-col :span="12">
          <span @click="showClose++" class="timeSelectTitle">日期區間</span>
          <el-date-picker v-model="sDate" type="date" placeholder="起始日期" :clearable="false">
          </el-date-picker>
          ~<el-date-picker v-model="eDate" type="date" placeholder="結束日期" :clearable="false">
          </el-date-picker>
      </el-col>
      <el-col :span="4">
        <el-row :gutter="1" class="col-row">
         <el-button type="primary" @click="changeToExcel()">EXCEL轉換</el-button>
        </el-row>
      </el-col> 
    </el-row>
    <el-col :span="4">
      <el-row>
        <el-button type="primary" style="margin: 15px;" @click="query()">查詢</el-button>
        <el-button type="primary" @click="reset()">重置</el-button>
       
      </el-row>
    </el-col> 

  </el-card>
  
  <!-- -------------------------------------------顯示資料------------------------------------------------- -->

  <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column prop="accountTitle" label="項目" width="270px"></el-table-column>
      <el-table-column prop="accountCode" label="會計科目" width="350px"></el-table-column>
      <el-table-column prop="debitCredit" label="借貸" width=""></el-table-column>
      <el-table-column prop="tax" label="營業稅" width=""></el-table-column>
      <el-table-column fixed="right" label="操作" width="250px">
        <el-button  @click="showDetailForm()" type="primary" size="small">編輯</el-button>
      </el-table-column>
    </el-table>

     <!-- 自訂分頁 -->
    <el-pagination 
    background layout="prev, pager, next" 
    style="margin-top:10px"
    :total="pageTotal"
    :current-page.sync="pageCurrent" 
    @current-change="query">
    </el-pagination>
  </el-card>

  <!-- -------------------------------------------彈出視窗_編輯------------------------------------------------- -->

  <el-dialog title="" :close-on-click-modal="false" :visible.sync="dialogStartRentVisible" width="50%" >
    <el-form :model="form" :rules="formRules" ref="form" label-width="80px">
      <el-form-item label="項目" prop="accountTitle">
        <el-select v-model="form.accountTitle" placeholder="請選擇" >
          <el-option width=120
              v-for="item in type1AccountTitle"
              :key="item.code"
              :label="item.disName"
              :value="item.code">
           </el-option>
         </el-select>  
      </el-form-item>

      <el-form-item label="會計科目" prop="accountCode">
        <el-input v-model="form.accountCode" placeholder="請輸入會計科目"></el-input>
      </el-form-item>

      <el-form-item label="借貸" prop="debitCredit">
        <!-- <el-input v-model="form.debitCredit" placeholder="請輸入11"></el-input> -->
        <el-select v-model="form.debitCredit" placeholder="請選擇">
          <el-option 
             v-for="item in type2DebitCredit" 
             :key="item.code"
             :label="item.disName"
             :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="營業稅" prop="tax">
        <el-select v-model="form.tax" placeholder="請選擇">
          <el-option 
          v-for="item in type3Tax" 
          :key="item.code"
          :label="item.disName"
          :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogStartRentVisible = false" style="font-size:150%;">取 消</el-button>
      <el-button type="primary" v-show="form.rowId == null" @click="submitForm()" style="font-size:150%;">確認</el-button>
    </div>
  </el-dialog>
</div>

</template>

<script>
import moment from 'moment';
export default {

  data() {
    return {
      // 頁面設定
      dialogStartRentVisible:false, //彈出視窗
      // dialogStartRentVisible:true, //彈出視窗
      fullscreenLoading: false,
      pageTotal: 0,
      pageCurrent: 1,
      labelPosition: 'right',
      q:{},

      //顯示資料欄位
      sDate:'',
      eDate:'',
      tableData: [],
      accountTitle:[
        ['成本-電池資費','成本-車輛保養維修', '成本-加油',
         '成本-保險費(第三人)', '成本-保險費(強制險)','成本-門店耗材費', 
         '成本-合作店家分潤',   '成本-車輛攤提', '成本-裝潢攤提', 
        '租金支出',
        '薪資費用',
        '廣告費',
        '文具用品',
        '車輛運費',
        '郵資',
        '交際費',
        '出差費',
        '停車費',       
        '電話費',
        '保險費-勞保',
        '保險費-健保',
        '保險費-勞退',
        '水電費',
        '手續費',
        '勞務費',
        '罰單',      
        '雜項費用',
        '個案金額',
        '長租訂金退回',
        '房客押金退回'
       ]
      ],
      debitCredit:[['1','2']],
      tax:[['是','否','輔助用']],

      //彈跳視窗form_欄位
      type1AccountTitle:[
        { code: '成本-電池資費', disName: '成本-電池資費' },
        { code: '成本-車輛保養維修', disName: '成本-車輛保養維修' },
        { code: '成本-加油', disName: '成本-加油' },
        { code: '成本-保險費(第三人)', disName: '成本-保險費(第三人)' },
        { code: '成本-保險費(強制險)', disName: '成本-保險費(強制險)' },
        { code: '成本-門店耗材費', disName: '成本-門店耗材費' },
        { code: '成本-合作店家分潤', disName: '成本-合作店家分潤' },
        { code: '成本-車輛攤提', disName: '成本-車輛攤提' },
        { code: '成本-裝潢攤提', disName: '成本-裝潢攤提' },
        { code: '租金支出', disName: '租金支出' },
        { code: '薪資費用', disName: '薪資費用' },
        { code: '廣告費', disName: '廣告費' },
        { code: '文具用品', disName: '文具用品' },
        { code: '車輛運費', disName: '車輛運費' },
        { code: '郵資', disName: '郵資' },
        { code: '交際費', disName: '交際費' },
        { code: '出差費', disName: '出差費' },
        { code: '停車費', disName: '停車費' },
        { code: '電話費', disName: '電話費' },
        { code: '保險費-勞保', disName: '個案金額' },
        { code: '保險費-健保', disName: '個案金額' },
        { code: '保險費-勞退', disName: '個案金額' },
        { code: '水電費', disName: '水電費' },
        { code: '手續費', disName: '手續費' },
        { code: '勞務費', disName: '勞務費' },
        { code: '罰單', disName: '罰單' },
        { code: '雜項費用', disName: '雜項費用' },
        { code: '個案金額', disName: '個案金額' },
        { code: '長租訂金退回', disName: '長租訂金退回' },
        { code: '房客押金退回', disName: '房客押金退回' },
      ],
      type2DebitCredit:[
        { code: '1', disName: '1' },
        { code: '2', disName: '2' },    
      ],
      type3Tax:[
        { code: '是', disName: '是' },
        { code: '否', disName: '否' },
        { code: '輔助用', disName: '輔助用' },
      ],
     
      //彈跳視窗form
      form: {
        accountTitle: '',
        accountCode: '',    
        debitCredit: '',
        tax: '',
      },

      //彈跳視窗位必填
      formRules: {
        accountTitle: [
          { required: true, message: '請選擇"項目"', trigger: 'change' },       
        ],
        accountCode: [
          { required: true, message: '該欄位不得為空"', trigger: 'blur' },
        ],
        debitCredit: [
          { required: true, message: '請選擇"借貸"', trigger: 'change' },
        ],
        tax: [
          { required: true, message: '請選擇"營業稅"', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.sDate = moment(new Date()).startOf("month").toDate();
    this.eDate = moment(new Date()).endOf("month").toDate();
   
    this.$axios
      .get('../api/financialwallet/getFinancialStores', {})
      
       
  },
  name: 'FinancialWSTP',
  components: {},
  computed: {},
  methods: { 
    query() {
      this.q.page=this.pageCurrent
      this.$axios
        .get('../api/financialWSTP/getFinancialWSTP', {
          
        })
      },
    
    showDetailForm(){
      this.dialogStartRentVisible = true
    },
    changeToExcel(){
      let sDate = this.sDate == null ? '' : moment(this.sDate).format('YYYYMMDD');
      let eDate = this.eDate == null ? '' : moment(this.eDate).format('YYYYMMDD');
      window.open('../api/financialWSTP/changeToExcel?qStatus='
       + this.qStatus
       +'&eDate='+eDate
       +'&sDate='+sDate
        );
    },
    reset(){
      this.sDate = moment(new Date()).startOf("month").toDate();
      this.eDate = moment(new Date()).endOf("month").toDate();
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 表單驗證通過，執行提交操作
          console.log('表單提交成功');
        } else {
          // 表單驗證失敗，不執行提交操作
          console.log('表單驗證失敗');
        }
      });
    },
  }
}

</script>

<style scoped>
  .col-row{
    display: flex;
  }
  .timeSelectTitle{
    margin-right: 10px;
  }
</style>

