<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div>
            <el-input placeholder="車型" v-model="qProdCatDisname">
              <template slot="prepend">車型</template>
            </el-input>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
        <el-col :span="2"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="showCreateDetailForm()">新增</el-button>
        </el-col>

      </el-row>      
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="prodCatId" label="ID"></el-table-column>
        <el-table-column   prop="disName" label="名稱">  </el-table-column>
        <el-table-column   prop="descp" label="描述" :formatter="fter_descp">  </el-table-column>
        <el-table-column   prop="status" label="狀態" :formatter="fter_status">  </el-table-column>
        <!-- <el-table-column   prop="pic" label="照片" :formatter="fter_url">  </el-table-column> -->
        <el-table-column   fixed="right"    label="操作"  width="160">
          <template slot-scope="scope">
            <el-button  @click="showDetailForm(scope.row)" type="primary" size="small">編輯</el-button>
            <el-button v-show="false" @click="showPicDetailForm(scope.row)" type="warning" size="small">照片</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>

<el-dialog title="編輯視窗" :visible.sync="dialogFormVisible">
  <el-form :model="form">
    <el-form-item label="車型ID" :label-width="formLabelWidth">{{form.prodCatId}}</el-form-item>
    <el-form-item label="名稱" :label-width="formLabelWidth">
      <el-input v-model="form.disName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="狀態" :label-width="formLabelWidth">
      <el-select v-model="form.status" placeholder="狀態">
        <el-option width=120 v-for="item in statusList" :key="item.code" :label="item.disName" :value="item.code"></el-option>               
      </el-select>
    </el-form-item>
    <el-form-item label="官網描述" :label-width="formLabelWidth">
      <el-input v-model="form.descp" maxlength="200"  show-word-limit type="textarea"></el-input>
    </el-form-item>
    <el-form-item label="是否為ECO車型" :label-width="formLabelWidth">
      <el-select v-model="form.isEcoProd" placeholder="是否為ECO車型">
        <el-option width=120 key="F" label="否" value="F"></el-option>
        <el-option width=120 key="T" label="是" value="T"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否為PBGN車型" :label-width="formLabelWidth">
      <el-select v-model="form.isPbgnProd" placeholder="是否為PBGN車型">
        <el-option width=120 key="F" label="否" value="F"></el-option>
        <el-option width=120 key="T" label="是" value="T"></el-option>
      </el-select>
      <div>※此設定將影響客戶端操作取還車流程時的<span style="color:red">車輛教學頁面</span>是否正確提供</div>
    </el-form-item>
    <el-form-item label="" :label-width="formLabelWidth">
    </el-form-item>




  </el-form>    
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditStore">確 定</el-button>
  </div>
</el-dialog>

<el-dialog title="照片設定" :visible.sync="dialogPicVisible"> 
    <el-row :gutter="20">
      官網照片<a v-if="pic" target="_blank" :href="pic">(開啟大圖)</a>
      <img :src="pic" class="pic"/>
      <form action="" method="post">
          <input type="file" accept="image/*" @change="fileChange">
          <el-button type="primary" @click="uploadFile()">上傳</el-button>
      </form>
    </el-row>  
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="backUploadDialog">退出</el-button>
  </div>
</el-dialog>
  </div>
</template>

<script>
import imageCompression from 'browser-image-compression'
export default {
  data () {
    return {
      formData:null,
      url:'',
      uploadPath:null,
      pic:null,
      pageTotal:0,
     pageCurrent:1,
       labelPosition: 'right',
      qProdCatDisname:'', 
      statusList:[
        {code:'0',disName:'啟用'},
        {code:'1',disName:'停用'}
      ],
      tableData:[],
      dialogFormVisible: false,
      dialogPicVisible:false,
      form: {
        prodCatId: '',
        disName: '',
        pic: '',
        status: '',
        descp:'',
        isEcoProd: 'F',
        isPbgnProd: 'T'
      },
      formLabelWidth: '120px',
      imageOption: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
      }
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
      });     
  },
  name: 'AdminProdCat',
  methods :{
   uploadFile(){
      let tUploadPath=this.uploadPath
      this.$axios.post(tUploadPath, this.formData).then((res) => {
        this.pic = '/imgs/zocha/prodCat/' + res.data.img
      }).catch((res) => {
        console.log(res)
      });
    },
    async fileChange(e) {
      this.formData=new FormData();
      //放進上傳的檔案
      try {
        const compressedFile = await imageCompression(e.target.files[0], this.imageOption)
        this.formData.append('file', compressedFile)
      } catch (error) {
        console.log(error)
      }
    },       
    backUploadDialog(){
      this.dialogPicVisible = false
      this.query()
    },    
    query(){
      let param = {
        page: this.pageCurrent,
        disName: this.qProdCatDisname
      }
      this.$axios
        .get('../api/prodCat/getProdCats',{params:param})
        .then((res)=>{
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(store=>{this.tableData.push(store)})
          this.pageTotal=res.data.pageTotal
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
      });  
    },
    confirmEditStore(){
      let url='../api/prodCat/updateProdCat'
      let confirmText = '是否確定編輯車型'
      if(this.form.prodCatId=='自動產生'){
        confirmText = '是否確定新增車型'
        this.form.prodCatId=undefined;
        url='../api/prodCat/createProdCat'
      }
      this.$confirm(confirmText,'注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.$axios
          .post(url,
            this.form)
          .then(()=>{
            this.query()
            this.dialogFormVisible=false
        }).catch((res)=>{
            console.log(res.data);
        });      
      })
    },
    showCreateDetailForm(){
      this.form = Object.assign({}, this.form, {prodCatId:'自動產生',disName:''})
      this.dialogFormVisible=true
    },
    showDetailForm(prodCat){
      this.$axios
        .get('../api/prodCat/getProdCat?prodCatId='+prodCat.prodCatId)
        .then((res)=>{
          this.dialogFormVisible=true
          this.form = Object.assign({}, this.form, res.data)
          // res.data.forEach(store=>{this.tableData.push(store)})
      }).catch((res)=>{
          console.log(res.response.status)
          this.$alert('login error', '結果', { confirmButtonText: '確定' });
      });
    },
    showPicDetailForm(prodCat){
      this.uploadPath='../api/prodCat/upload/'+prodCat.prodCatId
      if(prodCat.pic==undefined||prodCat.pic==null||prodCat.pic==''){
        this.pic=null
      }else{
        this.pic='/imgs/zocha/'+prodCat.pic
      }      
      this.dialogPicVisible=true
    },    
    deleteRow(index) {
      console.log(index)
    },
    fter_status(row, column) {
      let status = row[column.property];
      if (status == undefined)return "";
      for(var i in this.statusList){
        if(this.statusList[i].code==status){
          return this.statusList[i].disName
        }
      }
      return ""
    },
    fter_url(row, column) {
      let url = row[column.property];
      if (url == '')return ""
      else return "y"
    },
    fter_descp(row, column) {
      let url = row[column.property];

      if (url!=null && url.length>10)return url.substring(0,10)+'...'
      else return url
    } 
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #ad9393;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #2e2e2e;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 250px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 400px;
    height: 300px;
    display: block;
  }
.pic{
  width: 640px;
  height:480px;
}  
</style>