<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-collapse v-model="activeName">
      <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
        <el-collapse-item name="1">
          <template slot="title">
           <h2>訂單更改紀錄查詢</h2>
          </template>
          <el-row :gutter="10" style="margin-top:10px;">
              <el-col :span="12" :md="10">
                <el-input placeholder="訂單號" v-model="qClientOrderId">
                  <template slot="prepend">訂單號</template>
                </el-input>
            </el-col>
            <el-col :span="12" :md="10">
                <el-input placeholder="更新人" v-model="qUpdator">
                  <template slot="prepend">更新人</template>
                </el-input>
            </el-col>
            
          </el-row>
          
          
          <el-row :gutter="10" style="margin-top:10px;">
            <el-col :span="24" :md="6">
              <el-button type="primary" @click="query()">查詢</el-button>
              <el-button type="primary" @click="reset()">重置</el-button>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-card>
      
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="tableData" stripe border  style="width: 100%">
        <el-table-column   prop="clientOrderId" width="160px"   label="訂單號"></el-table-column>
        <el-table-column   prop="updator"  width="100px" label="更新人">  </el-table-column>
        <el-table-column   prop="mapContent" label="更動內容">  </el-table-column>
        <el-table-column   prop="updateDate"  width="180px" label="更動時間">  </el-table-column>
        <!-- <el-table-column   fixed="right"    label="操作"    width="160">
          <template slot-scope="scope">
          </template>
        </el-table-column>         -->
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
    </el-collapse>
  <!-- 明細 -->
  </div>
</template>


<script>
export default {
  components:{
  },
  data () {
    return {
      pageTotal:0,
      pageCurrent:1,
      qClientOrderId:null,
      qUpdator:null,
      tableData:[],
      historyFlow:[],
      clientOrderTrans: [], // 支付紀錄
      dialogFormVisible: false,
      form: {
        deposit:0,
        note: ""
      },
      formLabelWidth: '120px',
      stores: [],
      storesMap: [],
      moneyType: [{code:'',disName:'全部'},{code:'CREDIT',disName:'信用卡'}, {code:'CASH',disName:'現金'},{code:'ATM',disName:'匯款'}],
      status: [{code:'SUCCESS',disName:'成功'},  {code:'',disName:'全部'},{code:'ERROR',disName:'失敗'}],
      disNames: [{code:'',disName:'全部'},{code:'訂金',disName:'訂金'}, {code:'尾款',disName:'尾款'}, {code:'退款',disName:'退款'}, {code:'預付',disName:'預付'}],
      selectStores: [],
      flows:[],
      activeName: '1', // 搜尋手風琴預設
      assignRowBankNo: '',
      assignRowReadFlag: 'Y',
      fullscreenLoading: false,
      isHandle: false,
      comfirmModelsContent: null,
      comfirmModal: false
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
        if(res.data.authStores.length>=1){
          this.stores.push({code:'',disName:'全部'})
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e)
        });
        this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
        }        
      });   
  },
  name: 'ClientOrderChangeLog',
  methods :{
    query(){
      this.fullscreenLoading = true
      let qForm= {page:this.pageCurrent,
       qClientOrderId: this.qClientOrderId,
       qUpdator: this.qUpdator
       }
      this.$axios
        .get('../api/alterOrder/getAlterOrderLog', {params:qForm}) 
        .then((res)=>{
          this.fullscreenLoading = false
          this.tableData.splice(0, this.tableData.length)
          res.data.data.forEach(e=>{
              this.tableData.push(e)
              e.updateDate = this.$getDateTime_('_14', e.updateDate)
            })
          this.pageTotal=res.data.pageTotal
      }).catch(()=>{
        this.fullscreenLoading = false
          this.$alert('查詢錯誤', '結果', { confirmButtonText: '確定' })
      });  
    },
    reset(){
      this.qClientOrderId=null
      this.qUpdator = null
      // this.query()
    }
  }
}
</script>
