<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="8">
          事件類型
            <el-select v-model="qAlertType">
              <el-option value="">全部</el-option>
              <el-option value="100METER_MOVE" label="非租借期間移動警示">非租借期間移動警示</el-option>
              <el-option value="48HOURS_STATIC" label="48小時無動作警示">48小時無動作警示</el-option>
              <el-option value="TIME_RETURN" label="還車通知">還車通知</el-option>
              <el-option value="TIME_GET" label="取車通知">取車通知</el-option>
              <el-option value="OVER_DISTANCE_STORE" label="車輛距離店家過遠警示">車輛距離店家過遠警示</el-option>
              <el-option value="CLIENT_USER_LOW_RATIO" label="低評價通知">低評價通知</el-option>
              <el-option value="INSURANCE_DUE" label="保險到期通知">保險到期通知</el-option>
              <el-option value="CARNO_RIDE_DISTANCE" label="騎乘里程通知">騎乘里程通知</el-option>
              <el-option value="PROD_FIX" label="車輛報修通知">車輛報修通知</el-option>
            </el-select>
        </el-col>
        <el-col :span="14">
          日期區間
          <el-date-picker
              v-model="qSDate"
              type="date"
              placeholder="開始日期">
            </el-date-picker>
            &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
              v-model="qEDate"
              type="date"
              placeholder="結束日期">
            </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:10px;">
        <el-col :span="8"><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
          <el-button type="primary" @click="reset()">重置</el-button>
          <el-button type="primary" @click="getLog('admin')">門市端log</el-button>
          <el-button type="primary" @click="getLog('admin')">客戶端log</el-button>
        </el-col>
      </el-row>      
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table  :data="alertData" stripe border  style="width: 100%">
        <el-table-column   prop="typeDisname" label="類型" width="180"></el-table-column>
        <el-table-column   prop="updateDate" label="發生時間" width="180"></el-table-column>
        <el-table-column   prop="content" label="內容"></el-table-column>
        <el-table-column   prop="solve" label="狀態" width="80"></el-table-column>
        <el-table-column   fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="showDetailForm(scope.row)" type="primary" size="small">查看</el-button>
          </template>
        </el-table-column>        
      </el-table> 
      <el-pagination 
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal" 
        :current-page.sync="pageCurrent"
        @current-change="query">
      </el-pagination>                    
    </el-card>
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data () {
    return {
      pageTotal: 0,
      pageCurrent: 1,
      qSDate: '',
      qEDate: '',
      qAlertType: '',
      alertTypes: [],
      alertData: []
    }
  },
 created () {
    this.$axios
        .get('../api/params/getParams',{})
        .then((res)=>{
          this.$store.dispatch('login',res.data)
          this.query()
      }).catch((res)=>{
          if(res.response.status==401){
            window.location = '/';
          }        
      });     
  },
  name: 'AdminAlert',
  methods :{
    getLog (type) {
      window.open('../api/adminAlert/log/' + type +'.zip');
    },
    query(){  
      if (!this.$isEmpty(this.$route.query.qType)) {
        let qType = this.$route.query.qType
        if (qType == 'meterMove') {
          this.qAlertType = '100METER_MOVE'
        } else if (qType == 'prodFix') {
          this.qAlertType = 'PROD_FIX'
        } else if (qType == 'lowRatio') {
          this.qAlertType = 'CLIENT_USER_LOW_RATIO'
        }
        this.qSDate = moment(new Date).subtract(3, 'days')
      }

      // 100METER_MOVE 非租借期間移動警示
      // 48HOURS_STATIC 48小時無動作警示
      // TIME_RETURN 還車通知
      // TIME_GET 取車通知
      // OVER_DISTANCE_STORE 車輛距離店家過遠警示
      // CLIENT_USER_LOW_RATIO 低評價通知
      // INSURANCE_DUE 保險到期通知
      // CARNO_RIDE_DISTANCE 騎乘里程通知
      // PROD_FIX 車輛報修通知
      let qSDate_ = moment(this.qSDate).isValid() ? moment(this.qSDate).format('YYYYMMDD'):''
      let qEDate_ = moment(this.qEDate).isValid() ? moment(this.qEDate).format('YYYYMMDD'):''
      let qForm = {
        page: this.pageCurrent,
        qAlertType: this.qAlertType,
        qSDate: qSDate_,
        qEDate: qEDate_
      }
      this.$axios
        .get('../api/adminAlert/getAdminAlerts',{params: qForm})
        .then((res) => {
          this.alertData.splice(0, this.alertData.length)
            res.data.data.forEach(adminAlert => {
              if(adminAlert.type === '100METER_MOVE') {
                adminAlert.typeDisname = '非租借期間移動警示'
              } else if(adminAlert.type === '48HOURS_STATIC') {
                adminAlert.typeDisname = '48小時無動作警示'
              } else if(adminAlert.type === 'TIME_RETURN') {
                adminAlert.typeDisname = '還車通知'
              } else if(adminAlert.type === 'TIME_GET') {
                adminAlert.typeDisname = '取車通知'
              } else if(adminAlert.type === 'OVER_DISTANCE_STORE') {
                adminAlert.typeDisname = '車輛距離店家過遠警示'
              } else if(adminAlert.type === 'CLIENT_USER_LOW_RATIO') {
                adminAlert.typeDisname = '低評價通知'
              } else if(adminAlert.type === 'INSURANCE_DUE') {
                adminAlert.typeDisname = '保險到期通知'
              } else if(adminAlert.type === 'CARNO_RIDE_DISTANCE') {
                adminAlert.typeDisname = '騎乘里程通知'
              } else if(adminAlert.type === 'PROD_FIX') {
                adminAlert.typeDisname = '車輛報修通知'
              }

              if (adminAlert.solve === 'T') {
                adminAlert.solve = '已處理'
              } else if (adminAlert.solve === 'Y') {
                adminAlert.solve = '通知'
              } else if (adminAlert.solve === 'F') {
                adminAlert.solve = '未處理'
              }
              adminAlert.updateDate = this.$date_(adminAlert.updateDate)
              this.alertData.push(adminAlert)
            })
          this.pageTotal = res.data.pageTotal
        })
    },
    reset(){
      this.qSDate = ''
      this.qEDate = ''
      this.qAlertType = ''
    },
    showDetailForm(alertLog) {
      let path = ''
      let query = {}
      switch (alertLog.type) {
        case '100METER_MOVE':
          path = '/adminProd'
          query = {prodId: alertLog.note}
          break
        case '48HOURS_STATIC':
          path = '/adminQuickReturn'
          query = {clientOrderId: alertLog.note}
          break
        case 'TIME_RETURN':
          path = '/adminQuickReturn'
          query = {clientOrderId: alertLog.note}
          break
        case 'TIME_GET':
          path = '/adminQuickGet'
          query = {clientOrderId: alertLog.note}
          break
        case 'OVER_DISTANCE_STORE':
          path = '/adminProd'
          query = {prodId: alertLog.note}
          break
        case 'CLIENT_USER_LOW_RATIO':
          path = '/adminUserFeedback'
          query = {clientOrderId: alertLog.note}
          break
        case 'INSURANCE_DUE':
          path = '/adminProd'
          query = {prodId: alertLog.note}
          break
        case 'CARNO_RIDE_DISTANCE':
          path = '/adminProd'
          query = {prodId: alertLog.note}
          break
        case 'PROD_FIX':
          path = '/adminProd'
          query = {prodId: alertLog.note}
          break
        
      }
      this.$router.push({path:path,query:query})
    }
  }
}
</script>