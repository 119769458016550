<template>
  <div>
    <!-- search area -->
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="8">
        <el-col :span="8">
          <el-col :span="6">所屬店家</el-col>
          <el-col :span="16">
            <el-select v-model="q.qStoreId">
              <el-option
                width="120"
                v-for="item in selectStores"
                :key="item.code"
                :label="item.disName"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-col>
        </el-col>
        <el-col :span="16">
          日期區間
          <el-date-picker v-model="sDate" type="date" placeholder="起始日期" :clearable="false">
          </el-date-picker>
          ~<el-date-picker v-model="eDate" type="date" placeholder="結束日期" :clearable="false">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-top:15px;">
        <el-col :span="2">
          <el-button type="primary" @click="clickCard()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
      </el-row>
    </el-card>
    <!-- 會員相關圓餅 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="sexPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}}性別分布圖({{sexTotal}}人)</div>
        </el-card>
      </el-col>
      <el-col :span="22">
        <el-card>
          <div id="countryPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}}國籍分布圖({{countryTotal}}人)</div>
        </el-card>
      </el-col>
      <el-col :span="22">
        <el-card>
          <div id="agePie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}}年齡分布圖({{ageTotal}}人)</div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="requestTable" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>

            <!--X: 查詢當天日期(-11month to 0 month) y1:營收(長條)  y2:單數(折線) -->
          </div>
          <div style="text-align:center">註冊會員數(註冊日期)</div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import * as d3 from "d3";
export default {
  data() {
    return {
      stores: [],
      storesMap: [],
      selectStores: [],
      months: [],
      monthsMap: [],
      selectMonth: [],
      sDate: "",
      eDate: "",
      selectMonthsSub: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      nowDate: new Date().getTime(),
      labelPosition: "right",
      q: {
        qStoreId: null,
        qMonth: null,
        qProdStatus: null,
        qSDate: null,
        qEDate: null
      },
      tableData: [],
      pic1ViewDialog: false,
      formLabelWidth: "120px",
      svgWidth: 700,
      svgHeight: 500,
      sexPieData: [],
      countryPieData: [],
      agePieData: [],
      requestChartData: [],
      clientCreateDate: [],
      disSDate:"",
      disEDate:'',
      ageTotal: '0',
      sexTotal: '0',
      countryTotal: '0'
    };
  },
  created() {
    this.sDate = moment(new Date()).startOf("month");
    this.eDate = moment(new Date()).endOf("month");
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        if (res.data.authStores.length > 1) {
          this.selectStores.push({ code: "", disName: "全部" });
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e);
          this.storesMap[e.code] = e.disName;
          this.selectStores.push(e);
        });

        this.selectMonthsSub.forEach(e => {
          this.months.push(this.fter_month_option_value(e));
          this.storesMap[
            this.fter_month_option_value(e).code
          ] = this.fter_month_option_value(e).disName;
          this.selectMonth.push({
            code: this.fter_month_option_value(e),
            disName: this.fter_month_option(e)
          });
        });
        this.q.qStoreId = this.selectStores[0].code;
        this.q.qMonth = this.selectMonth[0].code;
        this.clickCard();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  mounted() {
    this.clickCard();
  },
  name: "AdminClientAnalyze",
  components: {},
  computed: {},
  methods: {
    clickCard() {
      this.disSDate = moment(this.sDate).format("YYYY/MM/DD")
      this.disEDate = moment(this.eDate).format("YYYY/MM/DD")
      this.getGraphData();
    },
    reset() {
      this.q.qCarNo = null;
      this.qStoreId = null;
      this.qProdStatus = null;
      this.sDate = moment(new Date()).startOf("month");
      this.eDate = moment(new Date()).endOf("month");
    },
    fter_store(row, column) {
      let storeId = row[column.property];
      if (storeId == undefined) return "";
      return this.$storeDisName(this.$store.state.stores, storeId);
    },
    trans(path, qType) {
      this.$emit("gotoMenu", path.substring(1));
      this.$router.push({ path: path, query: { qType: qType }});
    },

    fter_month_option(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYY 年 MM 月");
    },
    fter_month_option_value(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYYMM");
    },
    getGraphData() {
      let start_date_moment = moment(this.eDate).subtract(11, "months").startOf("month");
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      this.q.qGraphSDate = _qSdate;
      let start_week_date = moment(this.eDate).subtract(this.qWeeks, "weeks").startOf("week");
      this.q.qWeekSDate = start_week_date.isValid() ? start_week_date.format("YYYYMMDD") : "";
      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      this.q.qEDate = _qEdate;
      this.$axios
        .get("../api/adminReportIndex/getClientGraphData", { params: this.q })
        .then(res => {
          this.sexPieData = res.data.sexPieChartData;
          this.countryPieData = res.data.countryPieChartData;
          this.agePieData = res.data.birthdayData;
          this.requestChartData = res.data.clientCreateData;
          this.clientCreateDate = res.data.clientCreateDate;
          this.ageTotal = res.data.ageTotal
          this.sexTotal =res.data.sexTotal
          this.countryTotal = res.data.countryTotal
          this.sexPieChart();
          this.agePieChart();
          this.countryPieChart();
          this.requestBarChart();
        })
        .catch(res => {
          console.log(res.response);
          this.$alert("login error", '結果', { confirmButtonText: '確定' });
        });
    },
    sexPieChart() {
      let pieSvg = d3.selectAll("#sexPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#sexPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr("transform", "translate(" + this.svgWidth / 2 + "," + radius + ")");
      let showText = g.append("text").attr("text-anchor", "middle")
                      .attr("transform", "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")");
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.sexPieData))
        .enter()
        .append("g")
        .on("mouseleave", () => { showText.text("") });
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform)).on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    countryPieChart() {
      let pieSvg = d3.selectAll("#countryPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#countryPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        )
        .on("mouseleave", () => {
          showText.text("");
        });
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.countryPieData))
        .enter()
        .append("g").on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.countryPieData))
        .enter()
        .append("g");
      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style(
          "text-shadow",
          "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"
        );
    },
    agePieChart() {
      let pieSvg = d3.selectAll("#agePie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#agePie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr("transform", "translate(" + this.svgWidth / 2 + "," + radius + ")")
        .on("mouseleave", () => {
          showText.text("");
        });

      let showText = g.append("text").attr("text-anchor", "middle")
                      .attr("transform", "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")");
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");

      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.agePieData))
        .enter()
        .append("g");

      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.agePieData))
        .enter()
        .append("g");
      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    requestBarChart() {
      let data = this.requestChartData;
      let barSvg = d3.select("#requestTable").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#requestTable")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.clientCreateDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      //長條
      var rectStep = (700 - 70) / this.requestChartData.length;
      var g = barSvg.append("g")
      g
        .selectAll("rect")
        .data(this.requestChartData)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 5)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(10,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "人"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

    }
  }
};
</script>

<style scoped>
.vert {
  height: 100%;
  display: flex;
  /* -webkit-align-items: center; */
}
.col-row {
  display: flex;
}
.selectit {
  background-color: yellowgreen;
}
.empty {
  background-color: white;
}
</style>
