<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- search area -->
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="8">
        <el-col :span="6">
          <el-col :span="6">地區</el-col>
          <el-col :span="16">
            <el-select v-model="qAreaId" width="120">
          <el-option 
            v-for="item in areas"
            :key="item.code"
            :label="item.disName"
            :value="item.code"
            >
          </el-option>
        </el-select>
          </el-col>
        </el-col>
        <el-col :span="6">
          <el-col :span="6">店家</el-col>
          <el-col :span="16">
            <el-select v-model="qStoreId_" multiple width="120">
          <el-option 
            v-for="item in selectStores"
            :key="item.code"
            :label="item.disName"
            :value="item.code"
            v-show="item.areaId == qAreaId || qAreaId == ''"
            >
          </el-option>
        </el-select>
          </el-col>
        </el-col>
        <el-col :span="12">
          日期區間
          <el-date-picker v-model="sDate" type="date" placeholder="起始日期" :clearable="false">
          </el-date-picker>
          ~<el-date-picker v-model="eDate" type="date" placeholder="結束日期" :clearable="false">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-top:15px;">
        <el-col :span="2">
          <el-button type="primary" @click="clickCard()">查詢</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="downAllStoreReportExcel()">下載總門店報表</el-button>
        </el-col>
      </el-row>
    </el-card>
    <!-- kanban -->
    <el-row :gutter="10">
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          營收<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.revenue | filter_commaFormate }}</span>元
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;"
          @click.native="downXls('orderCount')">
          訂單數<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.orderCount | filter_commaFormate }}</span>筆
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          平均訂單收入<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ (isFinite(board.revenue / board.orderCount) ? board.revenue / board.orderCount : 0).toFixed(0) | filter_commaFormate }}</span>元/單
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          每車平均營收貢獻<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ (isFinite(board.revenue / board.prodCount) ? board.revenue / board.prodCount : 0).toFixed(0) | filter_commaFormate }}</span>元
        </el-card>
      </el-col>
    </el-row>
    <!-- kanban -->
    <el-row :gutter="10" class="vol-row">
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          新客戶數<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.newOrderCount }}</span>筆
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;"
          @click.native="downXls('cancelOrders')">
          取消訂單<br />
          <span style="color:green;font-size:40px;margin-left:10px;">{{ board.cancelOrderCount }}</span>筆
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          取消訂單占比<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ (isFinite(board.cancelOrderCount / (board.orderCount + board.cancelOrderCount)) ?
            (board.cancelOrderCount / (board.orderCount + board.cancelOrderCount)) * 100 : 0).toFixed(0) }}</span>%
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          取消訂金營收<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.depositRevenue | filter_commaFormate }}</span>元
        </el-card>
      </el-col>
    </el-row>
    <!-- kanban -->
    <el-row :gutter="10" class="vol-row">
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          總騎乘里程<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.distance | filter_commaFormate }}</span>公里
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          平均車數<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ (board.prodCount).toFixed(1) | filter_commaFormate }}</span>台
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          平均租借時間(短租)<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ (isFinite(board.rangeByMin / board.shortRentCount) ?
            board.rangeByMin / board.shortRentCount / 60 : 0).toFixed(1) | filter_commaFormate}}</span>小時
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;"
          @click.native="downXls('reservation')">
          預約訂單數(下單)<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.reservationCount }}</span>筆
        </el-card>
      </el-col>
    </el-row>
    <!-- kanban -->
    <el-row :gutter="10" class="vol-row" style="margin-bottom: 10px;">
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          車輛平均定價<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ (isFinite(board.expectedIncome / board.prodCount) ?
            board.expectedIncome / board.prodCount : 0).toFixed(0) | filter_commaFormate}}</span>元
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          業外營收<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.invoiceRevenue| filter_commaFormate}}</span>元
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          長租車平均數<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.longRentCount}}</span>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          長租分期數<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.stageCount}}</span>
        </el-card>
      </el-col>
    </el-row>

    <!-- kanban -->

    <!-- 放新看板位置 -->
    <!-- <el-row :gutter="10" class="vol-row">
  
    </el-row> -->

    <!-- ------------------------------------圖型分析--------------------------------------------------- -->

    <!--尾款支付方式圓餅圖-->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="entirePayWayPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!--有幾種支付方式-->
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 消費者尾款支付方式 (訂單數)</div>
        </el-card>
      </el-col>
    </el-row>
    <!--分期支付方式圓餅圖-->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="stagePayWayPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!--有幾種支付方式-->
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}} 消費者分期支付方式 (分期數)</div>
        </el-card>
      </el-col>
    </el-row>
    <!--營收訂單表-->
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="revenueTable" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>

            <!--X: 查詢當天日期(-11month to 0 month) y1:營收(長條)  y2:單數(折線) -->
          </div>
          <div style="text-align:center">
            近12個月營運概況(營收(萬)：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            訂單數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!--周營收訂單表-->
    <el-row :gutter="20" class="vol-row">
      <el-col :span="22">
        <el-card>
          <div id="weekRevenueTable" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>

            <!--X: 查詢當天日期(-12week to 0 month) y1:營收(長條)  y2:單數(折線) -->
          </div>
          <div style="text-align:center">
            近<el-input-number v-model="qWeeks" />周營運概況(營收(萬)：
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            訂單數：
            <svg style="background-color:red;width:20px;height:5px;"></svg>)<el-button type="primary" size="mini" @click="getGraphData()">查詢</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!--保險看板-->
    <el-row :gutter="10" class="vol-row">
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          短租保障收入<br />
          <span style="color:green;font-size:40px;margin-left:10px;text-align:center;">
            {{ board.insuranceCount*100 | filter_commaFormate }}</span>元
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          短租保障單數<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ board.insuranceCount | filter_commaFormate }}</span>單
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          保障收入營收占比<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ ((isFinite(board.insRevenue / board.revenue) ? board.insRevenue / board.revenue : 0) * 100).toFixed(0) }}</span>%
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="always"
          style="margin:0 auto;margin-top:5px;text-align:center;">
          保障訂單占比<br />
          <span style="color:green;font-size:40px;margin-left:10px;">
            {{ ((isFinite(board.insuranceCount / board.shortRentCount) ? board.insuranceCount / board.shortRentCount : 0) * 100).toFixed(0) }}</span>%
        </el-card>
      </el-col>
    </el-row>
    <!-- 保險表-->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="insTable" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!-- x:日期 y:0~100% 保險訂單占比-->
          </div>
          <div style="text-align:center">近12個月保障訂單占比(X軸：時間；Y軸：保障數/訂單數)</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 長短租營收分布圖 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="rentDateTypePie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}}長短租營收分布圖</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 預約訂單統計 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="clientTypeChange" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
            <!--x:當日(-11月~0月)y:0~100%-->
          </div>
          <!-- clientTypeKey:["1","2","3","4","5","6","500"], -->
          <div style="text-align:center">
            預約訂單統計(不包含自動取消)<br /> (網頁
            <svg style="background-color:steelBlue;width:20px;height:14px;"></svg>
            ，會員系統
            <svg style="background-color:#ff7f0e;width:20px;height:14px;"></svg>
            ，電話
            <svg style="background-color:#2ca02c;width:20px;height:14px;"></svg>
            ，OTA平台
            <svg style="background-color:#d62728;width:20px;height:14px;"></svg>
            ，其他
            <svg style="background-color:#9467bd;width:20px;height:14px;"></svg>
            )
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 智取比例圖 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="keyBoxPie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}}智取比例圖</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 業外營收分布圖 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div id="saleInvoicePie" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>
          <div style="text-align:center">{{disSDate}}~{{disEDate}}業外營收分布圖</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 未來訂單分布圖 -->
    <el-row>
      <el-col :span="22">
        <el-card>
          <div style="position: relative; text-align: center;">
            <!-- SVG 圖表 -->
            <div id="futureDistribution" style="position: relative;">
            <svg :width="svgWidth" :height="svgHeight"></svg>

            <!-- 下拉式選擇框 -->
            <div style="position: absolute; top: 98%; right: 65%; margin-top: 10px;">
              預計取車時間(天數)
              <el-select id="selectDays" placeholder="預計取車時間(天數)" style="width:50%" v-model="selectedDays" @change="updateChart" size="small">
                <el-option :value="7" label="7天"></el-option>
                <el-option :value="14" label="14天">14天</el-option>
                <el-option :value="30" label="30天">30天</el-option>
                <!-- 其他選項... -->
              </el-select>
            </div>
          </div>
             <!--futureOrdereKey:["10","1100","0","1000","1200","50"]-->
            <div style="position: absolute; top: 30%; left: 90%; transform: translateX(-50%); margin-top: 10px;">         
              <div style="position: relative; top: 0; left: 50%; transform: translateX(-50%); margin-bottom:10px">
                <span>目前數量:</span> 
                <span id="titleForSVG" ></span>
             
              </div>        
            等待付訂
            <svg style="background-color:#1f77b4;width:20px;height:14px;"></svg>
            <br>取消訂單
            <svg style="background-color:#ff7f0e;width:20px;height:14px;"></svg>
            <br>等待確定
            <svg style="background-color:#2ca02c;width:20px;height:14px;"></svg>
            <br>取消預約
            <svg style="background-color:#d62728;width:20px;height:14px;"></svg>
            <br>自動取消
            <svg style="background-color:#9467bd;width:20px;height:14px;"></svg>
            <br>等待取車
            <svg style="background-color:#8c564b;width:20px;height:14px;"></svg>
          </div>
          </div>
          <div style="text-align:center; font-size: 24px;">未來訂單分布情況</div>
          <p style="text-align:center">根據預約訂單 預約取車時間的 訂單狀態分布(若是自動取消類別有多筆訂單在同一間僅記錄1筆)</p>
          <!---
          <div id="futureOrder" style="text-align:center">
            <svg :width="svgWidth" :height="svgHeight"></svg>
          </div>-->
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import * as d3 from "d3";
export default {
  data() {
    return {
      fullscreenLoading: false,
      board: {
        revenue: 0,
        orderCount: 0, //看板數量
        distance: 0,
        cancelOrderCount: 0,
        depositRevenue: 0,
        insRevenue: 0,
        insuranceCount: 0,
        prodCount: 0,
        newOrderCount: 0,
        rangeByMin: 0,
        longRentCount: 0,
        reservationCount: 0,
        shortRentCount: 0,
        reactionMin: 0,
        reactionOrderCount: 0,
        expectedIncome: 0,
        invoiceRevenue: 0,
        limitedOrderCount: 0,
        payedDeposit: 0,
        stageCount: 0,
        reservationCountBySDate: 0
      },
      selectedDays: 7,
      areas: [],
      stores: [],
      storesMap: [],
      selectStores: [],
      months: [],
      monthsMap: [],
      sDate: "",
      eDate: "",
      nowDate: new Date().getTime(),
      labelPosition: "right",
      q: {
        qStoreId: '',
        qProdStatus: null,
        qSDate: null,
        qEDate: null,
      },
      qAreaId: '',
      qStoreId_: null,
      tableData: [],
      formLabelWidth: "120px",
      svgWidth: 700,
      svgHeight: 500,
      entireKey: [],
      stageKey: [],
      revenueDate: [],
      weekRevenueDate: [],
      revenueSumData: [],
      weekRevenueSumData: [],
      revenueCounteData: [],
      weekRevenueCounteData: [],
      shortRentCountData:[],
      saleInvoicePieData:[],
      futureOrderTime:[], //時間
      futureOrderData:[], //資料
      insTableData: [],
      clientTypeChangeData: [],
      rentDateTypeData: [],
      clientTypeChangeDate: [],
      entirePayWayCriData: [],
      stagePayWayCriData: [],
      clientTypeKey:["1","2","4","5","500","6"],
      futureOrdereKey:["10","1100","0","1000","1200","50"],
      disSDate:"",
      disEDate:'',
      qWeeks: 12
    };
  },
  created() {
    this.sDate = moment(new Date()).startOf("month");
    this.eDate = moment(new Date()).endOf("month");
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        if(res.data.areas.length>1){
          this.areas.push({code:'',disName:'全部'})
        }
        res.data.areas.forEach(e => {
          this.areas.push(e)
        });
        this.qAreaId = this.areas[0].code
        res.data.authStores.forEach(e => {
          this.stores.push(e);
          this.storesMap[e.code] = e.disName;
          this.selectStores.push(e);
        });

        this.clickCard();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  mounted() {
    this.clickCard();
  },
  name: "AdminReportIndex",
  components: {},
  computed: {},
  methods: {
    clickCard() {
      this.disSDate = moment(this.sDate).format("YYYY/MM/DD")
      this.disEDate = moment(this.eDate).format("YYYY/MM/DD")
      this.q.qStoreId = ''
      this.qStoreId_.forEach(e=>{
        this.q.qStoreId = this.q.qStoreId + e +','
      })
      if (this.qStoreId_.length > 0){
        this.q.qStoreId = this.q.qStoreId.substring(0,this.q.qStoreId.length -1)
      }
      this.getBoards();
      this.getGraphData();
      this.updateChart()
    },
    async getBoards() {
      this.fullscreenLoading = true
      let start_date_moment = moment(this.sDate);
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      this.q.qSDate = _qSdate;
      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      this.q.qEDate = _qEdate;
      await this.$axios
        .get("../api/adminReportIndex/getBoards", { params: this.q })
        .then(res => {
          if (res.data.rs == "success") {
            // getRevenueForBoard
            this.board.revenue = res.data.data.revenue
            this.board.orderCount = res.data.data.orderCount
            this.board.stageCount = res.data.data.stageCount
            // getInsuranceForBoard
            this.board.insRevenue = res.data.data.insRevenue
            this.board.insuranceCount = res.data.data.insuranceCount
            // getClientStatusForBoard
            this.board.longRentCount = res.data.data.longRentCount
            // getCancelOrderForBoard
            this.board.depositRevenue = res.data.data.depositRevenue
            this.board.cancelOrderCount = res.data.data.cancelOrderCount
          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
        })
        .catch(res => {
          console.log(res.response);
          this.$alert("不知名錯誤001，請聯絡管理員", '結果', { confirmButtonText: '確定' });
          this.fullscreenLoading = false
        });
      await this.$axios
        .get("../api/adminReportIndex/getBoardsPart2", { params: this.q })
        .then(res => {
          if (res.data.rs == "success") {
            // getRangeForBoard
            this.board.rangeByMin = res.data.data.rangeByMin
            this.board.rangeByDays = res.data.data.rangeByDays
            this.board.shortRentCount = res.data.data.shortRentCount
            // getDistanceForBoard
            this.board.distance = res.data.data.distance
            // getprodQtyForBoard
            this.board.prodCount = res.data.data.prodCount
            this.board.expectedIncome = res.data.data.expectedIncome
            this.board.longRentCount = res.data.data.longRentCount
            // getNewClinetForBoard
            this.board.newOrderCount = res.data.data.newOrderCount
          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
        })
        .catch(res => {
          console.log(res.response);
          this.$alert("不知名錯誤002，請聯絡管理員", '結果', { confirmButtonText: '確定' });
          this.fullscreenLoading = false
        });
      await this.$axios
        .get("../api/adminReportIndex/getBoardsPart3", { params: this.q })
        .then(res => {
          if (res.data.rs == "success") {
            // getReservationOrderForBoard
            this.board.reservationCount = res.data.data.reservationCount
            this.board.reservationCountBySDate = res.data.data.reservationCountBySDate
            // getInvoiceRevenueForBoard
            this.board.invoiceRevenue = res.data.data.invoiceRevenue
          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
          this.fullscreenLoading = false
        })
        .catch(res => {
          console.log(res.response);
          this.$alert("不知名錯誤003，請聯絡管理員", '結果', { confirmButtonText: '確定' });
          this.fullscreenLoading = false
        });
        await this.$axios
        .get("../api/adminReportIndex/getBoardsPart4", { params: this.q })
        .then(res => {
          if (res.data.rs == "success") {
            // getReactionTimeForBoard
            this.board.reactionMin = res.data.data.reactionMin
            this.board.reactionOrderCount = res.data.data.reactionOrderCount
          } else {
            this.$alert(res.data.rsMsg, '結果', { confirmButtonText: '確定' });
          }
          this.fullscreenLoading = false
        })
        .catch(res => {
          console.log(res.response);
          this.$alert("不知名錯誤004，請聯絡管理員", '結果', { confirmButtonText: '確定' });
          this.fullscreenLoading = false
        });
    },
    reset() {
      this.q.qCarNo = null;
      this.q.qStoreId = '';
      this.qStoreId_ = [];
      this.qProdStatus = null;
      this.sDate = moment(new Date()).startOf("month");
      this.eDate = moment(new Date()).endOf("month");
      this.getGraphData();
    },
    fter_store(row, column) {
      let storeId = row[column.property];
      if (storeId == undefined) return "";
      return this.$storeDisName(this.$store.state.stores, storeId);
    },
    trans(path, qType) {
      this.$emit("gotoMenu", path.substring(1));
      this.$router.push({ path: path, query: { qType: qType }});
    },

    fter_month_option(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYY 年 MM 月");
    },
    fter_month_option_value(subNum) {
      return moment(moment.nowDate)
        .subtract(subNum, "month")
        .format("YYYYMM");
    },
    entirePieChart() {
      let pieSvg = d3.selectAll("#entirePayWayPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#entirePayWayPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.entirePayWayCriData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.entirePayWayCriData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    stagePieChart() {
      let pieSvg = d3.selectAll("#stagePayWayPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#stagePayWayPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.stagePayWayCriData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.stagePayWayCriData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + ":" + d.data.percentage + "%");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}:${d.data.percentage}%`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    //直條圖
    revenueBarGrap() {
      this.revenueSumData.forEach(e => e / 1000);
      let data = [];
      let data_ = Object.assign([], data, this.revenueSumData);
      data_.forEach(e => data.push(e / 10));

      let barSvg = d3.select("#revenueTable").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#revenueTable")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDate)
        .range([0, this.svgWidth - 80]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.revenueCounteData)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(660,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 90) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 5)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(10,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "萬"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDate[i]))
        .y((d, i) => yRScale(this.revenueCounteData[i]));
      barSvg
        .append("path")
        .attr("d", line(this.revenueCounteData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.revenueCounteData)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "筆"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    weekRevenueGrap() {
      this.weekRevenueSumData.forEach(e => e / 1000);
      let data = [];
      let data_ = Object.assign([], data, this.weekRevenueSumData);
      data_.forEach(e => data.push(e / 10));

      let barSvg = d3.select("#weekRevenueTable").select("svg");
      barSvg.remove();
      barSvg = d3
        .select("#weekRevenueTable")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      //軸
      let xScale = d3
        .scaleBand()
        .domain(this.weekRevenueDate)
        .range([0, this.svgWidth - 80]);
      let x_axis = d3.axisBottom().scale(xScale);
      let yLScale = d3
        .scaleLinear()
        .domain([0, d3.max(data)])
        .range([this.svgHeight - 30, 0]);
      let y_L_axis = d3
        .axisLeft()
        .scale(yLScale)
        .ticks(5);
      let gXAxis = barSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let gYLAxis = barSvg.append("g").attr("transform", "translate(40,10)");
      y_L_axis(gYLAxis);
      let yRScale = d3
        .scaleLinear()
        .domain([0, d3.max(this.weekRevenueCounteData)])
        .range([this.svgHeight - 50, 0]);
      let y_R_axis = d3
        .axisRight()
        .scale(yRScale)
        .ticks(5);
      let gYRAxis = barSvg.append("g").attr("transform", "translate(660,30)");
      y_R_axis(gYRAxis);
      //長條
      var rectStep = (700 - 90) / data.length;
      var g = barSvg.append("g");
      g.selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", "steelBlue")
        .attr("x", (d, i) => 40 + i * rectStep)
        .attr("y", d => yLScale(d) - 20)
        .attr("width", rectStep - 5)
        .attr("height", d => this.svgHeight - 30 - yLScale(d))
        .attr("transform", "translate(10,30)");
      g.selectAll("#barText")
        .data(data)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "萬"))
        .attr("id", "barText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 40 + i * (rectStep + 1))
        .attr("y", d => yLScale(d))
        .attr("font-size", "13px")
        .attr("transform", "translate(" + rectStep / 2 + ",10)");

      //折線
      let line = d3
        .line()
        .x((d, i) => xScale(this.weekRevenueDate[i]))
        .y((d, i) => yRScale(this.weekRevenueCounteData[i]));
      barSvg
        .append("path")
        .attr("d", line(this.weekRevenueCounteData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",30)")
        .attr("stroke", "red")
        .attr("stroke-width", 2)
        .attr("fill", "none");

      g.selectAll("#lineText")
        .data(this.weekRevenueCounteData)
        .enter()
        .append("text")
        .text(d => (d == 0 ? 0 : d + "筆"))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", d => yRScale(d))
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",25)")
        .style("fill", "red");
    },
    getGraphData() {
      let start_date_moment = moment(this.eDate).subtract(11, "months").startOf("month");
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";
      this.q.qGraphSDate = _qSdate;
      let start_week_date = moment(this.eDate).subtract(this.qWeeks-1, "weeks");
      this.q.qWeekSDate = start_week_date.isValid() ? start_week_date.format("YYYYMMDD") : "";
      let end_date_moment = moment(this.eDate);
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      this.q.qEDate = _qEdate;
      this.$axios
        .get("../api/adminReportIndex/getGraphData", { params: this.q })
        .then(res => {     
          this.entireKey = res.data.entirePayWayKey;
          this.entirePayWayCriData = res.data.entirePayWayTotal;
          this.entirePieChart();

          this.stageKey = res.data.stagePayWayKey;
          this.stagePayWayCriData = res.data.stagePayWayTotal;
          this.stagePieChart();

          this.revenueDate = res.data.revenueDate,
          this.revenueSumData = res.data.revenueSumData,
          this.revenueCounteData = res.data.revenueCounteData;
          this.revenueBarGrap();

          this.weekRevenueDate = res.data.weekRevenueDate,
          this.weekRevenueSumData = res.data.weekRevenueSumData,
          this.weekRevenueCounteData = res.data.weekRevenueCounteData;
          this.weekRevenueGrap(); 

          this.shortRentCountData = res.data.shortRentCountData;
          this.insTableData = res.data.insTableData;
          this.insChart();
          this.rentDateTypeData = res.data.rentDateTypeData
          this.rentDateTypePieChart();
          this.clientTypeChangeData = res.data.clientTypeChange
          this.clientTypeChangeDate = res.data.clientTypeChangeDate
          this.clientTypeChangeChart()
          this.keyBoxData = res.data.keyBoxData
          this.keyBoxPieChart();
          this.saleInvoicePieData = res.data.saleInvoicePieData
          this.saleInvoicePieChart();
        })
        .catch(res => {
          console.log("error_"+res.response);
          this.$alert("login error1", '結果', { confirmButtonText: '確定' });
        });
    },
    insChart() {
      let insSvg = d3.select("#insTable").select("svg");
      insSvg.remove();
      insSvg = d3
        .select("#insTable")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let xScale = d3
        .scaleBand()
        .domain(this.revenueDate)
        .range([0, this.svgWidth - 50]);
      let x_axis = d3.axisBottom().scale(xScale);
      let gXAxis = insSvg.append("g").attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let yScale = d3
        .scaleLinear()
        .domain([0, 100])
        .range([this.svgHeight - 30, 0]);
      let y_axis = d3.axisLeft().scale(yScale);
      let gYAxis = insSvg.append("g").attr("transform", "translate(40,10)");
      y_axis(gYAxis);
      let line = d3
        .line()
        .x((d, i) => xScale(this.revenueDate[i]))
        .y((d, i) =>
          yScale(isFinite(this.insTableData[i] / this.shortRentCountData[i]) ? (this.insTableData[i] / this.shortRentCountData[i]) * 100 : 0)
        );
      var rectStep = (700 - 70) / this.revenueDate.length;
      let g = insSvg.append("g");
      insSvg
        .append("path")
        .attr("d", line(this.insTableData))
        .attr("transform", "translate(" + (rectStep / 2 + 40) + ",10)")
        .attr("stroke", "steelBlue")
        .attr("stroke-width", 2)
        .attr("fill", "none");
      g.selectAll("text")
        .data(this.insTableData)
        .enter()
        .append("text")
        .text((d,i) => (d == 0 ? 0 : (isFinite(this.insTableData[i] / this.shortRentCountData[i]) ? (this.insTableData[i] / this.shortRentCountData[i]) * 100 : 0).toFixed(0)+'%'))
        .attr("id", "lineText")
        .attr("text-anchor", "middle")
        .attr("x", (d, i) => 50 + i * (rectStep + 1))
        .attr("y", (d, i) =>
          yScale(isFinite(this.insTableData[i] / this.shortRentCountData[i]) ? (this.insTableData[i] / this.shortRentCountData[i]) * 100 : 0)
        )
        .attr("font-size", "15px")
        .attr("transform", "translate(" + rectStep / 2 + ",13)")
        .style("fill", "red");
    },
    downXls(type) {
      if (this.sDate === "" || this.eDate === "" || this.sDate === null || this.eDate === null) {
        this.$alert("日期區間不可為空", "結果", { confirmButtonText: "確定" });
        return;
      }
      if (moment(this.eDate) - moment(this.sDate) > 90 * 86400000) {
        this.$alert("日期區間不可超過90天", "結果", { confirmButtonText: "確定" });
        return;
      }
      let _qSDate = moment(this.sDate).isValid() ? moment(this.sDate).format("YYYYMMDD") : "";
      let _qEDate = moment(this.eDate).isValid() ? moment(this.eDate).format("YYYYMMDD") : "";
      let url = "../api/clientOrder/export/excel?" + "&qStoreId=" + this.q.qStoreId;

      if (type === "orderCount") {
        url += "&qPayDateStart=" + _qSDate + "&qPayDateEnd=" + _qEDate;
      } else if (type === "cancelOrders") {
        url += "&qCancelDateStart=" + _qSDate + "&qCancelDateEnd=" + _qEDate;
      } else if (type === "reservation") {
        url += "&qCreateDateStart=" + _qSDate + "&qCreateDateEnd=" + _qEDate + "&qRentDateType=短租&downExcelType=create";
      }
      window.open(url);
    },
    rentDateTypePieChart() {
      let pieSvg = d3.selectAll("#rentDateTypePie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#rentDateTypePie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.rentDateTypeData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.rentDateTypeData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + "(" + d.data.percentage + "%)");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}(${d.data.percentage}%)`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    clientTypeChangeChart() {
      let maxTotal = 0
      this.clientTypeChangeData.forEach(e=>{
        maxTotal = e.total > maxTotal ? e.total: maxTotal
      })
      let changeSvg = d3.select("#clientTypeChange").select("svg");
      changeSvg.remove();
      changeSvg = d3
        .select("#clientTypeChange")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);

      var rectStep = (700 - 70) / this.clientTypeChangeDate.length;

      let xScale = d3
        .scaleBand()
        .domain(this.clientTypeChangeDate)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let gXAxis = changeSvg
        .append("g")
        .attr("transform", "translate(40,480)");
      x_axis(gXAxis);
      let yScale = d3
        .scaleLinear()
        .domain([0,maxTotal])
        .range([this.svgHeight - 40, 0]);
      let y_axis = d3.axisLeft().scale(yScale);

      let gYAxis = changeSvg
        .append("g")
        .attr("transform", "translate(40,20)");
      y_axis(gYAxis);

      let series = d3
        .stack()
        .keys(this.clientTypeKey)
        .offset(d3.stackOffsetDiverging)(this.clientTypeChangeData)
        .map(d => (d.forEach(v => (v.key = d.key)), d));
      let color = d3.scaleOrdinal(d3.schemeCategory10);

      changeSvg
        .append("g")
        .selectAll("g")
        .data(series)
        .enter()
        .append("g")
        .attr("fill", d => color(d.key))
        .selectAll("rect")
        .data((d, i) => series[i])
        .enter()
        .append("rect")
        .attr("x", (d, i) => xScale(this.clientTypeChangeDate[i]))
        .attr("y", d => yScale(d[1]))
        .attr("height", d => yScale(d[0]) - yScale(d[1]))
        .attr("width", rectStep-5)
        .attr("transform", "translate(45,20)");
    },
    keyBoxPieChart() {
      let pieSvg = d3.selectAll("#keyBoxPie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#keyBoxPie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.keyBoxData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.keyBoxData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + "(" + d.data.percentage + "%)");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}(${d.data.percentage}%)`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    saleInvoicePieChart() {
      let pieSvg = d3.selectAll("#saleInvoicePie").select("svg");
      pieSvg.remove();
      pieSvg = d3
        .select("#saleInvoicePie")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight);
      let radius = Math.min(this.svgHeight, this.svgWidth) / 2;
      let g = pieSvg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.svgWidth / 2 + "," + radius + ")"
        );
      let showText = g.append("text").attr("text-anchor", "middle").attr(
          "transform",
          "translate(" + this.svgWidth / 4 + ",-"+(radius-25)+")"
        );
      showText
        .append("tspan")
        .attr("class", "percentage")
        .attr("font-size", "3em")
        .text("");
      let color = d3.scaleOrdinal(d3.schemeCategory10);
      let pie = d3.pie().value(d => d.percentage);

      let path = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(0);
      let arc = g
        .selectAll("arc")
        .data(pie(this.saleInvoicePieData))
        .enter()
        .append("g")
        .on("mouseleave", () => {
          showText.text("");
        });
      let arc2 = g
        .selectAll("arc")
        .data(pie(this.saleInvoicePieData))
        .enter()
        .append("g");
      arc
        .append("path")
        .attr("d", path)
        .attr("fill", d => color(d.data.platform))
        .on("mouseenter", d => {
          showText.text(d.data.platform + "(" + d.data.percentage + "%)");
        });

      let label = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(220);

      arc2
        .append("text")
        .attr("transform", d => `translate(${label.centroid(d)})`)
        .attr("text-anchor", "middle")
        .text(d => `${d.data.platform}(${d.data.percentage}%)`)
        .style("fill", "white")
        .style("text-shadow", "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000");
    },
    async futureOrderDataChart(){
      // 計算最大總數
      let maxTotal = 0
      this.futureOrderData.forEach(e=>{
        maxTotal = e.total > maxTotal ? e.total: maxTotal
      })

      // 刪除現有的SVG元素並添加新的SVG元素
      let futureOrderSvg = d3.select("#futureDistribution").select("svg");
      futureOrderSvg.remove();
      futureOrderSvg = d3
        .select("#futureDistribution")
        .append("svg")
        .attr("width", this.svgWidth)
        .attr("height", this.svgHeight)
        // .append("g");

      // 計算每個矩形的寬度
      var rectStep = (700 - 70) / (this.futureOrderTime.length);

      // 定義x軸比例尺和軸
      let xScale = d3
        .scaleBand()
        .domain(this.futureOrderTime)
        .range([0, this.svgWidth - 60]);
      let x_axis = d3.axisBottom().scale(xScale);
      let gXAxis = futureOrderSvg
        .append("g")
        .attr("transform", "translate(40,480)");
      x_axis(gXAxis);

      // 定義y軸比例尺和軸
      let yScale = d3
        .scaleLinear()
        .domain([0,maxTotal])
        .range([this.svgHeight - 40, 0]);
      let y_axis = d3.axisLeft().scale(yScale);

      let gYAxis = futureOrderSvg
        .append("g")
        .attr("transform", "translate(40,20)");
      y_axis(gYAxis);

      // 定義堆疊數據
      let series = d3
        .stack()
        .keys(this.futureOrdereKey)
        .offset(d3.stackOffsetDiverging)(this.futureOrderData)
        .map(d => (d.forEach(v => (v.key = d.key)), d));
      let color = d3.scaleOrdinal(d3.schemeCategory10);

      // 創建一個群組元素來容納堆疊標題和矩形元素
      let g = futureOrderSvg.append("g")
      // let g_ = futureOrderSvg
        .append("g")

      // 添加顯示堆疊名稱的文本元素
      // let showText = g_
      //   .append("text")
      //   .attr("text-anchor", "midden")

      // 創建矩形元素並添加鼠標事件
      g.selectAll("g")
      .data(series)
      .enter()
      .append("g")
      .attr("fill", d => color(d.key))
        .selectAll("rect")
        .data((d, i) => series[i])
        .enter()
        .append("rect")
        .attr("x", (d, i) => xScale(this.futureOrderTime[i]))
        .attr("y", d => yScale(d[1]))
        .attr("height", d => yScale(d[0]) - yScale(d[1]))
        .attr("width", rectStep-5)
        .attr("transform", "translate(45,20)")

        .on("mouseleave", () => {
          document.getElementById("titleForSVG").textContent = "";
        })
        .on("mouseenter", (d)  => {
          let data = d[1] - d[0]
          document.getElementById("titleForSVG").textContent =  data
          // console.log("數量"+data);
        })

        await this.futureOrderDataList();
    },
    async updateChart() {
      const days = parseInt(this.selectedDays, 10);
      //let start_date_moment = moment('20240115', 'YYYYMMDD');
      let start_date_moment = moment(); // 使用 moment() 取得當前日期時間
      let _qSdate = start_date_moment.isValid() ? start_date_moment.format("YYYYMMDD") : "";

      // 設定結束時間為今天之後的指定天數
      let end_date_moment = start_date_moment.add(days-1, 'days');
      let _qEdate = end_date_moment.isValid() ? end_date_moment.format("YYYYMMDD") : "";
      let params = {qSDate: _qSdate, qEDate: _qEdate, qStoreId: this.q.qStoreId}
      this.$axios
        .get("../api/adminReportIndex/getFutureOrderDate", { params: params })
        .then(res => {
          this.futureOrderTime = res.data.futureOrderDate //時間
          this.futureOrderData = res.data.futureOrderDateList //資料
          this.futureOrderDataChart();
          this.futureOrderDataList();

          this.waitConfirm = res.data.waitConfirm; // 等待確認
          this.waitDeposit = res.data.waitDeposit; // 等待付訂
          this.waitPickup = res.data.waitPickup; // 等待取車
          this.reservedCancel = res.data.reservedCancel; // 取消預約
          this.orderCancel = res.data.orderCancel; // 取消訂單
          this.autoCancel = res.data.autoCancel; // 自動取消
        })
        .catch(res => {
          console.log(res.response);
          this.$alert("login error2", '結果', { confirmButtonText: '確定' });
        });
    },

  async futureOrderDataList() {
    d3.select("#futureOrder").selectAll("*").remove();

    // 假设 this.futureOrderData 是表格數據
    const tableData = this.futureOrderData;
    // 获取表格容器
    const tableContainer = d3.select("#futureOrder");
    // 创建表格元素
    const table = tableContainer.append("table")
      .classed('custom-table', true)  // 添加一个自定义类名
      .style('border', '1px solid #ddd')  // 添加表格外框
      .style('margin', 'auto');  // 將表格居中

    // 创建表头
    const thead = table.append('thead')
      .classed('thead-dark', true);  // 添加表头的背景色类名

    const headerRow = thead.append('tr');
    // 在表头最前面插入"序號"
    headerRow.append('th')
      .text('序號')
      .style('border', '1px solid #ddd');  // 添加表头的外框

    // 假设表格数据的第一个对象包含表头信息
    const headerData = ["等待確認","等待付訂","等待取車","取消預約","取消訂單","自動取消","日期","總計"]
    headerData.forEach(header => {
      headerRow.append('th')
        .text(header)
        .style('border', '1px solid #ddd');  // 添加表头的外框
    });
    // 创建表格内容
    const tbody = table.append('tbody');
    tableData.forEach((rowData, index) => {
      const row = tbody.append('tr');
      // 在每一行最前面插入訂單號
      row.append('td')
        .text(index + 1)
        .style('border', '1px solid #ddd');  // 添加表格单元格的外框
      Object.values(rowData).forEach(value => {
        row.append('td')
          .text(value)
          .style('border', '1px solid #ddd');  // 添加表格单元格的外框
      });
    });
    },
    downAllStoreReportExcel(){
      let params ={}
      Object.assign(params,{},this.q)
      params.sDate = moment(this.sDate).format('YYYYMMDD'),
      params.eDate = moment(this.eDate).format('YYYYMMDD')
      let url = "../api/adminReportIndex/downAllStoreReportExcel?sDate=" 
              + encodeURIComponent(params.sDate) 
              + "&eDate=" 
              + encodeURIComponent(params.eDate);
              
      window.open(url);
    }
  }
};
</script>

<style scoped>
.vert {
  height: 100%;
  display: flex;
  /* -webkit-align-items: center; */
}
.col-row {
  display: flex;
}
.selectit {
  background-color: yellowgreen;
}
.empty {
  background-color: white;
}
</style>
