<template>
  <div>
    <el-card shadow="always" style="margin:0 auto;margin-top:5px;">
      <el-row :gutter="20">
        <el-col :span="10">
          <div style="width:30vw; height:10vh; text-align:center;">
            <p style="margin:4vh 0vw 0vh 0vw; font-size:60px;">
              <!--上右下左 -->
              {{ this.title.average }}
            </p>
            <p style="margin-top:0vh; font-size:15px;">
              共&nbsp;&nbsp;&nbsp;{{ this.title.total }}&nbsp;&nbsp;&nbsp;人評論
            </p>
          </div>
        </el-col>
        <!--評分表-->
        <el-col :span="10">
          <div id="ratioPie" style="width:20vw; height:20vh;"></div>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input placeholder="客戶帳號" v-model="qClientUserId">
            <template slot="prepend">客戶帳號</template>
          </el-input>
        </el-col>
        <el-col :span="8">
          區域<el-select v-model="qAreaId">
            <el-option
              v-for="item in areas"
              :key="item.code"
              :label="item.disName"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          分店<el-select v-model="qStoreId" filterable>
            <el-option
              v-for="item in stores"
              :key="item.code"
              :label="item.disName"
              :value="item.code"
              v-show="item.areaId == qAreaId || qAreaId == ''"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top:10px;">
        <el-col :span="8">
          評價<el-select v-model="qRatio">
            <el-option key="1" label="1" value="1"></el-option>
            <el-option key="2" label="2" value="2"></el-option>
            <el-option key="3" label="3" value="3"></el-option>
            <el-option key="4" label="4" value="4"></el-option>
            <el-option key="5" label="5" value="5"></el-option>
          </el-select>
        </el-col>

        <el-col :span="16">
          評價日期區間
          <el-date-picker v-model="qSDate" type="date" placeholder="評價日期">
          </el-date-picker>
          &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;<el-date-picker
            v-model="qEDate"
            type="date"
            placeholder="評價日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row style="margin-top:10px;">
        <el-col :span="2"
          ><div class="grid-content bg-purple"></div>
          <el-button type="primary" @click="query()">查詢</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="always" style="margin:0 auto;margin-top:10px;">
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column prop="clientUserId" label="客戶帳號"></el-table-column>
        <el-table-column prop="disName" label="客戶姓名"></el-table-column>
        <el-table-column prop="storeDisName" label="店家"> </el-table-column>
        <el-table-column prop="ratio" label="星等"> </el-table-column>
        <el-table-column prop="content" label="客戶評論"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button
              @click="showDetailForm(scope.row)"
              type="primary"
              size="small"
              >編輯</el-button
            >
            <el-button @click="deleteRow(scope.row)" type="danger" size="small" v-show="false"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        style="margin-top:10px"
        :total="pageTotal"
        :current-page.sync="pageCurrent"
        @current-change="query"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="編輯視窗" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="客戶帳號" :label-width="formLabelWidth">
          {{form.clientUserId}}
          
        </el-form-item>
        <el-form-item label="訂單號" :label-width="formLabelWidth">
          {{form.clientOrderId}}
        </el-form-item>
        <el-form-item label="回饋日期" :label-width="formLabelWidth">
          {{form.createDate}}
        </el-form-item>
        <el-form-item label="星等" :label-width="formLabelWidth">
          {{form.ratio}}
          <el-select v-model="form.ratio" autocomplete="off" v-show="false">
            <el-option key="1" label="1" value="1"></el-option>
            <el-option key="2" label="2" value="2"></el-option>
            <el-option key="3" label="3" value="3"></el-option>
            <el-option key="4" label="4" value="4"></el-option>
            <el-option key="5" label="5" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="內容" :label-width="formLabelWidth">
          {{form.content}}
        </el-form-item>
        <el-form-item label="狀態" :label-width="formLabelWidth">
          <el-input v-model="form.flow" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="回應訊息" :label-width="formLabelWidth">
          <el-input v-model="form.responseMsg" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditStore">確 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import * as moment from "moment/moment";
import * as echarts from "echarts";
export default {
  data() {
    return {
      pageTotal: 0,
      pageCurrent: 1,
      labelPosition: "right",
      formLabelAlign: {
        disName: "234",
        region: "",
        type: ""
      },
      qClientUserId: "",
      qClientOrderId: "",
      qFlow: "",
      qSDate: "",
      qEDate: "",
      qStoreId: "",
      qRatio: "",
      qAreaId: '',
      tableData: [],
      ratio1L: [],
      ratio2L: [],
      ratio3L: [],
      ratio4L: [],
      ratio5L: [],
      dialogFormVisible: false,
      dialogAuthVisible: false,
      title: {
        average: "",
        total: "", // 總共
        ratio1: "", // 放置陣列長度當作數值
        ratio2: "",
        ratio3: "",
        ratio4: "",
        ratio5: ""
      },
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      },
      formLabelWidth: "120px",
      areas: [],
      stores: []
    };
  },
  created() {
    if (this.$route.query.clientOrderId != undefined) {
      this.qClientOrderId = this.$route.query.clientOrderId;
    }
    this.$axios
      .get("../api/params/getParams", {})
      .then(res => {
        this.$store.dispatch("login", res.data);
        if (res.data.areas.length >= 1) {
          this.areas.push({ code: "", disName: "全部" });
        }
        res.data.areas.forEach(e => {
          this.areas.push(e);
        });
        this.qAreaId = this.areas[0].code
        if (res.data.authStores.length >= 1) {
          this.stores.push({ code: "", disName: "全部" });
        }
        res.data.authStores.forEach(e => {
          this.stores.push(e);
        });
        this.qStoreId = this.stores[0].code
        this.query();
      })
      .catch(res => {
        if (res.response.status == 401) {
          window.location = "/";
        }
      });
  },
  name: "AdminUserFeedback",
  components: {},
  computed: {},
  methods: {
    query() {
      let _qSDate = moment(this.qSDate).isValid()
        ? moment(this.qSDate).format("YYYYMMDD")
        : "";

      let _qEDate = moment(this.qEDate).isValid()
        ? moment(this.qEDate).format("YYYYMMDD")
        : "";

      this.$axios
        .get("../api/adminClientFeedBack/getClientFeedBacks?", {
          params: {
            page: this.pageCurrent,
            clientUserId: this.qClientUserId,
            clientOrderId: this.qClientOrderId,
            storeId: this.qStoreId,
            sDate: _qSDate,
            eDate: _qEDate,
            ratio: this.qRatio,
            areaId: this.qAreaId
          }
        })
        .then(res => {
          this.tableData.splice(0, this.tableData.length);
          res.data.data.forEach(store => {
            this.tableData.push(store);
          });
          this.pageTotal = res.data.pageTotal;
          if ((this.qClientOrderId != "") & (this.tableData.length == 1)) {
            this.qClientOrderId = "";
            this.showDetailForm(this.tableData[0]);
          }
          this.title.total = res.data.charData.length;
          let ranking = 0
          this.ratio1L.splice(0, this.ratio1L.length); // 清空陣列
          this.ratio2L.splice(0, this.ratio2L.length);
          this.ratio3L.splice(0, this.ratio3L.length);
          this.ratio4L.splice(0, this.ratio4L.length);
          this.ratio5L.splice(0, this.ratio5L.length);
          for (let i = 0; i < res.data.charData.length; i++) {
            if (res.data.charData[i].ratio == "1") {
              this.ratio1L.push(1);
              ranking += 1
            }
            if (res.data.charData[i].ratio == "2") {
              this.ratio2L.push(2);
              ranking += 2
            }
            if (res.data.charData[i].ratio == "3") {
              this.ratio3L.push(3);
              ranking += 3
            }
            if (res.data.charData[i].ratio == "4") {
              this.ratio4L.push(4);
              ranking += 4
            }
            if (res.data.charData[i].ratio == "5") {
              this.ratio5L.push(5);
              ranking += 5
            }
          }
          this.title.average = (ranking /this.title.total).toFixed(1)
          this.title.ratio1 = this.ratio1L.length;
          this.title.ratio2 = this.ratio2L.length;
          this.title.ratio3 = this.ratio3L.length;
          this.title.ratio4 = this.ratio4L.length;
          this.title.ratio5 = this.ratio5L.length;
          this.barGrap();
        })
        .catch(res => {
          console.log(res.response.status);
          this.$alert("login error", '結果', { confirmButtonText: '確定' });
        });
    },
    confirmEditStore() {
      let url = "../api/adminClientFeedBack/updateClientFeedback";
      if (this.form.mode == "create") {
        url = "../api/adminClientFeedBack/createClientFeedback";
      }
      this.$confirm('是否確定編輯客戶回饋','注意',{
        confirmButtonText:'確定',
        cancelButtonText:'取消'
      }).then(() => {
        this.form.mode = undefined;
        this.$axios
          .post(url, this.form)
          .then(() => {
            this.query();
            this.dialogFormVisible = false;
          })
          .catch(res => {
            console.log(res.data);
          });
      })
    },
    showCreateDetailForm() {
      this.form = Object.assign({}, this.form, {
        rowId: "",
        disName: "",
        mode: "create"
      });
      this.dialogFormVisible = true;
    },
    showDetailForm(clientFeedBack) {
      this.$axios
        .get(
          "../api/adminClientFeedBack/getClientFeedback?rowId=" +
            clientFeedBack.rowId
        )
        .then(res => {
          this.dialogFormVisible = true;
          res.data.mode = "update";
          this.form = Object.assign({}, this.form, res.data);
          // res.data.forEach(store=>{this.tableData.push(store)})
          this.form.createDate = this.$date_(this.form.createDate)
        })
        .catch(res => {
          console.log(res.response.status);
          this.$alert("login error", '結果', { confirmButtonText: '確定' });
        });
    },
    deleteRow(index) {
      console.log(index);
    },
    //橫條圖
    barGrap() {
      var xdata = [
        this.title.ratio1,
        this.title.ratio2,
        this.title.ratio3,
        this.title.ratio4,
        this.title.ratio5
      ];
      var ydata = ["1", "2", "3", "4", "5"];
      var xdataL = 0;
      for (let i = 0; i < xdata.length; i++) {
        if (xdataL < xdata[i]) {
          xdataL = xdata[i];
        }
      }
      var myChart;

      myChart = echarts.dispose(document.getElementById("ratioPie")); // 先清除echart
      myChart = echarts.init(document.getElementById("ratioPie"));
      myChart.clear();
      myChart.resize({
        width: 550,
        height: 200
      });

      var option = {
        xAxis: {
          type: "value",
          data: xdata,
          max: xdataL * 2,
          axisLabel: { show: false }, // 數值關閉
          axisLine: { show: false }, // 底邊線
          splitLine: { show: false } //網格線
        },
        yAxis: {
          type: "category",
          data: ydata,
          max: 5,
          axisLine: { show: false }, // 底邊線
          axisTick: { show: false } // 刻度線
        },
        grid: {
          x: 20,
          y: -0
        },
        series: [
          {
            data: xdata,
            type: "bar",
            itemStyle: {
              borderRadius: 10, //圓
              //柱狀顏色
              color: function(d) {
                let colorList = [
                  "#F75000",
                  "#FFD306",
                  "#2894FF",
                  "#00CACA",
                  "#01B468"
                ];
                return colorList[d.dataIndex];
              }
            },
            label: {
              show: true, // 條狀數值開啟
              position: "right", //值在柱子上方显示
              color: "#000000" //颜色
            },
            barWidth: 15, //柱狀寬度
            showBackground: true, //底色
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)"
            }
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
};
</script>
